/**
 * API actions for diet-plan-options resource
 * ref: https://github.com/redux-utilities/flux-standard-action
 *      /state/middleware/request-middleware.js
 *      https://docs.google.com/spreadsheets/d/1MmffvNk7OJFhEYA2ZSUOVZ6OT_um1848_o-EpzCv1MA/edit?usp=sharing
 */
import {
	axios,
	getApiUrl,
	API_REQUEST,
	startRequest,
	completeRequest,
	NOK_RESPONSE_HINT,
	UNABLE_TO_CREATE_MENU_HINT,
	UPDATE_COMPLETE_HINT
} from './api-base.js';
import { saveDietPlanOptions } from '../diet-plan-options.js';
import { PubSub } from '../../services/pub-sub.js';
import { REQUEST_CANCELLED_EVENT } from '../../utilities/constants.js';

// REST resources
const DIET_PLAN_OPTIONS_RESOURCE = 'diet-plan-options';

/**
 * get diet plan options, including Heart Healthy status
 */
export function requestDietPlanOptions() {
	return {
		type: API_REQUEST,
		payload(dispatch, token) {
			const url = getApiUrl(DIET_PLAN_OPTIONS_RESOURCE);

			return axios.get(url, {
				headers: { Authorization: `Bearer ${token}` }
			})
				.then(response => {
					console.log(response.data);
					dispatch([
						saveDietPlanOptions(response.data)
					]);
				})
				.catch(error => {
					dispatch(completeRequest(NOK_RESPONSE_HINT));
					console.log(error);
				});
		}
	}
}

/**
 * put diet plan options, including Heart Healthy status
 */
export function requestUpdateDietPlanOptions(dto, isSilent = false) {
	const hint = isSilent? '' : UPDATE_COMPLETE_HINT;

	return {
		type: API_REQUEST,
		payload(dispatch, token) {
			dispatch(startRequest());

			const url = getApiUrl(DIET_PLAN_OPTIONS_RESOURCE);
			console.log(dto);
			return axios.put(url,
				dto,
				{
					headers: { Authorization: `Bearer ${token}` }
				})
				.then(response => {
					dispatch([
						saveDietPlanOptions(response.data),
						completeRequest(hint)
					]);
				})
				.catch(() => {
					PubSub.publish(REQUEST_CANCELLED_EVENT);
					dispatch(completeRequest(UNABLE_TO_CREATE_MENU_HINT));
				});
		}
	}
}