import { html } from 'lit-element';
import "@material/mwc-button";
import { DdCard2 } from './dd-card-2.js';
import { sharedStyles } from '../../../theme/shared-styles.js';
import style from './dd-plan-option-card.scss';
import { getChipTemplate } from '../../template-helpers.js';

export const OPTION_CLICK_EVENT = 'option-click';

/**
 * subscription plan option card
 */
class DdPlanOptionCard extends DdCard2 {
  static get properties() {
		return {
      option: { Object },
			promoted: { Boolean },
      disabled: { Boolean }
		};
	}

  static get styles() {
		return [
			sharedStyles,
      style
		];
	}

  render() {
    const promoteClass = this.promoted? 'promoted' : 'unpromoted';
    const fillClass = this.promoted? 'dark-filled' : '';

		return html`
    <div class="container ${promoteClass}" @click="${this.onClick_}">
      ${this.promoted
        ? html`
        <div class="centered-chip-container">
          ${getChipTemplate('most popular', 'dd-primary-dark-bg')}
        </div>`
        : ''
      }
      <h2>${this.option.title}</h2>
      <h1>$${this.option.amount}</h1>
      <p class="dd-caption">${this.option.term}</p>
      <div class="dd-host-centered">
        <mwc-button ?outlined="${this.promoted}" label="select" class="${fillClass}"></mwc-button>
      </div>
      <p class="dd-caption">${this.option.promotion}</p>
    </div>
    `;
  }

  onClick_() {
    if (!this.disabled) {
      this.fireGlobalEvent_(OPTION_CLICK_EVENT, { planId: this.option.id });
    }
  }
}

customElements.define('dd-plan-option-card', DdPlanOptionCard);