import { LitElement, html, css } from "lit-element";
import './dd-card/dd-menu-item-card.js';
import { sharedStyles } from '../../theme/shared-styles.js';

class DdMiniMenu extends LitElement {
  static get properties() {
		return {
			menuItems: { type: Array },
      clickable: { type: Boolean }
		};
	}

  static get styles() {
    return [
      sharedStyles,
      css`
        dd-menu-item-card {
          margin: 8px 0;
        }
      `
    ];
  }

  constructor() {
    super();
    this.menuItems = [];
    this.clickable = false;
  }

  render() {
    return this.menuItems.map((menuItem) => this.getMenuItemTemplate_(menuItem));
  }

  getMenuItemTemplate_(menuItem) {
    const cursorClass = this.clickable ? 'clickable' : '';
    return html`${menuItem.main
      ? html`
        <dd-menu-item-card class="${cursorClass}"
          .clickable=${this.clickable}
          .dayMenu=${menuItem}></dd-menu-item-card>
      `
      : ''
    }`;
  }
}

customElements.define('dd-mini-menu', DdMiniMenu);