import {
	SET_TOKEN
} from '../actions/token.js';
import { PubSub } from '../services/pub-sub.js';

export const TOKEN_SET_EVENT = 'token-set';

const INITIAL_STATE = {
	auth: '',
	refresh: ''
};

export function token(state = INITIAL_STATE, action) {
	switch (action.type) {
		case SET_TOKEN:
			PubSub.publish(TOKEN_SET_EVENT);
			return {
				...state,
				auth: action.payload.authToken,
				refresh: action.payload.refreshToken
			};
		default:
			return state;
	}
}
