import { html } from 'lit-element';
import { CARD_BODY_ID } from '../../dd-card/dd-card.js';
import { DdActivatableCard2 } from './dd-activatable-card-2.js';
import './dd-shop-item-expander.js';
import { getSmallIconTemplate } from '../../template-helpers.js';
import { SALES_ICON, SALES_FG } from '../../../theme/color-classes.js';
import { sharedStyles } from '../../../theme/shared-styles.js';
import style from './dd-shop-item-card-2.scss';
import '@material/mwc-button';
import '@material/mwc-checkbox';
import '@material/mwc-icon';
import '@material/mwc-icon-button';
import '@material/mwc-textfield';

import '../../dd-item-editor.js';
import { DISH_TYPE_UNKNOWN } from '../../../utilities/constants.js';
import { toFullFormattedAmount } from '../../../utilities/text-format.js';
import { DayMenuColorProvider } from '../../../services/day-menu-color-provider.js';
import { RecipeShopItemMapProvider } from '../../../services/recipe-shop-item-map-provider.js';
import { EVENTS } from '../../events.js';

/**
 * shopping list item card
 */
function getRecipeNameTemplates(recipeNames) {
  return recipeNames.map(recipeName => html`<li class="dd-body1">${recipeName}</li>`);
}
class DdShopItemCard2 extends DdActivatableCard2 {
  static get properties() {
    return {
      shoppingListItem: { type: Object },
    };
  }

  static get styles() {
    return [
      sharedStyles,
      style
    ];
  }

  constructor() {
    super();

    this.shoppingListItem = {
      name: '',
      isChecked: false,
      formattedAmount: '',
      brand: '',
      notes: '',
      dishType: DISH_TYPE_UNKNOWN
    };
  }

  render() {
    const formattedAmount = toFullFormattedAmount(this.shoppingListItem);

    return html`
      <div id="${CARD_BODY_ID}" class="parentDiv">
        <dd-shop-item-expander
          ?clickableTitle=${false}
          @pre-open="${this.onExpanderOpen_}">
          <div class="dd-flex-container ${this.isOpen ? "opened" : ""}" slot="title">
            <div class="dd-body2 dd-row-container">
              ${this.getSelectorTemplate_()}
              <div>
                <div id="button-label" class="dd-flex-start-container">
                  <div class="dd-body2 ${this.getTitleClass_()}">${this.getTitle_()}</div>
                </div>
              </div>
            </div>
            ${ !this.isOpen ? html`
            <div id="color-key-container">
              <span>${this.getColorKeyTemplate_()}<span id="amount">${formattedAmount}</span></span>
            </div>`: html`
            <div id="amount-container">
              <mwc-textfield id="amount-field" .value="${this.shoppingListItem.formattedAmount}"></mwc-textfield>
            </div>
            `}
          </div>

          ${this.shoppingListItem.notes ?
            html`
            <div class="dd-flex-start-container" slot="notes">
              <span >${this.shoppingListItem.notes}</span>
            </div>` : ''}


          ${this.getSaleInformation_()}

          <div class="opened" slot="content">
            <mwc-textfield id="notes-field" .value="${this.shoppingListItem.notes}" placeholder="Add Note"></mwc-textfield>
            <div class="dd-flex-end-container">
              <mwc-button id="delete-button" @click="${this.onDeleteClick_}">
                Remove
                <mwc-icon slot="icon">delete</mwc-icon>
              </mwc-button>
              <mwc-button id="submit-button" @click="${this.submitClick_}">
                Done
                <mwc-icon slot="icon">check_circle</mwc-icon>
              </mwc-button>
            </div>
            ${this.getRecipeNameSection_()}
            <div class="dd-card-bottom-border"></div>
          </div>
        </dd-shop-item-expander>
      </div>
		`;
  }

  // cancelClick_() {
  //   // const editor = this.shadowRoot.querySelector("#item-editor");
  //   // editor.resetTextValues(this.shoppingListItem);
  //   // this.cancel = true;
  //   this.shadowRoot.querySelector('#notes-field').value = this.shoppingListItem.notes;
  //   this.shadowRoot.querySelector("dd-shop-item-expander").close();
  // }


  close() {
    this.isOpen = false;
    this.shadowRoot.querySelector("dd-shop-item-expander").close();
    this.shadowRoot.querySelector('#notes-field').value = this.shoppingListItem.notes;
  }

  validateChange_(formattedAmount, notes) {
    return (formattedAmount !== this.shoppingListItem.formattedAmount) || (notes !== this.shoppingListItem.notes);
  }

  submitClick_() {
    const formattedAmount = this.shadowRoot.querySelector('#amount-field').value;
    const notes = this.shadowRoot.querySelector('#notes-field').value;

    if (this.validateChange_(formattedAmount, notes)) {
      this.fireLocalEvent_(EVENTS.UPDATE, {
        name: this.shoppingListItem.name,
        formattedAmount: this.shadowRoot.querySelector('#amount-field').value,
        notes: this.shadowRoot.querySelector('#notes-field').value,
        brand: this.shoppingListItem.brand,
        shoppingListItemId: this.shoppingListItem.id });
    }

    this.close();
  }

  getSelectorTemplate_() {
    const disabled = this.shoppingListItem.isFulfilled;
    const checked = this.shoppingListItem.isChecked || this.shoppingListItem.isFulfilled;

    return html`
      <mwc-checkbox
        .checked="${checked}"
        .disabled="${disabled}"
        @change="${this.onCheckedChange_}"></mwc-checkbox>
    `;
  }

  getColorKeyTemplate_() {
    const recipeIds = RecipeShopItemMapProvider.getRecipeIds(this.shoppingListItem.id);
    const colorClasses = DayMenuColorProvider.getColorClasses(recipeIds);

    return colorClasses.map((colorClass) => html`
      <span class="item-decorator ${colorClass}"></span>
    `);
  }

  getNoteIndicator_() {
    return this.shoppingListItem.notes? html`<mwc-icon class="dd-secondary-dark-fg dd-small-icon dd-margin-left" title="${this.shoppingListItem.notes}">sticky_note_2</mwc-icon>` : '';
  }

  updateActive_() {
    const isUserAdded = this.shoppingListItem.dishType === DISH_TYPE_UNKNOWN;
    this.isActive = RecipeShopItemMapProvider.isItemActive(this.shoppingListItem.id) || isUserAdded;
  }

  getTitle_() {
    const title = this.shoppingListItem && this.shoppingListItem.name ? this.shoppingListItem.name : 'No item name';
    return title;
  }

  getTitleClass_() {
    let titleClass = '';
    if (this.shoppingListItem.isFulfilled) {
      titleClass = 'disabled-text';
    } else if (this.shoppingListItem.isChecked) {
      titleClass = 'strike-through';
    }

    return titleClass;
  }

  getSaleInformation_() {
    // const brand = isMaximized || this._largeScreen? this.shoppingListItem.brand : this.getTruncatedBrand_();
    const brand = this.shoppingListItem.brand;
    if (this.shoppingListItem.isOnSale) {
      return html`
        <div class="dd-flex-start-container" slot="sales">
          ${getSmallIconTemplate('', SALES_FG, SALES_ICON)}
          <span class="dd-caption ">${brand}</span>
        </div>
        `;
    }

    return brand  ? html`
      <div class="dd-flex-start-container" slot="sales">
        <span class="dd-caption ">${brand}</span>
      </div>
      ` : '';
  }

  // getTruncatedBrand_() {
  //   if (this.shoppingListItem.brand && (this.shoppingListItem.brand.length > MAX_BRAND_LENGTH + ELLIPSIS_LENGTH)) {
  //     return `${this.shoppingListItem.brand.slice(0, MAX_BRAND_LENGTH)}${ELLIPSIS}`;
  //   }

  //   return this.shoppingListItem.brand;
  // }

  getRecipeNameSection_() {
    const recipeNames = RecipeShopItemMapProvider.getRecipeNames(this.shoppingListItem.id);

    return recipeNames.length > 0 ? html`
      <div class="dd-shop-item-content-container">
        <p class="dd-overline">used in</p>
        <ul class="dd-card-list">
          ${getRecipeNameTemplates(recipeNames)}
        </ul>
      </div>
    `
    : '';
  }

  fireDeleteEvent_() {
    const detail = {
      shopItemId: this.shoppingListItem.id
    };
    this.fireGlobalEvent_(EVENTS.REMOVE_SHOP_ITEM, detail);
  }


	onExpanderOpen_() {
		if (this.shoppingListItem.id) {
			this.isOpen = true;
			this.onMaximize_(this.shoppingListItem.id);
		}
	}

  onCheckedChange_(event) {
    const checkedBox = event.target;
    const detail = {
      isChecked: checkedBox.checked,
      shopItemId: this.shoppingListItem.id
    };
    this.fireGlobalEvent_(EVENTS.CHECKED_SHOP_ITEM, detail);
    this.close();
  }

  onDeleteClick_() {
    this.fireDeleteEvent_();
    this.close();
  }

  // onItemEditorSubmit_(event) {
  //   const detail = {
  //     ...event.detail,
  //     shoppingListItemId: this.shoppingListItem.id
  //   };
  //   this.fireLocalEvent_(EVENTS.UPDATE, detail);
  // }
}

customElements.define('dd-shop-item-card-2', DdShopItemCard2);