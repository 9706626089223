import { SET_SUBSCRIPTION } from '../actions/subscription.js';

const INITIAL_STATE = {
};

export function subscription(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_SUBSCRIPTION:
      return {
        ...state,
        ...action.payload.subscription
      };

    default:
      return state;
  }
}
