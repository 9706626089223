export function getStatus(shoppingCategory) {
  let totalUnchecked = 0;

  shoppingCategory.forEach(sli => {
    if (!sli.isChecked && !sli.isFulfilled) {
      totalUnchecked += 1;
    }
  });

  return totalUnchecked;
}