/**
 * token handling
 * ref: https://github.com/redux-utilities/flux-standard-action
 *      /state/middleware/request-middleware.js
 */

export const SET_TOKEN = 'SET_TOKEN';

export function setToken(authToken, refreshToken) {
	return {
		type: SET_TOKEN,
		payload: {
			authToken,
			refreshToken
		}
	};
}
