function toCamel(s) {
  return s.replace(/([-_][a-z])/ig, ($1) => $1.toUpperCase()
    .replace('-', '')
    .replace('_', '')
  );
}


/**
 * Object empty check
 * @param {} obj
 * @return bool true = object has no fields
 */
export function isEmpty(obj) {
  const hasProperty = Object.keys(obj).some(key => obj[key]);

  return !hasProperty;
}

// deprecated: use 'import isEqual from 'lodash-es/isEqual.js';'
export function deepEqual(object1, object2) {
  return JSON.stringify(object1) === JSON.stringify(object2);
}

/**
*
* @param {*} value1
* @param {*} value2
*/
export function isNumericallyEqual(value1, value2) {
  return ((value1 * 1) === (value2 * 1));
}

export function cloneArray(array1) {
  return array1.slice(0);
}

export function isArray(a) {
  return Array.isArray(a);
};

function isObject(o) {
  return o === Object(o) && !isArray(o) && typeof o !== 'function';
}

export function toCamelCase(object1) {
  if (isObject(object1)) {
    const newObject = {};

    Object.keys(object1)
      .forEach((k) => {
        newObject[toCamel(k)] = toCamelCase(object1[k]);
      });

    return newObject;
  }

  if (isArray(object1)) {
    return object1.map(i => toCamelCase(i));
  }

  return object1;
}
