import NoSleep from 'nosleep.js';

/**
 * progressive wake lock service uses NoSleep or Wake Lock API, if available
 */
export const WakeLock = {
  _noSleep: null,

  async enable() {
    let wakeLock = null;

    if (!this._noSleep && !wakeLock) {
      // request wakeLock if available
      if ('wakeLock' in navigator && 'request' in navigator.wakeLock) {
        const requestWakeLock = async () => {
          try {
            wakeLock = await navigator.wakeLock.request('screen');
            wakeLock.addEventListener('release', () => {
              wakeLock = null;
            });
            console.log('Screen Wake Lock is active');
          } catch (err) {
            console.error(`${err.name}, ${err.message}`);
          }
        };

        await requestWakeLock();
      } else {
        this._noSleep = new NoSleep();
        this._noSleep.enable();
      }
    }
  },

  disable() {
    if (this._noSleep) {
      this._noSleep.disable();
      this._noSleep = null;
    }
  }
}
