export const Stores = [
  {
    internalName: "Market Basket",
    states: ["ME", "MA", "NH", "RI"]
  },
  {
    internalName: "Stop & Shop",
    states: ["CT", "MA", "NH", "NJ", "NY", "RI"]
  },
  {
    internalName: "Hannaford",
    states: ["ME", "MA", "NH", "NY", "VT"]
  },
  {
    internalName: "Shaws and Star Market",
    alias: "Shaw's and Star Market",
    states: ["ME", "MA", "NH", "RI", "VT"]
  },
  {
    internalName: "Roche Bros",
    states: ["MA"]
  },
  {
    internalName: "AnyStore",
    alias: "Any Store",
    states: ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "DC", "FL", "GA", "HI", "ID", "IL", "IN",
      "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV",
      "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN",
      "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"]
  },
  {
    internalName: "Whole Foods",
    states: ["AL", "AZ", "AR", "CA", "CO", "CT", "DE", "DC", "FL", "GA", "HI", "ID", "IL", "IN", "IA",
      "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "NE", "NV", "NH", "NJ", "NM", "NY",
      "NC", "OH", "OK", "OR", "PA", "RI", "SC", "TN", "TX", "UT", "VA", "WA", "WI"]
  },
  {
    internalName: "Kroger Central",
    alias: "Kroger",
    states: ["IL", "IN"]
  },
  {
    internalName: "Marsh",
    states: []
  },
  {
    internalName: "Kroger Ohio Cincinnati-Miami Valley",
    alias: "Kroger - Cincinnati-Miami Valley",
    states: ["OH"]
  },
  {
    internalName: "Big Y",
    states: ["CT", "MA"]
  },
  {
    internalName: "Safeway CA",
    alias: "Safeway",
    states: ["CA"]
  },
  {
    internalName: "Vons",
    states: ["CA"]
  },
  {
    internalName: "Food Lion",
    states: ["DE", "GA", "KY", "MD", "NC", "PA", "SC", "TN", "VA", "WV"]
  },
  {
    internalName: "Kroger Mid-Atlantic",
    alias: "Kroger",
    states: ["VA", "WV"]
  },
  {
    internalName: "Publix Thurs-Wed",
    alias: "Publix – Sales start Thur",
    states: ["FL", "GA", "NC", "SC", "TN"]
  },
  {
    internalName: "Winn Dixie",
    states: ["AL", "FL", "GA", "LA", "MS"]
  },
  {
    internalName: "Aldi Sun-Sat",
    alias: "Aldi – Sales start Sun",
    states: ["AK", "CT", "GA", "KY", "MA", "MI", "MN", "MO", "NH", "NJ", "NY", "OH", "PA", "RI", "SD", "VT"]
  },
  {
    internalName: "Aldi Wed-Tues",
    alias: "Aldi – Sales start Wed",
    states: ["AL", "AK", "DC", "FL", "GA", "IL", "IN", "IA", "KS", "KY",
	    "MD", "MS", "MI", "NE", "NC", "OH", "PA", "SC", "TN", "VA", "WI", "WV"]
  },
  {
    internalName: "Aldi TX and OK",
    alias: "Aldi",
    states: ["OK", "TX"]
  },
  {
    internalName: "Giant PA",
    alias: "Giant",
    states: ["PA"]
  },
  {
    internalName: "Giant",
    states: ["DE", "DC", "MD", "VA"]
  },
  {
    internalName: "Martins",
    states: []
  },
  {
    internalName: "Price Chopper",
    states: ["CT", "MA", "NH", "NY", "PA", "VT"]
  },
  {
    internalName: "Frys",
    alias: "Fry's Food",
    states: ["AZ"]
  },
  {
    internalName: "Kroger Texas - Dallas",
    alias: "Kroger - Dallas region",
    states: ["TX"]
  },
  {
    internalName: "Smiths",
    alias: "Smith's Food and Drug",
    states: ["AZ", "ID", "MT", "NM", "NV", "UT", "WY"]
  },
  {
    internalName: "Price Chopper MO and KS",
    alias: "Price Chopper",
    states: []
  },
  {
    internalName: "King Soopers",
    states: ["CO", "WY"]
  },
  {
    internalName: "Fred Meyer",
    states:["AK", "AL", "ID", "OR", "WA"]
  },
  {
    internalName: "Giant Eagle",
    states: ["MD", "OH", "PA", "WV"]
  },
  {
    internalName: "Cub Foods",
    states: ["IL", "MN"]
  },
  {
    internalName: "Acme",
    alias: "Acme Markets",
    states: []
  },
  {
    internalName: "HEB",
    states: ["TX"]
  },
  {
    internalName: "Hy-Vee",
    states: ["IA", "IL", "MN", "MO", "NE", "SD", "WI"]
  },
  {
    internalName: "Kroger Michigan",
    alias: "Kroger",
    states: ["MI"]
  },
  {
    internalName: "Harris Teeter",
    states: ["DE", "DC", "FL", "GA", "MD", "NC", "SC", "VA"]
  },
  {
    internalName: "Ralphs",
    states: ["CA"]
  },
  {
    internalName: "Kroger GA, AL and SC",
    alias: "Kroger",
    states: ["AL", "GA", "SC"]
  },
  {
    internalName: "Kroger TN",
    alias: "Kroger",
    states: []
  },
  {
    internalName: "ShopRite Sun-Sat",
    alias: "ShopRite",
    states: ["CT", "DE", "MD", "NJ", "NY", "PA"]
  },
  {
    internalName: "Kroger Ohio - Central",
    alias: "Kroger - Columbus/Central region",
    states: ["OH"]
  },
  {
    internalName: "Meijer",
    states: ["IL", "IN", "KY", "MI", "OH", "WI"]
  },
  {
    internalName: "Meijer Sun-Sat",
    states: []
  },
  {
    internalName: "Kroger Texas - Houston",
    alias: "Kroger - Houston region",
    states: ["TX"]
  },
  {
    internalName: "Fresh Thyme",
    states: ["IL", "IN", "IA", "KY", "MI", "MN", "MO", "OH", "WI"]
  },
  {
    internalName: "ShopRite Fri-Thurs",
    states: []
  },
  {
    internalName: "Safeway CO",
    alias: "Safeway",
    states: ["CO"]
  },
  {
    internalName: "Tops Markets",
    states: []
  },
  {
    internalName: "Safeway and Albertsons OR",
    alias: "Safeway and Albertsons",
    states: ["OR"]
  },
  {
    internalName: "Publix Wed-Tues",
    alias: "Publix – Sales start Wed",
    states: ["AL", "GA", "NC", "SC", "TN", "VA"]
  },
  {
    internalName: "Safeway and Albertsons WA",
    alias: "Safeway and Albertsons",
    states: ["WA"]
  },
  {
    internalName: "Albertsons CA",
    alias: "Albertsons",
    states: ["CA"]
  },
  {
    internalName: "Pick N Save",
    states: ["WI"]
  },
  {
    internalName: "Jewel Osco",
    states: ["IL", "IN"]
  },
  {
    internalName: "County Market",
    states: []
  },
  {
    internalName: "Save Mart CA",
    alias: "Save Mart",
    states: []
  },
  {
    internalName: "Aldi CA",
    alias: "Aldi",
    states: ["CA"]
  },
  {
    internalName: "Redners",
    states: []
  },
  {
    internalName: "Stater Bros",
    states: ["CA"]
  },
  {
    internalName: "Lucky CA",
    alias: "Lucky",
    states: []
  },
  {
    internalName: "Bi-Lo",
    states: ["GA", "NC", "SC"]
  },
  {
    internalName: "Wegmans",
    states: ["MD", "MA", "NJ", "NY", "NC", "PA", "VA"]
  },
  {
    internalName: "Walmart",
    states: ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "DC", "FL", "GA", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV",
      "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI"],
  },
  {
    internalName: "Sprouts CO",
    alias: "Sprouts Farmers Market",
    states: []
  },
  {
    internalName: "Kroger KY",
    alias: "Kroger",
    states: []
  },
  {
    internalName: "Sprouts AZ",
    alias: "Sprouts Farmers Market",
    states: []
  },
  {
    internalName: "Peapod"
  },
  {
    internalName: "Lunds & Byerlys",
    states: []
  },
  {
    internalName: "Kroger MS",
    alias: "Kroger",
    states: []
  },
  {
    internalName: "Safeway and Albertsons AZ",
    alias: "Safeway and Albertsons",
    states: ["AZ"]
  },
  {
    internalName: "Sprouts CA",
    alias: "Sprouts Farmers Market",
    states: []
  },
  {
    internalName: "Dierbergs",
    states: ["MO"]
  },
  {
    internalName: "Schnucks",
    states: ["MO"]
  }
];
