/**
 * subscription plan option details
 * ref: AppBundle\Services\UserManagement\SubscriptionAdapterInterface
 */

// internal plan IDs
export const PLAN_1 = 'plan-1';
export const PLAN_2 = 'plan-2';

export const SubscriptionPlans = {
  [PLAN_1]: {
    id: PLAN_1,
    title: '3 Months',
    amount: '18',
    term: 'Billed every 3 months',
    promotion: ''
  },
  [PLAN_2]: {
    id: PLAN_2,
    title: 'Annual',
    amount: '48',
    term: 'Billed once a year',
    promotion: 'Less than $1 per week'
  }
};
Object.freeze(SubscriptionPlans);
