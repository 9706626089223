import cloneDeep from 'lodash-es/cloneDeep.js';
import isEqual from 'lodash-es/isEqual.js';
import { PubSub } from './pub-sub.js';

export const FAVORITES_SET_EVENT = 'favorites-set';

/**
 * provider service wrapper around favorite recipe options substate
 */
export const FavoritesProvider = {
  _options: {},

  setOptions (options) {
    if (!isEqual(options, this._options)) {
      this._options = options;
      PubSub.publish(FAVORITES_SET_EVENT);
    }
  },

  getMains () {
    const mains = cloneDeep(this._options.mains);
    mains.sort((a, b) => (a.name > b.name)? 1 : -1);

    return mains;
  },

  getSides () {
    const sides = cloneDeep(this._options.sides);
    sides.sort((a, b) => (a.name > b.name)? 1 : -1);

    return sides;
  }
};
