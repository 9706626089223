import isEqual from 'lodash-es/isEqual.js';
import { PubSub } from './pub-sub.js';

export const PERSONAL_RECIPES_SET_EVENT = 'personal-recipes-set';

/**
 * provider service wrapper around personal recipes substate
 */
export const PersonalRecipesProvider = {
  _personalRecipes: {
    mains: [],
    sides: []
  },

  setPersonalRecipes (personalRecipes) {
    if (!isEqual(personalRecipes, this._personalRecipes)) {
      this._personalRecipes = personalRecipes;
      PubSub.publish(PERSONAL_RECIPES_SET_EVENT);
    }
  },

  getMains () {
    return this._personalRecipes.mains;
  },

  getSides () {
    return this._personalRecipes.sides;
  }
};
