/**
* API actions for the store endpoint
* ref: https://github.com/redux-utilities/flux-standard-action
*/
import {
	axios,
	getApiUrl,
	API_REQUEST,
	startRequest,
	completeRequest,
	UNABLE_TO_CREATE_MENU_HINT,
	UPDATE_COMPLETE_HINT
} from './api-base.js';
import { PubSub } from '../../services/pub-sub.js';
import { REQUEST_CANCELLED_EVENT } from '../../utilities/constants.js';

// REST resources
const STORE_RESOURCE = 'store';

/**
 * change store
 * @param string storeName
 */
export function requestChangeStore(storeName) {
	return {
		type: API_REQUEST,
		payload (dispatch, token) {
			dispatch(startRequest());
			const url = getApiUrl(STORE_RESOURCE);

			return axios.put(url,
				{ store: storeName },
				{
					headers: { Authorization: `Bearer ${token}`
				}
			})
				.then(() => {
					dispatch(completeRequest(	UPDATE_COMPLETE_HINT));
				})
				.catch(() => {
					PubSub.publish(REQUEST_CANCELLED_EVENT);
					dispatch(completeRequest(UNABLE_TO_CREATE_MENU_HINT));
				});
		}
	}
}
