/**
 * helpers for single app FAB customized per child component context
 */

 const FAB_CONTEXT_CHANGE_EVENT = 'fab-context-change';

// center FAB 1 rem from bottom
export const FAB_Y_OFFSET = 16;
export const FAB_HEIGHT = 48;
export const FAB_WIDTH = 150;

/**
 * mobile: because inner viewport can be larger than outer viewport, dynamically locate FAB relative to smaller of
 * inner or outer viewport
 * desktop: place FAB element in host element
 * @param {*} fabElement
 * @param {*} hostElement
 * @param int offsetWidth
 * @param int offsetHeight
 * @returns int
 */
export function floatElement(fabElement, hostElement, offsetWidth = 0, offsetHeight = 0) {
  let spacerHeight = 0;

  if (hostElement) {
    fabElement.style.position = '';
    fabElement.style.left = '';
    fabElement.style.top = '';
    hostElement.appendChild(fabElement);
  } else {
    const smallestWidth = window.outerWidth > window.innerWidth? window.innerWidth : window.outerWidth;
    const smallestHeight = window.outerHeight > window.innerHeight? window.innerHeight : window.outerHeight;

    const elementWidth = offsetWidth || fabElement.offsetWidth;
    const elementHeight = offsetHeight || fabElement.offsetHeight;
    spacerHeight = elementHeight + FAB_Y_OFFSET;
    const xLocation = smallestWidth/2 - elementWidth/2;
    const yLocation = smallestHeight - (spacerHeight);
    console.log(`elementWidth: ${elementWidth}, elementHeight: ${elementHeight}, xLocation: ${xLocation}, yLocation: ${yLocation}`);

    fabElement.style.position = `fixed`;
    fabElement.style.left = `${xLocation}px`;
    fabElement.style.top = `${yLocation}px`;

    console.log(`outerWidth: ${window.outerWidth}, innerWidth: ${window.innerWidth}, screen width: ${window.screen.width}, outerHeight: ${window.outerHeight}, innerHeight: ${window.innerHeight}, screen height: ${window.screen.height}, orientation: ${window.orientation}`);
  }

  return spacerHeight;
}

export function locateFAB(fabElement, hostElement) {
  floatElement(fabElement, hostElement, FAB_WIDTH, FAB_HEIGHT);
}

/**
 * on minimize, call this to hide FAB before notifying parent, which will then show FAB
 * on maximize, notify parent, which will hide FAB, before calling this to show FAB
 * @param {*} caller originator component
 * @param {*} iconName FAB button icon
 * @param {*} fabText FAB button text
 * @param Boolean show
 * @param {*} callback FAB onClick callback
 */
export function fireFabContextChangeEvent(caller, iconName, fabText, show, callback = () => {}) {
		// event to bubble out of parent's shadow DOM
		const globalEvent = new CustomEvent(FAB_CONTEXT_CHANGE_EVENT, {
			bubbles: true,
			composed: true,
			detail: {
				iconName,
        fabText,
        show,
        callback
			}
		});
		caller.dispatchEvent(globalEvent);
}
