/**
 * API actions for shop-list-items resource
 * ref: https://github.com/redux-utilities/flux-standard-action
 *      /state/middleware/request-middleware.js
 *      https://docs.google.com/spreadsheets/d/1MmffvNk7OJFhEYA2ZSUOVZ6OT_um1848_o-EpzCv1MA/edit?usp=sharing
 */
import {
	axios,
	getApiUrl,
	getParameters,
	API_REQUEST,
	startRequest,
	completeRequest,
	NOK_RESPONSE_HINT
} from './api-base.js';
import { saveShoppingList } from '../shopping-list.js';

// REST resources
function getItemEndpoint(shopItemId) {
	return `shopping-list-items/${shopItemId}`;
}

function getItemsEndpoint(shoppingListId) {
	return `shopping-list/${shoppingListId}/shopping-list-items`;
}

function getCheckedEndpoint(shopItemId) {
	return `${getItemEndpoint(shopItemId)}/checked`;
}

export function requestCheckShopItem(shopItemId) {
	return {
		type: API_REQUEST,
		payload(dispatch, token) {
			dispatch(startRequest());

			const checkedEndpoint = getCheckedEndpoint(shopItemId);
			const url = getApiUrl(checkedEndpoint);

			return axios.post(url,
				[],
				{
					headers: { Authorization: `Bearer ${token}` }
				})
				.then(() => {
					dispatch(completeRequest());
				})
				.catch(error => {
					// (optional): uncheck SLI using returned DTI
					dispatch(completeRequest(NOK_RESPONSE_HINT));
					console.log(error);
				});
		}
	}
}

export function requestUncheckShopItem(shopItemId) {
	return {
		type: API_REQUEST,
		payload(dispatch, token) {
			dispatch(startRequest());

			const checkedEndpoint = getCheckedEndpoint(shopItemId);
			const url = getApiUrl(checkedEndpoint);

			return axios.delete(url, {
				headers: { Authorization: `Bearer ${token}` }
			})
				.then(() => {
					dispatch(completeRequest());
				})
				.catch(error => {
					// (optional): uncheck SLI using returned DTI
					dispatch(completeRequest(NOK_RESPONSE_HINT));
					console.log(error);
				});
		}
	}
}

/**
 * ref: Symfony \AppBundle\Tests\Controller\Rest\ShoppingListItemsControllerFunctionalTest::requestAddItem()
 * @param object detail
 */
export function requestAddShopItem(previousWeek, detail) {
	return {
		type: API_REQUEST,
		payload(dispatch, token) {
			dispatch(startRequest());

			const itemsEndpoint = getItemsEndpoint(detail.shoppingListId);
			const url = getApiUrl(itemsEndpoint);

			return axios.post(url,
				{ ...detail },
				{
					params: getParameters(previousWeek),
					headers: { Authorization: `Bearer ${token}` }
				})
				.then(response => {
					dispatch([
						saveShoppingList(response.data),
						completeRequest()
					]);
				})
				.catch(error => {
					dispatch(completeRequest(NOK_RESPONSE_HINT));
					console.log(error);
				});
		}
	}
}

export function requestUpdateShopItem(previousWeek, detail) {
	return {
		type: API_REQUEST,
		payload(dispatch, token) {
			dispatch(startRequest());

			const itemEndpoint = getItemEndpoint(detail.shoppingListItemId);
			const url = getApiUrl(itemEndpoint);

			return axios.put(url,
				{ ...detail },
				{
					params: getParameters(previousWeek),
					headers: { Authorization: `Bearer ${token}` }
				})
				.then(response => {
					dispatch([
						saveShoppingList(response.data),
						completeRequest()
					]);
				})
				.catch(error => {
					dispatch(completeRequest(NOK_RESPONSE_HINT));
					console.log(error);
				});
		}
	}
}

export function requestRemoveShopItem(previousWeek, shoppingListItemId) {
	return {
		type: API_REQUEST,
		payload(dispatch, token) {
			dispatch(startRequest());

			const itemEndpoint = getItemEndpoint(shoppingListItemId);
			const url = getApiUrl(itemEndpoint);

			return axios.delete(url, {
				params: getParameters(previousWeek),
				headers: { Authorization: `Bearer ${token}` }
			})
				.then(response => {
					dispatch([
						saveShoppingList(response.data),
						completeRequest()
					]);
				})
				.catch(error => {
					dispatch(completeRequest(NOK_RESPONSE_HINT));
					console.log(error);
				});
		}
	}
}
