/**
 * API action for features resource
 * ref: https://github.com/redux-utilities/flux-standard-action
 *      /state/middleware/request-middleware.js
 */
import { axios, getApiUrl, API_REQUEST, startRequest, completeRequest } from './api-base.js';
import { saveFeatures } from '../features.js';

// REST resources
const FEATURES_RESOURCE = 'features';

/**
 * returns action to monitor with requestMiddleware: { payload: async function }
 */
export function requestFeatures() {
	return {
		type: API_REQUEST,
		payload (dispatch, token) {
			dispatch(startRequest());

			const menuUrl = getApiUrl(FEATURES_RESOURCE);

			return axios.get(menuUrl, {
				headers: { Authorization: `Bearer ${token}` }
			})
				.then(response => {
					dispatch([
						saveFeatures(response.data),
						completeRequest()
					]);
				})
				.catch(error => {
					console.log(error);
				});
		}
	}
}
