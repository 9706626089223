/**
 * publishes an event on device orientation change
 */

import { PubSub } from './pub-sub.js';

/* ref: theme/theme-variables.$desktop-breakpoint */
export const DESKTOP_BREAKPOINT = 1248;
export const MINIMUM_LANDSCAPE_WIDTH = 300;
export const ORIENTATION_CHANGE_EVENT = 'orientation-change';
export const NOT_MAXIMIZED_EVENT = 'window-not-maximized';
export const Orientation = {
  portrait: 'portrait',
  landscape: 'landscape'
}
Object.freeze(Orientation);

/**
 * publishes ORIENTATION_CHANGE_EVENT event with data = [ Orientation.portrait|Orientation.landscape ]
 */
export const OrientationPublisher = {
  windowObj: null, // DI window object
  oldWindowWidth: 0,
  orientation: '',

/**
 *
 * @param {*} w window object
 */
  initializeOrientation(w) {
    OrientationPublisher.windowObj = w;
    OrientationPublisher.oldWindowWidth = w.outerWidth;
    OrientationPublisher.windowObj.addEventListener('resize', handleOrientation, true);

    if ((w.screen.availWidth >= DESKTOP_BREAKPOINT) && (w.outerWidth < w.screen.availWidth)) {
      PubSub.publish(NOT_MAXIMIZED_EVENT);
    }
  }
};

/* eslint no-use-before-define: "off" */
function handleOrientation() {
  const newWindowWidth = OrientationPublisher.windowObj.outerWidth;

  let orientation = Orientation.portrait;

  if ((newWindowWidth > OrientationPublisher.oldWindowWidth) && (newWindowWidth > MINIMUM_LANDSCAPE_WIDTH)) {
    orientation = Orientation.landscape;
  }

  OrientationPublisher.orientation = orientation;
  OrientationPublisher.oldWindowWidth = newWindowWidth;
  PubSub.publish(ORIENTATION_CHANGE_EVENT, orientation);
}
