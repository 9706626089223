import { html } from 'lit-element';
import '@material/mwc-formfield';
import { DdSelectOption } from './dd-select-option.js';
import { sharedStyles } from '../theme/shared-styles.js';
import style from './dd-meal-select-option.scss';

import { FeatureProvider } from '../services/feature-provider.js';
import { FEATURES} from '../utilities/constants.js';

/**
 * select option with formatting for main and side dishes
 */
class DdMealSelectOption extends DdSelectOption {
	static get styles() {
		return [
			sharedStyles,
			style
		];
	}

	getRtlFormfieldTemplate_() {
		return html`
			<div class="option-label" @click="${this.onLabelClick_}">
				<h5>${this.option.name}</h5>
				<span class="dd-caption">${this.option.sideNames}</span>
			</div>
			${this.getHideableCheckboxTemplate_()}
		`;
	}

	getLtrFormfieldTemplate_() {
		return html`
			${this.getHideableCheckboxTemplate_()}
			<div class="option-label" @click="${this.onLabelClick_}">
				<h5>${this.option.name}</h5>
				<span class="dd-caption">${this.option.sideNames}</span>
			</div>
		`;
	}

	getHideableCheckboxTemplate_() {
		const checkboxVisibilityClass = FeatureProvider.isActive(FEATURES.PARTIAL_QUANTITIES)
			? 'dd_show'
			: 'dd_hide';

		return html`
			<div class="${checkboxVisibilityClass}">
				${this.getCheckboxTemplate_()}
			</div>
		`;
	}

	onLabelClick_() {
		if (this.enabled) {
			this.setControl_(!this.checked);
			this.onCheckedChange_();
		}
	}
}

customElements.define('dd-meal-select-option', DdMealSelectOption);