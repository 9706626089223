import { html, css, LitElement } from 'lit-element';
import '@material/mwc-list/mwc-list-item';
import '@material/mwc-select';
import { sharedStyles } from '../../theme/shared-styles.js'

const WEEK_ACTION_EVENT = 'week-action';

/**
 * week select using mwwc-select
 */
class DdWeekSelect extends LitElement {
  static get properties() {
		return {
      disabled: { type: Boolean },
      previousWeek: { type: Boolean }
		};
	}

  set disabled(value) {
    const select = this.shadowRoot.querySelector('mwc-select');
    if (select) {
      select.disabled = value;
    }
  }

	static get styles() {
		return [
			sharedStyles,
			css`
        mwc-select {
					width: 130px;
					--mdc-select-fill-color: var(--mdc-theme-surface);
					--mdc-select-disabled-fill-color: var(--mdc-theme-surface);
					--mdc-select-idle-line-color: var(--mdc-theme-surface);
					--mdc-select-hover-line-color: var(--mdc-theme-surface);
				}

        mwc-select , mwc-list-item {
          --mdc-typography-subtitle1-font-size: 14px;
        }
			`
		];
	}

  constructor() {
		super();
    this.previousWeek = false;
	}

  render() {
		return html`
      <mwc-select
        ?disabled=${this.disabled}
        @action="${this.onSelectWeekAction_}">
        <mwc-list-item value="0" ?selected=${!this.previousWeek}>this week</mwc-list-item>
        <mwc-list-item value="1" ?selected=${this.previousWeek}>last week</mwc-list-item>
      </mwc-select>
		`;
	}

  onSelectWeekAction_(event) {
    const weekSelectIndex = this.previousWeek? 1 : 0;
    if (event.detail.index !== weekSelectIndex) {
			const newEvent = new CustomEvent(WEEK_ACTION_EVENT, {
				detail: {}
			});
			this.dispatchEvent(newEvent);
		}
  }
}

customElements.define('dd-week-select', DdWeekSelect);