export const SIDE_PREFIX = 'with';

const MIN_PER_HR = 60;
// const MONTHS_FULL = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
const MONTHS_ABBREVIATED = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
const DAYS_ABBREVIATED = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
const DATE_ENDINGS = ['st', 'nd', 'rd', 'th'];

function getDateSuffix(date) {
  const day = date.getUTCDate();
  const dayEnding = day % 10;

  if ((dayEnding > 3) || (dayEnding < 1) || (day === 11) || (day === 12)) {
    return 'th';
  }

  return DATE_ENDINGS[dayEnding - 1];
}

function getDayOfWeekAbbreviation(date) {
  return DAYS_ABBREVIATED[date.getUTCDay()];
}

/**
 * app specific formatting
 */
export function toFullFormattedAmount(shoppingListItem) {
  let fullFormattedAmount = '';
  if (shoppingListItem.isOptional) {
    fullFormattedAmount = shoppingListItem.formattedAmount? `${shoppingListItem.formattedAmount}, optional` :
    'optional';
  } else {
    fullFormattedAmount = shoppingListItem.formattedAmount? `${shoppingListItem.formattedAmount}` : '';
  }

  return fullFormattedAmount;
}

export function toShopItemName(shoppingListItem) {
  const additionalInfo = toFullFormattedAmount(shoppingListItem);

  return additionalInfo? `${shoppingListItem.name} (${additionalInfo})` : shoppingListItem.name;
}

export function toTimeMeasure(minutes) {
  const hours = Math.trunc(minutes / MIN_PER_HR);
  const minuteRemainder = minutes % MIN_PER_HR;

  if (hours > 1) {
    return `${hours} hrs ${minuteRemainder} mins`
  }

  if (hours > 0) {
    return `${hours} hr ${minuteRemainder} mins`
  }

  return `${minuteRemainder} minutes`;
}

export function toDateRange(isoStartDate, isoEndDate) {
  const startDate = new Date(isoStartDate);
  const startDayOfWeek = getDayOfWeekAbbreviation(startDate);
  const startDateStr = startDate.getUTCDate().toString();
  const startSuffix = getDateSuffix(startDate);
  const endDate = new Date(isoEndDate);
  const endDayOfWeek = getDayOfWeekAbbreviation(endDate);
  const endDateStr = endDate.getUTCDate().toString();
  const endSuffix = getDateSuffix(endDate);
  const startMonth = MONTHS_ABBREVIATED[startDate.getUTCMonth()]
  const endMonth = MONTHS_ABBREVIATED[endDate.getUTCMonth()];

  return  `${startDayOfWeek} ${startMonth} ${startDateStr}${startSuffix} to ${endDayOfWeek} ${endMonth} ${endDateStr}${endSuffix}`;
}

/**
 * gets day menu sides formatted for titles
 * @param {*} dayMenu { main: recipeObj, sides: recipeObj[] } where recipe obj = { name }
 * @returns
 */
export function getDaySidesTitle(dayMenu) {
	const firstSide = (dayMenu && dayMenu.sides && dayMenu.sides[0]) ? dayMenu.sides[0].name : '';
	const secondSide = (dayMenu && dayMenu.sides && dayMenu.sides[1]) ? dayMenu.sides[1].name : '';

  if (secondSide) {
    return `${SIDE_PREFIX} ${firstSide}, ${secondSide}`;
  }

  if (firstSide) {
    return `${SIDE_PREFIX} ${firstSide}`;
  }

  return '';
}

export function htmlDecode(rawText) {
  const textarea = document.createElement("textarea");
  textarea.innerHTML = rawText;

  return textarea.value;
}
