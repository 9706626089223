/**
 * API actions for recipe ingredients resource
 * ref: https://github.com/redux-utilities/flux-standard-action
 *      /state/middleware/request-middleware.js
 *      https://docs.google.com/spreadsheets/d/1MmffvNk7OJFhEYA2ZSUOVZ6OT_um1848_o-EpzCv1MA/edit?usp=sharing
 */
import {
	axios,
	getApiUrl,
	API_REQUEST,
	startRequest,
	completeRequest,
	NOK_RESPONSE_HINT
} from './api-base.js';
import { savePersonalRecipes } from '../personal-recipes.js';

// REST resources
const RECIPE_INGREDINTS_ENDPOINT = 'recipe-ingredients';

/**
 *
 * @param {*} dto { id, amount, shoppingMeasurementId }
 */
export function requestUpdateRecipeIngredient(dto) {
  return {
    type: API_REQUEST,
    payload(dispatch, token) {
      dispatch(startRequest());

      const updateEndpoint = `${RECIPE_INGREDINTS_ENDPOINT}/${dto.id}`;
      const url = getApiUrl(updateEndpoint);

      return axios.put(url,
        dto,
        {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then(response => {
          dispatch([
            savePersonalRecipes(response.data),
            completeRequest()
          ]);
        })
        .catch(error => {
          dispatch(completeRequest(NOK_RESPONSE_HINT));
          console.log(error);
        });
    }
  }
}
