import { LitElement, html, css } from "lit-element";
import { sharedStyles } from '../../theme/shared-styles.js';

export class DdHomeBanner extends LitElement {
  static properties = {
    image: { type: String },
  };

  constructor() {
    super();
    this.image = null;
  }

  static get styles() {
    return [
      sharedStyles,
      css`
        h2, .dd-h2 {
          color: var(--mdc-theme-surface);
        }

        .home-banner {
          background-image: url("images/home-banner.png");
          background-repeat: no-repeat;
          background-position: center center, center center;
          background-size: 100%, cover;
          border-radius: 20px;
          position: relative;
          align-items: center;
        }

        .home-banner > div {
          flex-grow: 1;
        }

        .home-banner-text {
          width: 111px;
          text-transform: capitalize;
        }

        @media only screen and (min-width: 1248px) {
          .dd-content-container {
            padding: 68px 32px;
          }

          .home-banner h2, .home-banner .dd-h2 {
            font-size: 32px;
            line-height: 44px;
          }

          .home-banner-text {
            width: 200px;
          }
        }
      `
    ];
  }

  render() {
    return html`
      <div class="home-banner dd-host-centered dd-content-container">
        <div class="dd-flex-start-container">
          <h2 class="home-banner-text">
            let's make dinner<span class="dd-h2 bold"> easy</span>
          </h2>
        </div>
      </div>
    `;
  }
}

customElements.define("dd-home-banner", DdHomeBanner);
