const BACK_END = true;
const WORDPRESS = false;

/**
 * Web and API URL translation utilities
 */

/** client =>> (API) Web domain
 * localhost =>> (db.)dev.local
 * app.dev.local =>> (db.)dev.local
 * app.test.thedinnerdaily.com =>> (db2.)test.thedinnerdaily.com | dinnerdailydev.wpengine.com
 * app.thedinnerdaily.com =>> (db.)thedinnerdaily.com
	* @param {*} origin
	* @param {boolean} isBackEndRequest
	*/
export function changeToServerDomain(origin, isBackEndRequest) {
	const protocolMap = { client: 'http:', server: 'https:' };

	// special cases
	const domainMaps = [
		{
			// localhost connecting to Stage backend and site
			client: 'localhost',
			backEndSubdomain: 'db2.test.thedinnerdaily.com',
			siteSubdomain: 'dinnerdailydev.wpengine.com',
			portMap: { client: '8080', server: '443' }
		},
		{
			// local dev connecting to local backend and site
			client: 'localhost',
			backEndSubdomain: 'localhost',
			siteSubdomain: 'localhost',
			portMap: { client: '8081', server: '44300' }
		},
		{
			client: 'app.dev.thedinnerdaily',
			backEndSubdomain: 'db.dev.thedinnerdaily',
			siteSubdomain: 'dinnerdailydev.wpengine'
		},
		{
			client: 'app.test.thedinnerdaily',
			backEndSubdomain: 'db2.test.thedinnerdaily',
			siteSubdomain: 'dinnerdailydev.wpengine'
		}
	]

	let server = origin;
	if (origin.includes(protocolMap.client)) {
		server = server.replace(protocolMap.client, protocolMap.server);
	}

	let isSpecialCase = false;
	domainMaps.forEach(domainMap => {
		const serverSubdomain = isBackEndRequest ? domainMap.backEndSubdomain : domainMap.siteSubdomain;
		const clientDomainMatch = origin.includes(domainMap.client);
		if (clientDomainMatch) {
			if (domainMap.portMap) {
				if (origin.includes(domainMap.portMap.client)) {
					server = server.replace(domainMap.portMap.client, domainMap.portMap.server);
					server = server.replace(domainMap.client, serverSubdomain);
				}
			} else {
				server = server.replace(domainMap.client, serverSubdomain);
			}

			isSpecialCase = true;
		}
	});

	if (isSpecialCase) {
		return server;
	}

	return isBackEndRequest? server.replace('app.', 'db.'): server.replace('app.', '');
}

function getServerDomain(isBackEndRequest) {
	const origin = window.location.origin;
	return changeToServerDomain(origin, isBackEndRequest);
}

export function sanitizeEndpoint(endpoint) {
	return endpoint.replace(/^\/|\/$/g, '');
}

export function getApiUrl(endpoint) {
	const server = getServerDomain(BACK_END);
	const url = `${server}/api/v2/${sanitizeEndpoint(endpoint)}`;

	return url;
}

export function getAuthUrl(endpoint) {
	const server = getServerDomain(BACK_END);
	const url = `${server}/api/auth/${sanitizeEndpoint(endpoint)}`;

	return url;
}

export function getWebUrl(endpoint) {
	const server = getServerDomain(WORDPRESS);
	const url = endpoint ? `${server}/${sanitizeEndpoint(endpoint)}` : `${server}`;

	return url;
}
export function getWebResourceUrl(endpoint) {
	const server = getServerDomain(BACK_END);
	const url = `${server}/${sanitizeEndpoint(endpoint)}`;

	return url;
}

export function getTimestampedUrl(rawUrl, timestamp) {
	const queryVar = 'timestamp=';
	const queryParamExists = rawUrl.includes('?');

	return queryParamExists ? `${rawUrl}&${queryVar}${timestamp}` :
		`${rawUrl}?${queryVar}${timestamp}`;
}

export function getParameters(previousWeek, newParameters = {}) {
	const week = previousWeek ? 'previous' : 'current';

	return {
		...newParameters,
		week
	};
}
