/**
* API actions for the family-size endpoint
* ref: https://github.com/redux-utilities/flux-standard-action
*/
import {
	axios,
	getApiUrl,
	getParameters,
	API_REQUEST,
	startRequest,
	completeRequest,
	NOK_RESPONSE_HINT
} from './api-base.js';
import { saveWeekMenu } from '../menu.js';
import { FAMILY_SIZE } from '../../utilities/constants.js';

// REST resources
const FAMILY_SIZE_RESOURCE = 'family-size';



/**
 * send new family size
 * @param int familySize
 */
export function requestChangeFamilySize(familySize, previousWeek, verbose) {
	const halfSizeHint = verbose? `Your meal plan is now for ${FAMILY_SIZE.half.description}` : '';
	const fullsizeHint = verbose? `Your meal plan is now for ${FAMILY_SIZE.full.description}` : '';

	return {
		type: API_REQUEST,
		payload(dispatch, token) {
			dispatch(startRequest());
			const url = getApiUrl(FAMILY_SIZE_RESOURCE);
			const parameters = getParameters(previousWeek, { familySize });

			return axios.put(url,
				parameters,
				{
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					const requestCompleteHint = (familySize === FAMILY_SIZE.full.value)? fullsizeHint : halfSizeHint;
					dispatch([
						saveWeekMenu(response.data),
						completeRequest(requestCompleteHint)
					]);
				})
				.catch(() => {
					dispatch(completeRequest(NOK_RESPONSE_HINT));
				});
		}
	}
}
