
import { DdCard } from './dd-card.js';

const ACTIVE_CHANGED_EVENT = 'active-changed';

/**
 * cards that manage their active state
 *
 * @abstract
 */
export class DdActivatableCard extends DdCard {
  static get properties() {
    return {
      isActive: { type: Boolean, reflect: true }
    };
  }

  set isActive(value) {
    const oldValue = this._active;
    this._active = value;
    this.requestUpdate('isActive', oldValue);
    this.fireActiveChangedEvent_();
  }

  get isActive() {
    return this._active;
  }

  constructor() {
    super();

    this.isActive = true;
  }

  updated() {
		super.updated();
		this.updateActive_();
	}

  /* eslint class-methods-use-this: "off" */
  updateActive_() {
    // derived class implements
  }

  fireActiveChangedEvent_() {
    this.fireLocalEvent_(ACTIVE_CHANGED_EVENT, {});
  }
}
