import { html, css, LitElement } from 'lit-element';
import { sharedStyles } from '../theme/shared-styles.js';
import '@material/mwc-button';
import '@material/mwc-icon';
import '@material/mwc-textfield';
import {
	MAX_TEXTFIELD_LENGTH,
	VALID_CHAR_REGEX,
	VALIDATION_MESSAGE
} from '../utilities/constants.js';
import debounce from '../utilities/debounce.js';

const ITEM_EDITOR_CLOSE_EVENT = 'item-editor-close';
const ITEM_EDITOR_SUBMIT_EVENT = 'item-editor-submit';
const INITAL_TEXT_VALUE = '';
const VALID_SHORT_REGEX = "[a-zA-Z0-9 ,()&+-/.!@*?:;^'\"]*";
const SHORT_VALIDATION_MESSAGE = "It has special characters";
const DEBOUNCE_MS = 400;

// There is no "Brand" in the new RG version.
class DdItemEditor extends LitElement {
	static get properties() {
		return {
			heading: { type: String },
			closeable: { type: Boolean },
			name: { type: String },
			amount: { type: String },
			brand: { type: String },
			notes: { type: String }
		};
	}

	static get styles() {
		return [
			sharedStyles,
			css`
				#editor-frame {
					background-color: #EEF8F7;
				}

				#submit-container {
					column-gap: 16px;
				}

				.closeable-frame {
					padding: 16px 0px;
				}

				.fixed-frame {
					padding: 0 16px;
				}

				/* .dd-item-editor-border {
					border-top: 1px solid var(--dd-section-border);
				} */

				.dd-item-editor-placeholder {
					width: 48px;
				}

				mwc-textfield {
					width: 100%;
					margin-bottom: 8px;
					--mdc-text-field-fill-color: transparent;
					--mdc-text-field-hover-line-color: #56BAB4;
					--mdc-theme-primary: #56BAB4;
				}

				#name-text-field {
					display: inline-block;
					width: 53%;
				}
				#amount-text-field {
					display: inline-block;
					margin-left: 5.73%;
					width: 40%
				}
				mwc-button {
					--mdc-theme-primary: black;
					--mdc-typography-button-font-weight: 400;
					--mdc-typography-button-font-size: 16px;
					--mdc-typography-button-line-height: 24px;
				}

				#delete-button mwc-icon {
					font-size: 20px;
					color: #9b9b9b;
				}

				#submit-button mwc-icon {
					font-size: 17px;
					color: #56BAB4;
				}
			`
		];
	}

	constructor() {
		super();
		this.heading = INITAL_TEXT_VALUE;
		this.closeable = false;
		this.name = INITAL_TEXT_VALUE;
		this.amount = INITAL_TEXT_VALUE;
		this.brand = INITAL_TEXT_VALUE;
		this.notes = INITAL_TEXT_VALUE;
		const callback = (this.enableSubmit_).bind(this);
		this.debouncedShowSubmit_ = debounce(callback, DEBOUNCE_MS, false);
	}

	render() {
		const frameClass = this.closeable? 'closeable-frame' : 'fixed-frame';

		return html`
			<div id="editor-frame" class="${frameClass}">
				<br>
				${
					!this.closeable ? "" : html`
						<div>
						<mwc-textfield id="name-text-field" placeholder="name"
							maxLength=${MAX_TEXTFIELD_LENGTH}
							pattern=${VALID_CHAR_REGEX}
							validationMessage=${VALIDATION_MESSAGE}
							.value="${this.name}"
							@change="${this.onChange_}"
							@input="${this.onNameInput_}"
							required>
						</mwc-textfield>
						<mwc-textfield id="amount-text-field"  placeholder="quantity"
							maxLength=${MAX_TEXTFIELD_LENGTH}
							pattern=${VALID_SHORT_REGEX}
							validationMessage=${SHORT_VALIDATION_MESSAGE}
							.value="${this.amount}"
							@keypress="${this.onKeypress_}"
							@change="${this.onChange_}">
						</mwc-textfield>
					</div>
					`
				}
				<mwc-textfield id="notes-text-field"  placeholder="Add Note"
					maxLength=${MAX_TEXTFIELD_LENGTH}
					pattern=${VALID_SHORT_REGEX}
					validationMessage=${SHORT_VALIDATION_MESSAGE}
					.value="${this.notes}"
					@keypress="${this.onKeypress_}"
					@change="${this.onChange_}">
				</mwc-textfield>
				${this.closeable
					? html`
						<div id="submit-container" class="dd-flex-end-container">
							<mwc-button id="delete-button" @click="${this.onCloseClick_}">
								Cancel
								<mwc-icon slot="icon">close</mwc-icon>
							</mwc-button>
							<mwc-button id="submit-button" disabled @click="${this.onAddClick_}">
								Done
								<mwc-icon slot="icon">check_circle</mwc-icon>
							</mwc-button>
						</div>
					`
					: ''
				}

			</div>
		`;
	}

	firstUpdated() {
		if (this.closeable) {
			const editorFrameClasses = this.shadowRoot.querySelector('#editor-frame').classList;
			editorFrameClasses.add('dd-item-editor-border');
		}
	}

	updated() {
		this.onChange_();
	}

	focus(keepName = false) {
		const input = this.shadowRoot.querySelector('#name-text-field');
		if (!keepName) {
			input.value = '';
		}

		input.focus();
		this.layout();
	}

	layout() {
		if (this.closeable) {
			const nameTextField = this.shadowRoot.querySelector('#name-text-field');
			nameTextField.layout();
		}
		const amountTextField = this.shadowRoot.querySelector('#amount-text-field');
		amountTextField.layout();
		const notesTextField = this.shadowRoot.querySelector('#notes-text-field');
		notesTextField.layout();
	}

	getTextValues_() {
		let name;
		if (this.closeable) {
			const nameTextField = this.shadowRoot.querySelector('#name-text-field');
			name = nameTextField.value.trim();

		} else {
			name = this.name
		}
		const amountTextField = this.shadowRoot.querySelector('#amount-text-field');
		const formattedAmount = amountTextField.value.trim();
		const notesTextField = this.shadowRoot.querySelector('#notes-text-field');
		const notes = notesTextField.value.trim();

		return {
			name,
			formattedAmount,
			notes
		};
	}

	clearTextValues_() {
		if (this.closeable) {
			const nameTextField = this.shadowRoot.querySelector('#name-text-field');
			nameTextField.value = INITAL_TEXT_VALUE;
		}
		const amountTextField = this.shadowRoot.querySelector('#amount-text-field');
		amountTextField.value = INITAL_TEXT_VALUE;
		const notesTextField = this.shadowRoot.querySelector('#notes-text-field');
		notesTextField.value = INITAL_TEXT_VALUE;
	}

	resetTextValues(shoppingListItem) {
		if (this.closeable) {
			const nameTextField = this.shadowRoot.querySelector('#name-text-field');
			nameTextField.value = shoppingListItem.name;
		}
		const amountTextField = this.shadowRoot.querySelector('#amount-text-field');
		amountTextField.value = shoppingListItem.formattedAmount;
		const notesTextField = this.shadowRoot.querySelector('#notes-text-field');
		notesTextField.value = shoppingListItem.notes;
	}

	isChanged_(textValues) {
		return this.closeable && (this.name.trim() !== textValues.name) ||
			(this.amount !== textValues.formattedAmount) ||
			(this.notes.trim() !== textValues.notes);
	}

	enableSubmit_() {
		const submitButton = this.shadowRoot.querySelector('#submit-button');
		submitButton.disabled = false;

		// scroll after submitContainer DOM updated
		// const editorFrame = this.shadowRoot.querySelector('#editor-frame');
		// offsetScrollToBottom(editorFrame, ASYNC_PAUSE);
	}

	disableSubmit_() {
		const submitButton = this.shadowRoot.querySelector('#submit-button');
		submitButton.disabled = true;
	}

	onNameInput_() {
		if (this.closeable) {
			const nameTextField = this.shadowRoot.querySelector('#name-text-field');
			const validName = nameTextField && nameTextField.formElement && nameTextField.validity.valid;

			if (validName && this.isChanged_(this.getTextValues_())) {
				this.debouncedShowSubmit_();
			} else {
				this.disableSubmit_();
			}
		}
  }

	onKeypress_(event) {
		if (event.key === "Enter") {
			this.onChange_();
		}
	}

	onChange_() {
		const nameTextField = this.shadowRoot.querySelector('#name-text-field');
		// on rehydrating state from persistStore, nameTextField.formElement is null
		const validName = nameTextField && nameTextField.formElement && nameTextField.validity.valid;

		if (!this.closeable && validName && this.isChanged_(this.getTextValues_())) {
			// this.onAddClick_();
		}
	}

	onCloseClick_() {
		const event = new CustomEvent(ITEM_EDITOR_CLOSE_EVENT, {});
		this.dispatchEvent(event);
		if (this.closeable) {
			this.clearTextValues_();
			this.disableSubmit_();
		}
	}

	onAddClick_() {
		const event = new CustomEvent(ITEM_EDITOR_SUBMIT_EVENT, {
			detail: this.getTextValues_()
		});
		this.dispatchEvent(event);
	}
}

customElements.define('dd-item-editor', DdItemEditor);