/**
 * fruits and vegetables included in the Home Screen fruit/veg callout
 */
export const FrozenIngredients = [
  77,
//  80, swee potato fries
  105,
  302,
  318,
  339,
//  346,  gnocchi
//  361,  frozen cheese tortellini
  362,
  467,
  500,
  533,
  784,
  821,
  825,
  877,
//  916,  philo douch
//  986,  french fries
//  1014, gluten-free ravioli
//  1027, Ore-Ida frozen mashed potato
//  1032, plant-based meat
//  1103, buttermilk biscuits
  1110,
  1112,
//  1129, onion rings
//  1146, garden veggie meatballs
  1154,
//  1240, tater tots
//  1341, frozen tater tots
//  1370, Aldi red bag chicken
//  1374, hash brown patties
  1380
];
