export const SET_FULFILLMENT_ACCOUNTS = 'SET_FULFILLMENT_ACCOUNTS';
export const SET_FULFILLMENT_KROGER_LOCATIONS = 'SET_FULFILLMENT_KROGER_LOCATIONS';
export const SET_FULFILLMENT_KROGER_PRODUCT = 'SET_FULFILLMENT_KROGER_PRODUCT';
export const SET_FULFILLMENT_KROGER_PRODUCTS = 'SET_FULFILLMENT_KROGER_PRODUCTS';

export function saveFulfillmentAccounts(accountStatus) {
  return {
    type: SET_FULFILLMENT_ACCOUNTS,
    payload: { accountStatus }
  }
}

export function saveFulfillmentKrogerLocations(krogerLocations) {
  return {
    type: SET_FULFILLMENT_KROGER_LOCATIONS,
    payload: { krogerLocations }
  }
}

export function saveFulfillmentKrogerProduct(krogerProduct) {
  return {
    type: SET_FULFILLMENT_KROGER_PRODUCT,
    payload: { krogerProduct }
  }
}

export function saveFulfillmentKrogerProducts(krogerProducts) {
  return {
    type: SET_FULFILLMENT_KROGER_PRODUCTS,
    payload: { krogerProducts }
  }
}