/**
 * view navigation actions
 * ref: https://github.com/redux-utilities/flux-standard-action
 *      /state/middleware/request-middleware.js
 */

export const NAVIGATION_ACTION = 'NAVIGATION';

export const NO_VIEW = 'NO_VIEW';
export const HOME_VIEW = 'HOME_VIEW';
export const LOGIN_VIEW = 'LOGIN_VIEW';
export const MENU_VIEW = 'MENU_VIEW';
export const WEB_VIEW = 'WEB_VIEW';
export const SL_VIEW = 'SL_VIEW';
export const RECIPES_VIEW = 'RECIPES_VIEW';
export const WEEK_PRINT_VIEW = 'WEEK_PRINT_VIEW';
export const SL_PRINT_VIEW = 'SL_PRINT_VIEW';
export const SUBSCRIPTION_VIEW = 'SUBSCRIPTION_VIEW';
export const ONBOARDING_VIEW = 'ONBOARDING_VIEW';

export function navigate(view, destination, title = '') {
	return {
		type: NAVIGATION_ACTION,
		payload: {
			view,
			destination,
			title
		}
	}
}
