import cloneDeep from 'lodash-es/cloneDeep.js';
import {
	SET_FULFILLMENT_ACCOUNTS,
	SET_FULFILLMENT_KROGER_LOCATIONS,
	SET_FULFILLMENT_KROGER_PRODUCT,
	SET_FULFILLMENT_KROGER_PRODUCTS
} from '../actions/fulfillment.js';
import { PubSub } from '../services/pub-sub.js';
import {
	ACCOUNT_STATE,
	ASYNC_PAUSE,
	LOCATIONS_SET_EVENT,
	PRODUCTS_SET_EVENT
} from '../utilities/constants.js'

const INITIAL_STATE = {
	accountStatus: {},
	krogerLocations: [],
	krogerProducts: []
};

function setAccountStatus(isSet) {
	return isSet ? ACCOUNT_STATE.AUTHORIZED : ACCOUNT_STATE.UNAUTHORIZED;
}

function addProductMap(productMap, existingProductMaps) {
	const productMaps = cloneDeep(existingProductMaps);

	const mapIndex = productMaps.findIndex( pm => pm.shopping_list_item_id === productMap.shopping_list_item_id);
	if (mapIndex === -1) {
			productMaps.push(productMap);
	} else {
		productMaps[mapIndex] = productMap;
	}

	return productMaps;
}

export function fulfillment(state = INITIAL_STATE, action) {
	switch (action.type) {
		case SET_FULFILLMENT_ACCOUNTS:
			return {
				...state,
				accountStatus: {
					peapod: action.payload.accountStatus.peapod,
					google: setAccountStatus(action.payload.accountStatus.google),
					kroger: action.payload.accountStatus.kroger
				}
			};

		case SET_FULFILLMENT_KROGER_LOCATIONS:
			setTimeout(() => {
				PubSub.publish(LOCATIONS_SET_EVENT);
			}, ASYNC_PAUSE);

			return {
				...state,
				krogerLocations: action.payload.krogerLocations
			};

		case SET_FULFILLMENT_KROGER_PRODUCT:
			setTimeout(() => {
				PubSub.publish(PRODUCTS_SET_EVENT);
			}, ASYNC_PAUSE);

			return {
				...state,
				krogerProducts: addProductMap(action.payload.krogerProduct, state.krogerProducts)
			};

		case SET_FULFILLMENT_KROGER_PRODUCTS:
			setTimeout(() => {
				PubSub.publish(PRODUCTS_SET_EVENT);
			}, ASYNC_PAUSE);

			return {
				...state,
				krogerProducts: action.payload.krogerProducts
			};

		default:
			return state;
	}
}