import { COLOR_CLASSES} from '../theme/color-classes';
/**
 * provides theme color classes for day menus
 */

export const DayMenuColorProvider = {
  _dayMenus: [],

  /**
   *
   * @param Array dayMenus
   */
  setDayMenus(dayMenus) {
    this._dayMenus = dayMenus;
  },

  /**
   *
   * @param Array recipeIds
   */
  getColorClasses(recipeIds) {
    const self = this;
    const result = [];

    recipeIds.forEach((recipeId) => {
      self._dayMenus.forEach((dayMenu, index) => {
        const isMain = dayMenu.main && dayMenu.main.id === recipeId;
        const isSide = dayMenu.sides && dayMenu.sides.some((side) => side.id === recipeId);

        if (isMain || isSide) {
          result.push(COLOR_CLASSES[index]);
        }
      })
    });

    // remove duplicates
    const set = new Set(result);
    return [...set];
  }
}