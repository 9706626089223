import { html, css, LitElement } from 'lit-element';
import '@material/mwc-snackbar';
import { sharedStyles } from '../theme/shared-styles.js';

const MESSAGE_SHOWN_EVENT = 'message-shown';

const MESSAGE_DURATION = 4000; // ms

class DdApiSnackbar extends LitElement {
  static get properties() {
		return {
      isFetching: { type: Boolean },
      message: { type: String },
      spacerHeight: { type: Number}
		};
	}

	static get styles() {
		return [
			sharedStyles,
			css`
        #snackbar {
          position: fixed;
          transform: translateZ(0);
          right: 0;
          left: 0;
        }
			`
		];
  }

  set isFetching(value) {
    const oldValue = this._isFetching;
    this._isFetching = value;
    this.requestUpdate('isFetching', oldValue);

    if (this.message && oldValue && !value) {
      this.openSnackBar(this.message);

      const event = new CustomEvent(MESSAGE_SHOWN_EVENT, {});
      this.dispatchEvent(event);
    }
  }

  get isFetching() {
    return this._isFetching;
  }

  constructor() {
    super();
		this.isFetching = false;
    this.message = '';
    this.spacerHeight = 0;
  }


  render() {
		return html`<mwc-snackbar id="snackbar" .timeoutMs=${MESSAGE_DURATION} style="bottom: calc(${this.spacerHeight}px + env(safe-area-inset-bottom))"></mwc-snackbar>`;
  }

	openSnackBar(message) {
		const snackbar = this.shadowRoot.querySelector('#snackbar');
		snackbar.labelText = message;
		snackbar.show();
	}
}

customElements.define('dd-api-snackbar', DdApiSnackbar);
