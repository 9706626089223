/**
 * UI settings actions
 */

 export const SET_UI_SETTINGS = 'SET_UI_SETTINGS';

 export function saveUISettings(uiSettings) {
   return {
    type: SET_UI_SETTINGS,
    payload: { uiSettings }
   }
 }