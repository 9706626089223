import { html, css } from 'lit-element';
import { sharedStyles } from '../theme/shared-styles.js';
import '@material/mwc-textfield';
import "@material/mwc-icon-button-toggle";
import { PageViewElement } from './page-view-element.js';
import { MAX_TEXTFIELD_LENGTH } from '../utilities/constants.js';
import { isValidEmail } from '../utilities/email-validation.js';
import { fireFabContextChangeEvent } from '../utilities/fab-integration.js';
import { getBuildVariant, BUILD_VARIANT } from '../utilities/build-variant.js';
import { getWebUrl } from '../utilities/url-translation.js';

class DdLogin extends PageViewElement {
	static get properties() {
		return {
			heading: { type: String }
		};
	}

	static get styles() {
		return [
			sharedStyles,
			css`
				#logo, .link-container {
					margin: 56px 0 56px 0;
				}

				.dd-login {
					padding: 16px;
					display: flex;
					flex-direction: column;
					align-items: center;
				}
			`
		];
	}

	constructor() {
		super();
		this.heading = 'Log in';
		this._buildVariant = getBuildVariant();
	}

	firstUpdated() {
		super.firstUpdated();

		if (this.active && (this._buildVariant !== BUILD_VARIANT.whiteLabelInIframe)) {
			const showFab = true;
			const fabClickCallback = (this.onLoginClick_).bind(this);
			fireFabContextChangeEvent(this, 'lock_open', 'Log in', showFab, fabClickCallback);
		}
	}

	render() {
		return html`
			<div class="dd-login">
				<img id="logo" src="images/logo-sm.png">
				${this.getFormTemplate_()}
				${this.getDesktopFabHostTemplate_()}
			</div>
		`;
	}

	getFormTemplate_() {
		const lostPasswordUrl = getWebUrl('cms/wp-login.php?action=lostpassword');
		const homePageUrl = getWebUrl();

		switch (this._buildVariant) {
			case (BUILD_VARIANT.whiteLabelInIframe):
				return '';

			case (BUILD_VARIANT.whiteLabelInstalled):
				return html`
					<div>
						<mwc-textfield
							id="email-text-field"
							outlined
							label="email"
							maxLength=${MAX_TEXTFIELD_LENGTH}
							type="email"
							required>
						</mwc-textfield>
					</div>
				`;

			case (BUILD_VARIANT.tdd):
			default:
				return html`
					<div>
						<mwc-textfield
							id="email-text-field"
							outlined
							label="email"
							maxLength=${MAX_TEXTFIELD_LENGTH}
							type="email"
							required>
						</mwc-textfield>
						<br>
						<br>
						<br>
						<mwc-textfield
							id="password-text-field"
							outlined
							label="password"
							maxLength=${MAX_TEXTFIELD_LENGTH}
							type="password"
							required
							@change="${this.onLoginClick_}">
						</mwc-textfield>
						<mwc-icon-button-toggle
								id="view-pw-toggle"
								onIcon="visibility_off"
								offIcon="visibility"
								@click="${this.onViewPwToggle_}"></mwc-icon-button-toggle>
					</div>
					<div class="link-container">
						<a href="${lostPasswordUrl}" target="_blank">Forgot your password?</a>
						<br>
						<br>
						<br>
						<a href="${homePageUrl}" target="_blank">Not a member?</a>
					</div>
				`;
		}
	}

	onViewPwToggle_(event) {
		const actionView = event.target.on;
		const passwordTextField = this.shadowRoot.querySelector('#password-text-field');
		passwordTextField.type = actionView? 'text' : 'password';
	}

	// BUILD defined by webpack
	/* eslint no-undef: "off" */
	onLoginClick_() {
		const email = this.shadowRoot.querySelector('#email-text-field').value;
		let password;

		switch (this._buildVariant) {
				case (BUILD_VARIANT.whiteLabelInstalled):
					if (isValidEmail(email)) {
						const event = new CustomEvent('login-submit', {
							detail: {
								email,
								password: BUILD
							}
						});
						this.dispatchEvent(event);
					}
					break;

				case (BUILD_VARIANT.tdd):
					password = this.shadowRoot.querySelector('#password-text-field').value;
					if (isValidEmail(email) && password) {
						const event = new CustomEvent('login-submit', {
							detail: {
								email,
								password
							}
						});
						this.dispatchEvent(event);
					}
					break;

				case (BUILD_VARIANT.whiteLabelInIframe):
				default:
					break;
		}
	}
}

customElements.define('dd-login', DdLogin);
