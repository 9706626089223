export const TestStores = [
  {
    internalName: "Any Store",
    alias: 'any-store'
  },
  {
    internalName: "TESTING"
  },
  {
    internalName: "Training Store"
  },
  {
    internalName: "Stop&Shop VIP"
  },
  {
    internalName: "TESTSTORE2"
  },
  {
    internalName: "TestStore3"
  },
  {
    internalName: "Test schedule"
  },
  {
    internalName: "Vegetarian"
  },
  {
    internalName: "VegetarianWithSeafood"
  }
]