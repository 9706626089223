/**
 * features data actions
 */

export const SET_FEATURES = 'SET_FEATURES';

export function saveFeatures(features) {
 return {
   type: SET_FEATURES,
   payload: { features	}
 }
}
