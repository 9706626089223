import { html, css, LitElement } from 'lit-element';
import '@material/mwc-linear-progress';
import { sharedStyles } from '../theme/shared-styles.js';

class DdApiProgress extends LitElement {
  static get properties() {
		return {
      isFetching: { type: Boolean },
      message: { type: String }
		};
	}

	static get styles() {
		return [
			sharedStyles,
      css`
        mwc-linear-progress {
          --mdc-theme-primary: var(--mdc-theme-primary-dark);
        }
			`
		];
  }
  
  set isFetching(value) {
    const oldValue = this._isFetching;
    this._isFetching = value;
    this.requestUpdate('isFetching', oldValue);

    if (!oldValue && value) {
      this.openBar_();
    } else if (oldValue && !value) {
      this.closeBar_();
    }
  }

  get isFetching() { return this._isFetching; }
  
  constructor() {
    super();
		this._isFetching = false;
    this.message = '';
  }

  firstUpdated() {
    super.firstUpdated();
    this.closeBar_();
  }

  
  render() {
		return html`<mwc-linear-progress id="progress-bar" indeterminate></mwc-linear-progress>`;
  }
  
  
	openBar_() {
		const progressBar = this.shadowRoot.querySelector('#progress-bar');
		progressBar.open();
  }
  
  closeBar_() {
		const progressBar = this.shadowRoot.querySelector('#progress-bar');
		progressBar.close();
	}
}

customElements.define('dd-api-progress', DdApiProgress);
