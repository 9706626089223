import {
	SET_APP_FLAG
} from '../actions/app-flags.js';
import { APP_FLAGS, TOUCH_STATUS } from '../utilities/constants.js';

// initialize not nullable flags
const INITIAL_STATE = {};
INITIAL_STATE[`${APP_FLAGS.FIRST_VISIT_COMPLETED}`] = false;
INITIAL_STATE[`${APP_FLAGS.NOTIFIED_NO_STORES}`] = false;
INITIAL_STATE[`${APP_FLAGS.PREVIOUS_WEEK}`] = false;
INITIAL_STATE[`${APP_FLAGS.VISITED_CLASSIC}`] = false;
INITIAL_STATE[`${APP_FLAGS.NOTIFIED_BELOW_LIMIT}`] = false;
INITIAL_STATE[`${APP_FLAGS.MENU_ID}`] = '0';
INITIAL_STATE[`${APP_FLAGS.MENU_REQUEST_COUNT}`] = '0';
INITIAL_STATE[`${APP_FLAGS.CREATE_MENU_TIMESTAMP}`] = '0';
INITIAL_STATE[`${APP_FLAGS.DEBUG}`] = false;
INITIAL_STATE[`${APP_FLAGS.DEV_FEATURE}`] = false;
INITIAL_STATE[`${APP_FLAGS.TOUCH_STATUS_OVERRIDE}`] = TOUCH_STATUS.NONE;

function updateFlag(state, name, value) {
  const clonedFlags = {};
  Object.assign(clonedFlags, state);
  clonedFlags[name] = value;

  return clonedFlags;
}

export function appFlags(state = INITIAL_STATE, action) {
	switch (action.type) {
		case SET_APP_FLAG:
			return updateFlag(state, action.payload.name, action.payload.value);
		default:
			return state;
	}
}
