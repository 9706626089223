/**
 * store observation with substate select
 * proposed in https://github.com/reduxjs/redux/issues/303#issuecomment-125184409
 * @param {*} store
 * @param {*} select function returns substate
 * @param {*} onChange substate change handler
 * @returns
 */
export function observeStore(store, select, onChange) {
  /*  eslint prefer-const: "off" */
  let currentState;

  function handleChange() {
    let nextState = select(store.getState());
    if (nextState !== currentState) {
      currentState = nextState;
      onChange(currentState);
    }
  }

  const unsubscribe = store.subscribe(handleChange);
  handleChange();

  return unsubscribe;
}
