/**
 * actions for application-wide flags
 */

export const SET_APP_FLAG = 'SET_APP_FLAG';

export function setFlag(name, value) {
	return {
		type: SET_APP_FLAG,
		payload: { 
			name,
			value
    }
  };
}
