/**
 * menu data actions
 */
 import { toCamelCase } from '../utilities/object-evaluation.js';

 export const SET_MENU = 'SET_MENU';

 export function saveWeekMenu(dto) {
	return {
		type: SET_MENU,
		payload: {
			weekMenu: toCamelCase(dto)
		}
	}
 }
