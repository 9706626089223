/**
 * service wrapper around analytics and logging libraries
 */

import LogRocket from 'logrocket';

// intrumentation library
const mixpanel = require('mixpanel-browser');

mixpanel.init("cc7960b50708b907a783f8904ff4de5f");
if (typeof APP_ENV !== 'undefined') {
  mixpanel.register({
    "app_env": APP_ENV
  });
}

const LOGGER_PROJECTS = {
  DEV: 'bbpfki/dev-pwa',
  TEST: '',
  STAGE: 'bbpfki/stage-pwa',
  PROD: 'bbpfki/prod-pwa'
}

function getLoggerProject(env) {
  let loggerProject = '';

  switch (env) {
    case 'prod':
    case 'prod2':
      loggerProject = LOGGER_PROJECTS.PROD;
      break;
    case 'stage':
      loggerProject = LOGGER_PROJECTS.STAGE;
      break;
    case 'dev':
      // loggerProject = LOGGER_PROJECTS.DEV;
      break;
    default:
      break;
  }

  return loggerProject;
}

export const Instrumentation = {
  _analytics: mixpanel,
  _logger: null,
  _userId: 0,

  // APP_ENV defined by webpack
  /* eslint no-undef: "off" */
  setMetadata() {
    if ((typeof APP_ENV !== 'undefined') && !this._logger) {
      const lp = getLoggerProject(APP_ENV)
      if (lp) {
        LogRocket.init(lp);
        this._logger = LogRocket;
      }
    }
  },

  identifyUser(userId) {
    if (this._logger && !this._userId) {
      this._userId = userId;
      this._logger.identify(userId);
    }
  },

  getAnalytics() {
    return this._analytics;
  },

  getLogger() {
    return this._logger;
  }
}
