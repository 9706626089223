
export class State {
  constructor(
    stepNumber,
    setView,
    setCompleted = null
  ) {
    this._stepNumber = stepNumber;
    this._prevState = null;
    this._nextState = null;
    this._setView = setView;
    this._setCompleted = setCompleted;
  }

  set nextState (value) {
    this._nextState = value;
  }

  set previousState (value) {
    this._prevState = value;
  }

  get stepNumber() {
    return this._stepNumber;
  }

  next () {
    if (this._setCompleted) {
      this._setCompleted();
    }
    return this._nextState;
  }

  previous () {
    return this._prevState;
  }

  enter () {
    this._setView();
  }
}