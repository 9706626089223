/**
 * publishes an event on setting features
 */

import { PubSub } from './pub-sub.js';
import { deepEqual } from '../utilities/object-evaluation.js';

export const FEATURES_SET_EVENT = 'features-set';

/**
 * provider service wrapper around features substate
 */
export const FeatureProvider = {
  _flags: {},

  setFlags(flags) {
    const flagsChanged = !deepEqual(this._flags, flags);
    this._flags = flags;

    if (flagsChanged) {
      PubSub.publish(FEATURES_SET_EVENT);
    }
  },

  isActive(flag) {
    return this._flags.active.includes(flag);
  }
}