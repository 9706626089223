import cloneDeep from 'lodash-es/cloneDeep.js';
import { DISH_TYPE_SIDE, DISH_TYPE_MAIN } from '../utilities/constants.js';

/**
 * provider service wrapper around clone of recipe shop items substate to check active status of SLIs recipe shop item maps
 */

 export const RecipeShopItemMapProvider = {
  _map: [],

  setMap (recipeShopItems) {
    RecipeShopItemMapProvider._map = cloneDeep(recipeShopItems);
  },

  getMap () {
    return RecipeShopItemMapProvider._map;
  },

  isItemActive (shoppingListItemId) {
    const isActive = RecipeShopItemMapProvider._map.find(item => (item.shoppingListItemId === shoppingListItemId) && item.active);

    return typeof isActive !== 'undefined';
  },

  isInOnlySides (shoppingListItemId) {
    const mapItems = RecipeShopItemMapProvider._map.filter(item => item.shoppingListItemId === shoppingListItemId);
    return (mapItems.length > 0) && mapItems.every((item) => item.dishType === DISH_TYPE_SIDE);
  },

  isRecipeActive (recipeId) {
    const isActive = RecipeShopItemMapProvider._map.find(item => (item.recipeId === recipeId) && item.active);

    return typeof isActive !== 'undefined';
  },

  getRecipeNames (shoppingListItemId) {
    return RecipeShopItemMapProvider._map
      .filter(item => item.shoppingListItemId === shoppingListItemId)
      .map(item => item.recipeName);
  },

  getRecipeIds (shoppingListItemId, getMainsOnly) {
    const callback = getMainsOnly
    ? item => item.shoppingListItemId === shoppingListItemId && item.dishType === DISH_TYPE_MAIN
    : item => item.shoppingListItemId === shoppingListItemId;

    return RecipeShopItemMapProvider._map
      .filter(callback)
      .map(item => item.recipeId);
  }
}
