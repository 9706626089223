import { ScreenAwareElement } from '../../screen-aware-element.js';
import { EVENTS } from "../../events.js";
import { ASYNC_PAUSE } from '../../../utilities/constants.js';
import { isNumericallyEqual } from '../../../utilities/object-evaluation.js';

// required elements
export const CARD_BODY_ID = 'card-body';
//  <dd-card-expander>

/**
 * base class for v2 cards
 */
export class DdCard2 extends ScreenAwareElement {
  static get properties() {
		return {
			isOpen: { type: Boolean, reflect: true }
		};
	}

  constructor() {
		super();

    this._initialized = false;
  }

  firstUpdated() {
    super.firstUpdated();

    this._cardBody = this.shadowRoot.querySelector(`#${CARD_BODY_ID}`);
  }

  updated() {
    super.updated();

    if (this.offsetParent /* is visible */) {
      this.asyncUpdateFromState_();
    }
  }

  open() {
		this.isOpen = true;
		const expander = this.shadowRoot.querySelector('dd-card-expander');
    if (expander) {
      expander.open();
      this.asyncUpdateFromState_();
    }
	}

	close() {
		this.isOpen = false;
		const expander = this.shadowRoot.querySelector('dd-card-expander');
    if (expander) {
    	expander.close();
    }
	}

  /**
   * updating controls after state change and DOM rendered
   */
  asyncUpdateFromState_() {
		const updateFromState = (this.updateFromState_).bind(this);
    const setInitialized = (this.setInitialized_).bind(this);
    const layout = (this.layout_).bind(this);

		setTimeout(() => {
			updateFromState();
      setInitialized();
      layout();
		}, ASYNC_PAUSE);
	}

  /* eslint class-methods-use-this: "off" */
  layout_() {
    // derived cards implement
  }

  /* eslint class-methods-use-this: "off" */
  updateFromState_() {
    // derived cards implement
  }

  setInitialized_() {
    if(!this._initialized) {
      if (this.isOpen) {
        this.open();
      }
      this._initialized = true;
    }
  }

  /* eslint class-methods-use-this: "off" */
  /**
 * selects using loose (string, int, decimal) value match
 * @param Node select (mwc-select)
 * @param {*} value
 */
  setSelected_(select, value) {
    let i = 0;

    for (i = 0; i < select.items.length; i += 1) {
      if (isNumericallyEqual(select.items[i].value, value)) {
        select.value = select.items[i].value;
        // work around for render bug with mwc-select v0.18
        if ('selectedText' in select) {
          select.selectedText = select.items[i].text;
        }

        break;
      }
    }
  }

  fireGlobalEvent_(eventName, detail = {}) {
    const globalEvent = new CustomEvent(eventName, {
      bubbles: true,
      composed: true,
      detail
    });
    this.dispatchEvent(globalEvent);
  }

  fireLocalEvent_(eventName, detail = {}) {
    const event = new CustomEvent(eventName, { detail });
    this.dispatchEvent(event);
  }

  onMaximize_(id) {
    this.fireLocalEvent_(EVENTS.MAXIMIZE, { id });
  }
}