import { html } from 'lit-element';
import "@material/mwc-button";
import "@material/mwc-icon-button";
import "@material/mwc-icon-button-toggle";
import '@material/mwc-list/mwc-list-item';
import '@material/mwc-select';
import '@material/mwc-tab-bar';
import '@material/mwc-tab';
import "@material/mwc-ripple"

import {RippleHandlers} from '@material/mwc-ripple/ripple-handlers';
import { DdAbstractMenuItemCard } from './dd-abstract-menu-item-card.js';
import { CARD_BODY_ID } from "./dd-card-2.js";
import './dd-card-expander.js';
import '../../dd-max-height-button.js';
import './dd-recipe-card-2.js';
import '../../dd-search.js';
import '../../dd-search-2.js';
import '../../dd-dialog.js';
import { getProteinNameTemplate, getIconTemplate } from '../../template-helpers.js';
import { EVENTS } from '../../events.js';
import { FavoritesProvider, FAVORITES_SET_EVENT } from '../../../services/favorites-provider.js';
import { PersonalRecipesProvider, PERSONAL_RECIPES_SET_EVENT } from '../../../services/personal-recipes-provider.js';
import { PubSub } from '../../../services/pub-sub.js';
import { SideRecipeOptionsProvider } from '../../../services/side-recipe-options-provider.js';
import { sharedStyles } from '../../../theme/shared-styles.js';
import style from './dd-day-card-2.scss';
import { SALES_ICON, SALES_FG } from '../../../theme/color-classes.js';
import { DISH_TYPE } from '../../../utilities/constants.js';
import { toTimeMeasure } from '../../../utilities/text-format.js';
import '@material/mwc-dialog/mwc-dialog.js';


const OPTION_SUGGESTIONS = 'option-suggestions';
const OPTION_FAVORITES = 'option-favorites';
const OPTION_SEARCH = 'option-search';
const OPTION_PERSONALS = 'option-personals';
const SUGGESTIONS_TITLE = 'Suggestions';
const FAVORITES_TITLE = 'Favorites';
const SEARCH_TITLE = 'Search';
const PERSONALS_TITLE = 'My Recipes';
const REPLACE_OPTIONS = [
	{
		id: OPTION_SUGGESTIONS,
		name: SUGGESTIONS_TITLE
	},
	{
		id: OPTION_FAVORITES,
		name: FAVORITES_TITLE
	},
	{
		id: OPTION_PERSONALS,
		name: PERSONALS_TITLE
	},
	{
		id: OPTION_SEARCH,
		name: SEARCH_TITLE
	}
];
Object.freeze(REPLACE_OPTIONS);

const NUTRITION_DISCLAIMER = 'Nutritional values are a general guideline only. Actual results may vary. Please see our Terms & Conditions for our complete policy.';

/**
 * menu day card
 */
class DdDayCard2 extends DdAbstractMenuItemCard {
	static get properties() {
		return {
			searchResults: { type: Object }
		};
	}

	get canAddRecipe() {
		return !(this.hasMain && this.dayMenu.sides && this.dayMenu.sides.length === 2);
	}

	/** pass through to dd-search */
	set searchResults(value) {
		this._searchResults = value;
		this.requestUpdate();
	}

	get searchResults() {
		return this._searchResults;
	}


  set isAddingRecipe(isAddingRecipe) {
		this._isAddingRecipe = isAddingRecipe;
	}

	get isAddingRecipe() {
		return this._isAddingRecipe;
	}

	set dishReplacing(dishReplacing) {
		this._dishReplacing = dishReplacing;
	}

	get dishReplacing() {
		return this._dishReplacing;
	}



	static get styles() {
		return [
			sharedStyles,
			style
		];
	}

	constructor() {
		super();
		this._searchResults = [];
		this._dishReplacing = DISH_TYPE.MAIN;
		this._isAddingRecipe = false;
	}

	// BEGIN LitElement lifecycle
	firstUpdated() {
    super.firstUpdated();
		PubSub.subscribe(FAVORITES_SET_EVENT, (this.requestUpdate).bind(this));
		PubSub.subscribe(PERSONAL_RECIPES_SET_EVENT, (this.requestUpdate).bind(this));
	}

	updated() {
		super.updated();
		const ripples = Array.from(this.shadowRoot.querySelectorAll('mwc-ripple'));
		ripples.forEach((ripple) => {
			if (ripple.dataset.eventListenersPlaced === "true") return;
			ripple.dataset.eventListenersPlaced = "true";
			const rh = new RippleHandlers(async () => ripple);
			const parent = ripple.parentNode;
			parent.addEventListener('mouseenter', rh.startHover);
			parent.addEventListener('mouseleave', rh.endHover);
			parent.addEventListener('mousedown', (e) => {
			  const onMouseUp = () => {
				window.removeEventListener('mouseup', onMouseUp);
				rh.endPress();
			  };

			  window.addEventListener('mouseup', onMouseUp);
			  rh.startPress(e);
			});
			parent.addEventListener('touchstart', (e) => {
			  const onTouchEnd = () => {
				window.removeEventListener('touchend', onTouchEnd);
				rh.endPress();
			  };

			  window.addEventListener('touchend', onTouchEnd);
			  rh.startPress(e);
			});
			parent.addEventListener('focus', rh.startFocus);
			parent.addEventListener('blur', rh.endFocus);
		  });
	}

  render() {
		return html`
      <div id="${CARD_BODY_ID}" class="dd-card-body-2">
			<dd-card-expander
				?isOpen=${this.isOpen}
				?clickableTitle=${true}
				@pre-open="${this.onExpanderOpen_}">
				${this.getTitleTemplate_()}
				<div id="recipes-container" slot="content">
					${this.getRecipeContentTemplate_()}
					<p class="nutrition-overline">nutritional values</p>
					<div class="content-container">
						<p class="dd-body1">${this.getNutritionValues_()}</p>
						<p class="fine-print">${NUTRITION_DISCLAIMER}</p>
					</div>

					<div class="dd-flex-end-container dd-add-button">
						${this.getAddButtonTemplate_()}
					</div>
				</div>
			</dd-card-expander>
      </div>
	<mwc-dialog id="replace-dialog" hideActions @closed="${this.onDialogClosed_}">
		${this.dialogTemplate_()}
	</mwc-dialog>

    `;
  }

    dialogTemplate_() {
		const isMain = this.dishReplacing === DISH_TYPE.MAIN;
		return html`
		<div id="replace-dialog-container">
			<div id="replace-dialog-header" class="dd-flex-top-container">
				<div id="replace-dialog-title" class="dialog-heading">Looking for more options?</div>
				<mwc-icon-button icon="close" @click="${this.onCloseDialogClick_}"></mwc-icon-button>
			</div>
			<dd-search-2 ?isMain="${isMain}" @select-search-option="${this.onSelectSearchOption_}" @search-text-entry="${this.onSearch_}"></dd-search-2>
			<mwc-tab-bar activeIndex="0" @MDCTabBar:activated="${this.onReplaceOptionAction_}">
				<mwc-tab ?stacked="${true}" label="We Suggest" icon="restaurant_menu"></mwc-tab>
				<mwc-tab ?stacked="${true}"  label="Favorites" icon="favorite" ></mwc-tab>
				<mwc-tab ?stacked="${true}"  label="Your Recipes" icon="menu_book"></mwc-tab>
				<mwc-tab ?stacked="${true}"  label="Invisible Dummy" icon="menu_book" class="dd_hide"></mwc-tab>
			</mwc-tab-bar>
			<div id="suggested-container" class="dd_hide options-list">
				${this.getSuggestionsTemplate_(isMain)}
			</div>
			<div id="favorite-container" class="dd_hide options-list">
				${this.getFavoritesTemplate_(isMain)}
			</div>
			<div id="personal-container" class="dd_hide options-list">
				${this.getPersonalsTemplate_(isMain)}
			</div>
			<div id="search-container" class="dd_hide options-list">
				${this.getSearchTemplate_()}
			</div>
		</div>`
	}

  	onSearch_() {
		this.showSearchList_();
		const tabBar = this.shadowRoot.querySelector('mwc-tab-bar');
		if (tabBar) {
			tabBar.activeIndex = 3;
		}
	}

	openWithEvent() {
		this.open();
		this.onMaximize_(this.dayMenu.main.id);
	}

	getSearchOptionTemplate_(itemIdentity) {
		return html`
		  <div class="option-container-search">
			<div
			  .idValue=${itemIdentity.id}
			  @click="${this.onRecipeOptionClick_}">${itemIdentity.name}</div>
			  <mwc-ripple></mwc-ripple>
		  </div>
		`;
		}

	getSearchTemplate_(){
		if (this.searchResults && this.searchResults.length > 0) {
			return this.searchResults.map(searchResult => this.getSearchOptionTemplate_(searchResult), this);
		}
		return html``;
		// return html`<div class="no-results-placeholder"><p>It seems you reached the end of our recipe book, unfortunately we can’t find what you are looking for...</p></div>`;
	}

	getProteinNameTemplate_() {
		if (this.dayMenu && this.hasMain) {
			const proteinCategory = this.dayMenu.main.proteinCategory;
			return getProteinNameTemplate(proteinCategory);
		}

		return '';
	}

	getAddButtonTemplate_() {
		if (this._dayMenu.main) {
			if (this.canAddRecipe) {
				return html`
					<mwc-button
						outlined
						class="light-filled"
						icon="add_circle"
						@click="${this.onAddRecipeClick_}">add side</mwc-button>
					`
			}

			return '';
		}

		return '';
	}

	getRecipeTemplate_(recipe, location, timeToTable = '') {
		if (recipe) {
			return html`
				<dd-recipe-card-2 id="${location}"
					.recipe=${recipe}
					.location=${location}
					.timeToTable=${timeToTable}
					@replace="${this.onChangeRecipe_}"
					@remove="${this.onDeleteRecipe_}"></dd-recipe-card-2>
			`;
		}

		return '';
	}

	getMobileRecipeTemplates_(main, firstSide, secondSide) {
		const timeToTable = toTimeMeasure(this.dayMenu.timeToTable);
		return html`
			<div id="recipe-container">
				${this.getRecipeTemplate_(main, DISH_TYPE.MAIN, timeToTable)}
				${this.getRecipeTemplate_(firstSide, DISH_TYPE.FIRST_SIDE)}
				${this.getRecipeTemplate_(secondSide, DISH_TYPE.SECOND_SIDE)}
			</div>
		`;
	}

	getRecipeContentTemplate_() {
		if (this.dayMenu) {
			const main = this.dayMenu.main;
			const firstSide = (this.dayMenu && this.dayMenu.sides) ? this.dayMenu.sides[0] : null;
			const secondSide = this.dayMenu.sides ? this.dayMenu.sides[1] : null;

			return this.getMobileRecipeTemplates_(main, firstSide, secondSide);
		}

		return '';
	}

	getNutritionValues_() {
		return `${this.dayMenu.calories} Cals, ${this.dayMenu.protein}g Protein, ${this.dayMenu.fat}g Fat, ${this.dayMenu.fiber}g Fiber, ${this.dayMenu.carbs}g Carbs, ${this.dayMenu.saturatedFat}g Saturated Fat, ${this.dayMenu.sodium}mg Sodium`;
	}

	// BEGIN swap dialog
	/**
 *
 * @param {*} dayMenuAlternative ref Symfony \AppBundle\Controller\Rest\DTO\DayMenuAlternative
 */
	getOptionTemplate_(dayMenuAlternative) {
		const iconClass = dayMenuAlternative.isOnSale? SALES_ICON : '';

		return html`
			<div class="option-container">
				<div class="option-button-container">
					<div .idValue=${dayMenuAlternative.id}
								@click="${this.onRecipeOptionClick_}">
								${dayMenuAlternative.name}
					</div>
					<mwc-ripple></mwc-ripple>
				</div>
				${getIconTemplate('', false, '', '', SALES_FG, iconClass)}
			</div>
		`;
	}

	getSuggestionsTemplate_(isMain) {
		const suggestions = this.getSuggestions_(isMain);
		return suggestions.map(suggestion => this.getOptionTemplate_(suggestion), this);
	}

	getFavoritesTemplate_(isMain) {
		const favorites = isMain ? FavoritesProvider.getMains() : FavoritesProvider.getSides();

		if (favorites && favorites.length) {
			return favorites.map(favorite => this.getOptionTemplate_(favorite), this);
		}

		return html`<div class="no-results-placeholder"><p>You didn’t mark any recipes as favorites yet.</p><div>`;
	}

	getPersonalsTemplate_(isMain) {
		const personals = isMain ? PersonalRecipesProvider.getMains() : PersonalRecipesProvider.getSides();

		if (personals && personals.length) {
			return personals.map(personal => this.getOptionTemplate_(personal), this);
		}

		return html`<div class="no-results-placeholder"><p>You have no recipes in your “Book of Personal Recipes”</p><div>`;
	}

	getSuggestions_(isMain) {

		if (isMain) {
			return this.dayMenu.mainRecipeOptions;
		}
		return SideRecipeOptionsProvider.getOptions();
	}

	setInitialReplaceOption_() {
		const tabBar = this.shadowRoot.querySelector('mwc-tab-bar')
		if (tabBar) {
			tabBar.activeIndex = 0;
			this.showReplaceOption_(0);
		}
	}

	onReplaceOptionAction_(event) {
		if (event.detail.index > 2 ) return;
		const search = this.shadowRoot.querySelector('dd-search-2');
		if (search) {
			search.clear();
			search.searchResults = [];
			this.searchResults = [];
		}
		this.showReplaceOption_(event.detail.index);
	}

	showReplaceOption_(value) {
		const array = [()=>this.showSuggestionList_(),()=>this.showFavoriteList_(),()=>this.showPersonalList_(),()=>this.showSearchList_()];
		array[value]();
		this.requestUpdate();
	}

	openReplaceDialog_(location) {
		this.dishReplacing = location;
		this.setInitialReplaceOption_();
		const dialog = this.shadowRoot.querySelector('#replace-dialog');
		dialog.show();
	}

	closeReplaceDialog_() {
		this.hideAllReplacementChoices_();
		const replaceDialog = this.shadowRoot.querySelector('#replace-dialog');
		replaceDialog.close();
		const search = this.shadowRoot.querySelector('dd-search-2');
		if (search) {
			search.clear();
			search.searchResults = [];
			this.searchResults = [];
		}
	}

	showSuggested_() {
		const containerClasses = this.shadowRoot.querySelector('#suggested-container').classList;
		containerClasses.replace('dd_hide', 'dd_show');
	}

	hideSuggested_() {
		const containerClasses = this.shadowRoot.querySelector('#suggested-container').classList;
		containerClasses.replace('dd_show', 'dd_hide');
	}

	showFavorite_() {
		const containerClasses = this.shadowRoot.querySelector('#favorite-container').classList;
		containerClasses.replace('dd_hide', 'dd_show');
	}

	hideFavorite_() {
		const containerClasses = this.shadowRoot.querySelector('#favorite-container').classList;
		containerClasses.replace('dd_show', 'dd_hide');
	}

	showPersonal_() {
		const containerClasses = this.shadowRoot.querySelector('#personal-container').classList;
		containerClasses.replace('dd_hide', 'dd_show');
	}

	hidePersonal_() {
		const containerClasses = this.shadowRoot.querySelector('#personal-container').classList;
		containerClasses.replace('dd_show', 'dd_hide');
	}

	showSearch_() {
		const containerClasses = this.shadowRoot.querySelector('#search-container').classList;
		containerClasses.replace('dd_hide', 'dd_show');
		const ddSearch = this.shadowRoot.querySelector('dd-search-2');
		ddSearch.focus();
	}

	hideSearch_() {
		const containerClasses = this.shadowRoot.querySelector('#search-container').classList;
		containerClasses.replace('dd_show', 'dd_hide');
	}

	hideAllReplacementChoices_() {
		this.hideSuggested_();
		this.hideFavorite_();
		this.hideSearch_();
	}

	onDialogClosed_(event) {
		const tappedOutsideDialog = (event.detail && (event.detail.action === "close"));
		if (tappedOutsideDialog) {
			this.onCloseDialogClick_();
			this.fireLocalEvent_(EVENTS.CLOSE_DIALOG, {});
		}
	}

	onCloseDialogClick_() {
		this.closeReplaceDialog_();
		if(!this.hasMain) {
			this.close();
			this.isActive = false;
		}
	}

	showSuggestionList_() {
		this.showSuggested_();
		this.hideFavorite_();
		this.hidePersonal_();
		this.hideSearch_();
	}

	showFavoriteList_() {
		this.showFavorite_();
		this.hidePersonal_();
		this.hideSuggested_();
		this.hideSearch_();
	}

	showSearchList_() {
		this.showSearch_();
		this.hideSuggested_();
		this.hideFavorite_();
		this.hidePersonal_();
	}

	showPersonalList_() {
		this.showPersonal_();
		this.hideFavorite_();
		this.hideSuggested_();
		this.hideSearch_();
	}
	// END swap dialog

	onAddRecipeClick_() {
		// start with main dish if null, then first side, then second side
		this.isAddingRecipe = true;
		let location = DISH_TYPE.MAIN;
		if (this.hasMain) {
			location = this.dayMenu.sides[0] ? DISH_TYPE.SECOND_SIDE : DISH_TYPE.FIRST_SIDE;
		}
		this.openReplaceDialog_(location, true);
	}

	// BEGIN event generators
	fireRecipeChangeEvent_(recipeId) {
		// event to bubble out of parent's shadow DOM
		const eventName = this.isAddingRecipe ? EVENTS.ADD_RECIPE : EVENTS.REPLACE_RECIPE;
		const detail = {
			location: this.dishReplacing,
			dayMenuId: this.dayMenu.id,
			recipeId
		};
		this.fireGlobalEvent_(eventName, detail);
		this.closeReplaceDialog_();
	}

	fireClearSearchEvent_() {
		this.fireGlobalEvent_(EVENTS.CLEAR_SEARCH, {});
	}

	onChangeRecipe_(event) {
		this.isAddingRecipe = false;
		console.log(event.detail);
		this.openReplaceDialog_(event.detail);
	}

	onDeleteRecipe_(event) {
		const location = event.detail;
		if (location === DISH_TYPE.MAIN) {
			this.onDeleteRecipes_();
		} else {
			const detail = {
				location,
				dayMenuId: this.dayMenu.id
			};
			this.fireGlobalEvent_(EVENTS.DELETE_RECIPE, detail);
		}
	}

	onDeleteRecipes_() {
		this.fireGlobalEvent_(EVENTS.DELETE_RECIPES, this.dayMenu.id);
		this.close();
		this.isActive = false;
	}

	onRecipeOptionClick_(event) {
		this.fireRecipeChangeEvent_(event.target.idValue);
	}

	onSelectSearchOption_(event) {
		this.fireRecipeChangeEvent_(event.detail.recipeId);
	}

	onExpanderOpen_() {
		if (this.dayMenu.main) {
			this.isOpen = true;
			this.onMaximize_(this.dayMenu.main.id);
		}
	}
}

customElements.define('dd-day-card-2', DdDayCard2);