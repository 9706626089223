/**
 * Kroger product map repository
 */
export const KrogerProductsProvider = {
  _productShoppingItemMaps: [],

  setProductShoppingItemMaps(maps) {
    this._productShoppingItemMaps = maps;
  },

  hasProducts() {
    return this._productShoppingItemMaps.length > 0;
  },

  getDefaultProduct(shoppingitemId) {
    const productShoppingItemMap = this._productShoppingItemMaps.find(map => map.shopping_list_item_id === shoppingitemId);

    if (productShoppingItemMap && productShoppingItemMap.products && productShoppingItemMap.products.length) {
      const dto = {
        shoppingListItemId: shoppingitemId
      };

      return Object.assign(dto, productShoppingItemMap.products[0]);
    }

    return null;
  },

  getUnmatchedShoppingItems() {
    return this._productShoppingItemMaps.filter(map => (!map.products || map.products.length === 0));
  }
}
