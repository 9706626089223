import { SET_SEARCH } from '../actions/search';

export const INITIAL_STATE = {
	results: []
};

export function search(state = INITIAL_STATE, action) {
	switch (action.type) {
		case SET_SEARCH:
			return {
				...state,
				results: action.payload.search.results
			};
		default:
			return state;
	}
}

