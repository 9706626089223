/**
 * calculates cost metrics from list of SLIs from SL substate
 * @param {*} shoppingListItems
 * @returns float
 */
export function getCostMetrics(shoppingListItems) {
  const costMetrics = {
    total: 0,
    costItems: 0,
    noCostItems: 0
  };

  shoppingListItems.forEach(sli => {
    if (sli.cost) {
      costMetrics.total += sli.cost;
      costMetrics.costItems += 1;
    } else {
      costMetrics.noCostItems += 1;
    }
  });

  return costMetrics;
}

export function getSaleCount(shoppingList) {
  let count = shoppingList.dairy.filter(item => item.isOnSale).length;
  count +=shoppingList.frozenFoods.filter(item => item.isOnSale).length;
  count +=shoppingList.grocery.filter(item => item.isOnSale).length;
  count +=shoppingList.meatPoultry.filter(item => item.isOnSale).length;
  count +=shoppingList.produce.filter(item => item.isOnSale).length;
  count +=shoppingList.seafood.filter(item => item.isOnSale).length;
  count +=shoppingList.staples.filter(item => item.isOnSale).length;

  return count;
}

export function getProduceCount(shoppingList, frozenIngredients, produceIngredients) {
  const frozenCount = shoppingList.frozenFoods.filter(item => frozenIngredients.includes(item.ingredientId)).length;
  const produceCount = shoppingList.produce.filter(item => produceIngredients.includes(item.ingredientId)).length;

  return frozenCount + produceCount;
}
