import {
  SET_NEWS_FEATURED,
  SET_NEWS_MEMBER,
  SET_NEWS_QUOTE,
  SET_NEWS_WEEKLY_TIP
} from '../actions/news.js'

const INITIAL_STATE = {
  featured: [],
  memberNews: [],
  quote: [],
  weeklyTip: []
};

export function news(state = INITIAL_STATE, action) {
	switch (action.type) {
		case SET_NEWS_FEATURED:
			return {
				...state,
				featured: action.payload.news
			};
    case SET_NEWS_MEMBER:
      return {
        ...state,
        memberNews: action.payload.news
      };
    case SET_NEWS_QUOTE:
      return {
        ...state,
        quote: action.payload.news
      };
    case SET_NEWS_WEEKLY_TIP:
      return {
        ...state,
        weeklyTip: action.payload.news
      };
		default:
			return state;
	}
}