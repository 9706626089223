/**
 * common large screen breakpoint also used in @media queries
 * 1280x800 (netbook) - 32px margin
 * ref: https://ricostacruz.com/til/css-media-query-breakpoints
 */
export const SCREEN_WIDTH_THRESHOLD = 1248;

export function isLargeScreen() {
  return (window.outerWidth >= SCREEN_WIDTH_THRESHOLD);
}
