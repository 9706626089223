import cloneDeep from 'lodash-es/cloneDeep.js';
import { SET_FAVORITE, SET_FAVORITES } from '../actions/favorites.js';
import { DISH_TYPE_MAIN } from '../utilities/constants.js'

const INITIAL_STATE = {
  mains: [],
  sides: []
};

function filterFavorites(recipeOptions, recipeId) {
  const clonedRecipeOptions = cloneDeep(recipeOptions);
  return clonedRecipeOptions.filter(recipeOption => (recipeOption.id !== recipeId));
}

function addFavorite(recipeOptions, recipeId, recipeName) {
  const clonedRecipeOptions = cloneDeep(recipeOptions);
  clonedRecipeOptions.push({
    id: recipeId,
    name: recipeName
  });

  return clonedRecipeOptions;
}

export function favorites(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_FAVORITE: {
      const recipeId = action.payload.recipeId;
      const recipeName = action.payload.name;

      if (DISH_TYPE_MAIN === action.payload.dishType) {
        const mains = (action.payload.isFavorited) ? addFavorite(state.mains, recipeId, recipeName) : filterFavorites(state.mains, recipeId);

        return {
          ...state,
          mains
        };
      }

      const sides = (action.payload.isFavorited) ? addFavorite(state.sides, recipeId, recipeName) : filterFavorites(state.sides, recipeId);

      return {
        ...state,
        sides
      };
    }

    case SET_FAVORITES:
      return {
        ...state,
        mains: action.payload.favorites.mains,
        sides: action.payload.favorites.sides
      };

    default:
      return state;
  }
}

