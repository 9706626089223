import { APP_FLAGS } from '../utilities/constants.js';

const ID_ANY = 'any';

/**
 * provider service wrapper around appFlags substate
 */
export const AppFlagProvider = {
  _flags: {},
  _userId: '',

  /**
   *
   * @param {*} flags
   * @param int userId
   */
  setFlags (flags, userId) {
    this._flags = flags;
    this._userId = userId;
  },

  getFlag (flag) {
    return (typeof this._flags[flag] === 'undefined')? null : this._flags[flag];
  },

  isDebug() {
    return (this.isDevBuild_() || this.getFlag(APP_FLAGS.DEBUG));
  },

  isDev() {
    return (this.isDevBuild_() || this.getFlag(APP_FLAGS.DEV_FEATURE));
  },

  // DEV_BUILD defined by webpack
  /* eslint no-undef: "off" */
  isDevBuild_() {
    return ((typeof DEV_BUILD !== 'undefined') && ((DEV_BUILD === ID_ANY) || (String(DEV_BUILD) === String(this._userId))));
  }
};
