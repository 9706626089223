export const INVALID_ID = -1;
export const TEMPORARY_ID = 0;

/**
* gets first unique new item ID not present in oldItems
* @param {*} oldItems
* @param {*} newItems
* @return null | item ID
*/
export function getNewItemId(oldItems, newItems) {
  const initializationCase = !oldItems.length && newItems.length && (newItems[0].id !== TEMPORARY_ID);
  if (initializationCase) {
    return INVALID_ID;
  }

  const uniqueItems = newItems.filter(newItem => !oldItems.some(oldItem => oldItem.id === newItem.id));
  const newItemId = (uniqueItems.length > 0) ? uniqueItems[0].id : INVALID_ID;
  return newItemId;
}
