import cloneDeep from 'lodash-es/cloneDeep.js';

import {
	REMOVE_EMPTY_RECIPE,
	REMOVE_EMPTY_RECIPE_INGREDIENT,
	SET_PERSONAL_RECIPE,
	SET_PERSONAL_RECIPES,
	SET_RECIPE_INGREDIENT
} from '../actions/personal-recipes';
import { sortByName } from '../utilities/array-helpers.js';
import { TEMPORARY_ID } from '../utilities/new-item-id';
import { isArray } from '../utilities/object-evaluation.js';

const INITIAL_STATE = {
	mains: [],
	sides: []
};

export function personalRecipes(state = INITIAL_STATE, action) {
	const mainsClone = cloneDeep(state.mains);
	const mainEntities = mainsClone.filter(main => main.id > TEMPORARY_ID);

	switch (action.type) {
		case REMOVE_EMPTY_RECIPE:
			return {
				...state,
				mains: mainEntities
			};
		case REMOVE_EMPTY_RECIPE_INGREDIENT:
			return {
				...state,
				mains: mainsClone.map(main => {
					if (main.id === action.payload.recipeId) {
						main.ingredients = main.ingredients.filter(ingredient => ingredient.id > TEMPORARY_ID);
					}

					return main;
				})
			};
		case SET_PERSONAL_RECIPE:
			mainEntities.push(action.payload);

			return {
				...state,
				mains: mainEntities
			};
		case SET_PERSONAL_RECIPES: {
			const stateObjs = action.payload.personalRecipes;

			return {
				...state,
				mains: sortByName(stateObjs.mains),
				sides: sortByName(stateObjs.sides)
			};
		}

		case SET_RECIPE_INGREDIENT:
			return {
				...state,
				mains: mainsClone.map(main => {
					if (main.id === action.payload.recipeId) {
						if (!isArray(main.ingredients)) {
							main.ingredients = [];
						}
						main.ingredients.push({ id: action.payload.id });
					}

					return main;
				})
			};

		default:
			return state;
	}
}
