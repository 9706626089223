import { html, css, LitElement } from 'lit-element';
import '@material/mwc-formfield';
import '@material/mwc-icon';
import '@material/mwc-list/mwc-list-item';
import '@material/mwc-radio';
import '@material/mwc-select';
import { sharedStyles } from '../../theme/shared-styles.js';
import { StateNames } from '../../resources/state-names.js';
import { StatesStores } from '../../resources/states-stores.js';
import { Stores } from '../../resources/stores.js';
import { FAMILY_SIZE } from '../../utilities/constants.js';

const STATE_SELECTED_EVENT = 'state-selected';
const STORE_SELECTED_EVENT = 'store-selected';
const FAMILY_SIZE_CHANGED_EVENT = 'family-size-changed';

function getDefaultStateCode(storeName) {
	const defaultStore = Stores.find( storeItem => storeItem.internalName === storeName);

	let defaultStateCode = '';
	if (defaultStore && defaultStore.states && defaultStore.states.length) {
		defaultStateCode =  defaultStore.states[0];
	}

	return defaultStateCode;
}

function getStateListItemTemplates(storeName = '', statePreselected = false) {
	const defaultStateCode = storeName? getDefaultStateCode(storeName) : '';

	return StateNames.map((stateName) => {
		const selected = !statePreselected && (stateName.code === defaultStateCode);
		return html`
			<mwc-list-item
				value="${stateName.code}"
				?selected="${selected}"
				>${stateName.state}</mwc-list-item>`;
	});
}


/**
 * store selector
 */
 class DdStoreSelect extends LitElement {
	static get properties() {
		return {
      familySize: { type: Number },
      store: { type: String },
      _selectedStateCode: { type: String }
		};
	}

	static get styles() {
		return [
			sharedStyles,
			css`
        mwc-icon {
          --mdc-icon-size: 24px;
          font-size: 24px;
          flex-grow: 0;
          margin-right: 16px;
        }

        mwc-formfield {
          flex-grow: 1;
        }

        p.hint {
          margin: 0 0 32px 0;
		  line-height: 24px;
        }

		span.hint {
			font-size: 10px;
			line-height: 12px;
		}

        .dd-line-divider {
          margin: 16px 0;
        }

        @media only screen and (min-width: 1248px) {
          .dd-line-divider {
            display: none;
          }
        }
			`
		];
	}

	render() {
		const isLarge = (this.familySize === FAMILY_SIZE.full.value);

		return html`
      <p class="hint">This helps us create a weekly menu based on the sales at your selected store!</p>

			<div class="split-section">
				<div class="split-section-child">
					<div class="dd-content-block">
						<mwc-select id="state-select" label="State" class="dd-full-width"
							outlined
							.value=${this._selectedStateCode}
							@action="${this.onStateAction_}">
							${getStateListItemTemplates('', this._selectedStateCode)}
						</mwc-select>
					</div>
					<div class="dd-content-block">
						<mwc-select id="store-select" class="dd-full-width"
							outlined
							label="Store"
							?disabled=${!this._selectedStateCode}
							@action="${this.onStoreAction_}">
							${this.getStoreListItemTemplates_()}
						</mwc-select>
					</div>
					<span class="dd-caption hint">Don’t see your store? Select Any Store and you will still receive an easy and personalized menu. The only difference with the Any Store option is your menu will not be based on the weekly sales flyer at a particular store.</span>
					<div class="dd-line-divider"></div>
				</div>
				<div class="split-section-child">
					<div class="dd-flex-container">
						<mwc-icon class="dd-purple-fg">groups</mwc-icon>
						<mwc-formfield alignEnd spaceBetween label="Serves ${FAMILY_SIZE.full.description}">
							<mwc-radio name="family-size" id="option-simple-family-size-full"
								?checked=${isLarge}
								.value=${FAMILY_SIZE.full.value.toString()}
								@change="${this.onFamilySizeChange_}"></mwc-radio>
						</mwc-formfield>
					</div>
					<div class="dd-flex-container">
						<mwc-icon class="dd-purple-fg">group</mwc-icon>
						<mwc-formfield alignEnd spaceBetween label="Serves ${FAMILY_SIZE.half.description}">
								<mwc-radio name="family-size" id="option-simple-family-size-half"
									?checked=${!isLarge}
									.value=${FAMILY_SIZE.half.value.toString()}
									@change="${this.onFamilySizeChange_}"></mwc-radio>
						</mwc-formfield>
					</div>
				</div>
			</div>
		`;
	}

	focus() {
		const stateSelect = this.shadowRoot.getElementById('state-select');
		if (stateSelect) {
			stateSelect.focus();
		}
	}

  getStoreListItemTemplates_() {
    const storesInState = StatesStores[this._selectedStateCode];

    if (storesInState) {
      storesInState.sort((storeA, storeB) => {
        if (storeA.internalName < storeB.internalName) {
          return -1;
        }
        if (storeA.internalName > storeB.internalName) {
          return 1;
        }

        return 0;
      });

      return storesInState.map((storeItem) => {
        const name = storeItem.alias? storeItem.alias : storeItem.internalName;

        return html`
        <mwc-list-item
          value="${storeItem.internalName}"
          >${name}</mwc-list-item>`;
      });
    }

    return '';
  }

	onFamilySizeChange_(event) {
		const newEvent = new CustomEvent(FAMILY_SIZE_CHANGED_EVENT, {
      bubbles: true,
      composed: true,
      detail: {
				familySize: event.target.value,
				isSilent: true
			}
    });
    this.dispatchEvent(newEvent);
	}

  onStateAction_(event) {
		this._selectedStateCode = event.target.value;

    const newEvent = new CustomEvent(STATE_SELECTED_EVENT, {
      bubbles: true,
      composed: true,
      detail: { state: this._selectedStateCode }
    });
    this.dispatchEvent(newEvent);

		const stateSelect = this.shadowRoot.getElementById('state-select');
		stateSelect.blur();
		const storeSelect = this.shadowRoot.getElementById('store-select');
		storeSelect.focus();
	}

	onStoreAction_(event) {
		// can be any store, including existing
		if (event.target.value) {
			this.store = event.target.value;

			const newEvent = new CustomEvent(STORE_SELECTED_EVENT, {
				detail: {
					store: event.target.value,
					isSilent: true
				}
			});
			this.dispatchEvent(newEvent);
		}
	}
}

customElements.define('dd-store-select', DdStoreSelect);