import cloneDeep from 'lodash-es/cloneDeep.js';
import { SET_SHOPPING_LIST, SET_SHOP_ITEM_CHECKED } from '../actions/shopping-list';

// REST API DTO: AppBundle\Controller\Rest\DTO\ShoppingList
const INITIAL_STATE = {
  id: 0,
  name: '',
  dairy: [],
  frozenFoods: [],
  grocery: [],
  meatPoultry: [],
  produce: [],
  seafood: [],
  staples: [],
  other: []
};

function updateCategoryShopItem(category, shopItemId, isChecked) {
  const clonedCategory = cloneDeep(category);

  return clonedCategory.map(shopItem => {
    if (shopItem.id === shopItemId) {
      shopItem.isChecked = isChecked;
    }
    return shopItem;
  });
}

export function shoppingList(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_SHOPPING_LIST:
      return {
        ...state,
        ...action.payload.shoppingList
      };
    case SET_SHOP_ITEM_CHECKED:
      return {
        ...state,
        dairy: updateCategoryShopItem(state.dairy, action.payload.shopItemId, action.payload.isChecked),
        frozenFoods: updateCategoryShopItem(state.frozenFoods, action.payload.shopItemId, action.payload.isChecked),
        grocery: updateCategoryShopItem(state.grocery, action.payload.shopItemId, action.payload.isChecked),
        meatPoultry: updateCategoryShopItem(state.meatPoultry, action.payload.shopItemId, action.payload.isChecked),
        produce: updateCategoryShopItem(state.produce, action.payload.shopItemId, action.payload.isChecked),
        seafood: updateCategoryShopItem(state.seafood, action.payload.shopItemId, action.payload.isChecked),
        staples: updateCategoryShopItem(state.staples, action.payload.shopItemId, action.payload.isChecked),
        other: updateCategoryShopItem(state.other, action.payload.shopItemId, action.payload.isChecked)
      };
    default:
      return state;
  }
}
