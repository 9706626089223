import { SET_INGREDIENTS } from '../actions/ingredients.js';


const INITIAL_STATE = {
	ingredients: [],
	cookingMeasurements: []
};

export function ingredients(state = INITIAL_STATE, action) {
	switch (action.type) {
		case SET_INGREDIENTS: {
			return {
				...state,
				ingredients: action.payload.ingredients.ingredients,
				cookingMeasurements: action.payload.ingredients.cookingMeasurements
			};
		}

		default:
			return state;
	}
}
