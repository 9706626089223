/**
 * API action for ingredients resource
 * ref: https://github.com/redux-utilities/flux-standard-action
 *      /state/middleware/request-middleware.js
 */
import { axios, getApiUrl, API_REQUEST, startRequest, completeRequest } from './api-base.js';
import { saveIngredients } from '../ingredients.js';

// REST resources
const INGREDIENTS_RESOURCE = 'ingredients';

/**
 * returns action to monitor with requestMiddleware: { payload: async function }
 */
export function requestIngredients() {
	return {
		type: API_REQUEST,
		payload (dispatch, token) {
			dispatch(startRequest());

			const menuUrl = getApiUrl(INGREDIENTS_RESOURCE);

			return axios.get(menuUrl, {
				headers: { Authorization: `Bearer ${token}` }
			})
				.then(response => {
					dispatch([
						saveIngredients(response.data),
						completeRequest()
					]);
				})
				.catch(error => {
					console.log(error);
				});
		}
	}
}
