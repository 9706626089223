/**
 * menu data actions
 */
import { toCamelCase } from '../utilities/object-evaluation.js';


export const SET_FAVORITES = 'SET_FAVORITES';
export const SET_FAVORITE = 'SET_FAVORITE';

export function saveFavorites(favoritesDto) {
  const favorites = toCamelCase(favoritesDto)

  return {
    type: SET_FAVORITES,
    payload: { favorites	}
  }
}

/**
 * @param {*} favoriteEventDetail ref DdRecipeCard.getFavoritedDTO_()
 */
export function setFavorite(favoriteEventDetail) {
  return {
    type: SET_FAVORITE,
    payload: {
      recipeId: favoriteEventDetail.recipeId,
      isFavorited: favoriteEventDetail.isFavorited,
      dishType: favoriteEventDetail.dishType,
      name: favoriteEventDetail.name
    }
  }
}
