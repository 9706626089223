/**
 * API actions for recipes resource
 * ref: https://github.com/redux-utilities/flux-standard-action
 *      /state/middleware/request-middleware.js
 *      https://docs.google.com/spreadsheets/d/1MmffvNk7OJFhEYA2ZSUOVZ6OT_um1848_o-EpzCv1MA/edit?usp=sharing
 */
import {
	axios,
	getApiUrl,
	API_REQUEST,
	startRequest,
	completeRequest,
	NOK_RESPONSE_HINT
} from './api-base.js';
import { saveFavorites } from '../favorites.js';
import { createPersonalRecipe, savePersonalRecipes } from '../personal-recipes.js';
import { saveSearch } from '../search.js';

// REST resources
const FAVORITES_ENDPOINT = 'recipes/favorites';
const SEARCH_ENDPOINT = 'recipes/search'
const PERSONAL_RECIPES_ENDPOINT = 'recipes/personal';

function getFavoriteEndpoint(recipeId) {
	return `recipes/${recipeId}/favorite`;
}

function getSearchEndpoint(dishType, searchText) {
	return `${SEARCH_ENDPOINT}?dish-type=${dishType}&search-text=${searchText}`;
}

export function requestSearch(dishType, searchText) {
  return {
		type: API_REQUEST,
		payload (dispatch, token) {
			dispatch(startRequest());

			const searchEndpoint = getSearchEndpoint(dishType, searchText);
			const url = getApiUrl(searchEndpoint);

			return axios.get(url, {
				headers: { Authorization: `Bearer ${token}` }
			})
				.then(response => {
					dispatch([
						saveSearch(response.data),
						completeRequest()
					]);
				})
				.catch(error => {
					console.log(error);
				});
		}
	}
}

export function requestFavorites() {
  return {
		type: API_REQUEST,
		payload (dispatch, token) {
			dispatch(startRequest());

			const url = getApiUrl(FAVORITES_ENDPOINT);

			return axios.get(url, {
				headers: { Authorization: `Bearer ${token}` }
			})
				.then(response => {
					dispatch([
						saveFavorites(response.data),
						completeRequest()
					]);
				})
				.catch(error => {
					console.log(error);
				});
		}
	}
}

export function requestAddFavorite(recipeId) {
	return {
		type: API_REQUEST,
		payload (dispatch, token) {
			dispatch(startRequest());

      const favoriteEndpoint = getFavoriteEndpoint(recipeId);
			const url = getApiUrl(favoriteEndpoint);

			return axios.post(url,
				[],
				{
				headers: { Authorization: `Bearer ${token}` }
			})
				.then(response => {
					dispatch([
						saveFavorites(response.data),
						completeRequest()
					]);
				})
				.catch(error => {
					dispatch(completeRequest(NOK_RESPONSE_HINT));
					console.log(error);
				});
		}
	}
}

export function requestRemoveFavorite(recipeId) {
	return {
		type: API_REQUEST,
		payload (dispatch, token) {
			dispatch(startRequest());

      const favoriteEndpoint = getFavoriteEndpoint(recipeId);
			const url = getApiUrl(favoriteEndpoint);

			return axios.delete(url, {
				headers: { Authorization: `Bearer ${token}` }
			})
				.then(response => {
					dispatch([
						saveFavorites(response.data),
						completeRequest()
					]);
				})
				.catch(error => {
					dispatch(completeRequest(NOK_RESPONSE_HINT));
					console.log(error);
				});
		}
	}
}

export function requestPersonalRecipes() {
  return {
		type: API_REQUEST,
		payload (dispatch, token) {
			dispatch(startRequest());

			const url = getApiUrl(PERSONAL_RECIPES_ENDPOINT);

			return axios.get(url, {
				headers: { Authorization: `Bearer ${token}` }
			})
				.then(response => {
					dispatch([
						savePersonalRecipes(response.data),
						completeRequest()
					]);
				})
				.catch(error => {
					console.log(error);
				});
		}
	}
}

export function requestCreatePersonalRecipe(name, dishType) {
  return {
		type: API_REQUEST,
		payload (dispatch, token) {
			dispatch(startRequest());

			const url = getApiUrl(PERSONAL_RECIPES_ENDPOINT);

			return axios.post(url,
				{
					name,
					dishType
				},
				{
				headers: { Authorization: `Bearer ${token}` }
			})
				.then(response => {
					dispatch([
						createPersonalRecipe(response.data),
						completeRequest()
					]);
				})
				.catch(error => {
					dispatch(completeRequest(NOK_RESPONSE_HINT));
					console.log(error);
				});
		}
	}
}

export function requestUpdatePersonalRecipe(recipe) {
  return {
		type: API_REQUEST,
		payload (dispatch, token) {
			dispatch(startRequest());

			const updateEndpoint = `${PERSONAL_RECIPES_ENDPOINT}/${recipe.id}`;
			const url = getApiUrl(updateEndpoint);

			return axios.put(url,
				recipe,
				{
				headers: { Authorization: `Bearer ${token}` }
			})
				.then(response => {
					dispatch([
						savePersonalRecipes(response.data),
						completeRequest()
					]);
				})
				.catch(error => {
					dispatch(completeRequest(NOK_RESPONSE_HINT));
					console.log(error);
				});
		}
	}
}

export function requestDeletePersonalRecipe(recipeId) {
  return {
		type: API_REQUEST,
		payload (dispatch, token) {
			dispatch(startRequest());

			const deleteEndpoint = `${PERSONAL_RECIPES_ENDPOINT}/${recipeId}`;
			const url = getApiUrl(deleteEndpoint);

			return axios.delete(url, {
				headers: { Authorization: `Bearer ${token}` }
			})
				.then(response => {
					dispatch([
						savePersonalRecipes(response.data),
						completeRequest()
					]);
				})
				.catch(error => {
					dispatch(completeRequest(NOK_RESPONSE_HINT));
					console.log(error);
				});
		}
	}
}

/**
 *
 * @param {*} dto { recipeId, ingredientId, amount, cookingMeasurementId }
 */
export function requestAddPersonalRecipeIngredient(dto) {
  return {
		type: API_REQUEST,
		payload (dispatch, token) {
			dispatch(startRequest());

			const addIngredientEndpoint = `${PERSONAL_RECIPES_ENDPOINT}/${dto.recipeId}/ingredients`;
			const url = getApiUrl(addIngredientEndpoint);

			return axios.post(url,
				dto,
				{
				headers: { Authorization: `Bearer ${token}` }
			})
				.then(response => {
					dispatch([
						savePersonalRecipes(response.data),
						completeRequest()
					]);
				})
				.catch(error => {
					console.log(error);
				});
		}
	}
}

/**
 *
 * @param {*} dto { recipeId, recipeIngredientId }
 */
export function requestRemoveRecipeIngredient(dto) {
	return {
		type: API_REQUEST,
		payload (dispatch, token) {
			dispatch(startRequest());

			const removeIngredientEndpoint = `${PERSONAL_RECIPES_ENDPOINT}/${dto.recipeId}/ingredients/${dto.recipeIngredientId}`;
			const url = getApiUrl(removeIngredientEndpoint);

			return axios.delete(url, {
				headers: { Authorization: `Bearer ${token}` }
			})
				.then(response => {
					dispatch([
						savePersonalRecipes(response.data),
						completeRequest()
					]);
				})
				.catch(error => {
					dispatch(completeRequest(NOK_RESPONSE_HINT));
					console.log(error);
				});
		}
	}
}
