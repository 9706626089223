import { html, css, LitElement } from 'lit-element';
import "@material/mwc-icon-button-toggle";
import { sharedStyles } from '../../../theme/shared-styles.js';

// on click
const PRE_OPEN_EVENT = 'pre-open';
const PRE_CLOSE_EVENT = 'pre-close';

// after transition animation
const OPEN_EVENT = 'open';
const CLOSE_EVENT = 'close';

// delay for open/close transitions to complete
export const TRANSITION_PAUSE = 200;

function getTooltip(isExpanded) {
	return isExpanded? 'see less' : 'see more';
}

class DdCardExpander extends LitElement {
	static get properties() {
		return {
			isOpen: { type: Boolean },
			clickableTitle: { type: Boolean },
			closedIcon: { type: String },
			indentTitle: { type: Boolean, reflect: true }
		};
	}

	static get styles() {
		return [
			sharedStyles,
			css`
				#container {
					position: relative;
				}
				#content-panel {
					transition: max-height 0.3s ease;
					overflow: hidden;
				}
        .normal_title {
          font-weight: normal;
        }

				.indent-title {
					padding-left: 12px;
				}

				.minimized {
					max-height: 0;
				}

				.maximized {
					max-height: 2000px;
				}

				#title-box {
					/* vertically centered full height slot */
					position: relative;
					display: flex;
					justify-content: center;
					flex-direction: column;
					min-height: 48px;

					flex-grow: 10;
				}
			`
		];
	}

	constructor() {
		super();
		this.closedIcon = 'expand_more';
	}

  firstUpdated () {
    super.firstUpdated();
    if (this.isOpen) {
      this.shadowRoot.querySelector('#expand-toggle').on = true;
      this.onResize_();
    }
  }

	render() {
		const indentClass = this.indentTitle? 'indent-title' : '';
		const cursorClass = this.clickableTitle ? 'clickable' : '';

		return html`
			<div id="container">
				<div class="dd-flex-top-container ${indentClass}">
					<div id="title-box" @click="${this.onTitleClick_}" class="${cursorClass}">
						<slot name="title"></slot>
					</div>
					<mwc-icon-button-toggle
						id="expand-toggle"
						onIcon="expand_less"
						offIcon="${this.closedIcon}"
						title="${getTooltip(false)}"
						@click="${this.onClick_}"></mwc-icon-button-toggle>
				</div>
				<div id="content-panel" class="minimized">
          <slot name="content"></slot>
				</div>
		</div>
		`;
	}

	open() {
		const expandToggle = this.shadowRoot.querySelector('#expand-toggle');
		if (expandToggle) {
			expandToggle.on = true;
			this.onResize_();
		}
	}

	close() {
		const expandToggle = this.shadowRoot.querySelector('#expand-toggle');
		if (expandToggle) {
			expandToggle.on = false;
			this.onResize_();
		}
	}

	fireEvent_(name) {
    const event = new CustomEvent(name, {
      detail: {}
    });
    this.dispatchEvent(event);
	}

	fireEventSequence_(isExpanded) {
		const preEventName = isExpanded? PRE_OPEN_EVENT : PRE_CLOSE_EVENT;
		this.fireEvent_(preEventName);

		const eventName = isExpanded? OPEN_EVENT : CLOSE_EVENT;
		const fireEvent = (this.fireEvent_).bind(this);
		setTimeout(() => {
			fireEvent(eventName);
		}, TRANSITION_PAUSE);
	}

	onTitleClick_() {
		if (this.clickableTitle) {
			const expandToggle = this.shadowRoot.querySelector('#expand-toggle');
			expandToggle.on = !expandToggle.on;
			this.onClick_();
		}
	}

	onClick_() {
		this.onResize_();
		this.fireEventSequence_(this.isOpen);
	}

	onResize_() {
		const expandToggle = this.shadowRoot.querySelector('#expand-toggle');
		this.isOpen = expandToggle.on;
		const optionsPanelClasses = this.shadowRoot.querySelector('#content-panel').classList;
		if (this.isOpen) {
			optionsPanelClasses.replace('minimized', 'maximized');
		} else {
			optionsPanelClasses.replace('maximized', 'minimized');
		}

		expandToggle.setAttribute('title', getTooltip(this.isOpen));
	}
}

customElements.define('dd-card-expander', DdCardExpander);