
/**
 * alphabetically sort array of named objects
 * @param array namedItems { name }[]
 * @return array
 */
 export function sortByName(namedItems) {
  return namedItems.sort((a, b) => {
    const nameA = Object.prototype.hasOwnProperty.call(a, 'name')? a.name.toUpperCase() : ''; // ignore upper and lowercase
    const nameB = Object.prototype.hasOwnProperty.call(b, 'name')? b.name.toUpperCase() : ''; // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  })
}