/**
 * diet plan options actions
 */
import { toCamelCase } from '../utilities/object-evaluation.js';

export const SET_DIET_PLAN_OPTIONS = 'SET_DIET_PLAN_OPTIONS';

export function saveDietPlanOptions(dto) {
  return {
    type: SET_DIET_PLAN_OPTIONS,
    payload: {
      dietPlanOptions: toCamelCase(dto)
    }
  }
}
