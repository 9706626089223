/**
 * determines TDD/White Label build variant
 */

export const BUILD_VARIANT = {
  tdd: 0,
  whiteLabelInIframe: 1,
  whiteLabelInstalled: 2
};
Object.freeze(BUILD_VARIANT);

/**
 * @return true if in iframe
 */
function inIframe() {
  try {
      return window.self !== window.top;
  } catch (e) {
      return true;
  }
}

// BUILD defined by webpack
/* eslint no-undef: "off" */
export function getBuildVariant()
{
  if (BUILD === 'tdd') {
    return BUILD_VARIANT.tdd;
  }

  return inIframe()? BUILD_VARIANT.whiteLabelInIframe : BUILD_VARIANT.whiteLabelInstalled;
}
