/**
 * API actions for the fulfillment endpoint
 * ref: https://github.com/redux-utilities/flux-standard-action
 *      /state/middleware/request-middleware.js
 */
import {axios,
	getApiUrl,
	getParameters,
	API_REQUEST,
	startRequest,
	completeRequest,
	NOK_RESPONSE_HINT
} from './api-base.js';
import {
	saveFulfillmentAccounts,
	saveFulfillmentKrogerLocations,
	saveFulfillmentKrogerProduct,
	saveFulfillmentKrogerProducts
} from '../fulfillment.js';
import { saveShoppingList } from '../shopping-list.js';
import { saveRecipeShopItems } from '../recipe-shop-items.js';

// REST resources
const FEATURES_RESOURCE = 'fulfillment';
const AUTH_ERROR_HINT = 'Press ORDER to login to your delivery service.';

/**
 * returns action to monitor with requestMiddleware: { payload: async function }
 */
export function requestFulfillmentAccounts(message) {
	return {
		type: API_REQUEST,
		payload(dispatch, token) {
			dispatch(startRequest(message));

			const endpoint = `${FEATURES_RESOURCE}/account-status`;
			const url = getApiUrl(endpoint);

			return axios.get(url, {
				headers: { Authorization: `Bearer ${token}` }
			})
				.then(response => {
					dispatch([
						saveFulfillmentAccounts(response.data),
						completeRequest()
					]);
				})
				.catch(error => {
					console.log(error);
				});
		}
	}
}

/**
 * returns action to monitor with requestMiddleware: { payload: async function }
 * silent call (doesn't manage api.isFetching state)
 * @param object params { lat, long }
 */
export function requestFulfillmentKrogerLocations(params) {
	return {
		type: API_REQUEST,
		payload(dispatch, token) {
			const endpoint = `${FEATURES_RESOURCE}/kroger/locations`;
			const url = getApiUrl(endpoint);

			return axios.get(url, {
				headers: { Authorization: `Bearer ${token}` },
				params
			})
				.then(response => {
					dispatch(saveFulfillmentKrogerLocations(response.data));
				})
				.catch(error => {
					dispatch(requestFulfillmentAccounts(AUTH_ERROR_HINT));
					console.log(error);
				});
		}
	}
}

/**
 * returns action to monitor with requestMiddleware: { payload: async function }
 * silent call (doesn't manage api.isFetching state)
 * @param string locationId
 * @param int shoppingListItemId
 */
export function requestFulfillmentKrogerProduct(locationId, shoppingListItemId) {
	return {
		type: API_REQUEST,
		payload(dispatch, token) {
			const endpoint = `${FEATURES_RESOURCE}/kroger/products/${shoppingListItemId}`;
			const url = getApiUrl(endpoint);

			return axios.get(url, {
				headers: { Authorization: `Bearer ${token}` },
				params: {
					locationId
				}
			})
				.then(response => {
					dispatch(saveFulfillmentKrogerProduct(response.data));
				})
				.catch(error => {
					console.log(error);
				});
		}
	}
}

/**
 * returns action to monitor with requestMiddleware: { payload: async function }
 * silent call (doesn't manage api.isFetching state)
 * @param string locationId
 * @param int shoppingListId
 */
export function requestFulfillmentKrogerProducts(locationId, shoppingListId) {
	return {
		type: API_REQUEST,
		payload(dispatch, token) {
			const endpoint = `${FEATURES_RESOURCE}/kroger/products`;
			const url = getApiUrl(endpoint);

			return axios.get(url, {
				headers: { Authorization: `Bearer ${token}` },
				params: {
					locationId,
					shoppingListId
				}
			})
				.then(response => {
					dispatch(saveFulfillmentKrogerProducts(response.data));
				})
				.catch(error => {
					console.log(error);
				});
		}
	}
}

/**
 * send shopping list to Peapod for fulfillment
 * @param int shoppingListId
 */
export function requestPeapodFulfillment(previousWeek, shoppingListId) {
	return {
		type: API_REQUEST,
		payload(dispatch, token) {
			dispatch(startRequest('We sent your order to Peapod.'));

			const endpoint = `${FEATURES_RESOURCE}/peapod`;
			const url = getApiUrl(endpoint);

			return axios.post(url,
				{ shoppingListId },
				{
					params: getParameters(previousWeek),
					headers: { Authorization: `Bearer ${token}` }
				})
				.then(response => {
					dispatch([
						saveShoppingList(response.data),
						saveRecipeShopItems(response.data),
						completeRequest()
					]);
				})
				.catch(() => {
					dispatch(completeRequest(NOK_RESPONSE_HINT));
				});
		}
	}
}

/**
 * send shopping list to Kroger for fulfillment
 * @param int shoppingListId
 */
export function requestKrogerFulfillment(previousWeek, shoppingListId, locationId, productMaps, storeName) {
	return {
		type: API_REQUEST,
		payload(dispatch, token) {
			dispatch(startRequest(`We sent your order to ${storeName}.`));

			const endpoint = `${FEATURES_RESOURCE}/kroger`;
			const url = getApiUrl(endpoint);

			return axios.post(url,
				{
					shoppingListId,
					locationId,
					productMaps
				},
				{
					params: getParameters(previousWeek),
					headers: { Authorization: `Bearer ${token}` }
				})
				.then(response => {
					dispatch([
						saveShoppingList(response.data),
						saveRecipeShopItems(response.data),
						completeRequest()
					]);
				})
				.catch(() => {
					dispatch(completeRequest(NOK_RESPONSE_HINT));
				});
		}
	}
}