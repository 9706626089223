import { html, css, LitElement } from 'lit-element';
import "@material/mwc-icon-button-toggle";
import "@material/mwc-icon-button";
import { sharedStyles } from '../../../theme/shared-styles.js';

// on click
const PRE_OPEN_EVENT = 'pre-open';

// delay for open/close transitions to complete
export const TRANSITION_PAUSE = 200;

function getTooltip(isExpanded) {
	return isExpanded? 'see less' : 'see more';
}

class DdShopItemExpander extends LitElement {
	static get properties() {
		return {
			isOpen: { type: Boolean },
			clickableTitle: { type: Boolean },
			indentTitle: { type: Boolean, reflect: true }
		};
	}

	static get styles() {
		return [
			sharedStyles,
			css`
				#container {
					position: relative;
					min-height: 56px;
				}
				#content-panel {
					transition: max-height 0.3s ease;
					overflow: hidden;
				}

				.normal_title {
					font-weight: normal;
				}

				.indent-title {
					padding-left: 12px;
				}

				.minimized {
					max-height: 0;
				}

				.maximized {
					max-height: 2000px;
				}

				#title-box {
					/* vertically centered full height slot */
					position: relative;
					display: flex;
					justify-content: center;
					flex-direction: column;
					min-height: 48px;

					flex-grow: 10;
				}
				mwc-icon-button {
					color: black;
				}

				#notes-box, #sales-box {
					margin-left: 48px;
				}
			`
		];
	}

	constructor() {
		super();
		this.closedIcon = 'expand_more';
		this.openIcon = 'expand_less';
	}

  firstUpdated () {
    super.firstUpdated();
    if (this.isOpen) {
      // this.shadowRoot.querySelector('#expand-toggle').on = true;
      // this.onResize_();
    }
  }

	render() {
		const indentClass = this.indentTitle? 'indent-title' : '';
		const cursorClass = this.clickableTitle ? 'clickable' : '';

		return html`
			<div id="container">
				<div class="dd-flex-top-container ${indentClass}">
					<div id="title-box" @click="${this.onTitleClick_}" class="${cursorClass}">
						<slot name="title"></slot>
					</div>
					${this.isOpen ? '' : html`<mwc-icon-button
						id="expand-toggle"
						icon="arrow_drop_down"
						title="${getTooltip(false)}"
						@click="${this.open}"></mwc-icon-button>`}

				</div>

				<div class="dd-flex-top-container ${indentClass}">
					<div id="notes-box" @click="${this.onTitleClick_}" class="${cursorClass}">
						<slot name="notes"></slot>
					</div>
				</div>

				<div class="dd-flex-top-container ${indentClass}">
					<div id="sales-box" @click="${this.onTitleClick_}" class="${cursorClass}">
						<slot name="sales"></slot>
					</div>
				</div>

				<div id="content-panel" class="minimized">
          			<slot name="content"></slot>
				</div>
			</div>
		`;
	}

	open() {
		this.isOpen = true;
		const optionsPanelClasses = this.shadowRoot.querySelector('#content-panel').classList;
		optionsPanelClasses.replace('minimized', 'maximized');
		const notesBox = this.shadowRoot.querySelector('#notes-box');
		notesBox.classList.add('dd_hide');
		const salesBox = this.shadowRoot.querySelector('#sales-box');
		salesBox.classList.add('dd_hide');
		this.fireEvent_(PRE_OPEN_EVENT);
	}

	close() {
		this.isOpen = false;
		const optionsPanelClasses = this.shadowRoot.querySelector('#content-panel').classList;
		optionsPanelClasses.replace('maximized', 'minimized');
		const notesBox = this.shadowRoot.querySelector('#notes-box');
		notesBox.classList.remove('dd_hide');
		const salesBox = this.shadowRoot.querySelector('#sales-box');
		salesBox.classList.remove('dd_hide');
	}

	fireEvent_(name) {
    const event = new CustomEvent(name, {
      detail: {}
    });
    this.dispatchEvent(event);
	}

	// fireEventSequence_(isExpanded) {
	// 	const preEventName = isExpanded? PRE_OPEN_EVENT : PRE_CLOSE_EVENT;
	// 	this.fireEvent_(preEventName);

	// 	const eventName = isExpanded? OPEN_EVENT : CLOSE_EVENT;
	// 	const fireEvent = (this.fireEvent_).bind(this);
	// 	setTimeout(() => {
	// 		fireEvent(eventName);
	// 	}, TRANSITION_PAUSE);
	// }

	onTitleClick_() {
		if (this.clickableTitle) {
			const expandToggle = this.shadowRoot.querySelector('#expand-toggle');
			expandToggle.on = !expandToggle.on;
			this.onClick_();
		}
	}

	// onClick_() {
	// 	this.onResize_();
	// 	this.fireEventSequence_(this.isOpen);
	// }

	// onResize_() {
	// 	const expandToggle = this.shadowRoot.querySelector('#expand-toggle');
	// 	this.isOpen = expandToggle.on;
	// 	const optionsPanelClasses = this.shadowRoot.querySelector('#content-panel').classList;
	// 	if (this.isOpen) {
	// 		optionsPanelClasses.replace('minimized', 'maximized');
	// 	} else {
	// 		optionsPanelClasses.replace('maximized', 'minimized');
	// 	}

	// 	expandToggle.setAttribute('title', getTooltip(this.isOpen));
	// }
}

customElements.define('dd-shop-item-expander', DdShopItemExpander);