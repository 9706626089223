/**
 * listener to persist changes to app flags
 */
import {
  APP_FLAGS,
  TOUCH_STATUS
} from '../utilities/constants.js';
import { readItem, saveItem } from '../utilities/local-storage.js';

/* eslint no-unneeded-ternary: "off" */
export function readAppFlags() {
  const appFlags = {};
  // read-only
  appFlags[`${APP_FLAGS.DEBUG}`] = readItem(APP_FLAGS.DEBUG)? true : false;
  appFlags[`${APP_FLAGS.DEV_FEATURE}`] = readItem(APP_FLAGS.DEV_FEATURE)? true : false;
  appFlags[`${APP_FLAGS.LAT_OVERRIDE}`] = readItem(APP_FLAGS.LAT_OVERRIDE);
  appFlags[`${APP_FLAGS.LONG_OVERRIDE}`] = readItem(APP_FLAGS.LONG_OVERRIDE);
  appFlags[`${APP_FLAGS.TOUCH_STATUS_OVERRIDE}`] = readItem(APP_FLAGS.TOUCH_STATUS_OVERRIDE)?? TOUCH_STATUS.NONE;

  // read-write
  appFlags[`${APP_FLAGS.ONBOARDING_COMPLETED}`] = readItem(APP_FLAGS.ONBOARDING_COMPLETED)? true : false;
  appFlags[`${APP_FLAGS.FIRST_VISIT_COMPLETED}`] = readItem(APP_FLAGS.FIRST_VISIT_COMPLETED)? true : false;
  appFlags[`${APP_FLAGS.NOTIFIED_NO_STORES}`] = readItem(APP_FLAGS.NOTIFIED_NO_STORES)? true : false;
  appFlags[`${APP_FLAGS.PREVIOUS_WEEK}`] = readItem(APP_FLAGS.PREVIOUS_WEEK)? true : false;
  appFlags[`${APP_FLAGS.SENT_TO_SERVER}`] = readItem(APP_FLAGS.SENT_TO_SERVER);
  appFlags[`${APP_FLAGS.VISITED_CLASSIC}`] = readItem(APP_FLAGS.VISITED_CLASSIC)? true : false;
  appFlags[`${APP_FLAGS.NOTIFIED_BELOW_LIMIT}`] = readItem(APP_FLAGS.NOTIFIED_BELOW_LIMIT)? true : false;
  appFlags[`${APP_FLAGS.MENU_ID}`] = readItem(APP_FLAGS.MENU_ID)? readItem(APP_FLAGS.MENU_ID) : '0';
  appFlags[`${APP_FLAGS.MENU_REQUEST_COUNT}`] = readItem(APP_FLAGS.MENU_REQUEST_COUNT)? readItem(APP_FLAGS.MENU_REQUEST_COUNT) : '0';
  appFlags[`${APP_FLAGS.NOTIFIED_NOT_MAXIMIZED}`] = readItem(APP_FLAGS.NOTIFIED_NOT_MAXIMIZED)? true : false;
  appFlags[`${APP_FLAGS.CREATE_MENU_TIMESTAMP}`] = readItem(APP_FLAGS.CREATE_MENU_TIMESTAMP)? readItem(APP_FLAGS.CREATE_MENU_TIMESTAMP) : 0;
  appFlags[`${APP_FLAGS.SELECTED_LOCATION}`] = readItem(APP_FLAGS.SELECTED_LOCATION);
  appFlags[`${APP_FLAGS.SELECTED_STATE}`] = readItem(APP_FLAGS.SELECTED_STATE);

  return appFlags;
}

export function saveAppFlags(appFlagSubstate) {
  saveItem(APP_FLAGS.FIRST_VISIT_COMPLETED, appFlagSubstate[`${APP_FLAGS.FIRST_VISIT_COMPLETED}`]);
  saveItem(APP_FLAGS.NOTIFIED_NO_STORES, appFlagSubstate[`${APP_FLAGS.NOTIFIED_NO_STORES}`]);
  saveItem(APP_FLAGS.PREVIOUS_WEEK, appFlagSubstate[`${APP_FLAGS.PREVIOUS_WEEK}`]);
  saveItem(APP_FLAGS.SENT_TO_SERVER, appFlagSubstate[`${APP_FLAGS.SENT_TO_SERVER}`]);
  saveItem(APP_FLAGS.VISITED_CLASSIC, appFlagSubstate[`${APP_FLAGS.VISITED_CLASSIC}`]);
  saveItem(APP_FLAGS.NOTIFIED_BELOW_LIMIT, appFlagSubstate[`${APP_FLAGS.NOTIFIED_BELOW_LIMIT}`]);
  saveItem(APP_FLAGS.NOTIFIED_NOT_MAXIMIZED, appFlagSubstate[`${APP_FLAGS.NOTIFIED_NOT_MAXIMIZED}`]);
  saveItem(APP_FLAGS.MENU_ID, appFlagSubstate[`${APP_FLAGS.MENU_ID}`]);
  saveItem(APP_FLAGS.MENU_REQUEST_COUNT, appFlagSubstate[`${APP_FLAGS.MENU_REQUEST_COUNT}`]);
  saveItem(APP_FLAGS.ONBOARDING_COMPLETED, appFlagSubstate[`${APP_FLAGS.ONBOARDING_COMPLETED}`]);
  saveItem(APP_FLAGS.CREATE_MENU_TIMESTAMP, appFlagSubstate[`${APP_FLAGS.CREATE_MENU_TIMESTAMP}`]);
  saveItem(APP_FLAGS.SELECTED_LOCATION, appFlagSubstate[`${APP_FLAGS.SELECTED_LOCATION}`]);
  saveItem(APP_FLAGS.SELECTED_STATE, appFlagSubstate[`${APP_FLAGS.SELECTED_STATE}`]);
}
