/**
 * API action for shopping-list resource
 * ref: https://github.com/redux-utilities/flux-standard-action
 *      /state/middleware/request-middleware.js
 */
import { axios, getApiUrl, getParameters, API_REQUEST, startRequest, completeRequest } from './api-base.js';
import { saveShoppingList } from '../shopping-list.js';
import { saveRecipeShopItems } from '../recipe-shop-items.js';
import { PubSub } from '../../services/pub-sub.js';
import { INVALID_TOKEN_EVENT } from '../../utilities/constants.js';

// REST resources
const SHOPPING_LIST_RESOURCE = 'shopping-list';

/**
 * returns action to monitor with requestMiddleware: { payload: async function }
 */
export function requestShoppingList(previousWeek) {
	return {
		type: API_REQUEST,
		payload (dispatch, token) {
			dispatch(startRequest());

			const menuUrl = getApiUrl(SHOPPING_LIST_RESOURCE);

			return axios.get(menuUrl, {
				params: getParameters(previousWeek),
				headers: { Authorization: `Bearer ${token}` }
			})
				.then(response => {
					dispatch([
						saveShoppingList(response.data),
						saveRecipeShopItems(response.data),
						completeRequest()
					]);
				})
				.catch(error => {
					console.log(error);
					if (error.response.status === 401) {
						PubSub.publish(INVALID_TOKEN_EVENT);
					}
				});
		}
	}
}
