/**
 * ref: https://medium.com/@thebabscraig/javascript-design-patterns-part-2-the-publisher-subscriber-pattern-8fe07e157213
 *  */
export const PubSub = {
  subscribers: {},

  subscribe (eventName, callback) {
    if (!PubSub.subscribers[eventName]) {
      PubSub.subscribers[eventName] = [];
    }

    const index = PubSub.subscribers[eventName].push(callback) - 1;

    return {
      unsubscribe() {
        PubSub.subscribers[eventName].splice(index, 1);
      }
    }
  },

  publish (eventName, data) {
    if (!PubSub.subscribers[eventName]) {
      return;
    }

    PubSub.subscribers[eventName].forEach(subscriberCallback => subscriberCallback(data));
  }
};
