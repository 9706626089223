import { html, css, LitElement } from 'lit-element';
import "@material/mwc-icon-button-toggle";
import { sharedStyles } from '../theme/shared-styles.js';

const PRE_OPEN_EVENT = 'pre-open';
const PRE_CLOSE_EVENT = 'pre-close';
const OPEN_EVENT = 'open';
const CLOSE_EVENT = 'close';

// delay for open/close transitions to complete
const TRANSITION_PAUSE = 200;

function getTooltip(isExpanded) {
	return isExpanded? 'see less' : 'see more';
}

class DdExpandableContent extends LitElement {
	static get properties() {
		return {
			isOpen: { type: Boolean },
			clickableTitle: { type: Boolean },
			border: { type: Boolean }
		};
	}

	static get styles() {
		return [
			sharedStyles,
			css`
				#container {
					position: relative;
				}
				#content-panel {
					margin-right: 12px;	/* right justify to align under expand icon */
					transition: max-height 0.3s ease;
					overflow: hidden;
				}
        .normal_title {
          font-weight: normal;
        }

				.minimized {
					max-height: 0;
				}

				.maximized {
					max-height: 400px;
				}

				#title-box {
					/* vertically centered full height slot */
					position: relative;
					display: flex;
					justify-content: center;
					flex-direction: column;
					min-height: 48px;
					flex-grow: 0;
				}

				mwc-icon-button-toggle {
					color: #202020
				}

				div.dd-row-container.dd-flex-container {
					justify-content: left;
				}

				@media print {
					#container {
						margin: 8px 0;
					}
				}
			`
		];
	}

	constructor() {
		super();

		this.border = false;
	}

  firstUpdated () {
    super.firstUpdated();
    if (this.isOpen) {
      this.shadowRoot.querySelector('#expand-toggle').on = true;
      this.onResize_();
    }
  }

	render() {
		const borderClass = this.border? 'dd-surface-border' : '';

		return html`
			<div id="container" class="${borderClass}">
				<div class="dd-row-container dd-flex-container">
					<div id="title-box" @click="${this.onTitleClick_}">
						<slot name="title"></slot>
					</div>
					<mwc-icon-button-toggle
						id="expand-toggle"
						onIcon="expand_less"
						offIcon="expand_more"
						title="${getTooltip(false)}"
						@click="${this.onResize_}"></mwc-icon-button-toggle>
				</div>
				<div id="content-panel" class="minimized">
          <slot name="content"></slot>
				</div>
		</div>
		`;
	}

	open() {
		const expandToggle = this.shadowRoot.querySelector('#expand-toggle');
		expandToggle.on = true;
		this.onResize_();
	}

	close() {
		const expandToggle = this.shadowRoot.querySelector('#expand-toggle');
		expandToggle.on = false;
		this.onResize_();
	}

	fireEvent_(name) {
    const event = new CustomEvent(name, {
      detail: {}
    });
    this.dispatchEvent(event);
	}

	onTitleClick_() {
		if (this.clickableTitle) {
			const expandToggle = this.shadowRoot.querySelector('#expand-toggle');
			expandToggle.on = !expandToggle.on;
			this.onResize_();
		}
	}

	onResize_() {
		const expandToggle = this.shadowRoot.querySelector('#expand-toggle');
		const isExpanded = expandToggle.on;
		const optionsPanelClasses = this.shadowRoot.querySelector('#content-panel').classList;
		if (isExpanded) {
			optionsPanelClasses.replace('minimized', 'maximized');
		} else {
			optionsPanelClasses.replace('maximized', 'minimized');
		}

		expandToggle.setAttribute('title', getTooltip(isExpanded));

		const preEventName = isExpanded? PRE_OPEN_EVENT : PRE_CLOSE_EVENT;
		this.fireEvent_(preEventName);

		const eventName = isExpanded? OPEN_EVENT : CLOSE_EVENT;
		const fireEvent = (this.fireEvent_).bind(this);
		setTimeout(() => {
			fireEvent(eventName);
		}, TRANSITION_PAUSE);
	}
}

customElements.define('dd-expandable-content', DdExpandableContent);