/**
 * template builder functions
 * host must include sharedStyles (/theme/theme-variables.scss)
 */
import { html } from 'lit-element';
import { CATEGORY_CLASS, PROTEIN_CLASS } from '../theme/color-classes.js';
import { PERSONAL_RECIPE_ICON_BG_CLASS } from '../theme/shared-styles.js';
import { PROTEIN_CATEGORY, SHOPPING_CATEGORY } from '../utilities/constants.js';
import { getProteinMap } from '../utilities/map-helpers.js';

function normalizeProteinCategory(proteinCategory) {
	const outOfBounds = (proteinCategory < PROTEIN_CATEGORY.RED_MEAT) || (proteinCategory > PROTEIN_CATEGORY.VEGETARIAN);
	return outOfBounds? PROTEIN_CATEGORY.NONE : proteinCategory;
}

function getIconImageTemplate(
	isImage,
	imageName,
	leftMargin,
	bgClassName,
	tooltip,
	colorClassName,
	iconClassName
	) {
	const marginClassName = leftMargin ? 'dd-margin-left' : '';

	return html`
		<div slot="icon" class="dd-icon-placeholder ${bgClassName} ${marginClassName}" title="${tooltip}">
			${isImage
				? html`<img src="images/${imageName}.png">`
				: html`<span class="${colorClassName} ${iconClassName}">${imageName}</span>`
			}
		</div>
	`;
}

export function getImageTemplate(imageName, leftMargin = false, bgClassName = '', tooltip = '') {
	return getIconImageTemplate(true, imageName, leftMargin, bgClassName, tooltip, '', '');
}

export function getIconTemplate(
	imageName,
	leftMargin = false,
	bgClassName = '',
	tooltip = '',
	colorClassName = '',
	iconClassName = 'material-icons'
	) {
	return getIconImageTemplate(false, imageName, leftMargin, bgClassName, tooltip, colorClassName, iconClassName);
}

export function getSmallIconTemplate(
	imageName,
	colorClassName = '',
	iconClassName = 'material-icons'
	) {
	return html`<span class="${colorClassName} ${iconClassName} dd-small-icon">${imageName}</span>`
}

export function getImagePlaceholder() {
	return html`<div class="dd-icon-placeholder"></div>`;
}

export function getCategoryImageTemplate(categoryMap, leftMargin = false) {
	return getImageTemplate(categoryMap.imageName, leftMargin, categoryMap.bgClass, categoryMap.name);
}

/**
 *
 * @param {*} proteinCategory utilities/constants.PROTEIN_CATEGORY
 * @param {*} isPersonalRecipe
 * @param {*} padLeft
 * @returns
 */
export function	getProteinIconTemplate(proteinCategory, isPersonalRecipe, padLeft = false) {
	const category = normalizeProteinCategory(proteinCategory);
	const bgClassName = isPersonalRecipe? PERSONAL_RECIPE_ICON_BG_CLASS : PROTEIN_CLASS[category].bg;
	const nameMap = getProteinMap(category);
	const tooltip = nameMap? nameMap.name.toUpperCase() : '';

	return getIconTemplate('', padLeft, bgClassName, tooltip, PROTEIN_CLASS[category].fg, PROTEIN_CLASS[category].icon);
}

export function getCategoryIconTemplate(categoryKey, leftMargin = false, includeBg = false) {
	if ((categoryKey >= SHOPPING_CATEGORY.UNKNOWN) && (categoryKey <= SHOPPING_CATEGORY.STAPLE)) {
		const bgClass = includeBg? CATEGORY_CLASS[categoryKey].bg : '';
		return getIconTemplate('', leftMargin, bgClass, '', CATEGORY_CLASS[categoryKey].fg, CATEGORY_CLASS[categoryKey].icon);
	}

	return getImagePlaceholder();
}

export function getProteinNameTemplate(proteinCategory) {
	const category = normalizeProteinCategory(proteinCategory);
	if (category !== PROTEIN_CATEGORY.NONE) {
		const nameMap = getProteinMap(category);
		if (nameMap) {
			const proteinName = nameMap.name.toUpperCase();
			const colorClass = PROTEIN_CLASS[category].fg;
			return html`<span class="dd-pad-left ${colorClass}">${proteinName}</span>`;
		}
	}

	return '';
}

export function getSaleTitleTemplate(saleTitle) {
	const iconName = 'tddi-sale';
	const iconBgClass = 'dd-secondary-circular-bg';

	return html`
		<div class="dd-flex-container dd-zero-line-height">
			${getIconTemplate('', false, iconBgClass, '', iconBgClass, iconName)}
			<div class="dd-left-justify-flex-fill">
				<h5>Shop the Sales:</h5>
				<h5 class="bold_title">${saleTitle}</h5>
			</div>
		</div>
	`;
}

/**
 * icon with colored circular background
 * @param {*} iconClassName 'tddi-carb'
 * @param {*} colorClassName
 * @param {*} iconBgClass
 * @param {*} blurClass
 */
export function getTopCenterRoundelTemplate(iconClassName, colorClassName, blurClass, isMaterialIcon = false) {
	const iconClass = isMaterialIcon? 'material-icons' : iconClassName;
	const iconName = isMaterialIcon? iconClassName : '';

	return html`
		<div class="dd-centered-roundel-container">
			<div class="dd-centered-roundel ${colorClassName} ${blurClass}">
				<span class="${iconClass} ${colorClassName}">${iconName}</span>
			</div>
		</div>
	`;
}

export function getChipTemplate(text, backgroundColorClass) {
	return html`
		<div class="dd-chip ${backgroundColorClass}">
			<span class="fine-print dd-chip-text">${text}</span>
		</div>
	`;
}

export function getDesktopFabHostTemplate() {
	return html`
		<div class="dd-content-container dd-host-centered">
			<div>
				<slot name="fab"></slot>
			</div>
		</div>
	`;
}

export function getCardPlaceholderTemplate(title) {
	return html`
		<div class="dd-card-placeholder">
			<div class="dd-button-placeholder"></div>
			<div class="dd-pad-left">
				<h4 class="bold_title">${title}</h4>
			</div>
		</div>
	`;
}

export function getSpacerTemplate(spacerHeight) {
	return html`<div class="dd-bottom-spacer" style="height: calc(${spacerHeight}px + env(safe-area-inset-bottom))"></div>`;
}
