import { css } from 'lit-element';
import { Button } from "@material/mwc-button";

/**
 * mwc-button with minimum height
 */
class DdMaxHeightButton extends Button {
  static get properties() {
		return {
			idValue: { type: Number }
		};
  }

  static get styles() {
		return [
      Button.styles,
      css`
        :host {
          width: 100%;
        }

        .mdc-button.mdc-button--dense {
          height: 100%;
          -webkit-border-radius: 27px;
				  border-radius: 27px;
        }

        .mdc-button:not(:disabled) {
          min-height: 54px;
          background-color: var(--mdc-theme-primary-dark);
          color: var(--mdc-theme-surface);
        }
      `
    ];
  }
}

customElements.define('dd-max-height-button', DdMaxHeightButton);
