/**
 * API action for menu resource
 * ref: https://github.com/redux-utilities/flux-standard-action
 *      /state/middleware/request-middleware.js
 */
import {
	axios,
	getApiUrl,
	getParameters,
	API_REQUEST,
	startRequest,
	completeRequest,
	NOK_RESPONSE_HINT,
	UNABLE_TO_CREATE_MENU_HINT,
	UPDATE_COMPLETE_HINT
} from './api-base.js';
import { saveWeekMenu } from '../menu.js';
import { MENU_VIEW, navigate } from '../navigation.js';
import { PubSub } from '../../services/pub-sub.js';
import { INVALID_TOKEN_EVENT, REQUEST_CANCELLED_EVENT } from '../../utilities/constants.js';

// REST resources
const WEEK_MENU_RESOURCE = 'week-menu';

/**
 * returns action to monitor with requestMiddleware: { payload: async function }
 */
export function requestMenu(showProgress, previousWeek, navigateToMenu = false) {
	return {
		type: API_REQUEST,
		payload(dispatch, token) {
			if (showProgress) {
				dispatch(startRequest());
			}

			const menuUrl = getApiUrl(WEEK_MENU_RESOURCE);

			return axios.get(menuUrl, {
				params: getParameters(previousWeek),
				headers: { Authorization: `Bearer ${token}` }
			})
				.then(response => {
					const actions = [
						saveWeekMenu(response.data),
						completeRequest()
					];

					if (navigateToMenu) {
						actions.push(navigate(MENU_VIEW));
					}

					dispatch(actions);
				})
				.catch(error => {
					console.log(error);
					if (error.response.status === 401) {
						PubSub.publish(INVALID_TOKEN_EVENT);
					}
				});
		}
	}
}

export function requestRestoreMenu(previousWeek) {
	return {
		type: API_REQUEST,
		payload(dispatch, token) {
			dispatch(startRequest("Your original menu is back"));

			const endpoint = `${WEEK_MENU_RESOURCE}/restore`;
			const menuUrl = getApiUrl(endpoint);

			return axios.post(menuUrl,
				[],
				{
					params: getParameters(previousWeek),
					headers: { Authorization: `Bearer ${token}` }
				})
				.then(response => {
					dispatch([
						saveWeekMenu(response.data),
						completeRequest()
					]);
				})
				.catch(error => {
					dispatch(completeRequest(NOK_RESPONSE_HINT));
					console.log(error);
				});
		}
	}
}

/**
 * create weekly menu
 */
 export function requestCreateMenu(hideHint = false) {
	return {
		type: API_REQUEST,
		payload (dispatch, token) {
			dispatch(startRequest());
			const url = getApiUrl(WEEK_MENU_RESOURCE);

			return axios.post(url,
				[],
				{
					headers: { Authorization: `Bearer ${token}`
				}
			})
				.then(() => {
					const hint = hideHint? '' : UPDATE_COMPLETE_HINT;
					dispatch(completeRequest(	hint));
				})
				.catch(() => {
					PubSub.publish(REQUEST_CANCELLED_EVENT);
					dispatch(completeRequest(UNABLE_TO_CREATE_MENU_HINT));
				});
		}
	}
}
