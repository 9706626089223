import cloneDeep from 'lodash-es/cloneDeep.js';
import {
  SET_RECIPE_SHOP_ITEMS,
  ACTIVATE_RECIPE_SHOP_ITEMS,
  DEACTIVATE_RECIPE_SHOP_ITEMS
} from '../actions/recipe-shop-items';

// REST API DTO: AppBundle\Controller\Rest\DTO\ShoppingList.recipe_shop_items
const INITIAL_STATE = {
  map: []
};

function createHashKey(recipeShopItem) {
  return `${recipeShopItem.recipeId}|${recipeShopItem.shoppingListItemId}`;
}

function verifyProperties(recipeShopItem) {
  const userAddedItem = !recipeShopItem.recipeId;
  return recipeShopItem.shoppingListItemId && (userAddedItem || (recipeShopItem.recipeName && recipeShopItem.dishType));
}

function initializeItems(items) {
  return items.map(item => {
    item.active = true;
    return item;
  });
}

function mergeRecipeShopItems(stateMap, items) {
  const clonedStateMap = cloneDeep(stateMap);

  const stateMapExists = Array.isArray(clonedStateMap) && (clonedStateMap.length > 0) && verifyProperties(clonedStateMap[0]);

  if (stateMapExists) {
    const stateMapHash = clonedStateMap.reduce((map, item) => {
      map[createHashKey(item)] = item.recipeId;
      return map;
    }, []);

    const recipeShopItemHashMap = items.reduce((map, item) => {
      const key = createHashKey(item);
      map[key] = item.recipeId;
      return map;
    }, {});

    // items to add to state
    const recipeShopItemsToAdd = items.filter(recipeShopItem => {
      const itemHash = createHashKey(recipeShopItem);
      return !(itemHash in stateMapHash);
    });
    const stateItemsToAdd = initializeItems(recipeShopItemsToAdd);

    // keep existing active value for items to keep in state
    const stateItemsToKeep = clonedStateMap.filter(stateItem => {
      const stateHash = createHashKey(stateItem);
      return (stateHash in recipeShopItemHashMap);
    });

    return stateItemsToKeep.concat(stateItemsToAdd);
  }

  return initializeItems(items);
}

function setRecipeShopItemsActive(stateMap, recipeIds, isActive) {
  const clonedStateMap = cloneDeep(stateMap);

  const newStateMap = clonedStateMap.map(item => {
    if (recipeIds.includes(item.recipeId)) {
      item.active = isActive;
    }
    return item;
  })

  return newStateMap;
}

export function recipeShopItems(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_RECIPE_SHOP_ITEMS:
      return {
        ...state,
        map: mergeRecipeShopItems(state.map, action.payload.recipeShopItems)
      };
    case ACTIVATE_RECIPE_SHOP_ITEMS:
    return {
      ...state,
      map: setRecipeShopItemsActive(state.map, action.payload.recipeIds, true)
    };
    case DEACTIVATE_RECIPE_SHOP_ITEMS:
    return {
      ...state,
      map: setRecipeShopItemsActive(state.map, action.payload.recipeIds, false)
    };
    default:
      return state;
  }
}
