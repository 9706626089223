import { html } from 'lit-element';
import "@material/mwc-button";
import '@material/mwc-list/mwc-list-item';
import '@material/mwc-menu';
import Hammer from 'hammerjs';
import { ScreenAwareElement } from '../screen-aware-element.js';
import { AppFlagProvider } from '../../services/app-flag-provider.js';
import { PubSub } from '../../services/pub-sub.js';
import { ORIENTATION_CHANGE_EVENT } from '../../services/orientation-publisher.js';
import { WakeLock } from '../../services/wake-lock.js';
import { ASYNC_PAUSE } from '../../utilities/constants.js';
import { cumulativeOffset } from '../../utilities/dom.js';
import { fireFabContextChangeEvent } from '../../utilities/fab-integration.js';
import { isMobileTouch } from '../../utilities/touch-status.js';
import { isNumericallyEqual } from '../../utilities/object-evaluation.js';
import { EVENTS} from '../events.js';

export const CAROUSEL_ORDER = {
  first: 0,
  middle: 1,
  last: 2,
  only: 3
};
Object.freeze(CAROUSEL_ORDER);

export const SWIPE_DIRECTION = {
  left: 0,
  right: 1
};
Object.freeze(SWIPE_DIRECTION);

// a card has these div elements
export const CONTAINER_ID = 'container';
export const CARD_BODY_ID = 'card-body';
export const MIN_VIEW_ID = 'min-view';
export const MAX_VIEW_ID = 'max-view';
export const MINIMIZER_ID = 'minimizer';
export const CARD_SWIPE_BACKGROUND_ID = 'dd-card-swipe-background'; // if swipe to reveal enabled
export const MAXIMIZED_TOUCH_AREA_CLASS = 'maximized-touch-area'; // if supports card carousel
export const FULL_WIDTH_CONTENT_ID = 'full-width-content'; // full width content that bottom icons should be below
export const BOTTOM_CONTROL_ROW = 'bottom-control-row'; // contains bottom controls
export const BOTTOM_LEFT_CTL_ID = 'bottom-left-ctl';    // positioning ancestor should be MAX_VIEW_ID
export const BOTTOM_RIGHT_CTL_ID = 'bottom-right-ctl';  // positioning ancestor should be MAX_VIEW_ID
export const LEFT_SWIPE_AFFORDANCE = 'left-swipe-affordance';
export const RIGHT_SWIPE_AFFORDANCE = 'right-swipe-affordance';

// width of swipe revealed buttons for use when buttons are hidden (offsetWidth = 0)
export const REMOVE_WIDTH = 80;
export const REPLACE_WIDTH = 80;
export const SNAP_DURATION = 300;

const PAN_CARD_EVENT = 'pan-card';
const SWIPE_CARD_EVENT = 'swipe-card';
const RESET_PAN_CARD_EVENT = 'reset-pan-card';

// events for app level scroll management
const SWIPE_START_EVENT = 'swipe-start-event';
const SWIPE_END_EVENT = 'swipe-end-event';

const MAX_STALL_COUNT = 2;
const MAX_X_INCREMENT = 100;
const STALL_X_THRESHOLD = 10; // pixels
const LIMIT_OVERRUN_RANGE = 40; // pixels
const OVERRUN_PAUSE = 200;
const THRESHOLD_FACTOR = 0.5;
const DEFAULT_WIDTH = 400;
const CAROUSEL_SPACING = 24;	// spacing between cards when swiping, px
const ICON_BTN_HEIGHT = 48; //  to allow locating when hidden (offsetHeight = 0)
const SWIPE_EDGE_WIDTH = 48;
const SWIPE_AFFORDANCE_OFFSET = 50;
const BOTTOM_ROW_OFFSET = 16;  // adds margin for screens with rounded corners

function pan(element, offset) {
  const translateX = `translateX(${offset}px)`;
  element.style.transform = translateX;
}

/** abstract base card class
 * - common maximize/minimize behavior and events
 * - lower control position management
 * - swipe behavior
 *
 * *** Desktop view (swipe disabled)
 * maximized: true =  initialize as maximized card (configurable by parent)
 * _hasControlRow: true = control row instead of bottom corners (configurable by derived card)
 *
 * *** Mobile view: Card Swipe Handling ***
 * Config for minimized swipe-reveal:
 *  _allowPanMinimized = true
 *  _swipeLeftReveal = true
 *  set _swipeLeftRevealRange, _showSwipeBackgroundClass
 *  implement onPan()
 *  add 'dd-card-swipe-background' section
 *  implement onSwipeChangeClick_(), onSwipeDeleteClick_()
 * Config for maximized card carousel (dd-shop-category-card, dd-recipe-card):
 *  allowPanMaximized = true when maximized
 *  fire swipe, pan, minimized, maximized events
 *  implement public maximize(), minimize() - called by parent to open/close carousel
 *  parent's DdCarouselHost handles swipe, pan, reset pan, minimized, maximized events to control other cards, FAB
 *
 * @abstract */
/* eslint class-methods-use-this: "off", no-unused-vars: "off" */
export class DdCard extends ScreenAwareElement {
  static get properties() {
    return {
      maximized: { type: Boolean },
      allowMinimize: { type: Boolean },
      allowPanMaximized: { type: Boolean }
    };
  }

  set allowPanMaximized(value) {
    this._allowPanMaximized = value;

    if (this._cardBody) {
      this.clearTouchManagers_();
      this.setTouchManagers_();
    }
  }

  get carouselNumber() {
    return this._carouselNumber;
  }

  set carouselNumber(value) {
    this._carouselNumber = value;
  }

  get carouselOrder() {
    return this._carouselOrder;
  }

  set carouselOrder(value) {
    this._carouselOrder = value;
  }

  get isMinimizeAllowed() {
    return !this.largeScreen || this.allowMinimize;
  }

  get allowPanMaximized() {
    return !this.largeScreen && this._allowPanMaximized;
  }

  get allowPanMinimized() {
    return !this.largeScreen && this._allowPanMinimized;
  }

  get hasFocus() {
    return this._isMaxView && (this._offsetNumber === 0);
  }

  get minViewShowClass() {
    return this._isMaxView? 'dd_hide' : 'dd_show';
  }

  get maxViewShowClass() {
    return this._isMaxView? 'dd_show' : 'dd_hide';
  }

  get containerClass() {
    return this.maximized? '' : this._containerClass;
  }

  get maxViewClass() {
    return this.largeScreen? 'dd-card-max-view' : 'dd-sm-screen-max-view';
  }

  get cardBodyViewClasses() {
    return this.largeScreen
    ? `dd-body1 ${this._cardBodyMaxClass} ${this._cardBodyOverlayClass}`
    : `${this._cardBodyMinClass}`;
  }

  get _cardBodyMaxClass() {
    return this.largeScreen? this._cardBodyLargeScreenMaxClass : this._cardBodySmallScreenMaxClass;
  }

  get _pastCarouselRightEnd() {
    return this._isMaxView && ((this._carouselOrder === CAROUSEL_ORDER.last) || (this._carouselOrder === CAROUSEL_ORDER.only))
      && (this._deltaX < -LIMIT_OVERRUN_RANGE);
  }

  get _pastCarouselLeftEnd() {
    return this._isMaxView && ((this._carouselOrder === CAROUSEL_ORDER.first) || (this._carouselOrder === CAROUSEL_ORDER.only))
      && (this._deltaX > LIMIT_OVERRUN_RANGE);
  }

  get showControlRow() {
    return this.largeScreen && this._hasControlRow;
  }

  /**
   * LIFECYCLE
   */
  constructor() {
    super();
    this._allowPanRight = false;
    this._allowPanMinimized = false;
    this._allowPanMaximized = false;
    this._carouselNumber = 0;
    this._carouselOrder = CAROUSEL_ORDER.middle;
    this._offsetNumber = 0;
    this._isMaxView = false;  // flag for enabling mobile max view features
    this._swipeThreshold = 0;
    this._swipeRange = DEFAULT_WIDTH;
    this._deltaX = 0;
    this._touchManagers = [];
    this._cardBody = null;  // movable card element
    this._stallCounter = 0; // event count while panning stalled
    this._orientationEventSubscriber = null;
    this._deleteHandler = null; // handler for mini card delete icon decorator

    // for maximized desktop card selection animation
    this._previousId = 0;

    // settable by derived cards
    this._swipeLeftReveal = false;  // default: false. true = stop at swipe range, don't hide swipe background
    this._swipeLeftRevealRange = 0;
    this._swipeAffordanceOffset = SWIPE_AFFORDANCE_OFFSET; // position offset below half screen height for L-R swipe affordances
    this._showSwipeBackgroundClass = 'dd-card-reveal-background'; // option: 'dd-card-show-background' = passive swipe hint
    this._containerClass = 'dd-card-container';
    this._cardBodyMinClass = '';
    this._cardBodyLargeScreenMaxClass = 'dd-card-body-lg-screen-maximize';
    this._cardBodySmallScreenMaxClass = '';
    this._cardBodyOverlayClass = '';
    this._cardBodyOverlayMiddleClass = '';
    this._hasControlRow = false;  // true = replace bottom corner controls with center control row
  }

  firstUpdated() {
    super.firstUpdated();

    if (this.maximized) {
      this._isMaxView = true;
    }

    this._cardBody = this.shadowRoot.querySelector(`#${CARD_BODY_ID}`);

     if (this.allowMinimize) {
      // adjust layout after animation completed
      const setCssAfterTransition = (this.setCssAfterTransition_).bind(this);
      const clearScaleAnimation = (this.clearScaleAnimation_).bind(this);
      this._cardBody.addEventListener('transitionend', () => {
        setCssAfterTransition();
        clearScaleAnimation();
      });
    }

    if (this.largeScreen) {
      this.setDesktopScalingCss_();
      const bottomControlRow = this.shadowRoot.querySelector(`#${BOTTOM_CONTROL_ROW}`);

      if (this._hasControlRow) {
        this.hideBottomControls_();
      } else if (bottomControlRow) {
        bottomControlRow.classList.add('dd-flex-container');
      }
    } else {
      PubSub.subscribe(ORIENTATION_CHANGE_EVENT, (this.locateBottomControls_).bind(this));
      this.hideBottomControls_();
      this.setTouchManagers_();
    }

    if (this._isMaxView) {
      this.onMaximizeClick_();
    }
  }

  updated() {
    super.updated();

    if (!this.largeScreen) {
      this.stopPan();
    }
    this.setCssAfterTransition_();

    if (this.offsetParent /* is visible */) {
      this.asyncUpdateFromState_();
    }
  }

  /**
   * updating controls after state change and DOM rendered
   */
  asyncUpdateFromState_() {
		const updateFromState = (this.updateFromState_).bind(this);
		const layout = (this.layout).bind(this);

		setTimeout(() => {
			updateFromState();
			layout();
		}, ASYNC_PAUSE);
	}

  layout() {
    if (this.allowPanMaximized) {
      const leftWipeAffordance = this.shadowRoot.querySelector(`#${LEFT_SWIPE_AFFORDANCE}`);
      if (leftWipeAffordance) {
        const notRightSwipeable = (this._carouselOrder === CAROUSEL_ORDER.only) || (this._carouselOrder === CAROUSEL_ORDER.first);
        if (notRightSwipeable) {
          leftWipeAffordance.classList.add('dd_hide');
          leftWipeAffordance.classList.remove('dd_show');
        } else {
          leftWipeAffordance.classList.remove('dd_hide');
          leftWipeAffordance.classList.add('dd_show');
        }
      }

      const rightWipeAffordance = this.shadowRoot.querySelector(`#${RIGHT_SWIPE_AFFORDANCE}`);
      if (rightWipeAffordance) {
        const notLeftSwipeable = (this._carouselOrder === CAROUSEL_ORDER.only) || (this._carouselOrder === CAROUSEL_ORDER.last);
        if (notLeftSwipeable) {
          rightWipeAffordance.classList.add('dd_hide');
          rightWipeAffordance.classList.remove('dd_show');
        } else {
          rightWipeAffordance.classList.remove('dd_hide');
          rightWipeAffordance.classList.add('dd_show');
        }
      }
    }

    // derived cards extend (outlined select/textfield bug workaround)
  }

  updateFromState_() {
    // derived cards implement
  }

/**
 * maximized desktop card selection animation
 */

  manageSelectAnimation_(itemId) {
    if (this.largeScreen && this.maximized && itemId !== this._previousId) {
      this.addSelectFade_();

      const fadeDurationMs = 300;
      const remove = (this.removeSelectFade_).bind(this);
      setTimeout(() => {
        remove();
      }, fadeDurationMs);

      this._previousId = itemId;
    }
  }

  addSelectFade_() {
    this._cardBody.classList.add('select-fade-in-out');
  }

  removeSelectFade_() {
    this._cardBody.classList.remove('select-fade-in-out');
  }

/**
* MINIMIZING AND MAXIMIZING
*/
  maximize(offsetNumber, animateSnap = false) {
    this.maximize_(offsetNumber);
    if (!this.largeScreen) {
      if (animateSnap) {
        const snapDurationMs = 300;
        const setOffSetPos = (this.setOffSetPosition).bind(this);
        setTimeout(() => {
          setOffSetPos(offsetNumber);
        }, snapDurationMs);
      } else {
        this.setOffSetPosition(offsetNumber);
      }
    }
  }

  minimize() {
    if (this.isMinimizeAllowed) {
      this.minimize_();
    }
  }

  setMinimizedSwipeRange_() {
    this.swipeRange = this._swipeLeftRevealRange;
    this._swipeThreshold = THRESHOLD_FACTOR * this.swipeRange;
  }

  setDesktopScalingCss_() {
    if (this.largeScreen) {
      this._cardBodyOverlayClass = 'dd-card-lg-screen-overlay';
      this._cardBodyOverlayMiddleClass = 'dd-card-lg-screen-overlay-middle';  // dummy scale animation base
    }
  }

  setCssAfterTransition_() {
    const minimizeCompleted = !this._isMaxView && !this._cardBody.classList.contains(this._cardBodyMaxClass);

    if (minimizeCompleted) {
      this._cardBody.classList.remove(this._cardBodyOverlayClass);

      // show background after animation
      const swipeBackground = this.shadowRoot.querySelector(`#${CARD_SWIPE_BACKGROUND_ID}`);
      if (!this.largeScreen && swipeBackground) {
        swipeBackground.classList.replace('dd_hide', this._showSwipeBackgroundClass);
      }
    } else {
      this.showTopControls_();

      if (!this.showControlRow) {
        this.showBottomControls_();
      }
    }
  }

  /**
   * DESKTOP CONTROL MANAGEMENT
   * derived card calls getDesktopControlRowTemplate_() in render()
   */
  getDesktopControlRowTemplate_() {
    if (this.showControlRow) {
      return html`
        ${this.getControlRowTemplate_()}
      `;
    }

    return '';
  }

  getControlRowTemplate_() {
    // no op: derived card to override
  }


  getDesktopDeleteTemplate_(handler) {
    this._deleteHandler = handler;

		return this.largeScreen
		? html`<mwc-icon-button id="desktop-mini-delete" icon="delete_outline" title="remove this" @click="${this.onDesktopDeleteClick_}"></mwc-icon-button>`
		: '';
	}

  getDesktopMiniCardDeleteTemplate_(handler) {
    return this.largeScreen
		? html`
        <div class="dd-position-host">
          ${this.getDesktopDeleteTemplate_(handler)}
        </div>
      `
		: '';
  }

	getMoreMenuTemplate_(deleteHandler) {
		if (this.largeScreen){
      return html`
        <div class="dd-position-host">
          <mwc-icon-button id="desktop-mini-more" icon="more_vert" @click="${this.onMoreClick_}"></mwc-icon-button>
          <mwc-menu id="more-menu" x="-50" y="-8">
            <mwc-list-item @click="${deleteHandler}">Remove</mwc-list-item>
          </mwc-menu>
        </div>
      `;
    }

    return '';
	}

  onDesktopDeleteClick_(event) {
    this._deleteHandler(event);
    event.stopPropagation();
  }

  onMoreClick_(event) {
    const moreButton = this.shadowRoot.querySelector('#desktop-mini-more');
		const moreMenu = this.shadowRoot.querySelector('#more-menu');
    moreMenu.anchor = moreButton;

		moreMenu.show();
    event.stopPropagation();
	}

  /**
   * BOTTOM CONTROL ROW MANAGEMENT
   * absolutely positions in window corners when card overflows window
   */
  getLeftSwipeAffordance_() {
    return (this.largeScreen)
    ? ''
    : html`
      <span id=${LEFT_SWIPE_AFFORDANCE} class="material-icons-small">
      chevron_right
      </span>
    `;
  }

  getRightSwipeAffordance_() {
    return (this.largeScreen)
    ? ''
    : html`
      <span id=${RIGHT_SWIPE_AFFORDANCE} class="material-icons-small">
      chevron_left
      </span>
    `;
  }

  locateBottomControls_() {
    if (!this.largeScreen) {
      this.locateMobileBottomControls_();
    }
  }

  locateMobileBottomControls_() {
    let smallestWidth = window.outerWidth > window.innerWidth ? window.innerWidth : window.outerWidth;
    let smallestHeight = window.outerHeight > window.innerHeight ? window.innerHeight : window.outerHeight;
    if (this._isMaxView) {
      // clientWidth accounts for vertical scrollbar if present
      if (smallestWidth > this._cardBody.clientWidth) {
        smallestWidth = this._cardBody.clientWidth;
      }
      if (smallestHeight > this._cardBody.offsetHeight) {
        smallestHeight = this._cardBody.offsetHeight;
      }

      // push controls below full width content
      const fullWidthContent = this.shadowRoot.querySelector(`#${FULL_WIDTH_CONTENT_ID}`);
      const contentBottomPosition = fullWidthContent? cumulativeOffset(fullWidthContent).top + fullWidthContent.offsetHeight : 0;
      const pushedPosition = contentBottomPosition + ICON_BTN_HEIGHT;
      if (pushedPosition > smallestHeight) {
        smallestHeight = pushedPosition;
      }
    }

    this.setBottomControlPositions_(smallestHeight, smallestWidth);
  }

  setBottomControlPositions_(height) {
    const leftAffordance = this.shadowRoot.querySelector(`#${LEFT_SWIPE_AFFORDANCE}`);
    if (leftAffordance) {
      leftAffordance.style.position = `absolute`;
      leftAffordance.style.left = '0px';
      leftAffordance.style.top = `${height/2 + this._swipeAffordanceOffset}px`;
    }

    const leftControl = this.shadowRoot.querySelector(`#${BOTTOM_LEFT_CTL_ID} > mwc-icon-button`);
    if (leftControl) {
      leftControl.style.position = `absolute`;
      leftControl.style.left = '0px';
      leftControl.style.top = `${height - ICON_BTN_HEIGHT - BOTTOM_ROW_OFFSET}px`;
    }

    const rightAffordance = this.shadowRoot.querySelector(`#${RIGHT_SWIPE_AFFORDANCE}`);
    if (rightAffordance) {
      rightAffordance.style.position = `absolute`;
      rightAffordance.style.right = '0px';
      rightAffordance.style.top = `${height/2 + this._swipeAffordanceOffset}px`;
    }

    const rightControl = this.shadowRoot.querySelector(`#${BOTTOM_RIGHT_CTL_ID} > mwc-icon-button`);
    if (rightControl) {
      rightControl.style.position = `absolute`;
      rightControl.style.right = '0px';
      rightControl.style.top = `${height - ICON_BTN_HEIGHT -BOTTOM_ROW_OFFSET}px`;
    }
  }

  hideBottomControls_() {
    const leftControlContainer = this.shadowRoot.querySelector(`#${BOTTOM_LEFT_CTL_ID}`);
    if (leftControlContainer) {
      leftControlContainer.classList.add('dd_hide');
    }

    const rightControlContainer = this.shadowRoot.querySelector(`#${BOTTOM_RIGHT_CTL_ID}`);
    if (rightControlContainer) {
      rightControlContainer.classList.add('dd_hide');
    }
  }

  showTopControls_() {
    const minimizer = this.shadowRoot.querySelector(`#${MINIMIZER_ID}`);
    if (minimizer) {
      const visibilityClass = this.isMinimizeAllowed? 'dd-visible' : 'dd-invisible';
      minimizer.classList.add(visibilityClass);
    }
  }

  showBottomControls_() {
    const leftControlContainer = this.shadowRoot.querySelector(`#${BOTTOM_LEFT_CTL_ID}`);
    if (leftControlContainer) {
      leftControlContainer.classList.remove('dd_hide');
    }

    const rightControlContainer = this.shadowRoot.querySelector(`#${BOTTOM_RIGHT_CTL_ID}`);
    if (rightControlContainer) {
      rightControlContainer.classList.remove('dd_hide');
    }

    this.locateBottomControls_();
  }

  minimize_() {
    if (this._cardBody) {
      const sizeChange = this._isMaxView;
      this._isMaxView = false;
      const isAnimatable = this.isAnimatable_();

      this._allowPanRight = false;
      this.stopPan();
      this.setMinimizedSwipeRange_();
      this.setOffSetPosition(0);

      const container = this.shadowRoot.querySelector(`#${CONTAINER_ID}`);
      if (container) {
        container.classList.add(this._containerClass);
      }

      const minViewClasses = this.shadowRoot.querySelector(`#${MIN_VIEW_ID}`).classList;
      minViewClasses.replace('dd_hide', 'dd_show');
      const maxViewClasses = this.shadowRoot.querySelector(`#${MAX_VIEW_ID}`).classList;
      maxViewClasses.replace('dd_show', 'dd_hide');

      if (this.allowMinimize && isAnimatable && sizeChange) {
        this.setScaleAnimation_();
      } else {
        this.minimizeWithoutAnimation_();
      }

      this.hideBottomControls_();
      if (this.hasFocus) {
        WakeLock.disable();
      }
    }
  }

  maximize_(offsetNumber) {
    if (this._cardBody) {
      const sizeChange = !this._isMaxView;
      this._isMaxView = true;

      const minViewClasses = this.shadowRoot.querySelector(`#${MIN_VIEW_ID}`).classList;
      minViewClasses.replace('dd_show', 'dd_hide');
      const maxViewClasses = this.shadowRoot.querySelector(`#${MAX_VIEW_ID}`).classList;
      maxViewClasses.replace('dd_hide', 'dd_show');

      if (this.allowPanMaximized) {
        this._allowPanRight = true;
        this.setUpdatingSwipeRange_();
      }

      const container = this.shadowRoot.querySelector(`#${CONTAINER_ID}`);
      if (container) {
        container.classList.remove(this._containerClass);
      }

      const isAnimatable = (offsetNumber === 0);
      if (this.allowMinimize && isAnimatable && sizeChange) {
        this.setScaleAnimation_();
      } else {
        this.maximizeWithoutAnimation_();
      }

      // hide background before animation
      const swipeBackground = this.shadowRoot.querySelector(`#${CARD_SWIPE_BACKGROUND_ID}`);
      if (swipeBackground) {
        swipeBackground.classList.replace(this._showSwipeBackgroundClass, 'dd_hide');
      }

      // handle carousel swipe case (when state not changed on swiped in cards)
      this.asyncUpdateFromState_();
    }
  }

  maximizeWithoutAnimation_() {
    this._cardBody.classList.replace(this._cardBodyMinClass, this._cardBodyMaxClass);
    this._cardBody.classList.add('dd-body1', this._cardBodyOverlayClass);

    // adjust layout after DOM updated
    const setCssAfterTransition_ = (this.setCssAfterTransition_).bind(this);
    setTimeout(() => {
      setCssAfterTransition_();
    }, ASYNC_PAUSE);
  }

  minimizeWithoutAnimation_() {
    this._cardBody.classList.replace(this._cardBodyMaxClass, this._cardBodyMinClass);
    this._cardBody.classList.remove('dd-body1', this._cardBodyOverlayClass);

    // adjust layout after DOM updated
    const setCssAfterTransition_ = (this.setCssAfterTransition_).bind(this);
    setTimeout(() => {
      setCssAfterTransition_();
    }, ASYNC_PAUSE);
  }

  onMaximizeClick_() {
    this.stopPan();
    this.maximize(0, true);

    const detail = { number: this._carouselNumber };
    this.fireLocalEvent_(EVENTS.MAXIMIZE, detail);

    WakeLock.enable();
  }

  onMinimizeClick_(dto = {}) {
    this.minimize_();

    const event = new CustomEvent(EVENTS.MINIMIZE, {
      detail: {
        number: this._carouselNumber,
        ...dto
      }
    });
    this.dispatchEvent(event);
  }

  fireGlobalEvent_(eventName, detail = {}) {
    const globalEvent = new CustomEvent(eventName, {
      bubbles: true,
      composed: true,
      detail
    });
    this.dispatchEvent(globalEvent);
  }

  fireLocalEvent_(eventName, detail = {}) {
    const event = new CustomEvent(eventName, { detail });
    this.dispatchEvent(event);
  }

  showFab_(label, callback, icon = 'add') {
    // cards don't show FABs in desktop view
    if (!this.largeScreen && this.hasFocus) {
      const fabClickCallback = (callback).bind(this);

      // delay until after snap animation (hack to avoid sequencing)
      const self = this;
      setTimeout(() => {
        fireFabContextChangeEvent(self, icon, label, true, fabClickCallback);
      }, SNAP_DURATION);
    }
  }

  hideFab_(label, icon = 'add') {
    if (!this.largeScreen) {
      fireFabContextChangeEvent(this, icon, label, false);
    }
  }

  /**
	 * selects using loose (string, int, decimal) value match
	 * @param Node select (mwc-select)
   * @param {*} value
	 */
  setSelected_(select, value) {
    let i = 0;

		for (i = 0; i < select.items.length; i += 1) {
			if (isNumericallyEqual(select.items[i].value, value)) {
        select.value = select.items[i].value;
        // work around for render bug with mwc-select v0.18
        if ('selectedText' in select) {
          select.selectedText = select.items[i].text;
        }

				break;
			}
		}
  }

  /**
   * TOUCH/SWIPE HANDLING
   */

  set swipeRange(swipeRange) {
    this._swipeRange = swipeRange;
  }

  get swipeRange() {
    return this._swipeRange;
  }

  /**
   *
   * @param {*} center {int x, int y}
   * @return bool
   */
  isInSwipeEdge_(center) {
    const notInitialized = (center.x === 0 && center.y === 0);
    if (!this._isMaxView || notInitialized) {
      return false;
    }

    const inLeftEdge = center.x < SWIPE_EDGE_WIDTH;
    const inRightEdge = center.x > (this._swipeRange - SWIPE_EDGE_WIDTH);

    return (inLeftEdge || inRightEdge);
  }

	handleSwipeLeft_() {
		this.fireSwipeEvent_(SWIPE_DIRECTION.left);
    this.fireGlobalEvent_(SWIPE_END_EVENT);
	}

	handleSwipeRight() {
		this.fireSwipeEvent_(SWIPE_DIRECTION.right);
    this.fireGlobalEvent_(SWIPE_END_EVENT);
	}

  /** derived cards override
   * @param {*} event Hammer Event
  */
  onPan(event) {
    if (this.isInSwipeEdge_(event.center)) {
      this.fireGlobalEvent_(SWIPE_START_EVENT);
    }
  }

  stopTouchManagers() {
    this._touchManagers.forEach((touchManager) => {
      touchManager.stop(true);
    });
  }

  clearTouchManagers_() {
    this._touchManagers.forEach((touchManager) => {
      touchManager.off('pan');
      touchManager.off('swipeleft');
      touchManager.off('swiperight');
      touchManager.destroy();
    });
    this._touchManagers.splice(0, this._touchManagers.length);
  }

  addTouchManager_(touchSurface) {
    const touchManager = new Hammer(touchSurface);

    const panCallback = (this.onPan).bind(this);
    touchManager.on('pan', panCallback);

    const swipeRecognizer = touchManager.get('swipe');
    swipeRecognizer.set({ velocity: 0.05 });
    const swipeLeftCallback = (this.onSwipeLeft_).bind(this);
    touchManager.on('swipeleft', swipeLeftCallback);

    const swipeRightCallback = (this.onSwipeRight_).bind(this);
    touchManager.on('swiperight', swipeRightCallback);

    this._touchManagers.push(touchManager);
  }

  setTouchManagers_() {
    if (isMobileTouch()) {
      if (this.allowPanMinimized) {
        this.setMinimizedSwipeRange_();
        const minView = this.shadowRoot.querySelector(`#${MIN_VIEW_ID}`);
        this.addTouchManager_(minView);
      }

      if (this.allowPanMaximized) {
        const maximizedTouchAreas = this.shadowRoot.querySelectorAll(`.${MAXIMIZED_TOUCH_AREA_CLASS}`);
        const addTouchManager = (this.addTouchManager_).bind(this);
        maximizedTouchAreas.forEach(maximizedTouchArea => {
          addTouchManager(maximizedTouchArea);
        });
      }
    }
  }

  setUpdatingSwipeRange_() {
    this.setVariableSwipeRange_();

    if (this._orientationEventSubscriber) {
      this._orientationEventSubscriber.unsubscribe();
    }
    this._orientationEventSubscriber = PubSub.subscribe(ORIENTATION_CHANGE_EVENT, (this.setVariableSwipeRange_).bind(this));
  }

  setVariableSwipeRange_() {
    if (isMobileTouch()) {
      this.swipeRange = this._cardBody && (this._cardBody.offsetWidth > 0) ? this._cardBody.offsetWidth : DEFAULT_WIDTH;
      this._swipeThreshold = THRESHOLD_FACTOR * this.swipeRange;
      this.setOffSetPosition_();
    }
  }

  setOffSetPosition_() {
    if (isMobileTouch() && this._cardBody) {
      const startPostion = this._offsetNumber * (this.swipeRange + CAROUSEL_SPACING);
      this._cardBody.style.left = `${startPostion}px`;
    }
  }

  setSnapAnimation_() {
    if (isMobileTouch() && this._cardBody) {
      this._cardBody.classList.remove('dd-card-animate-scale-up', 'dd-card-animate-scale-down');
      this._cardBody.classList.add('dd-card-animate-snap');
    }
  }

  /**
   * sequences 1) baseline, 2) transition, 3) endpoint
   */
  setScaleAnimation_() {
    if (this._isMaxView) {
      this._cardBody.classList.add('dd-body1', this._cardBodyOverlayMiddleClass);
    }

    const setScaleCss = this._isMaxView
    ? () => { this._cardBody.classList.add('dd-card-animate-scale-up'); }
    : () => { this._cardBody.classList.add('dd-card-animate-scale-down'); };
    setTimeout(() => {
      setScaleCss();
    }, ASYNC_PAUSE);

    const setAnimationEndParams = this._isMaxView
    ? (this.setMaxAnimationEndParams_).bind(this)
    : (this.setMinAnimationEndParams_).bind(this);
    setTimeout(() => {
      setAnimationEndParams();
    }, 2 * ASYNC_PAUSE);
  }

  clearSnapAnimation_() {
    if (this._cardBody) {
      this._cardBody.classList.remove('dd-card-animate-snap');
    }
  }

  clearScaleAnimation_() {
    if (this._cardBody) {
      this._cardBody.classList.remove('dd-card-animate-scale-up', 'dd-card-animate-scale-down');

      if (!this._isMaxView) {
        this._cardBody.classList.remove('dd-body1', this._cardBodyOverlayMiddleClass);
      }
    }
  }

  setMaxAnimationEndParams_() {
    this._cardBody.classList.replace(this._cardBodyMinClass, this._cardBodyMaxClass);
    this._cardBody.classList.replace(this._cardBodyOverlayMiddleClass, this._cardBodyOverlayClass);
  }

  setMinAnimationEndParams_() {
    this._cardBody.classList.replace(this._cardBodyMaxClass, this._cardBodyMinClass);
    this._cardBody.classList.replace(this._cardBodyOverlayClass, this._cardBodyOverlayMiddleClass);
  }

  setOffSetPosition(offsetNumber) {
    this.clearSnapAnimation_();
    this._offsetNumber = offsetNumber;
    this.setOffSetPosition_();
  }

  isAnimatable_() {
    // only animate scaling if hasFocus (maximizing) or hadFocus (minimizing)
    const hadFocus = !this._isMaxView && this._offsetNumber === 0;

    return (this.hasFocus || hadFocus);
  }

  panX(deltaX) {
    if (isMobileTouch()) {
      pan(this._cardBody, deltaX);
    }
  }

  /**
   *
   * @param {*} event Hammer Event
   * @returns
   */
  handlePan(event) {
    if (isMobileTouch()) {
      if(AppFlagProvider.isDebug()) {
        console.log(`pan: deltaX=${event.deltaX}. X=${this._deltaX}, distance=${event.distance}, angle=${event.angle}, carousel# ${this._carouselNumber}`);
      }

      if (this.isNotSpuriousDeltaX_(event.deltaX) && this.isPanX_(event.angle)) {
        if (this.hasFocus) {
          this._deltaX = event.deltaX;
          if (this._pastCarouselRightEnd || this._pastCarouselLeftEnd) {
            // past end affordance: pause and snap back
            this.stopTouchManagers();
            setTimeout(() => {
              this.rewindPan();
              this.fireResetPanEvent_();
            }, OVERRUN_PAUSE);
            return;
          }

          const isLeftPanPastRange = (this._deltaX < -this.swipeRange);
          const isRightPanPastRange = (this._deltaX > this.swipeRange);

          if (isLeftPanPastRange || this.hasStalledLeft_(event.angle)) {
            this.stopTouchManagers();
            pan(this._cardBody, -this.swipeRange);
            this.handleSwipeLeft_();
          } else if (isRightPanPastRange || this.hasStalledRight_(event.angle)) {
            this.stopTouchManagers();
            pan(this._cardBody, this.swipeRange);
            this.handleSwipeRight();
          } else {
            pan(this._cardBody, this._deltaX);
          }
        } else if (this.allowPanMinimized && (this._allowPanRight || (event.deltaX < 0))) {
          this._deltaX = event.deltaX;

          if (this.hasStalledLeft_(event.angle)) {
            this.stopTouchManagers();
            pan(this._cardBody, -this.swipeRange);
            this.handleSwipeLeft_();
          } else {
            this.showSwipeBackground_();
            pan(this._cardBody, this._deltaX);
          }
        }
      } else {
        this.rewindPan();
        this.fireResetPanEvent_();
      }
    }
  }

  rewindPan() {
    if (isMobileTouch()) {
      this.stopTouchManagers();
      this._deltaX = 0;
      this.snapToPosition_(0);
      this.fireGlobalEvent_(SWIPE_END_EVENT);
    }
  }

  showSwipeSignifierAnimation() {
		if (isMobileTouch() && !this._isMaxView) {
			this.snapToPosition_(-this._swipeLeftRevealRange);

			const rewind = (this.rewindPan).bind(this);
			setTimeout(() => {
				rewind();
			}, 2 * SNAP_DURATION);
		}
	}

  stopPan() {
    if (isMobileTouch() && this._cardBody) {
      this._deltaX = 0;

      // remove snap override
      this._cardBody.style.removeProperty('transform');
    }
  }

  isNotSpuriousDeltaX_(deltaX) {
    const increment = Math.abs(deltaX - this._deltaX);

    return (increment < MAX_X_INCREMENT);
  }

  /**
   * recognize panX if within +/-60 degrees of X axis or is stalled
   * @param {*} angle
   */
  isPanX_(angle) {
    const absAngle = Math.abs(angle);
    const isValidLeftPan = (absAngle > 120) && (absAngle < 240);
    const isValidRighttPan = (absAngle < 60) || (absAngle > 300);

    const isPanX = isValidLeftPan || isValidRighttPan;
    this._stallCounter = isPanX ? 0 : this._stallCounter + 1;

    return isPanX;
  }

  hasStalledLeft_(angle) {
    const absAngle = Math.abs(angle);
    const isLeftPan = (absAngle >= 90) && (absAngle <= 270);
    const wasPanning = this._deltaX < -STALL_X_THRESHOLD;
    const stalled = this._stallCounter > MAX_STALL_COUNT;
    /*
    if (wasPanning && stalled && isLeftPan) {
      console.log('stalled left');
    }
    */
    return wasPanning && stalled && isLeftPan;
  }

  hasStalledRight_(angle) {
    const absAngle = Math.abs(angle);
    const isRightPan = (absAngle < 90) || (absAngle > 270);
    const wasPanning = this._deltaX > STALL_X_THRESHOLD;
    const stalled = this._stallCounter > MAX_STALL_COUNT;
    /*
    if (wasPanning && stalled && isRightPan) {
      console.log('stalled right');
    }
    */
    return wasPanning && stalled && isRightPan;
  }

  panToPosition_(deltaX) {
    this._deltaX = deltaX;
    if (this._cardBody) {
      pan(this._cardBody, deltaX);
    }
  }

  snapToPosition_(deltaX) {
    this.setSnapAnimation_();
    this.panToPosition_(deltaX);
  }

  showSwipeBackground_() {
    const swipeBackground = this.shadowRoot.querySelector('#dd-card-swipe-background');
    if (swipeBackground) {
      swipeBackground.classList.replace('dd_hide', this._showSwipeBackgroundClass);
    }
  }

  hideSwipeBackground_() {
    const swipeBackground = this.shadowRoot.querySelector('#dd-card-swipe-background');
    if (swipeBackground) {
      // delay until after snap animation (hack to avoid sequencing)
      setTimeout(() => { swipeBackground.classList.replace(this._showSwipeBackgroundClass, 'dd_hide'); }, 500);
    }
  }

  firePanEvent_() {
    const event = new CustomEvent(PAN_CARD_EVENT, {
      detail: {
        deltaX: this._deltaX,
        number: this._carouselNumber
      }
    });
    this.dispatchEvent(event);
  }

  fireSwipeEvent_(direction) {
    const event = new CustomEvent(SWIPE_CARD_EVENT, {
      detail: {
        number: this._carouselNumber,
        direction
      }
    });
    this.dispatchEvent(event);
  }

  fireResetPanEvent_() {
    const event = new CustomEvent(RESET_PAN_CARD_EVENT, {});
    this.dispatchEvent(event);
  }

  onSwipeLeft_() {
    this.stopTouchManagers();
    if (this._deltaX < -this._swipeThreshold) {
      // snap to left
      this.snapToPosition_(-this.swipeRange);
      if (this._isMaxView) {
        this.handleSwipeLeft_();
      }
    } else {
      this.rewindPan();
      this.fireResetPanEvent_();
    }

    if (!this._isMaxView && !this._swipeLeftReveal) {
      this.hideSwipeBackground_();
    }
  }

  onSwipeRight_() {
    this.stopTouchManagers();
    if (this._deltaX > this._swipeThreshold) {
      // snap to right
      pan(this._cardBody, this.swipeRange);
      if (this._isMaxView) {
        this.handleSwipeRight();
      }
    } else {
      this.rewindPan();
      this.fireResetPanEvent_();
    }
  }
}
