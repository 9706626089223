import { html } from 'lit-element';
import { DdActivatableCard2 } from "./dd-activatable-card-2.js";
import { getProteinIconTemplate } from '../../template-helpers.js';
import { getDaySidesTitle } from '../../../utilities/text-format.js';

export class DdAbstractMenuItemCard extends DdActivatableCard2 {
	static get properties() {
		return {
			dayMenu: { type: Object }
		};
	}

	set dayMenu(value) {
    const oldValue = this._dayMenu;
		this._dayMenu = value;

		this.requestUpdate('dayMenu', oldValue);
	}

	get dayMenu() {
		return this._dayMenu;
	}

  get hasMain() {
		return Boolean(this.dayMenu && this.dayMenu.main);
	}

  getProteinIconTemplate_(padLeft = false) {
		if (this.dayMenu && this.hasMain) {
			return getProteinIconTemplate(this.dayMenu.main.proteinCategory, this.dayMenu.main.isPersonal, padLeft);
		}

		return getProteinIconTemplate('');
	}

  getSecondTitleTemplate_() {
		const sidesTitle = getDaySidesTitle(this.dayMenu);

		if (sidesTitle) {
			return html`<span class="dd-caption">${sidesTitle}</span>`;
		}

		return '';
	}

  getTitleTemplate_() {
    return html`
    	<div class="dd-flex-top-container dd-zero-line-height" slot="title">
        ${this.getProteinIconTemplate_()}
        <div class="dd-left-justify-flex-fill">
          <h5>${this.getTitle_()}</h5>
          ${this.getSecondTitleTemplate_()}
        </div>
      </div>
    `;
  }

  getTitle_() {
		const title = (this.dayMenu && this.dayMenu.mainName) ? this.dayMenu.mainName : 'Add a meal';

		return title;
	}

  updateActive_() {
		this.isActive = this.hasMain || this.isOpen;
  }
}
