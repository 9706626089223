/**
* API actions for the notifications endpoint
* ref: https://github.com/redux-utilities/flux-standard-action
*/
import { axios, getApiUrl, API_REQUEST, startRequest, completeRequest } from './api-base.js';


// REST resources
const NOTIFICATIONS_RESOURCE = 'notifications';

/**
 * sign up for store topic notification
 * @param string registrationId 
 */
export function requestSubscribeStoreTopic(registrationId) {
	return {
		type: API_REQUEST,
		payload(dispatch, token) {
			dispatch(startRequest());
			const endpoint = `${NOTIFICATIONS_RESOURCE}/store`;
			const url = getApiUrl(endpoint);

			return axios.post(url,
				{
					registrationId
				},
				{
					headers: { Authorization: `Bearer ${token}` }
				})
				.then(() => {
					dispatch(completeRequest());
				})
				.catch(error => {
					dispatch(completeRequest());
					console.log(error);
				});
		}
	}
}

/**
 * unsubscribe from all notifications
 * @param string registrationId 
 */
export function requestUnsubscribeNotifications(registrationId) {
	return {
		type: API_REQUEST,
		payload(dispatch, token) {
			dispatch(startRequest());
			const endpoint = `${NOTIFICATIONS_RESOURCE}/${registrationId}`;
			const url = getApiUrl(endpoint);

			return axios.delete(url, {
				headers: { Authorization: `Bearer ${token}` }
			})
				.then(() => {
					dispatch(completeRequest());
				})
				.catch(error => {
					dispatch(completeRequest());
					console.log(error);
				});
		}
	}
}
