import { SET_MENU } from '../actions/menu';

const INITIAL_STATE = {
	startDate: '',
	endDate: '',
	metadata: {},
	dayMenus: [],
	sideRecipeOptions: []
};

export function menu(state = INITIAL_STATE, action) {
	switch (action.type) {
		case SET_MENU: {
			return {
				...state,
				...action.payload.weekMenu
			};
		}

		default:
			return state;
	}
}
