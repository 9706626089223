import { html, css } from 'lit-element';
import '@material/mwc-icon-button';
import { DdAbstractMenuItemCard } from './dd-abstract-menu-item-card.js';
import { sharedStyles } from '../../../theme/shared-styles.js';

const DAY_MENU_ITEM_SELECTED_EVENT = 'day-menu-item-selected';

class DdMenuItemCard extends DdAbstractMenuItemCard {
  static get properties() {
    return {
      clickable: { type: Boolean },
    };
  }

  static get styles() {
    return [
      sharedStyles,
      css`
        :host {
          display: none;
        }

        :host([isactive]) {
          display: block;
        }

        .dd-caption {
          display: block;
        }
        mwc-icon-button {
          font-size: 24px;
          color: #202020;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.isOpen = true;
    this.clickable = false;
  }

  render() {
    return html`
      <div class="dd-flex-top-container">
        <div title="see meal" @click="${this.onClick_}">
          ${this.getTitleTemplate_()}
        </div>
        ${this.clickable
          ? html`
              <mwc-icon-button
                icon="chevron_right"
                title="see meal"
                @click="${this.onClick_}"
              ></mwc-icon-button>
            `
          : ''}
      </div>
    `;
  }

  onClick_() {
    if (this.clickable) {
      const detail = { id: this.dayMenu.id };
      this.fireGlobalEvent_(DAY_MENU_ITEM_SELECTED_EVENT, detail);
    }
  }
}

customElements.define('dd-menu-item-card', DdMenuItemCard);
