/**
* API actions for the profile resource
* ref: https://github.com/redux-utilities/flux-standard-action
*/
import {
	axios,
	getApiUrl,
	API_REQUEST,
	startRequest,
	completeRequest,
	NOK_RESPONSE_HINT
} from './api-base.js';

// REST resources
const EMAIL_ENDPOINT = 'profile/email';

export function requestUpdateEmail(newEmail, currentEmail, successMessage) {
  return {
		type: API_REQUEST,
		payload (dispatch, token) {
			dispatch(startRequest());

			const url = getApiUrl(EMAIL_ENDPOINT);

			return axios.put(url,
				{
          newEmail,
          currentEmail
        },
				{
				headers: { Authorization: `Bearer ${token}` }
			})
				.then(() => {
					dispatch(completeRequest(successMessage));
				})
				.catch(error => {
					dispatch(completeRequest(NOK_RESPONSE_HINT));
					console.log(error);
				});
		}
	}
}
