import { createTransform } from 'redux-persist';
import {
	MENU_VIEW,
	SL_PRINT_VIEW,
	SL_VIEW,
	WEEK_PRINT_VIEW
} from '../../actions/navigation.js';

/**
 * navigation substate view transform
 */
const viewTransform = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState) => inboundState,
  // transform state being rehydrated
  (outboundState) => {
    // revert view if print view was persisted
    let view = outboundState.view;
    if (view === WEEK_PRINT_VIEW) {
      view = MENU_VIEW;
    } else if (view === SL_PRINT_VIEW) {
      view = SL_VIEW;
    }

    return { ...outboundState, view };
  },
  // define which reducers this transform gets called for.
  { whitelist: ['navigation'] }
);

export default viewTransform;