import { css, html } from 'lit-element';
import { sharedStyles } from '../theme/shared-styles.js';
import "@material/mwc-icon-button";
import { PageViewElement } from './page-view-element.js';

const CLOSE_EVENT = 'close';

class DdIframe extends PageViewElement {
	static get properties() {
		return {
			source: { type: String },
			title: { type: String }
		};
	}

	static get styles() {
		return [
			sharedStyles,
      css`
      :host {
				display: flex;
				flex-flow: column;
        border: none;
      }

      iframe {
				flex-grow: 1;
        display: block;
        height: 100%;
        width: 100%;
        border: none;
			}

			#close-container {
				flex-grow: 0;
				background-color: var(--mdc-theme-primary-light);
			}

			#title {
				font-size: 20px;
				color: var(--mdc-theme-on-primary);
				text-transform: capitalize;
			}
		  `
		];
	}

	/**
   * LIFECYCLE
   */
	 constructor() {
    super();
		this.title = '';
	 }

	render() {
		return html`
			<div id="close-container" class="dd-flex-container">
				<div class="dd-button-placeholder"></div>
				<span id="title">${this.title}</span>
				<mwc-icon-button icon="close" @click="${this.onClose_}"></mwc-icon-button>
			</div>
			<iframe src="${this.source}"></iframe>
		`;
	}

	onClose_() {
		const event = new CustomEvent(CLOSE_EVENT, {});
		this.dispatchEvent(event);
	}
}

customElements.define('dd-iframe', DdIframe);