/**
 * ref: https://github.com/redux-utilities/flux-standard-action
 *      /state/middleware/request-middleware.js
 *      https://docs.google.com/spreadsheets/d/1MmffvNk7OJFhEYA2ZSUOVZ6OT_um1848_o-EpzCv1MA/edit?usp=sharing
 */
import {
	axios,
	getApiUrl,
	startRequest,
	completeRequest,
	API_REQUEST
} from './api-base.js';
import { navigate, SUBSCRIPTION_VIEW } from '../navigation.js';
import { saveSubscription } from '../subscription.js';
import { SUBSCRIPTION_STATUS } from '../../utilities/constants.js';

// REST resources
const SUBSCRIPTION_RESOURCE = 'subscription';
const CHECKOUT_ENDPOINT = 'checkout';
const STATUS_ENDPOINT = 'status';
const PORTAL_ENDPOINT = 'portal';

function handleStatusResponse(dispatch, response) {
	const actions = [saveSubscription(response.data)];
	if (response.data.status === SUBSCRIPTION_STATUS.CANCELLED) {
		actions.push(navigate(SUBSCRIPTION_VIEW));
	}
	dispatch(actions);
}

/**
 * get subscription status
 */
 export function requestSubscription() {
	return {
		type: API_REQUEST,
		payload(dispatch, token) {
			const endpoint = `${SUBSCRIPTION_RESOURCE}/${STATUS_ENDPOINT}`;
			const url = getApiUrl(endpoint);

			return axios.get(url, {
				headers: { Authorization: `Bearer ${token}` }
			})
				.then(response => {
					handleStatusResponse(dispatch, response);
				})
				.catch(error => {
					console.log(error);
				});
		}
	}
}

/**
 * get subscription portal session
 */
export function requestSubscriptionPortal(handler) {
	return {
		type: API_REQUEST,
		payload(dispatch, token) {
			const endpoint = `${SUBSCRIPTION_RESOURCE}/${PORTAL_ENDPOINT}`;
			const url = getApiUrl(endpoint);

			return axios.get(url, {
				headers: { Authorization: `Bearer ${token}` }
			})
				.then(response => {
					handler(() => new Promise((resolve) => {
						resolve(response.data);
					}));
				})
				.catch(error => {
					console.log(error);
				});
		}
	}
}

/**
 * get subscription status and then portal session
 */
export function requestSubscriptionAndPortal(portalHandler) {
	return {
		type: API_REQUEST,
		payload(dispatch, token) {
			const endpoint = `${SUBSCRIPTION_RESOURCE}/${STATUS_ENDPOINT}`;
			const url = getApiUrl(endpoint);

			return axios.put(url,
				{},
				{
					headers: { Authorization: `Bearer ${token}` }
				})
				.then(response => {
					handleStatusResponse(dispatch, response);

					return dispatch(requestSubscriptionPortal(portalHandler));
				})
				.catch(error => {
					console.log(error);
				});
		}
	}
}

/**
 * update subscription status
 */
export function requestUpdateSubscription() {
	return {
		type: API_REQUEST,
		payload(dispatch, token) {
			const endpoint = `${SUBSCRIPTION_RESOURCE}/${STATUS_ENDPOINT}`;
			const url = getApiUrl(endpoint);

			return axios.put(url,
				{},
				{
					headers: { Authorization: `Bearer ${token}` }
				})
				.then(response => {
					handleStatusResponse(dispatch, response);
				})
				.catch(error => {
					console.log(error);
				});
		}
	}
}

/**
 * ref: AppBundle\Controller\Rest\SubscriptionController::getCheckoutAction
 * @param {*} handler
 * @param {*} planId
 * @returns
 */
export function openSubscriptionCheckout(handler, planId) {
	return {
		type: API_REQUEST,
		payload(dispatch, token) {
			dispatch(startRequest());

			const endpoint = `${SUBSCRIPTION_RESOURCE}/${CHECKOUT_ENDPOINT}`;
			const url = getApiUrl(endpoint);

			return axios.get(url, {
				params: { internalId: planId },
				headers: { Authorization: `Bearer ${token}` }
			})
				.then(response => {
					handler({
						hostedPage: () => new Promise((resolve) => {
							resolve(response.data);
						}),
						success: () => {
							dispatch(requestUpdateSubscription());
						}
					})
					dispatch(completeRequest());
				})
				.catch(error => {
					console.log(error);
				});
		}
	}
}
