/** context for states */
import { State } from './state.js';

export const STEP_0 = 0;
export const STEP_1 = 1;
export const STEP_2 = 2;
export const STEP_3 = 3;
export const STEP_4 = 4;

function  initializeStates(
  setIntroView,
  setDietaryNeedsView,
  setFoodPreferencesView,
  setStorePlanView,
  setSendoffView,
  setCompleted
) {
  const introState = new State(STEP_0, setIntroView);
  const dietaryNeedsState = new State(STEP_1, setDietaryNeedsView);
  const foodPreferencesState = new State(STEP_2, setFoodPreferencesView);
  const storePlanState = new State(STEP_3, setStorePlanView);
  const sendoffState = new State(STEP_4, setSendoffView, setCompleted);

  // wire state machine
  introState.nextState = dietaryNeedsState;
  dietaryNeedsState.previousState = introState;
  dietaryNeedsState.nextState = foodPreferencesState;
  foodPreferencesState.previousState = dietaryNeedsState;
  foodPreferencesState.nextState = storePlanState;
  storePlanState.previousState = foodPreferencesState;
  storePlanState.nextState = sendoffState;
  sendoffState.previousState = storePlanState;

  return introState;
}

/**
 * state machine for onboarding screen sequencing
 */
export class StateMachine {
  constructor(
    setIntroView,
    setDietaryNeedsView,
    setFoodPreferencesView,
    setStorePlanView,
    setSendoffView,
    setCompleted = null
  ) {
    this.setIntroView = setIntroView;
    this.setDietaryNeedsView = setDietaryNeedsView;
    this.setFoodPreferencesView = setFoodPreferencesView;
    this.setStorePlanView = setStorePlanView;
    this.setSendoffView = setSendoffView;
    this.setCompleted = setCompleted;

    this._currentState = initializeStates(
      setIntroView,
      setDietaryNeedsView,
      setFoodPreferencesView,
      setStorePlanView,
      setSendoffView,
      setCompleted
    );
  }

  get stepNumber() {
    return this._currentState.stepNumber;
  }

  start() {
    this._currentState.enter();
  }

  next() {
    const nextState = this._currentState.next();
    if (nextState) {
      this._currentState = nextState;
      this._currentState.enter();
    }
  }

  previous() {
    const prevState = this._currentState.previous();
    if (prevState) {
      this._currentState = prevState;
      this._currentState.enter();
    }
  }
}