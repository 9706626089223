/**
 * news feed API actions
 * ref: https://github.com/redux-utilities/flux-standard-action
 *      /state/middleware/request-middleware.js
 *
 * silent on failure
 */
import { axios } from './api-base.js';
import {
  saveFeatured,
  saveMemberNews,
  saveQuote,
  saveWeeklyTip
} from '../news.js'
import { getWebUrl } from '../../utilities/url-translation.js';

const NEWSFEED_REQUEST = 'NEWSFEED_REQUEST';
const FEATURED_ENDPOINT = 'tag/featured/feed/json';
const WEEKLY_TIP_ENDPOINT = 'tag/weekly-tip/feed/json';
const MEMBER_NEWS_ENDPOINT = 'tag/member-news/feed/json';
const QUOTE_ENDPOINT = 'tag/quote/feed/json';

export function requestFeatured() {
  return {
    type: NEWSFEED_REQUEST,
    payload(dispatch) {
      const url = getWebUrl(FEATURED_ENDPOINT);

      return axios.get(url, {})
        .then(response => {
          dispatch(saveFeatured(response.data));
        })
        .catch(() => {
        });
    }
  }
}

export function requestMemberNews() {
  return {
    type: NEWSFEED_REQUEST,
    payload(dispatch) {
      const url = getWebUrl(MEMBER_NEWS_ENDPOINT);

      return axios.get(url, {})
        .then(response => {
          dispatch(saveMemberNews(response.data));
        })
        .catch(() => {
        });
    }
  }
}

export function requestQuote() {
  return {
    type: NEWSFEED_REQUEST,
    payload(dispatch) {
      const url = getWebUrl(QUOTE_ENDPOINT);

      return axios.get(url, {})
        .then(response => {
          dispatch(saveQuote(response.data));
        })
        .catch(() => {
        });
    }
  }
}

export function requestWeeklyTip() {
  return {
    type: NEWSFEED_REQUEST,
    payload(dispatch) {
      const url = getWebUrl(WEEKLY_TIP_ENDPOINT);

      return axios.get(url, {})
        .then(response => {
          dispatch(saveWeeklyTip(response.data));
        })
        .catch(() => {
        });
    }
  }
}
