import axios from 'axios';
import { setToken } from '../../actions/token.js';
import { getApiUrl } from '../../utilities/url-translation.js';
import { TOKEN_UNAUTHORIZED } from '../../utilities/constants.js';
import { API_REQUEST } from '../../actions/api/api.js';

const jwtDecode = require('jwt-decode');

/**
 * handles JWT expiration of all API request thunk Flux Standard Actions
 * ref: https://redux.js.org/tutorials/fundamentals/part-4-store#writing-custom-middleware
 */
export default function requestMiddleware() {
	return ({ dispatch, getState }) => next => action => {
		const request = action.payload;
		if (typeof request !== 'function') {
			return next(action);
		}

		// action is a thunk
		const apiRequest = action.type === API_REQUEST;
		const token = getState().token;
		if ( apiRequest && token.auth && token.refresh) {
			const authToken = jwtDecode(token.auth);
			const now = new Date();
			const fiveMinFromNow = now.getTime()/1000 + 300;

			if (fiveMinFromNow > authToken.exp) {
				const refreshUrl = getApiUrl('token/refresh');
				return axios.post(refreshUrl, {
					refresh_token: token.refresh
				})
				.then(response => {
					dispatch(setToken(response.data.token, response.data.refresh_token));
				})
				.then(() => {
					request(dispatch, getState().token.auth);
				})
				.catch(error => {
						if (error.response.status === 401) {
							dispatch(setToken('', TOKEN_UNAUTHORIZED));
						} else {
							console.log(error);
						}
				});
			}

			return request(dispatch, token.auth);
		}

		return next(request);
	};
}
