import Fuse from 'fuse.js';
import cloneDeep from 'lodash-es/cloneDeep.js';
import isEqual from 'lodash-es/isEqual.js';
import { PubSub } from './pub-sub.js';


export const INGREDIENTS_SET_EVENT = 'ingredients-set';

export const VOL_MEASUREMENT = {
  en: {
    cup: 'cup',
    tsp: 'tsp',
    tbs: 'tbs'
  }
}

const OPTIONS = {
  includeScore: true,
  minMatchCharLength: 2,
  shouldSort: true,
  threshold: 0.3,
  keys: [
    {
      name: 'name',
      weight: 2
    },
    {
      name: 'shoppingCategory',
      weight: 1
    },
    {
      name: 'searchTerm',
      weight: 2
    }
  ]
};

/**
 * provider service wrapper around features substate
 */
export const IngredientsProvider = {
  _ingredients: [],
  _cookingMeasurements: [],
  _fuse: null,

  setData(data) {
    if (data.ingredients && !isEqual(data.ingredients, this._ingredients)) {
      this._ingredients = data.ingredients;
      this._cookingMeasurements = data.cookingMeasurements;
      this._fuse = new Fuse(this._ingredients, OPTIONS);
      PubSub.publish(INGREDIENTS_SET_EVENT);
    }
  },

  getIngredient(ingredientId) {
    return this._ingredients.find(ingredient => ingredient.id === ingredientId);
  },

  getCookingMeasurements() {
    return cloneDeep(this._cookingMeasurements);
  },

  getVolumetricMeasurements() {
    const volumetricMeasurements = this._cookingMeasurements.filter(measurement => {
      const normalizedName = measurement.name.toLowerCase();
      return (normalizedName === VOL_MEASUREMENT.en.cup) || (normalizedName === VOL_MEASUREMENT.en.tsp) || (normalizedName === VOL_MEASUREMENT.en.tbs);
    })

    return cloneDeep(volumetricMeasurements);
  },

  addAlternateMeasurements(cookingMeasurements) {
    const volMeasurements = this.getVolumetricMeasurements();

    if (cookingMeasurements.length) {
      const isIntersection = cookingMeasurements.some(cookingMeasurement => volMeasurements.some(volMeasurment => volMeasurment.id === cookingMeasurement.id));

      if (isIntersection) {
        // add volMeasurements and deduplicate
        const union = cookingMeasurements.concat(volMeasurements);
        const uniqueIdSet = new Set(union.map(u => u.id));
        const uniqueIds = [...uniqueIdSet];

        const cookingAndAltMeasurements = uniqueIds.map(id => union.find(a => a.id === id));
        return cookingAndAltMeasurements;
      }

      return cookingMeasurements;
    }

    return volMeasurements;
  },

  findIngredients(searchTerm) {
    const results = this._fuse.search(searchTerm);
    let lowScore = 1.0;

    results.forEach(element => {
      console.log(`${element.item.name} = ${element.score}`);
      if (element.score < lowScore) {
        lowScore = element.score;
      }
    });
    const items = results.map(result => result.item)

    return {
      lowScore,
      items
    };
  }
}