import { SET_DIET_PLAN_OPTIONS } from '../actions/diet-plan-options.js';

const INITIAL_STATE = {
};

export function dietPlanOptions(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_DIET_PLAN_OPTIONS:
      return {
        ...state,
        ...action.payload.dietPlanOptions
      };

    default:
      return state;
  }
}
