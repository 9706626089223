import { html, css } from 'lit-element';
import { DdCard2 } from './dd-card-2.js';
import { getChipTemplate } from '../../template-helpers.js';
import { sharedStyles } from '../../../theme/shared-styles.js';

export const NEWS_CLICK_EVENT = 'news-click';

class DdNewsCard extends DdCard2 {
  static get properties() {
		return {
			chipText: { String },
      colorClass: { String },
      title: { String },
      lede: { String },
      url: { String },
      imageUrl: { String }
		};
	}

  static get styles() {
		return [
			sharedStyles,
			css`
        #chip-container {
          margin-bottom: 16px;
        }

        .dd-body1 {
          margin-top: 8px;
          color: var(--dd-theme-main-medium);
        }

        .dd-flex-top-container > img {
          height: 44px;
          width: 44px;
          flex-shrink: 0;
          flex-grow: 0;
          margin-right: 16px;
        }

        @media only screen and (min-width: 1248px) {
          .container {
            box-sizing: border-box;
            height: 100%;
            background-color: var(--mdc-theme-surface);
            border-radius: 20px;
            padding: 16px;
            display: flex;
            flex-direction: column;
            align-items: stretch
          }
          .container > div:first-child {
            flex-grow: 0;
          }

          .spacer {
            flex-grow: 1;
          }
        }
      `
		];
	}

  render() {
		return html`
    <div class="container" @click="${this.onClick_}">
      <div>
        <div id="chip-container">
          ${getChipTemplate(this.chipText, this.colorClass)}
        </div>
        ${
          this.imageUrl
          ? html`
            <div class="dd-flex-top-container">
              <img src="${this.imageUrl}">
              <div>
                <h5>${this.title}</h5>
                ${this.lede
                  ? html`<div class="dd-body1">${this.lede}</div>`
                  : ''
                }
              </div>
            </div>
          `
          : html`
            <h5>${this.title}</h5>
            ${this.lede
              ? html`<div class="dd-body1">${this.lede}</div>`
              : ''
            }
          `
        }
      </div>
      <div class="spacer"></div>
    </div>
    `;
  }

  onClick_() {
    if (this.url) {
      this.fireGlobalEvent_(NEWS_CLICK_EVENT, {
        title: this.chipText,
        url: this.url});
    }
  }
}

customElements.define('dd-news-card', DdNewsCard);