/**
 * subscription data actions
 */
 import { toCamelCase } from '../utilities/object-evaluation.js';

// action type
 export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';

 export function saveSubscription(subscription) {
  return {
    type: SET_SUBSCRIPTION,
    payload: {
      subscription: toCamelCase(subscription)
    }
  }
 }
