import { START_REQUEST, COMPLETE_REQUEST, CLEAR_MESSAGE } from '../actions/api/api.js';

const INITIAL_STATE = {
	isFetching: false,
	message: ''
};

export function apiReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case START_REQUEST:
			return {
				...state,
				isFetching: true,
				message: action.payload.message
			};
		case COMPLETE_REQUEST:
			return {
				...state,
				isFetching: false,
				message: action.payload.message ? action.payload.message : state.message
			};
		case CLEAR_MESSAGE:
			return {
				...state,
				message: ''
			};
		default:
			return state;
	}
}

