/**
 * adapted from https://gist.github.com/jherax/a81c8c132d09cc354a0e2cb911841ff1
 * Lightweight script to detect whether the browser is running in Private mode.
 * @returns {Promise<boolean>}
 *
 * Live demo:
 * @see https://output.jsbin.com/tazuwif
 *
 * This snippet uses Promises. If you want to run it in old browsers, polyfill it:
 * @see https://cdn.jsdelivr.net/npm/es6-promise@4/dist/es6-promise.auto.min.js
 *
 * More Promise Polyfills:
 * @see https://ourcodeworld.com/articles/read/316/top-5-best-javascript-promises-polyfills
 */
export function isPrivateMode() {
  return new Promise((resolve) => {
    const yes = () => { resolve(true); }; // is in private mode
    const not = () => { resolve(false); }; // not in private mode

    function detectChromeOpera() {
      // https://developers.google.com/web/updates/2017/08/estimating-available-storage-space
      // Chrome 94 - 256MB quota: increase threshold to 500 MB
      const isChromeOpera = /(?=.*(opera|chrome)).*/i.test(navigator.userAgent) && navigator.storage && navigator.storage.estimate;
      if (isChromeOpera) {
        navigator.storage.estimate().then((data) => data.quota < 4000000000 ? yes() : not());
      }
      return !!isChromeOpera;
    }

    function detectFirefox() {
      const isMozillaFirefox = 'MozAppearance' in document.documentElement.style;
      if (isMozillaFirefox) {
        if (indexedDB == null) {
          yes();
        } else {
          const db = indexedDB.open('inPrivate');
          db.onsuccess = not;
          db.onerror = yes;
        }
      }
      return isMozillaFirefox;
    }

    function detectSafari() {
      const isSafari = /Safari/.test(window.navigator.userAgent);

      if (isSafari) {
        // for our purposes, return true if either in private mode or client side storage is disabled (cookie is disabled)
        if (window.safariIncognito || !navigator.cookieEnabled) {
          yes();
        } else {
          try {
            window.openDatabase(null, null, null, null);
            localStorage.setItem('inPrivate', '0');
            localStorage.removeItem('inPrivate');
            not();
          } catch (_) {
            yes();
          }
        }
      }
      return !!isSafari;
    }

    function detectEdgeIE10() {
      const isEdgeIE10 = !window.indexedDB && (window.PointerEvent || window.MSPointerEvent);
      if (isEdgeIE10) yes();
      return !!isEdgeIE10;
    }

    // when a browser is detected, it runs tests for that browser
    // and skips pointless testing for other browsers.
    if (detectChromeOpera()) return;
    if (detectFirefox()) return;
    if (detectEdgeIE10()) return;
    if (detectSafari()) return;

    // default navigation mode
    not();
  });
}
