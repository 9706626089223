import { SET_FEATURES } from '../actions/features';

const INITIAL_STATE = {
	active: []
};

export function features(state = INITIAL_STATE, action) {
	switch (action.type) {
		case SET_FEATURES:
			return {
				...state,
				active: action.payload.features
			};
		default:
			return state;
	}
}

