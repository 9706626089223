import { RecipeShopItemMapProvider } from '../services/recipe-shop-item-map-provider.js';
import { COLOR_CLASSES } from '../theme/color-classes.js';

/**
 * generate day menu selector options list
 * @param {*} dayMenus
 */
export function createSelectOptions(dayMenus) {
  const indexPool = dayMenus;
  function getIndex(mainId) {
    return indexPool.findIndex((indexPoolItem) => indexPoolItem.main && (indexPoolItem.main.id === mainId));
  }

  const filteredDayMenus = dayMenus.filter(dayMenu => dayMenu.mainName);

  return filteredDayMenus.map((dayMenu) => {
        const mainRecipeId = dayMenu.main? dayMenu.main.id : 0;
        const index = getIndex(mainRecipeId);
        const decorator = mainRecipeId? COLOR_CLASSES[index] : null;
        const sideRecipeIds = [];

        const firstSide = (dayMenu.sides && dayMenu.sides[0]) ? dayMenu.sides[0].name : '';
		    const secondSide = (dayMenu.sides && dayMenu.sides[1]) ? dayMenu.sides[1].name : '';
        let printName = dayMenu.mainName;
        let sideNames = '';
        if (firstSide) {
          printName += `, ${firstSide}`;
          sideNames = firstSide;
          sideRecipeIds.push(dayMenu.sides[0].id);
        }
        if (secondSide) {
          printName += `, ${secondSide}`;
          if (sideNames) {
            sideNames += `, ${secondSide}`;
          } else {
            sideNames = secondSide;
          }
          sideRecipeIds.push(dayMenu.sides[1].id);
        }

        return {
          id: dayMenu.id,
          decorator,
          recipeIds: {
            main: mainRecipeId,
            sides: sideRecipeIds
          },
          name: dayMenu.mainName,
          printName,
          sideNames,
          active: RecipeShopItemMapProvider.isRecipeActive(mainRecipeId)
        };
      }
    );
}
