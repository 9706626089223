import { html, css } from 'lit-element';
import '@material/mwc-button';
import '@material/mwc-formfield';
import '@material/mwc-radio';
import '@material/mwc-icon';
import isEqual from 'lodash-es/isEqual.js';
import { PageViewElement } from '../../page-view-element.js';
import { StateMachine, STEP_0, STEP_1, STEP_3 } from './state-machine.js';
import '../dd-dietary-needs.js';
import '../dd-food-limits.js';
import '../dd-store-select.js';
import '../dd-mini-menu.js';
import './dd-step-progress.js';
import { sharedStyles } from '../../../theme/shared-styles.js';
import style from './dd-onboarding.scss';
import { ASYNC_PAUSE } from '../../../utilities/constants.js';

const SET_DIET_PLAN_OPTIONS_EVENT = 'set-diet-plan-options';
const ONBOARDING_COMPLETED_EVENT = 'onboarding-completed';

const INTRO_VIEW = 'intro';
const DIETARY_NEEDS_VIEW = 'dietary-needs';
const FOOD_PREFS_VIEW = 'food-prefs';
const STORE_PLAN_VIEW = 'store-plan';
const SENDOFF_VIEW = 'sendoff';

class DdOnboarding extends PageViewElement {
  static get properties() {
    return {
      dietPlanOptions: { type: Object },
      store: { type: String },
      weekMenu: { type: Object },
      _internalDietPlanOptions: { type: Object },
      _stepNumber: { type: Number }, // internal render trigger on step progress
    };
  }

  set dietPlanOptions(value) {
    // freeze state once onboarding started
    if (this._stateMachine.stepNumber === STEP_0) {
      const oldValue = this._dietPlanOptions;
      this._dietPlanOptions = value;
      this._internalDietPlanOptions = value;
      this.requestUpdate('dietPlanOptions', oldValue);
    }
  }

  set store(value) {
    const oldValue = this._store;
    this._store = value;
    this._internalStore = value;
    this.requestUpdate('store', oldValue);
  }

  static get styles() {
    return [
      sharedStyles,
      style,
      css`
        .intro-image {
          background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 27.6%, #ffffff 100%),
            url('images/welcome-square.png');
        }

        /* large screen overrides, 1280x800+  */
        @media only screen and (min-width: 1248px) {
          .intro-image {
            background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 27.6%, #ffffff 100%),
              url('images/welcome-wide.png');
          }
        }
      `,
    ];
  }

  constructor() {
    super();

    this._dietPlanOptions = {};
    this._internalDietPlanOptions = {};
    this._store = '';
    this._internalStore = '';
    this.weekMenu = { metadata: {} };
    this._stepNumber = STEP_0 - 1;

    this._stateMachine = new StateMachine(
      this.setIntroView_.bind(this),
      this.setDietaryNeedsView_.bind(this),
      this.setFoodPreferencesView_.bind(this),
      this.setStorePlanView_.bind(this),
      this.setSendoffView_.bind(this),
      this.setCompleted_.bind(this),
    );
  }

  firstUpdated() {
    super.firstUpdated();
    this._stateMachine.start();
  }

  updated(changedProperties) {
    super.updated(changedProperties);

    // monkey patch
    const navButtons = this.shadowRoot.querySelectorAll('mwc-button.nav-button');
    navButtons.forEach(navButton => {
      const buttonNode = navButton.shadowRoot.querySelector('button');
      const stylePatch = document.createElement('style');
      stylePatch.innerHTML = '.mdc-button--outlined { height: 48px !important; }';
      buttonNode.appendChild(stylePatch);
    });
  }

  render() {
    return html`
      <div id="grid-outer" class="dd-full-screen">
        <div id="grid-middle" class="dd-full-screen">
          <div id="grid-inner">
            <div class="content-container">
              <div id="progress-container" class="dd_hide">
                <dd-step-progress
                  .first=${STEP_1}
                  .last=${STEP_3}
                  .current=${this._stateMachine.stepNumber}
                ></dd-step-progress>
              </div>

              <div id="${INTRO_VIEW}" class="onboarding-stage dd_hide">
                <div class="intro-image"></div>
                <h2>Welcome To</h2>
                <h1 class="heading-margin">The Dinner Daily!</h1>
                <p class="dd-h5">
                  We’re excited to create a <span class="dd-h5-bold">menu just for you</span>. Just
                  tell us what you like to eat, where you shop, and we'll do the rest.
                </p>
                <p class="dd-h5">
                  We'll also use this week's store sales so we can
                  <span class="dd-h5-bold">save you money</span>.
                </p>
              </div>

              <div id="${DIETARY_NEEDS_VIEW}" class="onboarding-stage dd_hide">
                <h2 class="heading-margin">
                  Do you have any <span class="dd-h2 bold">Dietary Needs?</span>
                </h2>
                <p class="hint">
                  Select all that apply. If you have none, just leave all options unchecked.
                </p>
                <dd-dietary-needs
                  include-hh
                  .dietPlanOptions=${this._internalDietPlanOptions}
                ></dd-dietary-needs>
              </div>

              <div id="${FOOD_PREFS_VIEW}" class="onboarding-stage dd_hide">
                <h2 class="heading-margin">
                  What are your <span class="dd-h2 bold">Food Preferences?</span>
                </h2>
                <dd-food-limits
                  .dietPlanOptions=${this._internalDietPlanOptions}
                  @food-limit-total-changed="${this.onFoodLimitTotalChanged_}"
                ></dd-food-limits>
              </div>

              <div id="${STORE_PLAN_VIEW}" class="onboarding-stage dd_hide">
                <h2 class="heading-margin">
                  Select your <span class="dd-h2 bold">Store</span> and
                  <span class="dd-h2 bold">Meal Size</span>
                </h2>
                <dd-store-select
                  .familySize=${this.weekMenu.metadata.familySize}
                  @store-selected="${this.onStoreSelected_}"
                ></dd-store-select>
              </div>
              <div id="${SENDOFF_VIEW}" class="onboarding-stage dd_hide">
                <h2>That's it</h2>
                <h1 class="heading-margin">You're done!</h1>
                <p>
                  Please watch our quick video tour below. Also, Keep an eye out for a series of
                  quick helpful tips delivered to your inbox over the next couple of weeks.
                </p>
                <div class="dd-section-vertical-margin">
                  <iframe
                    width="100%"
                    height="315px"
                    src="https://www.youtube.com/embed/vDCqTBD1DRQ?controls=0&modestbranding=1"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
                <p>And remember, you can change any of your preferences at any time.</p>
                <p>Now let’s get you to your first menu!</p>
              </div>
            </div>

            <div class="spacer"></div>
            <div id="${INTRO_VIEW}-nav" class="nav-container split-section dd_hide">
              <div class="split-section-child"></div>
              <div class="split-section-child">
                <mwc-button
                  outlined
                  label="let's get started"
                  class="nav-button dark-filled dd-full-width"
                  @click="${this.onNextClick_}"
                >
                  <mwc-icon slot="trailingIcon">arrow_forward</mwc-icon>
                </mwc-button>
              </div>
            </div>
            <div id="${DIETARY_NEEDS_VIEW}-nav" class="dd-flex-end-container nav-container dd_hide">
              <mwc-button
                outlined
                label="next"
                class="nav-button dark-filled"
                @click="${this.onNextClick_}"
              >
                <mwc-icon slot="trailingIcon">arrow_forward</mwc-icon>
              </mwc-button>
            </div>
            <div id="${FOOD_PREFS_VIEW}-nav" class="dd-flex-end-container nav-container dd_hide">
              <mwc-button
                outlined
                label="back"
                class="nav-button light-filled dd-button-margin-right"
                @click="${this.onPreviousClick_}"
              >
                <mwc-icon slot="icon">arrow_back</mwc-icon>
              </mwc-button>
              <mwc-button
                id="${FOOD_PREFS_VIEW}-next"
                outlined
                label="next"
                class="nav-button dark-filled dd-invisible"
                @click="${this.onNextClick_}"
              >
                <mwc-icon slot="trailingIcon">arrow_forward</mwc-icon>
              </mwc-button>
            </div>
            <div id="${STORE_PLAN_VIEW}-nav" class="dd-flex-end-container nav-container dd_hide">
              <mwc-button
                outlined
                label="back"
                class="nav-button light-filled dd-button-margin-right"
                @click="${this.onPreviousClick_}"
              >
                <mwc-icon slot="icon">arrow_back</mwc-icon>
              </mwc-button>
              <mwc-button
                id="${STORE_PLAN_VIEW}-next"
                outlined
                label="next"
                class="nav-button dark-filled dd-invisible"
                @click="${this.onNextSubmitClick_}"
              >
                <mwc-icon slot="trailingIcon">arrow_forward</mwc-icon>
              </mwc-button>
            </div>
            <div id="${SENDOFF_VIEW}-nav" class="dd-flex-end-container nav-container dd_hide">
              <mwc-button
                outlined
                label="finish"
                class="nav-button dark-filled"
                @click="${this.onNextClick_}"
              >
                <mwc-icon slot="trailingIcon">done</mwc-icon>
              </mwc-button>
            </div>
          </div>
        </div>
      </div>
      ${this.getSpacerTemplate_(0)}
    `;
  }

  setIntroView_() {
    this.setOnboardingStageView_(INTRO_VIEW);
  }

  setDietaryNeedsView_() {
    this.setOnboardingStageView_(DIETARY_NEEDS_VIEW);
  }

  setFoodPreferencesView_() {
    this.updateInternalDietPlanOptions_(FOOD_PREFS_VIEW);
    this.setOnboardingStageView_(FOOD_PREFS_VIEW);

    const foodLimits = this.shadowRoot.querySelector('dd-food-limits');
    setTimeout(() => {
      foodLimits.onShow();
    }, ASYNC_PAUSE);
  }

  setStorePlanView_() {
    this.updateInternalDietPlanOptions_(STORE_PLAN_VIEW);
    this.setOnboardingStageView_(STORE_PLAN_VIEW);
    const storePlan = this.shadowRoot.querySelector('dd-store-select');
    storePlan.focus();
  }

  setSendoffView_() {
    this.setOnboardingStageView_(SENDOFF_VIEW);

    // if prefs changed, assemble DTO, make API call
    if (this.isPrefsChanged_()) {
      const dietaryNeeds = this.shadowRoot.querySelector('dd-dietary-needs');
      const dietaryNeedsDto = dietaryNeeds.dietPlanOptionsDto;
      const foodLimits = this.shadowRoot.querySelector('dd-food-limits');
      const foodLimitsDto = foodLimits.dietPlanOptionsDto;
      const storePlan = this.shadowRoot.querySelector('dd-store-select');
      const store = storePlan.store;
      const detail = {
        dto: {
          ...dietaryNeedsDto,
          ...foodLimitsDto,
          store,
        },
      };
      const event = new CustomEvent(SET_DIET_PLAN_OPTIONS_EVENT, { detail });
      this.dispatchEvent(event);
    }

    this._stepNumber = this._stateMachine.stepNumber;
  }

  setCompleted_() {
    const event = new CustomEvent(ONBOARDING_COMPLETED_EVENT, {});
    this.dispatchEvent(event);
  }

  setOnboardingStageView_(id) {
    const views = this.shadowRoot.querySelectorAll('.onboarding-stage');
    views.forEach(view => {
      if (view.id === id) {
        view.classList.replace('dd_hide', 'dd_show');
      } else {
        view.classList.replace('dd_show', 'dd_hide');
      }
    });

    const navs = this.shadowRoot.querySelectorAll('.nav-container');
    navs.forEach(nav => {
      if (nav.id === `${id}-nav`) {
        nav.classList.replace('dd_hide', 'dd_show');
      } else {
        nav.classList.replace('dd_show', 'dd_hide');
      }
    });

    this.showHideProgressBar_(id);
    this.requestUpdate();
    window.scrollTo(0, 0);
  }

  showHideProgressBar_(id) {
    const progressContainer = this.shadowRoot.querySelector('#progress-container');
    if (id === INTRO_VIEW || id === SENDOFF_VIEW) {
      progressContainer.classList.replace('dd_show', 'dd_hide');
    } else {
      progressContainer.classList.replace('dd_hide', 'dd_show');
    }
  }

  setNextButtonVisibility_(view, condition) {
    const nextButton = this.shadowRoot.querySelector(`#${view}-next`);
    if (condition) {
      nextButton.classList.replace('dd-invisible', 'dd-visible');
    } else {
      nextButton.classList.replace('dd-visible', 'dd-invisible');
    }
  }

  updateInternalDietPlanOptions_(view) {
    let updaterSelector = null;

    switch (view) {
      case FOOD_PREFS_VIEW:
        updaterSelector = 'dd-dietary-needs';
        break;
      case STORE_PLAN_VIEW:
        updaterSelector = 'dd-food-limits';
        break;
      default:
        break;
    }

    if (updaterSelector) {
      const updater = this.shadowRoot.querySelector(updaterSelector);
      this._internalDietPlanOptions = updater.dietPlanOptions;
    }
  }

  isPrefsChanged_() {
    const storePlan = this.shadowRoot.querySelector('dd-store-select');
    const prefsEqual = isEqual(this._dietPlanOptions, this._internalDietPlanOptions);
    const storeEqual = this._store === storePlan.store;
    return !prefsEqual || !storeEqual;
  }

  onPreviousClick_() {
    this._stateMachine.previous();
  }

  onNextClick_() {
    this._stateMachine.next();
  }

  onNextSubmitClick_() {
    this._stateMachine.next();
  }

  onFoodLimitTotalChanged_(event) {
    this.setNextButtonVisibility_(FOOD_PREFS_VIEW, event.detail.validTotal);
  }

  onStoreSelected_() {
    this.setNextButtonVisibility_(STORE_PLAN_VIEW, true);
  }
}

customElements.define('dd-onboarding', DdOnboarding);
