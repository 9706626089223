/**
 * provider service wrapper around side recipe options substate
 */
export const SideRecipeOptionsProvider = {
  _options: [],

  setOptions (options) {
    SideRecipeOptionsProvider._options = options;
  },

  getOptions () {
    return SideRecipeOptionsProvider._options;
  }
};
