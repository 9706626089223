/**
 * news feed data actions
 */
 import { toCamelCase } from '../utilities/object-evaluation.js';
 import { htmlDecode } from '../utilities/text-format.js';

export const SET_NEWS_FEATURED = 'SET_NEWS_FEATURED';
export const SET_NEWS_MEMBER = 'SET_NEWS_MEMBER';
export const SET_NEWS_QUOTE = 'SET_NEWS_QUOTE';
export const SET_NEWS_WEEKLY_TIP = 'SET_NEWS_WEEKLY_TIP';

function getItems(jsonFeed) {
  const camelCaseItems = toCamelCase(jsonFeed.items);
  camelCaseItems.forEach(item => {
    item.title = htmlDecode(item.title);
    item.contentText = htmlDecode(item.contentText);
  });

  return camelCaseItems;
}

export function saveFeatured(jsonFeed) {

  return {
    type: SET_NEWS_FEATURED,
    payload: { news : getItems(jsonFeed)  }
  };
}

export function saveMemberNews(jsonFeed) {
  return {
    type: SET_NEWS_MEMBER,
    payload: { news : getItems(jsonFeed)  }
  };
}

export function saveQuote(jsonFeed) {
  return {
    type: SET_NEWS_QUOTE,
    payload: { news : getItems(jsonFeed)  }
  };
}

export function saveWeeklyTip(jsonFeed) {
  return {
    type: SET_NEWS_WEEKLY_TIP,
    payload: { news : getItems(jsonFeed)  }
  };
}