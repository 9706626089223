/**
 * API actions
 * ref: https://github.com/redux-utilities/flux-standard-action
 *      /state/middleware/request-middleware.js
 */

export const START_REQUEST = 'START_REQUEST';
export const API_REQUEST = 'API_REQUEST';
export const COMPLETE_REQUEST = 'COMPLETE_REQUEST';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export function startRequest(message) {
	return {
		type: START_REQUEST,
		payload: {
			message
		}
	};
}

export function completeRequest(message) {
	return {
		type: COMPLETE_REQUEST,
		payload: {
			message
		}
	};
}

export function clearRequestMessage() {
	return {
		type: CLEAR_MESSAGE,
		payload: {}
	};
}
