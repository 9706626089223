import { html, css, LitElement } from 'lit-element';
import { sharedStyles } from '../theme/shared-styles.js';
import '@material/mwc-switch/deprecated';
import '@material/mwc-formfield';
import '@material/mwc-textfield';
import './dd-expandable-content.js';

const WATCH_CHECKED_ITEMS_CHANGED_EVENT = 'watch-checked-items-changed';

const DEFAULT_TOTAL_BUDGET = 100;
const DEFAULT_CHECKED_BUDGET = 50;

// canvas internal coordinates: width=300, height=150, external height=24
const CANVAS_X = 300;
const CANVAS_Y = 150;
const BUDGET_MARK_X_LOC = CANVAS_X/2;
const VARIANCE = 0.05;
const X_VARIANCE = BUDGET_MARK_X_LOC * VARIANCE;
const RANGE_BOX_Y = CANVAS_Y / 3;
const RANGE_BOX_HEIGHT = CANVAS_Y / 3;

// theme CSS colors
const SUCCESS_COLOR = '#28A745';
const WARNING_COLOR = '#FFC107';
const DANGER_COLOR = '#DC3545';

const TOTAL_BUDGET_LABEL = "total budget";
const WATCH_CHECKED_LABEL = "today's budget";

function 	getBoxPlotTemplate() {
	return html`
		<div id="plot-box" class="dd-flex-container" slot="title">
			<canvas id="chart"></canvas>
			<span class="material-icons-small">attach_money</span>
		</div>
	`;
}

function drawAxis(context2d) {
	context2d.setLineDash([2, 3])
	context2d.beginPath();
	context2d.lineWidth = 5;
	context2d.moveTo(0, 105);
	context2d.lineTo(CANVAS_X, 105);
	context2d.stroke();
}

function drawBudgetTarget(context2d) {
	context2d.setLineDash([])
	context2d.beginPath();
	context2d.lineWidth = 1;
	context2d.moveTo(BUDGET_MARK_X_LOC, CANVAS_Y / 2);
	context2d.lineTo(BUDGET_MARK_X_LOC, CANVAS_Y);
	context2d.stroke();
}

function drawOutOfRangeIcon(context2d) {
		// draw out of range triangle
		context2d.beginPath();
		context2d.lineTo(CANVAS_X - X_VARIANCE, RANGE_BOX_Y);
		const yMidpoint = RANGE_BOX_Y - (RANGE_BOX_HEIGHT / 2);
		context2d.lineTo(CANVAS_X, yMidpoint);
		const yBottom = RANGE_BOX_Y - RANGE_BOX_HEIGHT;
		context2d.lineTo(CANVAS_X - X_VARIANCE, yBottom);
		context2d.closePath();
		context2d.lineWidth = 0;
		context2d.fillStyle = DANGER_COLOR;
		context2d.fill();
}

/**
 * budget target and simplified range chart
 */
class DdBudgetRangeChart extends LitElement {
	static get properties() {
		return {
			isOpen: { type: Boolean },
			totalCost: { type: Number },
			watchCheckedItems: { type: Boolean }
		};
	}

	static get styles() {
		return [
			sharedStyles,
			css`
				#plot-box {
					margin-right: 8px;
				}

				#chart {
					height: 24px;
					flex-grow: 10;
				}

				#budget-text-field {
					width: 120px;
				}

				#content-container {
					padding-top: 8px;
					padding-bottom: 16px;
				}

				#switch-container {
					padding: 16px 0;
				}

				.dd-budget-justified-content {
					padding: 0 16px;
				}

				.dd-mwc-label {
					font-family: 'Roboto', sans-serif;
					font-size: 14px;
					font-weight: 400;
					letter-spacing: 0.25px;
					color: rgba(0, 0, 0, 0.87);
				}
			`
		];
	}

	get watchCheckedItems() {
		return this._watchCheckedItems;
	}

	get _budget() {
		return this._watchCheckedItems? this._checkedBudget : this._totalBudget;
	}

	set _budget(value) {
		if (this._watchCheckedItems) {
			this._checkedBudget = value;
		} else {
			this._totalBudget = value;
		}
	}

	/**
	 * LIFECYCLE
	 */
	constructor() {
		super();

		this.totalCost = 0;
		this._watchCheckedItems = false;
		this._totalBudget = DEFAULT_TOTAL_BUDGET;
		this._checkedBudget = DEFAULT_CHECKED_BUDGET;
	}

	updated() {
    super.updated();
		this.drawChart_();
	}

	render() {
		return html`
			<dd-expandable-content
				?isOpen=${this.isOpen}
				?clickableTitle=${true}
				@open="${this.onOpen_}">
				${getBoxPlotTemplate()}
				<div id="content-container" slot="content">
					<div class="dd-host-centered">
						<mwc-textfield id="budget-text-field" outlined label="${TOTAL_BUDGET_LABEL}"
							icon="attach_money"
							required
							maxLength=3
							type="number"
							min=1
							max=300
							step=1
							value=${this._budget}
							@change="${this.onChange_}">
						</mwc-textfield>
					</div>
					<div id="switch-container" class="dd-flex-end-container">
						<mwc-formfield label="count only checked shopping items"
							.alignEnd=${true}>
							<mwc-switch id="watch-checked-items-checkbox"
								?checked=${this._watchCheckedItems}
								@change="${this.onCheckedChange_}"></mwc-switch>
						</mwc-formfield>
					</div>
					<div>
						<dd-expandable-content ?clickableTitle=${true}>
							<span slot="title" class="dd-overline">Details</span>
							<div slot="content">
								<span class="dd-body1">
									Compares the estimated cost of your shopping list to your budget. Your actual costs will vary based on quantities and brands purchased. Please note: staple items are only included when you switch on
								</span>
								<span class="dd-body1 bold">"count only checked shopping items"</span>
							</div>

						</dd-expandable-content>
					</div>
				</div>
			</dd-expandable-content>
		`;
	}


	drawChart_() {
		const chart = this.shadowRoot.querySelector('#chart');
		const context = chart.getContext("2d");
		context.clearRect(0, 0, CANVAS_X, CANVAS_Y);

		drawAxis(context);
		drawBudgetTarget(context);
		this.drawRangeBox_(context);
	}

	drawRangeBox_(context2d) {
		const xPerDollar = BUDGET_MARK_X_LOC / this._budget;
		const totalCostX = this.totalCost * xPerDollar;
		let boxStartX = totalCostX - X_VARIANCE;
		const overRange = boxStartX > (CANVAS_X - X_VARIANCE);
		let hintStatus = 'roughly at';
		if (!overRange) {
			if (boxStartX < 0) {
				boxStartX = 0;
			}

			let boxEndX = totalCostX + X_VARIANCE;
			if (boxEndX > CANVAS_X) {
				boxEndX = CANVAS_X;

			}

			let rangeBoxColor = WARNING_COLOR;
			if (boxEndX < BUDGET_MARK_X_LOC) {
				rangeBoxColor = SUCCESS_COLOR;
				hintStatus = 'under';
			} else if (boxStartX > BUDGET_MARK_X_LOC) {
				rangeBoxColor = DANGER_COLOR;
				hintStatus = 'over';
			}

			const rangeBoxWidth = boxEndX - boxStartX;

			context2d.fillStyle = rangeBoxColor;
			context2d.fillRect(boxStartX, RANGE_BOX_Y, rangeBoxWidth, RANGE_BOX_HEIGHT);
		} else {
			drawOutOfRangeIcon(context2d);
			hintStatus = 'over';
		}

		const watchDescription = this.watchCheckedItems? 'checked items are' : 'shopping list is';
		const hint = `your ${watchDescription} ${hintStatus} your budget`;
		const plotBox = this.shadowRoot.querySelector('#plot-box');
		plotBox.title = hint;
	}

	onOpen_() {
		const budgetTextField = this.shadowRoot.querySelector('#budget-text-field');
		budgetTextField.focus();
	}

	onChange_() {
		const budgetTextField = this.shadowRoot.querySelector('#budget-text-field');
		if (budgetTextField.validity.valid) {
			this._budget = budgetTextField.value;
			this.drawChart_();
		}
	}

	onCheckedChange_() {
		const selector = this.shadowRoot.querySelector('#watch-checked-items-checkbox');
		this._watchCheckedItems = selector.checked || selector.selected;

    const event = new CustomEvent(WATCH_CHECKED_ITEMS_CHANGED_EVENT, {
      detail: {}
    });
    this.dispatchEvent(event);

		const budgetTextField = this.shadowRoot.querySelector('#budget-text-field');
		budgetTextField.label = this._watchCheckedItems? WATCH_CHECKED_LABEL : TOTAL_BUDGET_LABEL;
		budgetTextField.focus();
	}
}


customElements.define('dd-budget-range-chart', DdBudgetRangeChart);