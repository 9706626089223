import { html, css, LitElement } from 'lit-element';
import '@material/mwc-textfield';
import "@material/mwc-ripple";
import {RippleHandlers} from '@material/mwc-ripple/ripple-handlers';
import {
  MAX_TEXTFIELD_LENGTH,
  VALID_CHAR_REGEX,
  VALIDATION_MESSAGE
} from '../utilities/constants.js';
import debounce from '../utilities/debounce.js';
import { sharedStyles } from '../theme/shared-styles.js';

const SEARCH_TEXT_ENTRY_EVENT = 'search-text-entry';
const SELECT_SEARCH_OPTION_EVENT = 'select-search-option';

// ref: Symfony \AppBundle\Entity\Recipe
const DISH_TYPE = {
  MAIN: 1,
  SIDE: 2
}
Object.freeze(DISH_TYPE);

const MIN_LENGTH = 3;
const DEBOUNCE_MS = 400;

function validateLength(text) {
  return text.length >= MIN_LENGTH;
}

class DdSearch extends LitElement {
	static get properties() {
		return {
      searchResults: { type: Object },
      isMain: { type: Boolean }
		};
	}

	static get styles() {
		return [
			sharedStyles,
			css`
        .content-container {
          margin: 8px;

        }
        .option-container {
          margin: 8px;
          height: 40px;
          padding: 10px;
          position: relative;
          box-sizing: border-box;
        }
        .option-container div {
            font-size: 16px;
        }
        mwc-textfield {
          width: 100%;
        }
			`
		];
	}

	constructor() {
		super();
    this.searchResults = [];
    const callback = (this.fireTextEntryEvent_).bind(this);
    this._debouncedFireTextEntryEvent = debounce(callback, DEBOUNCE_MS, false);
	}

  render() {
		return html`
			<div class="content-container">
        <mwc-textfield
          outlined
          maxLength=${MAX_TEXTFIELD_LENGTH}
          pattern=${VALID_CHAR_REGEX}
          validationMessage=${VALIDATION_MESSAGE}
          placeholder="search all recipes"
          .type=${"text"}
          @input="${this.onInput_}"></mwc-textfield>
      </div>
      <div>
        ${this.getOptionTemplates_()}
      </div>
		`;
  }

  updated() {
    super.updated();
    const ripples = Array.from(this.shadowRoot.querySelectorAll('mwc-ripple'));
		ripples.forEach((ripple) => {
			if (ripple.dataset.eventListenersPlaced === "true") return;
			ripple.dataset.eventListenersPlaced = "true";
			const rh = new RippleHandlers(async () => ripple);
			const parent = ripple.parentNode;
			parent.addEventListener('mouseenter', rh.startHover);
			parent.addEventListener('mouseleave', rh.endHover);
			parent.addEventListener('mousedown', (e) => {
			  const onMouseUp = () => {
				window.removeEventListener('mouseup', onMouseUp);
				rh.endPress();
			  };

			  window.addEventListener('mouseup', onMouseUp);
			  rh.startPress(e);
			});
			parent.addEventListener('touchstart', (e) => {
			  const onTouchEnd = () => {
				window.removeEventListener('touchend', onTouchEnd);
				rh.endPress();
			  };

			  window.addEventListener('touchend', onTouchEnd);
			  rh.startPress(e);
			});
			parent.addEventListener('focus', rh.startFocus);
			parent.addEventListener('blur', rh.endFocus);
		  });
  }

  focus() {
    const input = this.shadowRoot.querySelector('mwc-textfield');
    input.value = '';
    input.focus();
  }

  /**
   *
   * @param {*} itemIdentity ref Symfony \AppBundle\Controller\Rest\DTO\ItemIdentity
   */
  getOptionTemplate_(itemIdentity) {
  return html`
    <div class="option-container">
      <div
        .idValue=${itemIdentity.id}
        @click="${this.onRecipeOptionClick_}">${itemIdentity.name}</div>
        <mwc-ripple></mwc-ripple>
    </div>
  `;
  }

  getOptionTemplates_() {
    return this.searchResults.map(searchResult => this.getOptionTemplate_(searchResult), this);
  }

  fireTextEntryEvent_(searchText) {
    // event to bubble out of parent's shadow DOM
    const dishType = this.isMain? DISH_TYPE.MAIN : DISH_TYPE.SIDE;

    const event = new CustomEvent(SEARCH_TEXT_ENTRY_EVENT, {
      bubbles: true,
      composed: true,
      detail: {
        dishType,
        searchText
      }
    });
    this.dispatchEvent(event);
  }

  onInput_() {
    const input = this.shadowRoot.querySelector('mwc-textfield');
    if (validateLength(input.value)) {
      this._debouncedFireTextEntryEvent(input.value);
    }
  }

	onRecipeOptionClick_(event) {
    const externalEvent = new CustomEvent(SELECT_SEARCH_OPTION_EVENT, {
      detail: {
        recipeId: event.target.idValue
      }
    });
    this.dispatchEvent(externalEvent);
	}
}

customElements.define('dd-search', DdSearch);