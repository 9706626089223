/**
 * shopping list data actions
 */
 import { toCamelCase } from '../utilities/object-evaluation.js';

export const SET_SHOPPING_LIST = 'SET_SHOPPING_LIST';
export const SET_SHOP_ITEM_CHECKED = 'SET_SHOP_ITEM_CHECKED';

export function saveShoppingList(dto) {
  return {
    type: SET_SHOPPING_LIST,
    payload: {
      shoppingList: toCamelCase(dto)
    }
  }
}

export function setShopItemChecked(shopItemId, isChecked) {
  return {
    type: SET_SHOP_ITEM_CHECKED,
    payload: {
      isChecked,
      shopItemId
    }
  }
}
