import { html } from 'lit-element';
import {
  DdCard,
  CARD_BODY_ID,
  CARD_SWIPE_BACKGROUND_ID,
  CONTAINER_ID,
  MAX_VIEW_ID,
  MIN_VIEW_ID,
  MINIMIZER_ID,
  BOTTOM_LEFT_CTL_ID,
  BOTTOM_RIGHT_CTL_ID,
  MAXIMIZED_TOUCH_AREA_CLASS,
  REMOVE_WIDTH,
  REPLACE_WIDTH
} from './dd-card.js';
import { sharedStyles } from '../../theme/shared-styles.js';
import style from './dd-recipe-card.scss';
import "@material/mwc-button";
import "@material/mwc-icon-button";
import "@material/mwc-icon-button-toggle";
import '../dd-expandable-content.js';
import { PubSub } from '../../services/pub-sub.js';
import { ORIENTATION_CHANGE_EVENT } from '../../services/orientation-publisher.js';
import { getWebResourceUrl } from '../../utilities/url-translation.js';
import { EVENTS } from '../events.js';

const GRADIENT_BACKGROUND = 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0))';
const FLAT_BACKGROUND = 'linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7))';
const ONE_LINE_MAX_HEIGHT = 60;
const ONE_LINE_BACKGROUND_HEIGHT = 55;

function getIngredientTemplate(ingredient) {
  return html`<li>${ingredient}</li>`;
}

function getFavoriteTitle(isFavorited) {
  return isFavorited? 'remove from Favorites' : 'add to Favorites';
}

class DdRecipeCard extends DdCard {
  static get properties() {
    return {
      recipe: { type: Object },
      location: { type: String },
      _isFavorite: { type: Boolean }
    };
  }

  static get styles() {
    return [
      sharedStyles,
      style
    ];
  }

  constructor() {
    super();
    // overrides
    this._allowPanMinimized = true;
    this._swipeLeftReveal = true;
    this._swipeLeftRevealRange = REPLACE_WIDTH + REMOVE_WIDTH;
    this._showSwipeBackgroundClass = 'dd-card-reveal-background';
    this._cardBodyMinClass = 'dd-recipe-card-minimize';
    this._cardBodySmallScreenMaxClass = 'dd-recipe-card-body-maximize';
    this._cardBodyLargeScreenMaxClass = 'dd-recipe-card-body-lg-screen-maximize';
    this._cardBodyOverlayClass = 'dd-recipe-card-overlay';
    this._cardBodyOverlayMiddleClass = 'dd-recipe-card-overlay-middle';
    this._hasControlRow = true;
  }

  firstUpdated() {
    super.firstUpdated();
    PubSub.subscribe(ORIENTATION_CHANGE_EVENT, (this.setImage_).bind(this));
  }

  updated() {
    super.updated();
    this.requestIsFavorite_();

    // on desktop, maximize on adding
    if (this.largeScreen && this._isMaxView) {
      this.setImage_();
      this.maximize_(0);
    }
  }

  render() {
    return html`
    <div id="${CONTAINER_ID}" class="${this.containerClass}">
      <div id="${CARD_BODY_ID}" class="mdc-card ${this.cardBodyViewClasses}">
        <div id="${MIN_VIEW_ID}" class="mdc-ripple-surface mdc-ripple-surface--primary dd-body1 ${this.minViewShowClass}" @click="${this.onMaximizeClick_}">
          <div class="dd-card-mini-secondary-title" title="see more">${this.getTitle_()}</div>
        </div>
        <div id="${MAX_VIEW_ID}" class="${MAXIMIZED_TOUCH_AREA_CLASS} ${this.maxViewClass} ${this.maxViewShowClass}">
          ${this.getMediaTemplate_()}
          ${this.getDesktopControlRowTemplate_()}
          <div class="dd-flex-container">
            <div id="${BOTTOM_LEFT_CTL_ID}">
              ${this.getLeftSwipeAffordance_()}
              <div class="dd-button-placeholder"></div>
              <mwc-icon-button icon="create" title="pick a different recipe" @click="${this.onChangeClick_}"></mwc-icon-button>
            </div>
            <div>
              <dd-expandable-content
                ?clickableTitle="${true}"
                ?isOpen="${true}">
                <span slot="title" class="bold_title">${'ingredients'}</span>
                <ul class="dd-card-list" slot="content">
                  ${this.getIngredientTemplates_()}
                </ul>
              </dd-expandable-content>
              <dd-expandable-content
                ?clickableTitle="${true}"
                ?isOpen="${true}">
                <span slot="title" class="bold_title">${'instructions'}</span>
                <span slot="content">${this.getInstructions_()}</span>
              </dd-expandable-content>
            </div>
            <div id="${BOTTOM_RIGHT_CTL_ID}">
              ${this.getRightSwipeAffordance_()}
              <div class="dd-button-placeholder"></div>
              <mwc-icon-button icon="delete_outline" @click="${this.onDeleteClick_}"></mwc-icon-button>
            </div>
          </div>
          <div class="${MAXIMIZED_TOUCH_AREA_CLASS} dd-max-card-spacer"></div>
        </div>
      </div>
      <div id="${CARD_SWIPE_BACKGROUND_ID}" class="dd-card-swipe-reveal-background dd_hide">
        <div class="dd-card-replace-button ">
          <mwc-icon-button icon="create" @click="${this.onSwipeChangeClick_}"></mwc-icon-button>
        </div>
        <div class="dd-card-remove-button ">
          <mwc-icon-button icon="delete_outline" @click="${this.onSwipeDeleteClick_}"></mwc-icon-button>
        </div>
      </div>
    </div>
		`;
  }

  getTitle_() {
    const title = this.recipe && this.recipe.name ? this.recipe.name : 'No recipe name';

    return title;
  }

  getInstructions_() {
    const instructions = this.recipe && this.recipe.instructions ? this.recipe.instructions : '';

    return instructions;
  }

  getFavoriteTemplate_() {
    return html`
      <mwc-icon-button-toggle
        id="favorite-toggle"
        onIcon="favorite"
        offIcon="favorite_border"
        title="${getFavoriteTitle(this._isFavorite)}"
        ?on=${this._isFavorite}
        @click="${this.onFavoriteClicked_}"></mwc-icon-button-toggle>
    `;
  }

  getMediaTemplate_() {
    return html`
      <div class="dd-card-media dd-card-border">
        <div class="dd-flex-container">
          ${!this.showControlRow
            ? this.getFavoriteTemplate_()
            : ''
          }
          <div class="dd-card-title">${this.getTitle_()}</div>
          <mwc-icon-button id="${MINIMIZER_ID}" icon="close" @click="${this.onMinimizeClick_}"></mwc-icon-button>
        </div>
      </div>
    `;
  }

  getControlRowTemplate_() {
    return html`
      <div class="dd-dense-content-item dd-flex-container">
        ${this.getFavoriteTemplate_()}
        <div class="dd-flex-end-container">
          <div class="dd-card-control-row-raised">
            <mwc-button raised title="pick a different recipe" @click="${this.onChangeClick_}">swap</mwc-button>
          </div>
          <mwc-icon-button icon="delete_outline" title="remove this recipe" @click="${this.onDeleteClick_}"></mwc-icon-button>
        </div>
      </div>
    `;
  }

  getIngredientTemplates_() {
    if (this.recipe && this.recipe.ingredients) {
      return this.recipe.ingredients.map(getIngredientTemplate);
    }

    return '';
  }

  setImage_() {
    const mediaBox = this.shadowRoot.querySelector('.dd-card-media');

    if (mediaBox) {
      const baseImageUrl = this.recipe.largeImageUrl;
      if (baseImageUrl) {
        const overlay = this.largeScreen? GRADIENT_BACKGROUND : FLAT_BACKGROUND;
        const gradientAugmentation = this.largeScreen? 25 : 0;
        const imageUrl = getWebResourceUrl(baseImageUrl);
        const overlayHeight = this.getTitleBackgroundHeight_() + gradientAugmentation;
        mediaBox.style.backgroundImage = `${overlay}, url(${imageUrl})`;
        mediaBox.style.backgroundRepeat = 'no-repeat';
        mediaBox.style.backgroundPosition = 'center top, center center';
        mediaBox.style.backgroundSize = `100% ${overlayHeight}px, cover`;
      } else {
        mediaBox.style.backgroundImage = '';
        mediaBox.style.backgroundRepeat = '';
        mediaBox.style.backgroundPosition = '';
        mediaBox.style.backgroundSize = '';
      }
    }
  }

  getTitleBackgroundHeight_() {
    const titleHeight = this.shadowRoot.querySelector('.dd-card-title').clientHeight;

    if (titleHeight < ONE_LINE_MAX_HEIGHT) {
      return ONE_LINE_BACKGROUND_HEIGHT;
    }

    return titleHeight;
  }

  requestIsFavorite_() {
    this.fireFavoriteEvent_(EVENTS.REQUEST_FAVORITED);
  }

  fireFavoriteEvent_(eventName) {
    this.fireGlobalEvent_(eventName, this.getFavoritedDTO_());
  }

  fireRecipeChangeEvent_(eventName) {
    this.fireLocalEvent_(eventName, this.location);
  }

  getFavoritedDTO_() {
    const callback = (this.setFavorited_).bind(this);
    const favoriteToggle = this.shadowRoot.querySelector('#favorite-toggle');
    const isFavorited = favoriteToggle.on;
    favoriteToggle.setAttribute('title', getFavoriteTitle(isFavorited));

    return {
      callback,
      isFavorited,
      recipeId: this.recipe.id,
      dishType: this.recipe.dishType
    };
  }

  setFavorited_(isFavorite) {
    this._isFavorite = isFavorite;
  }

  maximize_(offsetNumber) {
    super.maximize_(offsetNumber);
    this.setImage_();
  }

  onChangeClick_() {
    this.fireRecipeChangeEvent_(EVENTS.REPLACE);
    if (!this.largeScreen) {
      this.onMinimizeClick_();
    }
  }

  onDeleteClick_() {
    this.fireRecipeChangeEvent_(EVENTS.REMOVE);
    this.onMinimizeClick_();
  }

  onFavoriteClicked_() {
    this.fireFavoriteEvent_(EVENTS.FAVORITE_ACTION);
  }

  onSwipeChangeClick_() {
    this.fireRecipeChangeEvent_(EVENTS.REPLACE);
  }

  onSwipeDeleteClick_() {
    this.fireRecipeChangeEvent_(EVENTS.REMOVE);
  }

  // BEGIN recipe card carousel methods
  onPan(event) {
    super.onPan(event);

    if (this.recipe) {
      this.handlePan(event);

      if (this.hasFocus) {
        this.firePanEvent_();
      }
    }
  }
  // END recipe card carousel methods
}

customElements.define('dd-recipe-card', DdRecipeCard);