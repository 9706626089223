import { html } from 'lit-element';
import { ListItem } from '@material/mwc-list/mwc-list-item';
import './dd-expandable-content.js';
import { sharedStyles } from '../theme/shared-styles.js';

const COLLAPSE_MENU_EVENT = 'collapse-menu';
const EXPAND_MENU_EVENT = 'expand-menu';

class DdExpanderListItem extends ListItem {
  	static get properties() {
		return {
			name: { type: String }
		};
	}

	static get styles() {
    const baseStyles = ListItem.styles;
		return [
      sharedStyles,
      baseStyles
		];
  }

  render() {
		return html`
      <dd-expandable-content
        ?clickableTitle="${true}"
        @pre-open="${this.onPreOpen_}"
        @pre-close="${this.onPreClose_}">
        <span slot="title">${this.name}</span>
      </dd-expandable-content>
    `;
  }

  onPreOpen_() {
      const event = new CustomEvent(EXPAND_MENU_EVENT, {});
    this.dispatchEvent(event);
  }

  onPreClose_() {
    const event = new CustomEvent(COLLAPSE_MENU_EVENT, {});
    this.dispatchEvent(event);
  }
}

customElements.define('dd-expander-list-item', DdExpanderListItem);
