import {
	CATEGORY_MAP,
	PROTEIN_MAP
} from './constants.js';

export function getCategoryKey(categoryName) {
	const categoryKeys = Object.keys(CATEGORY_MAP);

	for (let i = 0; i < categoryKeys.length; i += 1) {
		const category = categoryKeys[i];
		if (CATEGORY_MAP[category].name && (CATEGORY_MAP[category].name.toLowerCase() === categoryName.toLowerCase())) {
			return parseInt(category, 10);
		}
	}

	return null;
}

export function getProteinMap(proteinCategory) {
	const proteinKeys = Object.keys(PROTEIN_MAP);

	for (let i = 0; i < proteinKeys.length; i += 1) {
		const protein = proteinKeys[i];
		if (PROTEIN_MAP[protein].category === proteinCategory) {
			return PROTEIN_MAP[protein];
		}
	}

	return null;
}
