/**
 * component event definitions
 */
export const EVENTS = {
  MAXIMIZE: 'maximize-card',
  MINIMIZE: 'minimize-card',

  // recipe
  ADD_RECIPE: 'day-menu-add-recipe',
  REPLACE_RECIPE: 'day-menu-replace-recipe',
  DELETE_RECIPE: 'day-menu-delete-recipe',
  DELETE_RECIPES: 'day-menu-delete-recipes',
  CLEAR_SEARCH: 'day-menu-clear-search',
  CLOSE_DIALOG: 'close-replace-dialog',
  REPLACE: 'replace',
  REMOVE: 'remove',

  REQUEST_FAVORITED: 'recipe-request-favorited',
  FAVORITE_ACTION: 'recipe-favorite-action',

  // preferences
  MENU_LIMITS_CLICK: 'menu-limits-click',
  DIETARY_RESTRICTIONS_CLICK: 'dietary-restrictions-click',

  // menu
  RESTORE_MENU: 'restore-menu',
  CHANGE_WEEK_MENU: 'change-week-menu',
  CHANGE_STORE: 'change-store',
  CHANGE_FAMILY_SIZE: 'change-family-size',
  PRINT_MENU: 'print-menu',
  ADD_CALENDAR: 'add-calendar',

  // shopping category
  PRINT_SL: 'print-sl',
  ADD_SHOP_ITEM_TO_CATEGORY: 'add-shop-item-to-category',
  UPDATE_SHOP_ITEM_FROM_CATEGORY: 'update-shop-item-from-category',

  // shop item
  UPDATE: 'update',
  CHECKED_SHOP_ITEM: 'checked-shop-item',
  REMOVE_SHOP_ITEM: 'remove-shop-item',

  // editable recipe
  CREATE_PERSONAL_RECIPE: 'create',
  CHANGED_PERSONAL_RECIPE: 'changed-recipe',
  DELETE_PERSONAL_RECIPE: 'delete-recipe',
  NEW_RECIPE_INGREDIENT: 'new-recipe-ingredient',
  CHANGED_RECIPE_INGREDIENT: 'changed-recipe-ingredient',
  REMOVE_EMPTY_RECIPE_INGREDIENT: 'remove-empty-recipe-ingredient',
  REMOVE_RECIPE_INGREDIENT: 'remove-recipe-ingredient'
}
Object.freeze(EVENTS);
