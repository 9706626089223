/**
 * API actions
 * ref: https://github.com/redux-utilities/flux-standard-action
 *      /state/middleware/request-middleware.js
 */
import { axios, getApiUrl, startRequest, completeRequest } from './api-base.js';
import { MENU_VIEW, navigate } from '../navigation.js';
import { setToken } from '../token.js';

const LOGIN_REQUEST = 'LOGIN_REQUEST';

// REST resources
const LOGIN_RESOURCE = 'login';

/**
 * returns action to monitor with requestMiddleware: { payload: async function }
 */
/* eslint no-unused-vars: "off" */
export function login(email, password, token) {
	return {
		type: LOGIN_REQUEST,
		payload (dispatch) {
			dispatch(startRequest());

			const loginUrl = getApiUrl(LOGIN_RESOURCE);

			return axios.post(loginUrl, {
				username: email,
				password,
				token
			})
				.then(response => {
					dispatch([
						setToken(response.data.token, response.data.refresh_token),
						completeRequest(),
						navigate(MENU_VIEW)
					]);
				})
				.catch(error => {
					dispatch(completeRequest('Oops, wrong email or password!'));
				});
		}
	}
}