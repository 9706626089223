import { AppFlagProvider } from '../services/app-flag-provider.js';
import { APP_FLAGS, TOUCH_STATUS } from './constants.js';
import { isLargeScreen } from './screen-size.js';

/**
 * overrideable touch status
 */
export function isTouchEnabled() {
  const touchStatus = AppFlagProvider.getFlag(APP_FLAGS.TOUCH_STATUS_OVERRIDE);

  switch (touchStatus) {
    case (TOUCH_STATUS.ENABLED):
      return true;

    case (TOUCH_STATUS.NONE):
      return ('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0);

    case (TOUCH_STATUS.DISABLED):
    default:
      return false;
  }
}

/**
 * returns true for touch enabled in mobile view
 */
export function isMobileTouch() {
  return !isLargeScreen() && isTouchEnabled();
}
