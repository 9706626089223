/**
 * provider service wrapper around favorite recipe options substate
 */
import { PubSub } from './pub-sub.js';
import { ACCOUNT_STATE, FULFILLMENT_ACCOUNT_STATE } from '../utilities/constants.js';

export const OAUTH_CONNECTED_EVENT = 'oauth-connected';

export const FulfillmentAccountProvider = {
  _accountStatus: {
    peapod: FULFILLMENT_ACCOUNT_STATE.NOT_AVAILABLE,
    google: ACCOUNT_STATE.UNINITIALIZED,
    kroger: FULFILLMENT_ACCOUNT_STATE.NOT_AVAILABLE,
  },

  setStatus(accountStatus) {
    const peapodConnectChange = (this._accountStatus.peapod === FULFILLMENT_ACCOUNT_STATE.NOT_CONNECTED) &&
      (accountStatus.peapod === FULFILLMENT_ACCOUNT_STATE.IN_SUPPORTED_ZONE);
    this._accountStatus.peapod = accountStatus.peapod;

    const googleConnectChange = (this._accountStatus.google === ACCOUNT_STATE.UNAUTHORIZED) && (accountStatus.google === ACCOUNT_STATE.AUTHORIZED);
    this._accountStatus.google = accountStatus.google;

    const krogerConnectChange = (this._accountStatus.kroger === FULFILLMENT_ACCOUNT_STATE.NOT_CONNECTED) &&
      (accountStatus.kroger === FULFILLMENT_ACCOUNT_STATE.IN_SUPPORTED_ZONE);
    this._accountStatus.kroger = accountStatus.kroger;

    if (peapodConnectChange || googleConnectChange || krogerConnectChange) {
      PubSub.publish(OAUTH_CONNECTED_EVENT);
    }
  },

    /**
   * @return int FULFILLMENT_ACCOUNT_STATE
   */
  getKroger() {
    return this._accountStatus.kroger;
  },

  /**
   * @return int FULFILLMENT_ACCOUNT_STATE
   */
  getPeapod() {
    return this._accountStatus.peapod;
  },

  /**
   * @return bool
   */
  isGoogleAuthorized() {
    return (this._accountStatus.google === ACCOUNT_STATE.AUTHORIZED);
  },

  /**
   * @return bool
   */
  isPeapodAuthorized() {
    return (this._accountStatus.peapod === FULFILLMENT_ACCOUNT_STATE.IN_SUPPORTED_ZONE);
  },

    /**
   * @return bool
   */
  isKrogerAuthorized() {
    return (this._accountStatus.kroger === FULFILLMENT_ACCOUNT_STATE.IN_SUPPORTED_ZONE);
  },

  /**
   * @return bool
   */
  isPeapodEligible() {
    return (this._accountStatus.peapod === FULFILLMENT_ACCOUNT_STATE.NOT_CONNECTED) && !this.isKrogerAuthorized();
  },

  /**
   * @return bool
   */
  isKrogerEligible()
  {
    return (this._accountStatus.kroger === FULFILLMENT_ACCOUNT_STATE.NOT_CONNECTED) && !this.isPeapodAuthorized();
  }
};
