/**
 * app host componentar
 * - handle events requesting API calls
 * - invokes API call actions
 * - propagates state changes down to child components
 */

// Material Design and third party libraries
import { html, css } from 'lit-element';
import '@material/mwc-top-app-bar-fixed';
import '@material/mwc-checkbox';
import '@material/mwc-dialog';
import '@material/mwc-button';
import '@material/mwc-drawer';
import '@material/mwc-formfield';
import '@material/mwc-icon';
import '@material/mwc-icon-button';
import '@material/mwc-icon-button-toggle';
import '@material/mwc-menu';
import '@material/mwc-list';
import '@material/mwc-list/mwc-list-item';
import '@material/mwc-radio';
import '@material/mwc-select';
import '@material/mwc-switch/deprecated';
import { connect } from 'pwa-helpers/connect-mixin.js';
import createAuth0Client from '@auth0/auth0-spa-js';
import axios from 'axios';
import jstz from 'jstz';
import isEqual from 'lodash-es/isEqual.js';

import { getIconTemplate } from './template-helpers.js';
import '@material/mwc-snackbar'
// TDD
import { ScreenAwareElement } from './screen-aware-element.js';
// This element is connected to the Redux store.
import { store } from '../state/store.js';
import { sharedStyles } from '../theme/shared-styles.js';
import style from './dd-app.scss';
import './dd-api-progress.js';
import './dd-api-snackbar.js';
import './dd-expandable-content.js';
import { ICON_TRAY, ICON_TRAY_HEIGHT } from './v2/dd-icon-tray.js';
import './dd-iframe.js';
import { SORT_SWITCH_ID } from './dd-item-selector.js';
import './dd-login.js';
import './v2/dd-home.js';
import { ALL_DAYS_ID } from './v2/dd-menu-2.js';
import './v2/dd-dietary-needs.js';
import './v2/dd-food-limits.js';
import './v2/dd-shopping-list-2.js';
import './v2/dd-personal-recipes-2.js';
import './v2/onboarding/dd-onboarding.js';
import './dd-expander-list-item';
import './dd-select-option.js';
import './v2/dd-subscription_portal.js';
import './dd-week-sl-print.js';
import './dd-week-menu-print.js';
import { FrozenIngredients } from '../resources/frozen-ingredients.js';
import { ProduceIngredients } from '../resources/produce-ingredients.js';
import { StateNames } from '../resources/state-names.js';
import { StatesStores } from '../resources/states-stores.js';
import { Stores } from '../resources/stores.js';
import { TestStores } from '../resources/test-stores.js';
import {
  AppFeatureLevelProvider,
  FEATURE_LEVEL,
  LEVEL_CHANGE_EVENT,
} from '../services/app-feature-level-provider.js';
import { AppFlagProvider } from '../services/app-flag-provider.js';
import { OrientationPublisher, NOT_MAXIMIZED_EVENT } from '../services/orientation-publisher.js';
import { DayMenuColorProvider } from '../services/day-menu-color-provider.js';
import { PubSub } from '../services/pub-sub.js';
import { FavoritesProvider } from '../services/favorites-provider.js';
import { FeatureProvider } from '../services/feature-provider.js';
import {
  FulfillmentAccountProvider,
  OAUTH_CONNECTED_EVENT,
} from '../services/fulfillment-account-provider.js';
import { IngredientsProvider } from '../services/ingredients-provider.js';
import { Instrumentation } from '../services/instrumentation-service.js';
import { KrogerProductsProvider } from '../services/kroger-products-provider.js';
import { NotificationService } from '../services/notification-service.js';
import { PersonalRecipesProvider } from '../services/personal-recipes-provider.js';
import { RecipeShopItemMapProvider } from '../services/recipe-shop-item-map-provider.js';
import { SideRecipeOptionsProvider } from '../services/side-recipe-options-provider.js';
import { FAB_Y_OFFSET } from '../utilities/fab-integration.js';
import {
  APP_FLAGS,
  ANALYTICS,
  ASYNC_PAUSE,
  DIETARY_RESTRICTION,
  DISH_TYPE_MAIN,
  FAMILY_SIZE,
  FEATURES,
  FULFILLMENT_ACCOUNT_STATE,
  ID_PROVIDER,
  INVALID_TOKEN_EVENT,
  MOBILE_PRINT,
  OAUTH_SERVICE,
  ONBOARDING,
  LOCATIONS_SET_EVENT,
  REQUEST_CANCELLED_EVENT,
  SL_PRINT,
  SUBSCRIPTION_STATUS,
} from '../utilities/constants.js';
import { isFavoriteRecipe } from '../utilities/favorite-status.js';
import { isPrivateMode } from '../utilities/is-private-mode.js';
import { hasStorage } from '../utilities/local-storage.js';
import { TEMPORARY_ID } from '../utilities/new-item-id.js';
import { isEmpty } from '../utilities/object-evaluation.js';
import { createSelectOptions } from '../utilities/select-options.js';
import { getProduceCount, getSaleCount } from '../utilities/shopping-list.js';
import { getRecipeIds } from '../utilities/state-extraction.js';
import { toDateRange } from '../utilities/text-format.js';
import { isMobileTouch } from '../utilities/touch-status.js';
import {
  getAuthUrl,
  getTimestampedUrl,
  getWebUrl,
  getWebResourceUrl,
} from '../utilities/url-translation.js';

// API actions
import { clearRequestMessage } from '../actions/api/api.js';
import { requestToken } from '../actions/api/auth-api.js';
import {
  requestDietPlanOptions,
  requestUpdateDietPlanOptions,
} from '../actions/api/diet-plan-options-api.js';
import { requestChangeFamilySize } from '../actions/api/family-size-api.js';
import { requestFeatures } from '../actions/api/features-api.js';
import {
  requestFulfillmentAccounts,
  requestFulfillmentKrogerLocations,
  requestFulfillmentKrogerProduct,
  requestFulfillmentKrogerProducts,
  requestKrogerFulfillment,
  requestPeapodFulfillment,
} from '../actions/api/fulfillment-api.js';
import { login } from '../actions/api/login-api.js';
import { requestCreateMenu, requestMenu, requestRestoreMenu } from '../actions/api/menu-api.js';
import {
  requestAddRecipe,
  requestRemoveRecipe,
  requestRemoveRecipes,
  requestReplaceRecipe,
} from '../actions/api/day-menus-api.js';
import { requestCreateEvents } from '../actions/api/events-api.js';
import { requestIngredients } from '../actions/api/ingredients-api.js';
import {
  requestFeatured,
  requestMemberNews,
  requestQuote,
  requestWeeklyTip,
} from '../actions/api/news-api.js';
import { requestUpdateRecipeIngredient } from '../actions/api/recipe-ingredients-api.js';
import {
  requestFavorites,
  requestAddFavorite,
  requestAddPersonalRecipeIngredient,
  requestRemoveRecipeIngredient,
  requestCreatePersonalRecipe,
  requestDeletePersonalRecipe,
  requestPersonalRecipes,
  requestUpdatePersonalRecipe,
  requestRemoveFavorite,
  requestSearch,
} from '../actions/api/recipes-api.js';
import { requestShoppingList } from '../actions/api/shopping-list-api.js';
import {
  requestAddShopItem,
  requestUpdateShopItem,
  requestCheckShopItem,
  requestUncheckShopItem,
  requestRemoveShopItem,
} from '../actions/api/shopping-list-items-api.js';
import { requestChangeStore } from '../actions/api/store-api.js';
import {
  openSubscriptionCheckout,
  requestSubscriptionPortal,
  requestUpdateSubscription,
} from '../actions/api/subscription-api.js';

// other actions
import { setFlag } from '../actions/app-flags.js';
import {
  HOME_VIEW,
  LOGIN_VIEW,
  MENU_VIEW,
  RECIPES_VIEW,
  SL_PRINT_VIEW,
  SL_VIEW,
  SUBSCRIPTION_VIEW,
  WEB_VIEW,
  WEEK_PRINT_VIEW,
  ONBOARDING_VIEW,
  navigate,
} from '../actions/navigation.js';
import { setFavorite } from '../actions/favorites.js';
import {
  addRecipeIngredient,
  createPersonalRecipe,
  removeEmptyRecipe,
  removeEmptyRecipeIngredient,
} from '../actions/personal-recipes.js';
import {
  activateRecipeShopItems,
  deactivateRecipeShopItems,
} from '../actions/recipe-shop-items.js';
import { saveSearch } from '../actions/search.js';
import { setShopItemChecked } from '../actions/shopping-list.js';
import { saveSubscription } from '../actions/subscription.js';
import { saveUISettings } from '../actions/ui-settings.js';
import { TOKEN_SET_EVENT } from '../reducers/token.js';
import { requestUpdateEmail } from '../actions/api/profile-api.js';

const OAUTH_SERVICE_NAME = {
  [OAUTH_SERVICE.NONE]: '',
  [OAUTH_SERVICE.PEAPOD]: 'peapod',
  [OAUTH_SERVICE.GOOGLE]: 'google',
  [OAUTH_SERVICE.KROGER]: 'kroger',
};
Object.freeze(OAUTH_SERVICE_NAME);

const OAUTH_DIALOG_TEXT = {
  [OAUTH_SERVICE.NONE]: {
    fullName: '',
    connectedHint: '',
  },
  [OAUTH_SERVICE.PEAPOD]: {
    fullName: 'Stop & Shop deliveries',
    connectedHint: 'Now just check items and order!',
  },
  [OAUTH_SERVICE.GOOGLE]: {
    fullName: 'Google Calendar',
    connectedHint: 'Now you can add to your calendar!',
  },
  [OAUTH_SERVICE.KROGER]: {
    fullName: 'Kroger curbside pickups and deliveries',
    connectedHint: 'Now just check items and order!',
  },
};
Object.freeze(OAUTH_DIALOG_TEXT);

const ACTION_DIALOG = {
  NONE: 0,
  STORE_CHANGE: 1,
  DIET_PLAN_OPTIONS: 2,
  DIETARY_RESTRICTIONS: 3,
  NEW_MENU_NOTIFICATION: 4,
  RELOAD: 5,
  KROGER_CART: 6,
  DELETE_PERSONAL_RECIPE: 7,
  GOOGLE_CONNECT: 8,
  FAMILY_PLAN: 9,
  CHANGE_EMAIL: 10,
  CHANGE_PASSWORD: 11
};

const ACTION_DIALOG_HEADING = {
  [ACTION_DIALOG.NONE]: '',
  [ACTION_DIALOG.STORE_CHANGE]: 'Change Store?',
  [ACTION_DIALOG.DIET_PLAN_OPTIONS]: 'Change Food Preferences?',
  [ACTION_DIALOG.DIETARY_RESTRICTIONS]: 'Update Dietary Needs?',
  [ACTION_DIALOG.NEW_MENU_NOTIFICATION]: 'Enable Notifications',
  [ACTION_DIALOG.RELOAD]: 'New App Version Available',
  [ACTION_DIALOG.KROGER_CART]: 'Add Items to Your Cart?',
  [ACTION_DIALOG.DELETE_PERSONAL_RECIPE]: 'Delete Your Recipe?',
  [ACTION_DIALOG.GOOGLE_CONNECT]: 'Connect to Google Calendar?',
  [ACTION_DIALOG.FAMILY_PLAN]: 'Change Your Plan Size',
  [ACTION_DIALOG.CHANGE_EMAIL]: 'Change Email?',
  [ACTION_DIALOG.CHANGE_PASSWORD]: 'Change Password?'
};
Object.freeze(ACTION_DIALOG_HEADING);

const WORDPRESS_PATH = {
  accountSettings: 'account-settings',
  bonusContent: 'member-bonus-pages',
  faq: 'faqs',
  makeAhead: 'bonus-materials-make-ahead',
  privacyPolicy: 'privacy-policy',
  termsOfUse: 'terms-and-conditions',
};
Object.freeze(WORDPRESS_PATH);

const AUTH_CONFIG_RESOURCE = 'config';
const QUERY_IDENTIFIER = 'client=pwa';
const MONITOR_REQUEST_INTERVAL = 2500;
const MONITOR_REQUEST_INTERVAL_DEV = 5000;
const API_SEQUENCE_PAUSE_DEV = 3000;
const API_SEQUENCE_PAUSE_PROD = 500;
const MENU_SYNC_INTERVAL = 30 * 60 * 1000; // 30 minutes
const SUBSCRIPTION_STATUS_INTERVAL = 6 * 60 * 60 * 1000; // 6 hours, half of backend sync interval

// exponential menu request wait sequence with ceiling: 1, 2, 4, 8, 16, 60, 60,... minutes
const MENU_GENERATION_WAIT_PERIOD = 60 * 1000; // 1 minute
const MAX_WAIT_PERIOD = 60 * 60 * 1000; // 1 hour
const MAX_WAIT_EXPONENT = 5;

const SPACER_HEIGHT = FAB_Y_OFFSET + ICON_TRAY_HEIGHT + 50;

const LOCATION_BLOCKED_HEADING = "You've Blocked Location Access";
const RELOAD_CONTENT =
  "We've made improvements and installed a new version. Don't worry, your current menu won't change. Switch to it now?";
const CHANGE_STORE_LOCATION_BLOCKED_CONTENT =
  "We can't tell what state you're in. See the Kroger section in the FAQs for help with location access.";
const FULFILLMENT_LOCATION_BLOCKED_CONTENT =
  'We need to find a store to handle your order. See the Kroger section in the FAQs for help with location access and then switch to your menu to order with Kroger.';
const LOCATION_NOT_SUPPORTED_HEADING = 'Location is Unavailable.';
const LOCATION_NOT_SUPPORTED_CONTENT =
  "This browser doesn't support geolocation so we're unable to find a store to handle your order.";
const NO_LOCATIONS_HEADING = 'No Stores Nearby';
const NO_LOCATIONS_CONTENT = 'There are no stores near you to handle your order.';
const INVALID_LIMIT_HINT =
  'Not quite enough choices to create a varied menu. Please increase the frequency or add more selections';
const MENU_GENERATION_WARNING = 'Changing preferences will create a new menu';
const NEW_MENU_HINT = "Here's your menu";
const BASICS_UNAVAILABLE_HINT =
  'Stick To The Basics is available if your vegetarian limit is 2 or fewer';
const HH_INCOMPATIBLE_HINT = "This isn't available with Heart Healthy menus";
const HH_CHECKED_HINT = 'Selecting Heart Healthy will disable some dietary needs';
const NOT_MAXIMIZED_HEADING = "Your Browser isn't Maximized";
const NOT_MAXIMIZED_CONTENT =
  'This app works best on a full screen. For a better experience, please maximize and refresh your browser.';
const INCOGNITO_HEADING = "We Can't Save Your Information";
const INCOGNITO_CONTENT =
  'Looks like your browser is set to Incognito/Private Mode, or you have blocked cookies. We won’t be able to save your preferences so please adjust your settings and refresh your browser so we can give you the best experience!';
const MENU_PRINT_NA_HEADING = 'Print Currently Unavailable';
const MENU_PRINT_NA_CONTENT =
  'Please print your menu from your computer. We apologize for the inconvenience.';
const SL_PRINT_NA_HEADING = 'Printing This Is Coming Soon';
const SL_PRINT_NA_CONTENT =
  'Printing your shopping list from your mobile device is coming soon! In the meantime, you can print it from your computer.';

/**
 * user management
 */
const userManagement = {
  config: null,
  auth0: null,
  chargebeeInstance: null,
  isAuth0User: false,
  auth0UserEmail: null,
  statusRequestsStarted: false,
};

async function showLogin() {
  try {
    const state = store.getState();
    const redirectLoginOptions = state.router.queries.signup ? { screen_hint: 'signup' } : {};
    await userManagement.auth0.loginWithRedirect(redirectLoginOptions); // ref auth0-spa-js:RedirectLoginOptions
  } catch (err) {
    console.log('showLogin: auth0 error: ', err);
  }
}

// refresh Chargebee portal token (1 hr lifetime) and subscription status
function startChargebeeRefresh(setPortalSession) {
  userManagement.statusRequestsStarted = true;
  setInterval(() => {
    store.dispatch(requestSubscriptionPortal(setPortalSession));
  }, MENU_SYNC_INTERVAL);
  setInterval(() => {
    store.dispatch(requestUpdateSubscription());
  }, SUBSCRIPTION_STATUS_INTERVAL);
}

function startSubscriptionCheckout(planId) {
  const openCheckout = userManagement.chargebeeInstance.openCheckout.bind(
    userManagement.chargebeeInstance,
  );
  store.dispatch(openSubscriptionCheckout(openCheckout, planId));
}

async function startUserManagementClients() {
  if (typeof window.Chargebee !== 'undefined') {
    userManagement.chargebeeInstance = window.Chargebee.init({
      site: userManagement.config.subscription_site,
      iframeOnly: true,
    });
  }

  userManagement.auth0 = await createAuth0Client({
    domain: userManagement.config.auth_domain,
    client_id: userManagement.config.auth_client_id,
    redirect_uri: window.location.origin,
    cacheLocation: 'localstorage', // valid values are: 'memory' or 'localstorage'
  });

  const isAuthenticated = await userManagement.auth0.isAuthenticated();
  if (isAuthenticated) {
    const user = await userManagement.auth0.getUser();
    userManagement.isAuth0User = user.sub.startsWith('auth0|');
    if (userManagement.isAuth0User) {
      userManagement.auth0UserEmail = user.email;
    }

    if (AppFlagProvider.isDebug()) {
      console.log(user);
    }

    const first = user.given_name ?? '';
    const last = user.family_name ?? '';

    const setPortalSession = userManagement.chargebeeInstance.setPortalSession.bind(
      userManagement.chargebeeInstance,
    );
    const action = requestToken(
      user.email,
      first,
      last,
      user.name,
      user.sub,
      'test_client',
      'test_password',
      setPortalSession,
    );
    store.dispatch(action);

    startChargebeeRefresh(setPortalSession);
  } else {
    store.dispatch(saveSubscription({ status: SUBSCRIPTION_STATUS.NONE }));

    // check for the code and state parameters
    const query = window.location.search;
    if (query.includes('code=') && query.includes('state=')) {
      await userManagement.auth0.handleRedirectCallback();

      // remove the querystring parameters, reload (social login issue workaround)
      window.history.replaceState({}, document.title, '/');
      window.top.location.reload();

      if (AppFlagProvider.isDebug()) {
        console.log('reloading on login');
      }
    } else {
      showLogin();
    }
  }

  return true;
}

async function initializeUserManagement() {
  // ref: AppBundle\Security\OauthClientAuthenticator::CLIENT_ID_KEY
  const authConfigUrl = getAuthUrl(AUTH_CONFIG_RESOURCE);
  const fetchAuthConfig = () =>
    axios.post(authConfigUrl, {
      client_id: 'test_client',
      client_secret: 'test_password',
    });
  const response = await fetchAuthConfig();
  userManagement.config = response.data;

  await startUserManagementClients();
}

function isActiveRetailSubscription() {
  const subscription = store.getState().subscription;
  return subscription.status === SUBSCRIPTION_STATUS.ACTIVE && !subscription.sponsored;
}

function isSponsoredSubscription() {
  const subscription = store.getState().subscription;
  return subscription.status === SUBSCRIPTION_STATUS.ACTIVE && subscription.sponsored;
}

function isOnboardingCompleted() {
  const state = store.getState();
  const isInitialBackendSession =
    state.menu.metadata.isInitialSession || AppFlagProvider.getFlag(APP_FLAGS.DEBUG);
  return (
    AppFlagProvider.getFlag(APP_FLAGS.ONBOARDING_COMPLETED) ||
    !ONBOARDING ||
    !hasStorage() ||
    !isInitialBackendSession
  );
}

function getWaitPeriod(requestCount) {
  if (requestCount < MAX_WAIT_EXPONENT) {
    return MENU_GENERATION_WAIT_PERIOD * 2 ** requestCount;
  }

  return MAX_WAIT_PERIOD;
}

function getVanityStoreName(internalName) {
  const storeItem = Stores.find(item => item.internalName === internalName);
  if (storeItem) {
    return storeItem.alias ? storeItem.alias : storeItem.internalName;
  }

  return internalName;
}

function getTopTitle() {
  const state = store.getState();
  const storeName = getVanityStoreName(state.menu.name);
  const storePhrase = storeName ? ` for ${storeName}` : '';
  let name = '';

  switch (state.navigation.view) {
    case HOME_VIEW:
      name = (state.menu.metadata && state.menu.metadata.first) ? state.menu.metadata.first : '';
      return name ? `Welcome ${name}!` : 'Welcome!';
    case MENU_VIEW:
      return `Your Menu${storePhrase}`;
    case SL_VIEW:
      return `Your List${storePhrase}`;
    case RECIPES_VIEW:
      return `Your Own Recipes`;
    default:
      return 'The Dinner Daily';
  }
}

function getActionDialogHeading(dialogSelectionType) {
  switch (dialogSelectionType) {
    case ACTION_DIALOG.STORE_CHANGE:
      return ACTION_DIALOG_HEADING[ACTION_DIALOG.STORE_CHANGE];
    case ACTION_DIALOG.DIET_PLAN_OPTIONS:
      return ACTION_DIALOG_HEADING[ACTION_DIALOG.DIET_PLAN_OPTIONS];
    case ACTION_DIALOG.DIETARY_RESTRICTIONS:
      return ACTION_DIALOG_HEADING[ACTION_DIALOG.DIETARY_RESTRICTIONS];
    case ACTION_DIALOG.CHANGE_EMAIL:
      return ACTION_DIALOG_HEADING[ACTION_DIALOG.CHANGE_EMAIL];
    case ACTION_DIALOG.CHANGE_PASSWORD:
      return ACTION_DIALOG_HEADING[ACTION_DIALOG.CHANGE_PASSWORD];
    default:
      return ACTION_DIALOG_HEADING[dialogSelectionType];
  }
}

function getLegacyNoticeContent(pageName) {
  return `${pageName} is part of our website and you may need to log in. Use the <b>X</b> in the upper right corner to return to your meal plan.`;
}

function getWindowHeight() {
  const smallestHeight =
    window.outerHeight > window.innerHeight ? window.innerHeight : window.outerHeight;
  return smallestHeight;
}

function setContentHeight(content) {
  // dynamic content height
  const contentHeight =  0.75 * getWindowHeight();
  content.style.height = `${contentHeight}px`;
}

/**
 * mitigate chase condition (extra time on development machine)
 */
function getApiSequencePause() {
  return AppFlagProvider.isDebug() ? API_SEQUENCE_PAUSE_DEV : API_SEQUENCE_PAUSE_PROD;
}

function getMonitorRequestInterval() {
  return AppFlagProvider.isDebug() ? MONITOR_REQUEST_INTERVAL_DEV : MONITOR_REQUEST_INTERVAL;
}

function onLoginSubmit(event) {
  store.dispatch(login(event.detail.email, event.detail.password));
}

function onSubscriptionPortalClose() {
  store.dispatch(navigate(HOME_VIEW));
}

function onAppFeatureLevelChange(newLevel) {
  // open self-service portal if necessary
  if (userManagement.statusRequestsStarted && newLevel === FEATURE_LEVEL.ZERO) {
    store.dispatch(navigate(SUBSCRIPTION_VIEW));
  } else {
    store.dispatch(navigate(HOME_VIEW));
  }
}

function onFreeTrialCtaClick() {
  store.dispatch(navigate(SUBSCRIPTION_VIEW));
}

function onSearchTextEntry(event) {
  Instrumentation.getAnalytics().track(`${ANALYTICS.MEAL_PLANNING}: Request search`);
  store.dispatch(requestSearch(event.detail.dishType, event.detail.searchText));
}

function onClearSearch() {
  const emptyDto = {
    results: [],
  };
  store.dispatch(saveSearch(emptyDto));
}

function onOnboardingCompleted() {
  store.dispatch([setFlag(APP_FLAGS.ONBOARDING_COMPLETED, true), navigate(HOME_VIEW)]);
}

function openWebView(url, pageName) {
  const action = navigate(WEB_VIEW, url, pageName);
  store.dispatch(action);
  Instrumentation.getAnalytics().track(`Opened WP ${pageName}`);
}

function onFAQListItemClick() {
  const pageName = 'FAQ';
  const url = getWebUrl(`${WORDPRESS_PATH.faq}?${QUERY_IDENTIFIER}`);
  openWebView(url, pageName);
}

function onPrivacyPolicyListItemClick() {
  const pageName = 'Privacy Policy';
  const url = getWebUrl(`${WORDPRESS_PATH.privacyPolicy}?${QUERY_IDENTIFIER}`);
  openWebView(url, pageName);
}

function onTermsOfUseListItemClick() {
  const pageName = 'Terms And Conditions';
  const url = getWebUrl(`${WORDPRESS_PATH.termsOfUse}?${QUERY_IDENTIFIER}`);
  openWebView(url, pageName);
}

function onViewMenuClick() {
  const action = navigate(MENU_VIEW, ALL_DAYS_ID);
  store.dispatch(action);
}

function onViewSlClick() {
  const action = navigate(SL_VIEW);
  store.dispatch(action);
}

function onRestoreMenu() {
  Instrumentation.getAnalytics().track(`${ANALYTICS.MEAL_PLANNING}: Restore menu`);
  store.dispatch(requestRestoreMenu(AppFlagProvider.getFlag(APP_FLAGS.PREVIOUS_WEEK)));
}

function onAddRecipe(event) {
  Instrumentation.getAnalytics().track(`${ANALYTICS.MEAL_PLANNING}: Add recipe`);
  store.dispatch(requestAddRecipe(AppFlagProvider.getFlag(APP_FLAGS.PREVIOUS_WEEK), event.detail));
}

function onDeleteRecipe(event) {
  Instrumentation.getAnalytics().track(`${ANALYTICS.MEAL_PLANNING}: Delete recipe`);
  store.dispatch(
    requestRemoveRecipe(AppFlagProvider.getFlag(APP_FLAGS.PREVIOUS_WEEK), event.detail),
  );
}

function onDeleteRecipes(event) {
  Instrumentation.getAnalytics().track(`${ANALYTICS.MEAL_PLANNING}: Delete meal`);
  store.dispatch(
    requestRemoveRecipes(AppFlagProvider.getFlag(APP_FLAGS.PREVIOUS_WEEK), event.detail),
  );
}

function onReplaceRecipe(event) {
  Instrumentation.getAnalytics().track(`${ANALYTICS.MEAL_PLANNING}: Change recipe`);
  store.dispatch(
    requestReplaceRecipe(AppFlagProvider.getFlag(APP_FLAGS.PREVIOUS_WEEK), event.detail),
  );
}

function onRecipeRequestFavorited(event) {
  const state = store.getState();
  const isFavorited = isFavoriteRecipe(event.detail.recipeId, state.favorites);
  event.detail.callback(isFavorited);
}

function onRecipeFavoriteAction(event) {
  store.dispatch(setFavorite(event.detail));
  const recipeId = event.detail.recipeId;
  if (event.detail.isFavorited) {
    store.dispatch(requestAddFavorite(recipeId));
  } else {
    store.dispatch(requestRemoveFavorite(recipeId));
  }
}

function onAddShopitem(event) {
  Instrumentation.getAnalytics().track(`${ANALYTICS.SHOPPING}: Add shop item`);
  store.dispatch(
    requestAddShopItem(AppFlagProvider.getFlag(APP_FLAGS.PREVIOUS_WEEK), event.detail),
  );
}

function onUpdateShopitem(event) {
  store.dispatch(
    requestUpdateShopItem(AppFlagProvider.getFlag(APP_FLAGS.PREVIOUS_WEEK), event.detail),
  );
}

function onCheckedShopitem(event) {
  const shopItemId = event.detail.shopItemId;
  const isChecked = event.detail.isChecked;
  store.dispatch(setShopItemChecked(shopItemId, isChecked));

  if (isChecked) {
    store.dispatch(requestCheckShopItem(shopItemId));

    // get products if a Kroger location exists
    const krogerLocations = store.getState().fulfillment.krogerLocations;
    if (krogerLocations.length) {
      store.dispatch(requestFulfillmentKrogerProduct(krogerLocations[0].id, shopItemId));
    }
  } else {
    store.dispatch(requestUncheckShopItem(shopItemId));
  }
}

function onRemoveShopitem(event) {
  const shopItemId = event.detail.shopItemId;
  store.dispatch(
    requestRemoveShopItem(AppFlagProvider.getFlag(APP_FLAGS.PREVIOUS_WEEK), shopItemId),
  );
}

function onFeedbackListItemClick() {
  const pageName = 'Contact Us';
  const url = 'https://form.jotform.com/201005369950047';
  openWebView(url, pageName);
}

function onNewsClick(event) {
  const pageName = event.detail.title;
  openWebView(event.detail.url, pageName);
}

function onLogoutListItemClick() {
  if (userManagement.auth0) {
    Instrumentation.getAnalytics().track(`${ANALYTICS.ACCOUNT}: Log out`);
    localStorage.clear();
    userManagement.auth0.logout();
  }
}

function onGetSubscriptionStatusClick() {
  store.dispatch(requestUpdateSubscription());
}

function onSlPrintViewListItemClick() {
  store.dispatch(navigate(SL_PRINT_VIEW));
}

/**
 * Activated when user selects / checks the meal selector on the Shopping List
 * The item is shown on the Shopping List
 * @param {*} event
 */
function onCheckSelectOption(event) {
  if (event.detail.id === SORT_SWITCH_ID) {
    const action = saveUISettings({ sortSwitchId: true });
    store.dispatch(action);
  } else {
    const dayMenuId = event.detail.id;
    const state = store.getState();
    const recipeIds = getRecipeIds(dayMenuId, state);
    store.dispatch(activateRecipeShopItems(recipeIds));
  }
}

/**
 * Activated when user deselects / un-checks the meal selector on the Shopping List
 * The item is removed from the Shopping List
 * @param {*} event
 */
function onUncheckSelectOption(event) {
  if (event.detail.id === SORT_SWITCH_ID) {
    const action = saveUISettings({ sortSwitchId: false });
    store.dispatch(action);
  } else {
    const dayMenuId = event.detail.id;
    const state = store.getState();
    const recipeIds = getRecipeIds(dayMenuId, state);
    store.dispatch(deactivateRecipeShopItems(recipeIds));
  }
}

function oauthConnect(service) {
  // auth token is used after redirect to server authorized endpoint by Guard to authenticate user
  const endpoint = `connect/${service}?state=${store.getState().token.auth}`;
  const url = getWebResourceUrl(endpoint);
  window.open(url);
  console.log(`OAuth connect: ${url}`);
}

function onIframeClose() {
  store.dispatch(navigate(HOME_VIEW));
}

function onCbIframeClose() {
  userManagement.chargebeeInstance.closeAll();
}

function onSnackbarMessageShown() {
  store.dispatch(clearRequestMessage());
}

function onChangeWeekMenu(event) {
  this.startMenuMonitoring_();
  const navigateToMenu = event.detail.view === SL_VIEW;
  const oldPreviousWeek = AppFlagProvider.getFlag(APP_FLAGS.PREVIOUS_WEEK);
  const newPreviousWeek = !oldPreviousWeek;
  store.dispatch(setFlag(APP_FLAGS.PREVIOUS_WEEK, newPreviousWeek));

  store.dispatch(requestMenu(true, newPreviousWeek, navigateToMenu));
}

function onDayMenuItemSelected(event) {
  const dayMenuId = event.detail.id;
  store.dispatch(navigate(MENU_VIEW, dayMenuId));
}

function onChangeFamilySize(event) {
  Instrumentation.getAnalytics().track(`${ANALYTICS.PREFS}: Change family size`);
  const familySize = parseInt(event.detail.familySize, 10);
  const previousWeek = AppFlagProvider.getFlag(APP_FLAGS.PREVIOUS_WEEK);
  const action = requestChangeFamilySize(familySize, previousWeek, !event.detail.isSilent);
  store.dispatch(action);
}

function onChangeEmail(event) {
  Instrumentation.getAnalytics().track(`${ANALYTICS.ACCOUNT}: Change email`);
  // console.log(`onChangeEmail: event=${  JSON.stringify(event)}`);
  const action = requestUpdateEmail(event.detail.newEmail, event.detail.currentEmail, "Email is being updated")
  store.dispatch(action);
}

function onCreatePersonalRecipe(event) {
  if (event.detail.name) {
    Instrumentation.getAnalytics().track(`${ANALYTICS.PERSONAL_RECIPES}: Create personal recipe`);
    const action = requestCreatePersonalRecipe(event.detail.name, event.detail.dishType);
    store.dispatch(action);
  } else {
    const createTempPersonalRecipe = createPersonalRecipe(event.detail);
    store.dispatch(createTempPersonalRecipe);
  }
}

function onNewRecipeIngredient(event) {
  const selectedIngredient = event.detail.ingredientId > TEMPORARY_ID;
  const newIngredient = event.detail.ingredientId === TEMPORARY_ID && event.detail.ingredientName;

  if (selectedIngredient || newIngredient) {
    store.dispatch(requestAddPersonalRecipeIngredient(event.detail));
    if (newIngredient) {
      setTimeout(() => {
        store.dispatch(requestIngredients());
      }, getApiSequencePause());
    }
  } else {
    const addTempRecipeIngredient = addRecipeIngredient(event.detail.id, event.detail.recipeId);
    store.dispatch(addTempRecipeIngredient);
  }
}

function onRemoveEmptyRecipe() {
  store.dispatch(removeEmptyRecipe());
}

function onRemoveEmptyRecipeIngredient(event) {
  const removeTempRecipeIngredient = removeEmptyRecipeIngredient(event.detail.recipeId);
  store.dispatch(removeTempRecipeIngredient);
}

function onChangedRecipeIngredient(event) {
  const action = requestUpdateRecipeIngredient(event.detail);
  store.dispatch(action);
}

function onRemoveRecipeIngredient(event) {
  const action = requestRemoveRecipeIngredient(event.detail);
  store.dispatch(action);
}

function onChangedPersonalRecipe(event) {
  const action = requestUpdatePersonalRecipe(event.detail);
  store.dispatch(action);
}

function onPrintViewInteraction() {
  const state = store.getState();
  if (state.navigation.view === WEEK_PRINT_VIEW) {
    store.dispatch(navigate(MENU_VIEW));
  } else if (state.navigation.view === SL_PRINT_VIEW) {
    store.dispatch(navigate(SL_VIEW));
  }
}

function manageFirstVisitAppFlag() {
  if (!AppFlagProvider.getFlag(APP_FLAGS.FIRST_VISIT_COMPLETED)) {
    store.dispatch(setFlag(APP_FLAGS.FIRST_VISIT_COMPLETED, true));
  }
}

function setViewIfMember() {
  const state = store.getState();
  const loggedIn = state.token.auth && state.token.refresh;
  const featuresEnabled = AppFeatureLevelProvider.getLevel() === FEATURE_LEVEL.ONE;

  if (loggedIn && featuresEnabled) {
    store.dispatch(navigate(HOME_VIEW));
  } else {
    // white label JWT case
    const query = new URLSearchParams(document.location.search);
    if (query.get('token')) {
      store.dispatch(login('', '', query.get('token')));
    }
  }
}

function isMenuMissing(menu) {
  const menuHydrated = menu.metadata.env;
  return menuHydrated && !menu.dayMenus.length;
}

function isInMenu(recipeId) {
  const dayMenus = store.getState().menu.dayMenus;
  return dayMenus.find(dayMenu => dayMenu.main && dayMenu.main.id === recipeId);
}

function notifyIfLocationBlocked(locationBlockedContent) {
  // android/chrome permissions check
  if (navigator.permissions) {
    navigator.permissions.query({ name: 'geolocation' }).then(result => {
      if (AppFlagProvider.isDebug()) {
        console.log(`geolocation state: ${result.state}`);
      }

      if (result.state === 'denied') {
        const ddApp = document.querySelector('dd-app');
        ddApp.showNoticeDialog(LOCATION_BLOCKED_HEADING, locationBlockedContent);
      }
    });
  } else if (AppFlagProvider.isDebug()) {
    console.log('navigator.permissions not found');
  }
}

function getDefaultStateCode(storeName) {
  const defaultStore = Stores.find(storeItem => storeItem.internalName === storeName);

  let defaultStateCode = '';
  if (defaultStore && defaultStore.states && defaultStore.states.length) {
    defaultStateCode = defaultStore.states[0];
  }

  return defaultStateCode;
}

function getStateListItemTemplates(storeName = '', statePreselected = false) {
  const defaultStateCode = storeName ? getDefaultStateCode(storeName) : '';

  return StateNames.map(stateName => {
    const selected = !statePreselected && stateName.code === defaultStateCode;
    return html`
      <mwc-list-item value="${stateName.code}" ?selected="${selected}"
        >${stateName.state}</mwc-list-item
      >
    `;
  });
}

function getNoticeDialogTemplate() {
  return html`
    <mwc-dialog id="notice-dialog">
      <div id="notice-content"></div>
      <mwc-button dialogAction="ok" slot="primaryAction">ok</mwc-button>
    </mwc-dialog>
  `;
}

function getReloadContentTemplate() {
  return html`
    <span>${RELOAD_CONTENT}</span>
  `;
}

function getKrogerStoreListItemTemplates(locations = []) {
  return locations.map(location => {
    const address = `${location.address_line1}, ${location.city}, ${location.state}`;

    return html`
      <mwc-list-item twoline value="${location.id}">
        <span>${location.name}</span>
        <span slot="secondary">${address}</span>
      </mwc-list-item>
    `;
  });
}

function getKrogerLocations() {
  if (navigator.geolocation) {
    if (AppFlagProvider.isDebug()) {
      console.log('getKrogerLocations: navigator.geolocation found');
    }
    navigator.geolocation.getCurrentPosition(
      position => {
        const useOverrides = AppFlagProvider.isDev();
        if (AppFlagProvider.isDebug()) {
          console.log(
            `getKrogerLocations: lat: ${position.coords.latitude}, long: ${position.coords.longitude}, override: ${useOverrides}`,
          );
        }

        // default dev override location = Savannah, GA
        const latOverride = AppFlagProvider.getFlag(APP_FLAGS.LAT_OVERRIDE) ?? 32.0833333;
        const longOverride = AppFlagProvider.getFlag(APP_FLAGS.LONG_OVERRIDE) ?? -81.1;
        const params = {
          lat: !useOverrides ? position.coords.latitude : latOverride,
          long: !useOverrides ? position.coords.longitude : longOverride,
        };

        store.dispatch(requestFulfillmentKrogerLocations(params));
      },
      positionError => {
        const ddApp = document.querySelector('dd-app');
        ddApp.showNoticeDialog(LOCATION_BLOCKED_HEADING, FULFILLMENT_LOCATION_BLOCKED_CONTENT);

        if (AppFlagProvider.isDebug()) {
          console.log(`getKrogerLocations: geolocation positionError code: ${positionError.code}`);
        }
      },
      {
        enableHighAccuracy: false,
        maximumAge: 300000, // ms
        timeout: 10000, // ms
      },
    );
  } else {
    const ddApp = document.querySelector('dd-app');
    ddApp.showNoticeDialog(LOCATION_NOT_SUPPORTED_HEADING, LOCATION_NOT_SUPPORTED_CONTENT);
    if (AppFlagProvider.isDebug()) {
      console.log('getKrogerLocations: navigator.geolocation not found');
    }
  }
}

function tryGetKrogerLocations() {
  if (FulfillmentAccountProvider.isKrogerAuthorized()) {
    notifyIfLocationBlocked(FULFILLMENT_LOCATION_BLOCKED_CONTENT);

    getKrogerLocations();
  }
}

function saveKrogerLocationId(locationId) {
  const action = setFlag(APP_FLAGS.SELECTED_LOCATION, locationId);
  store.dispatch(action);
}

function getSelectedKrogerLocation() {
  const state = store.getState();
  const locations = state.fulfillment.krogerLocations;
  const persistedLocationId = AppFlagProvider.getFlag(APP_FLAGS.SELECTED_LOCATION);
  const foundPersistedLocation = persistedLocationId
    ? locations.find(location => location.id === persistedLocationId)
    : null;
  const location = foundPersistedLocation ?? locations[0];

  return location;
}

function getSanitizedStoreName(rawName) {
  const stores = FeatureProvider.isActive(FEATURES.DEBUG) ? Stores.concat(TestStores) : Stores;
  const storeMap = stores.find(storeItem => storeItem.internalName === rawName);

  if (storeMap) {
    return storeMap.alias || storeMap.internalName;
  }

  return '';
}

function getStoreAddressTemplate() {
  const selectedLocation = getSelectedKrogerLocation();

  return selectedLocation
    ? html`
        <span
          >${selectedLocation.address_line1}, ${selectedLocation.city},
          ${selectedLocation.state}</span
        >
      `
    : '';
}

function getDeletePersonalRecipeContentTemplate() {
  return html`
    <div>
      <span>This recipe is in your menu this week. Do you still want to delete it?</span>
    </div>
  `;
}

function getGoogleConnectContentTemplate() {
  return html`
    <div>
      <span>Connecting will allow you to add your dinners to your calendar.</span>
    </div>
  `;
}

function sendToCalendar() {
  const timezoneName = jstz.determine().name();
  const weekMenuId = store.getState().menu.id;
  const action = requestCreateEvents(weekMenuId, timezoneName);
  store.dispatch(action);
}

/* eslint no-undef: "off" */
class DdApp extends connect(store)(ScreenAwareElement) {
  static get properties() {
    return {
      _heading: { type: String },
      _storeName: { type: String },
      _view: { type: String },
      _webViewUrl: { type: String },
      _itemIdsToFulfill: { type: Object },
      _dietPlanOptions: { type: Object },
      _features: { type: Object },
      _metadata: { type: Object },
      _selectedStateCode: { type: String },
      _subscription: { type: Object },
    };
  }

  get isFreeTrial() {
    return this._subscription.status === SUBSCRIPTION_STATUS.FREE && this._subscription.trialDays;
  }

  get isOnboardingCompleted() {
    return isOnboardingCompleted();
  }

  static get styles() {
    return [sharedStyles, style];
  }

  constructor() {
    super();
    this._heading = '';
    this._storeName = '';
    this._selectInternalStoreName = '';
    this._view = ''; // delayed change view flag (reflects transition delay)
    this._instantView = ''; // undelayed change view flag
    this._webViewUrl = '';
    this._itemIdsToFulfill = [];
    this._timerId = 0;
    this._accountConnecting = OAUTH_SERVICE.NONE;
    this._reloadOkCallback = null;
    this._dialogSelection = {
      type: ACTION_DIALOG.NONE,
    };
    this._selectedStateCode = '';
    this._currentStore = '';
    this._onboardingDto = {};
    this._notMaximized = false;
    this._userManagementInitialized = false;
    this._notifiedMenuWillchange = false;
    this._subscription = {};

    // pre-window.load events
    const maximizedHandler = () => {
      this._notMaximized = true;
    };
    PubSub.subscribe(NOT_MAXIMIZED_EVENT, maximizedHandler);
    PubSub.subscribe(LEVEL_CHANGE_EVENT, onAppFeatureLevelChange); // handle level change starting with persist/REHYDRATE result
    PubSub.subscribe(TOKEN_SET_EVENT, this.onTokenSet_.bind(this));

    OrientationPublisher.initializeOrientation(window);
  }

  firstUpdated() {
    const accountMenu = this.shadowRoot.querySelector('#account-menu');
    accountMenu.anchor = this.shadowRoot.querySelector('#account-button');
    accountMenu.x = 0;
    accountMenu.y = 40;


    // post-window.load events
    PubSub.subscribe(OAUTH_CONNECTED_EVENT, this.onOauthConnected_.bind(this));
    PubSub.subscribe(LOCATIONS_SET_EVENT, this.onLocationsSet_.bind(this));
    PubSub.subscribe(REQUEST_CANCELLED_EVENT, this.onRequestCancelled_.bind(this));
    PubSub.subscribe(INVALID_TOKEN_EVENT, onLogoutListItemClick);

    const backgroundGetMenu = this.backgroundGetMenu_.bind(this);
    setInterval(backgroundGetMenu, MENU_SYNC_INTERVAL);

    const showIncognitoNoticeDialog = this.showIncognitoNoticeDialog_.bind(this);
    isPrivateMode().then(isPrivate => {
      if (isPrivate) {
        showIncognitoNoticeDialog();
      }
    });

    if (
      this._notMaximized &&
      !this.largeScreen &&
      !AppFlagProvider.getFlag(APP_FLAGS.NOTIFIED_NOT_MAXIMIZED)
    ) {
      this.showNoticeDialog(NOT_MAXIMIZED_HEADING, NOT_MAXIMIZED_CONTENT);
      store.dispatch(setFlag(APP_FLAGS.NOTIFIED_NOT_MAXIMIZED, true));
    }

    if (ID_PROVIDER) {
      initializeUserManagement();
    }

    if (this.isFreeTrial) {
      this.showSnackBarHint_(`You have ${this._subscription.trialDays} days left in your trial.`)
    }

    setViewIfMember();
    // auto-close disabled due to Safari bug (fires afterprint event on retrieving printer details)
    // window.onafterprint = () => { onPrintViewInteraction();	};

  }

  updated() {
    super.updated()
    const drawer = this.shadowRoot.querySelector('#desktop-drawer');
    if (drawer && !drawer.shadowRoot.querySelector('style')) {
      const drawerStyle = document.createElement('style');
      drawerStyle.innerHTML = css`
        aside {
          border-color: transparent !important;
          box-shadow: 0px 12px 20px rgba(48, 48, 48, 0.2);
        }
      `;
      drawer.shadowRoot.appendChild(drawerStyle);
    }

    const stateList = this.shadowRoot.querySelector('#state-select')?.shadowRoot.querySelector('mwc-menu')?.shadowRoot.querySelector('mwc-list');
    if (stateList) {
      stateList.style.maxHeight = this.largeScreen ? "600px" : "300px";
    }
    const storeList = this.shadowRoot.querySelector('#store-select')?.shadowRoot.querySelector('mwc-menu')?.shadowRoot.querySelector('mwc-list');
    if (storeList) {
      storeList.style.maxHeight = this.largeScreen ? "600px" : "300px";
    }
  }

  render() {
    const topAppBarShadowOffset = this.largeScreen ? 'drawer-open' : '';

    return html`
      <main role="main" class="${topAppBarShadowOffset}">
        ${this.largeScreen
          ? html`
              <div id="top-app-bar-container" class="dd_show">
                <mwc-top-app-bar-fixed @MDCTopAppBar:nav="${this.onNavClick_}">
                  <mwc-icon-button
                    id="navigation-icon"
                    icon="menu"
                    class="dd-desktop-hide"
                    slot="navigationIcon"
                  ></mwc-icon-button>
                  <h2 id="top-bar-title" slot="title">${getTopTitle()}</h2>
                  <mwc-icon-button
                    id="account-button"
                    label="Account"
                    icon="account_circle"
                    slot="actionItems"
                    title="${"Account Settings"}"
                    @click="${this.onAccountMenuClick_}"
                  >
                  </mwc-icon-button>
                  <mwc-menu fixed id="account-menu">
                    ${this.getAccountListItemTemplates_()}
                  </mwc-menu>
                </mwc-top-app-bar-fixed>
              </div>
              <mwc-drawer id="desktop-drawer">
                <div id="drawer-content" class="dd-flex-container">
                  <mwc-list id="primary-nav-list" activatable>
                    <mwc-list-item
                      graphic="icon"
                      selected
                      activated
                      @click="${this.onHomeListItemClick_}"
                    >
                      <h5>Home</h5>
                      <mwc-icon slot="graphic">home</mwc-icon>
                    </mwc-list-item>
                    <mwc-list-item graphic="icon" @click="${this.onMenuListItemClick_}">
                      <h5>Menu</h5>
                      <mwc-icon slot="graphic">restaurant_menu</mwc-icon>
                    </mwc-list-item>
                    <mwc-list-item graphic="icon" @click="${this.onShoppingListItemClick_}">
                      <h5>Shopping List</h5>
                      <mwc-icon slot="graphic">shopping_basket</mwc-icon>
                    </mwc-list-item>
                    <mwc-list-item graphic="icon" @click="${this.onPersonalRecipesListItemClick_}">
                      <h5>Your Recipes</h5>
                      <mwc-icon slot="graphic">menu_book</mwc-icon>
                    </mwc-list-item>
                  </mwc-list>
                  <mwc-list id="secondary-list" >
                    ${this.getSecondaryListItemTemplates_()}
                  </mwc-list>
                </div>
                <div slot="appContent">
                  ${this.getAppContentTemplate_()}
                </div>
              </mwc-drawer>
            `
          : html`
              <div id="top-app-bar-container" class="dd_show">
                <mwc-top-app-bar-fixed @MDCTopAppBar:nav="${this.onNavClick_}">
                  <mwc-icon-button-toggle
                    id="navigation-icon"
                    offIcon="menu"
                    onIcon="close"
                    class="dd-desktop-hide"
                    slot="navigationIcon"
                  ></mwc-icon-button-toggle>
                  <h2 id="top-bar-title" slot="title">${getTopTitle()}</h2>
                  <mwc-icon-button
                    id="account-button"
                    label="Account"
                    icon="account_circle"
                    slot="actionItems"
                    title="${"Account Settings"}"
                    @click="${this.onAccountMenuClick_}"
                  >
                  </mwc-icon-button>
                  <mwc-menu fixed id="account-menu">
                    ${this.getAccountListItemTemplates_()}
                  </mwc-menu>
                </mwc-top-app-bar-fixed>
              </div>
              <mwc-drawer type="modal" @MDCDrawer:closed="${this.onNavClose}">
                <div id="drawer-content" class="dd-flex-container">
                  <mwc-list>
                    ${this.getSecondaryListItemTemplates_()}
                  </mwc-list>
                </div>
                <div slot="appContent">
                  ${this.getAppContentTemplate_()}
                </div>
              </mwc-drawer>
            `}
        <dd-onboarding
          class="page full-screen-overlay"
          ?active="${this._view === ONBOARDING_VIEW}"
          @family-size-changed="${onChangeFamilySize}"
          @food-limit-total-changed="${this.onFoodLimitTotalChanged_}"
          @onboarding-completed="${onOnboardingCompleted}"
          @state-selected="${this.onStateSelected_}"
          @set-diet-plan-options="${this.onboardingSetDietPlanOptions_}"
        ></dd-onboarding>
        <dd-subscription-portal
          class="page full-screen-overlay"
          ?active="${this._view === SUBSCRIPTION_VIEW && ID_PROVIDER}"
          .isFreeTrial="${this.isFreeTrial}"
          @close="${onSubscriptionPortalClose}"
          @option-click="${this.onOpenPortal_}"
        ></dd-subscription-portal>
        <dd-week-menu-print
          class="page full-screen"
          ?active="${this._view === WEEK_PRINT_VIEW}"
          .heading="${this.getExtendedHeadingTemplate_()}"
          @print-view-interaction="${onPrintViewInteraction}"
        ></dd-week-menu-print>
        <dd-week-sl-print
          class="page full-screen"
          ?active="${this._view === SL_PRINT_VIEW}"
          .heading="${this.getExtendedHeadingTemplate_()}"
          @print-view-interaction="${onPrintViewInteraction}"
        ></dd-week-sl-print>
        <dd-iframe
          id="iframe-container"
          class="page absolute-full-screen"
          ?active="${this._view === WEB_VIEW}"
          .source=${this._webViewUrl}
          @close="${onIframeClose}"
        ></dd-iframe>
        ${getNoticeDialogTemplate()} ${this.getConfirmationDialogTemplate_()}
        ${this.getSimpleDialogTemplate_()}
        <dd-api-snackbar
          @message-shown="${onSnackbarMessageShown}"
          .spacerHeight=${SPACER_HEIGHT}
        ></dd-api-snackbar>
        ${this.getFreeTrialCtaTemplate_()}
        <div id="main-scrim" class="scrim main-scrim"></div>
        <div
          id="cb-iframe-close-button"
          style="z-index:9999999;position:absolute;top:0px;right:0px;color:#56BAB4;"
          class="dd_hide"
          aria-label="close"
        >
          <mwc-icon-button icon="close" @click="${onCbIframeClose}"></mwc-icon-button>
        </div>
      </main>
    `;
  }


  getAppContentTemplate_() {
    return html`
      <div id="app-content">
      <dd-api-progress></dd-api-progress>
        <div id="view-container">
          <dd-login
            id="log-in"
            class="page"
            ?active="${this._view === LOGIN_VIEW && typeof ID_PROVIDER === 'undefined'}"
            @login-submit="${onLoginSubmit}"
          ></dd-login>
          <div id="task-view">
            <dd-home
              class="page full-screen"
              ?active="${this._view === HOME_VIEW}"
              .spacerHeight=${SPACER_HEIGHT}
              .saleTitle=${this._heading}
              @change-week-menu="${onChangeWeekMenu}"
              @change-store="${this.onHomeChangeStore_}"
              @change-family-size="${this.onFamilySizeItemClick_}"
              @day-menu-item-selected="${onDayMenuItemSelected}"
              @menu-limits-click="${this.onMenuLimitsListItemClick_}"
              @print-menu="${this.onPrintMenuClick_}"
              @news-click="${onNewsClick}"
              @view-menu="${onViewMenuClick}"
              @print-sl="${this.onPrintSlClick_}"
              @view-sl="${onViewSlClick}"
            ></dd-home>
            <dd-menu-2
              class="page full-screen"
              ?active="${this._view === MENU_VIEW}"
              .spacerHeight=${140}
              @search-text-entry="${onSearchTextEntry}"
              @restore-menu="${onRestoreMenu}"
              @change-week-menu="${onChangeWeekMenu}"
              @change-store="${this.onChangeStore_}"
              @menu-limits-click="${this.onMenuLimitsListItemClick_}"
              @dietary-restrictions-click="${this.onDietaryRestrictionsListItemClick_}"
              @recipe-request-favorited="${onRecipeRequestFavorited}"
              @recipe-favorite-action="${onRecipeFavoriteAction}"
              @day-menu-add-recipe="${onAddRecipe}"
              @day-menu-delete-recipe="${onDeleteRecipe}"
              @day-menu-delete-recipes="${onDeleteRecipes}"
              @day-menu-replace-recipe="${onReplaceRecipe}"
              @day-menu-clear-search="${onClearSearch}"
              @add-calendar="${this.onCalendarClick_}"
              @print-menu="${this.onPrintMenuClick_}"
            ></dd-menu-2>
            <dd-shopping-list-2
              class="page full-screen"
              ?active="${this._view === SL_VIEW}"
              ?sortSimilar=${true}
              .spacerHeight=${SPACER_HEIGHT}
              .title=${this._heading}
              @oauth-connect="${this.onOauthConnect_}"
              @elect-fulfillment="${this.onElectFulfillment_}"
              @add-shop-item="${onAddShopitem}"
              @update-shop-item-from-category="${onUpdateShopitem}"
              @checked-shop-item="${onCheckedShopitem}"
              @remove-shop-item="${onRemoveShopitem}"
              @print-sl="${this.onPrintSlClick_}"
            >
              <dd-item-selector
                id="meals-selector"
                slot="item-selector"
                ?isOpen="${true}"
                @check-select-option="${onCheckSelectOption}"
                @uncheck-select-option="${onUncheckSelectOption}"
              ></dd-item-selector>
            </dd-shopping-list-2>
            <dd-personal-recipes-2
              class="page full-screen"
              ?active="${this._view === RECIPES_VIEW}"
              .spacerHeight=${SPACER_HEIGHT}
              @create-personal-recipe="${onCreatePersonalRecipe}"
              @new-recipe-ingredient="${onNewRecipeIngredient}"
              @remove-empty-recipe="${onRemoveEmptyRecipe}"
              @remove-empty-recipe-ingredient="${onRemoveEmptyRecipeIngredient}"
              @changed-recipe-ingredient="${onChangedRecipeIngredient}"
              @remove-recipe-ingredient="${onRemoveRecipeIngredient}"
              @changed-recipe="${onChangedPersonalRecipe}"
              @delete-recipe="${this.onDeletePersonalRecipe_}"
            ></dd-personal-recipes-2>
            <dd-icon-tray
              class="dd-desktop-hide"
              @nav_selection="${this.onNavIconSelect_}"
            ></dd-icon-tray>
          </div>
        </div>
        <div id="tab-scrim" class="tab-scrim"></div>
      </div>
    `;
  }

  getSecondaryListItemTemplates_() {
    const itemFontClass = this.largeScreen ? 'dd-h5' : 'dd-body1';

    return html`
      <mwc-list-item graphic="icon" @click="${this.onMenuLimitsListItemClick_}">
        <span class="${itemFontClass}">Food Preferences</span>
        <span slot="graphic" class="tddi-food-preferances secondary-list-item"></span>
      </mwc-list-item>
      <mwc-list-item graphic="icon" @click="${this.onDietaryRestrictionsListItemClick_}">
        <span class="${itemFontClass}">Dietary Needs</span>
        <span slot="graphic" class="tddi-dietery-needs secondary-list-item"></span>
      </mwc-list-item>
      <mwc-list-item graphic="icon" @click="${this.onFamilySizeItemClick_}">
        <span class="${itemFontClass}">Meal Size</span>
        <mwc-icon slot="graphic">group</mwc-icon>
      </mwc-list-item>
      <mwc-list-item graphic="icon" @click="${this.onChangeStore_}">
        <span class="${itemFontClass}">State and Store</span>
        <mwc-icon slot="graphic">store</mwc-icon>
      </mwc-list-item>
      <mwc-list-item graphic="icon" @click="${this.onBonusContentListItemClick_}">
        <span class="${itemFontClass}">Bonus Content</span>
        <mwc-icon slot="graphic">savings</mwc-icon>
      </mwc-list-item>
    `;
  }

  getAccountListItemTemplates_() {
    return html`
      ${CHANGE_EMAIL && userManagement.isAuth0User
        ? html `
          <mwc-list-item @click="${this.onChangeEmailListItemClick_}">
            <span class="al-noicon">Change Email</span>
          </mwc-list-item>
          <mwc-list-item @click="${this.onChangePasswordListItemClick_}">
            <span class="al-noicon">Change Password</span>
          </mwc-list-item>
        `
        : ``
      }
      ${!isSponsoredSubscription()
        ? html`
          <mwc-list-item graphic="icon" @click="${this.onSubscriptionSettingsListItemClick_}">
            <span class="dd-body1">Account</span>
            <mwc-icon slot="graphic">account_circle</mwc-icon>
          </mwc-list-item>
        `
        : ''
      }
      ${NotificationService.isEnabled() ? html`<mwc-list-item id="listItemSwitch" graphic="icon" noninteractive @click="${()=>{}}">
        <mwc-formfield class="dd-body-1" label="Notifications" alignEnd><mwc-switch class="dense" id="notification-switch" ?checked="${AppFlagProvider.getFlag(APP_FLAGS.SENT_TO_SERVER)}" @change="${this.onNotificationToggleChange_}"></mwc-switch></mwc-formfield>
        <mwc-icon slot="graphic">notifications</mwc-icon>
      </mwc-list-item>` : ""}
      <mwc-list-item graphic="icon" @click="${onFAQListItemClick}">
        <span class="dd-body1">FAQ</span>
        <mwc-icon slot="graphic">help</mwc-icon>
      </mwc-list-item>
      <mwc-list-item graphic="icon" @click="${onFeedbackListItemClick}">
        <span class="dd-body1">Contact Us</span>
        <mwc-icon slot="graphic">markunread</mwc-icon>
      </mwc-list-item>
      <mwc-list-item @click="${onPrivacyPolicyListItemClick}">
        <span class="al-noicon">Privacy Policy</span>
      </mwc-list-item>
      <mwc-list-item @click="${onTermsOfUseListItemClick}">
        <span class="al-noicon">Terms of Use</span>
      </mwc-list-item>
      <mwc-list-item graphic="icon" @click="${onLogoutListItemClick}">
        <span class="dd-body1">Sign Out</span>
        <mwc-icon slot="graphic">logout</mwc-icon>
      </mwc-list-item>
      ${AppFlagProvider.getFlag(APP_FLAGS.DEV_FEATURE)
        ? html`
            <li divider padded role="separator"></li>
            <mwc-list-item @click="${onGetSubscriptionStatusClick}"
              >Get Subscription Status</mwc-list-item
            >
            <mwc-list-item @click="${onSlPrintViewListItemClick}">SL Print View</mwc-list-item>
          `
        : ''}
    `;
  }

  getHeadingTemplate_() {
    return this.largeScreen ? this.getExtendedHeadingTemplate_() : this._heading;
  }

  getExtendedHeadingTemplate_() {
    return html`
      <span>${this._heading}</span><span class="dd-bar-spacing">|</span
      ><span>${this._storeName}</span>
    `;
  }

  getConfirmationDialogTemplate_() {
    return html`
      <mwc-dialog id="action-dialog" @closed="${this.onActionDialogClose_}">
        <div id="action-dialog-content">
          ${this.getActionDialogContentTemplate_()}
        </div>
        <mwc-button id="action-dialog-ok" dialogAction="ok" ?disabled=${true} slot="primaryAction">
          ok
        </mwc-button>
        <mwc-button dialogAction="cancel" slot="secondaryAction">
          cancel
        </mwc-button>
      </mwc-dialog>
    `;
  }

  getSimpleDialogTemplate_() {
    return html`
      <mwc-dialog id="simple-dialog" @closed="${this.onSimpleDialogClose_}">
        <div id="simple-dialog-content">
          ${this.getSimpleDialogContentTemplate_()}
        </div>
      </mwc-dialog>
    `;
  }

  showReloadDialog(reloadCallback) {
    const state = store.getState();
    const silentReloadCase = state.navigation.view === SUBSCRIPTION_VIEW;

    if (silentReloadCase) {
      reloadCallback();
    } else {
      this._reloadOkCallback = reloadCallback;
      this.showConfirmationDialog_({ type: ACTION_DIALOG.RELOAD });
    }
  }

  getDietaryRestrictionsContentTemplate_() {
    return html`
      <dd-dietary-needs
        .dietPlanOptions=${this._dietPlanOptions}
        @dietary-needs-changed="${this.onDietaryRestrictionChanged_}"
      ></dd-dietary-needs>
      <div class="dd-flex-start-container" style="margin-top: 20px">
        ${getIconTemplate('info', false, '', '', 'dd-vegetarian-fg')}
        <span class="dd-caption hint dd-pad-left">If you previously selected Heart Healthy, certain dietary options are not available.  If you wish to remove Heart Healthy, you can do so on the bottom of the Food Preferences screen.</span>
      </div>
    `;
  }

  getStoreListItemTemplates_() {
    const storesInState = StatesStores[this._selectedStateCode];

    if (storesInState) {
      storesInState.sort((storeA, storeB) => {
        if (storeA.internalName < storeB.internalName) {
          return -1;
        }
        if (storeA.internalName > storeB.internalName) {
          return 1;
        }

        return 0;
      });

      return storesInState.map(storeItem => {
        const name = storeItem.alias ? storeItem.alias : storeItem.internalName;

        return html`
          <mwc-list-item value="${storeItem.internalName}">${name}</mwc-list-item>
        `;
      });
    }

    return '';
  }

  getStoreChangeContentTemplate_() {
    const state = store.getState();
    const storeName = state.menu.name;
    const introTemplate = '';
    return html`
      ${introTemplate}
      <div class="dd-content-block">
        <span>From <b>${storeName}</b> to...</span><br /><br />
        <mwc-select
          id="state-select"
          label="State&nbsp;&nbsp;"
          class="dd-full-width"
          outlined
          .value=${this._selectedStateCode}
          @action="${this.onStateAction_}"
          @opened="${this.onStateStoreOpen_}"
        >
          ${getStateListItemTemplates(storeName, this._selectedStateCode)}
        </mwc-select>
      </div>
      <div class="dd-content-block">
        <mwc-select
          id="store-select"
          class="dd-full-width"
          outlined
          label="Store&nbsp;"
          @action="${this.onStoreAction_}"
          @opened="${this.onStateStoreOpen_}"
        >
          ${this.getStoreListItemTemplates_()}
        </mwc-select>
      </div>
      <dd-expandable-content ?clickableTitle=${true} ?isOpen=${false}>
        <span class="dd-overline" slot="title">Don't see your store?</span>
        <div slot="content">
          <span class="dd-body1"
            >You can still choose <b>Any Store</b> and get a personalized menu. You will have all
            the other benefits of membership but your menu won’t be based on weekly store
            specials.</span
          >
        </div>
      </dd-expandable-content>
    `;
  }

  getChangeEmailContentTemplate_() {
    return html`
      <div class="dd-content-block">
      Please enter your new email and press OK to update your email.
        <mwc-textfield id="new-email-text-field" outlined label="New Email"
                      icon="markunread"
                      required
                      maxLength=254
                      type="email"
                      @input="${this.onChangeEmail_}">
        </mwc-textfield>
      </div>
    `;
  }

  onChangeEmail_() {
    const input = this.shadowRoot.querySelector('#new-email-text-field');
    const actionDialogOk = this.shadowRoot.querySelector('#action-dialog-ok');
    actionDialogOk.disabled = !input.validity.valid;
  }

  getChangePasswordContentTemplate_() {
    return html`
      <div class="dd-content-block">
        Please press OK to reset your password. Instructions to reset your password will be sent to your email if you have registered with us.
      </div>
    `;
  }

  getStoreChangeSimpleContentTemplate_() {
    return html`
      <p>This helps us create a weekly menu based on the sales at your selected store!</p>
      <div class="dd-content-block">
        <mwc-select label="State" class="dd-full-width" outlined @action="${this.onStateAction_}">
          ${getStateListItemTemplates()}
        </mwc-select>
      </div>
      <div class="dd-content-block">
        <mwc-select
          id="simple-store-select"
          class="dd-full-width"
          outlined
          label="Store"
          @action="${this.onSimpleDialogAction_}"
        >
          ${this.getStoreListItemTemplates_()}
        </mwc-select>
      </div>
      <dd-expandable-content ?clickableTitle=${true} ?isOpen=${true}>
        <span slot="title">Don't see your store?</span>
        <div slot="content">
          <span
            >You can still choose <b>Any Store</b> and get a personalized menu without weekly store
            specials</span
          >
        </div>
      </dd-expandable-content>
    `;
  }

  getDietPlanOptionsContentTemplate_() {
    const isHeartHealthy = store.getState().dietPlanOptions.heartHealthy;

    return html`
      <dd-food-limits
        include-hh
        .dietPlanOptions=${this._dietPlanOptions}
        @food-limit-total-changed="${this.onFoodLimitTotalChanged_}"
      ></dd-food-limits>
      <div id="heart-healthy-container">
        <mwc-formfield label="I require a Heart Healthy diet">
          <mwc-checkbox
            id="heart-healthy-checkbox"
            .checked="${isHeartHealthy}"
            @change="${this.onHeartHealthyCheckedChanged_}"
          ></mwc-checkbox>
        </mwc-formfield>
      </div>
      <div class="split-section">
        <div class="split-section-child detail-expander-container">
          <dd-expandable-content
            id="heart-healthy-expandable"
            ?clickableTitle=${true}
            ?isOpen=${false}
          >
            <span class="dd-overline" slot="title">details</span>
            <div slot="content">
              <span class="dd-body1"
                >Our Heart Healthy menus are approved by registered dietitians and follow American
                Heart Association guidelines for daily intake of sodium and saturated fats. They do
                not contain red meat and each meal contains less than 800 mg sodium.</span
              >
              <br /><br />
              <span class="dd-body1 italic"
                >Note: These menus do not support all dietary needs, such as gluten-free or reduced
                carb.</span
              >
            </div>
          </dd-expandable-content>
        </div>
        <div class="split-section-child"></div>
      </div>
    `;
  }

  getNewMenuContentTemplate_() {
    const enableNotificationText = 'Do you want us to notify you when your new menu is ready?';
    const disableNotificationText = 'Do you want to stop new menu notifications?';
    const notificationSwitch = this.shadowRoot.querySelector('#notification-switch');
    const contentText = notificationSwitch.checked ? enableNotificationText : disableNotificationText;

    return html`
      <span>${contentText}</span>
    `;
  }

  getKrogerCartContentTemplate_() {
    const locations = store.getState().fulfillment.krogerLocations;
    const location = getSelectedKrogerLocation();

    const width = true;
    if (locations.length) {
      return html`
        <div id="kroger-select-container">
          <mwc-select
            id="kroger-location-select"
            class="dd-full-width"
            outlined
            label="Location"
            .value=${location.id}
            .naturalMenuWidth=${width}
            @action="${this.onLocationSelection_}"
          >
            ${getKrogerStoreListItemTemplates(locations)}
          </mwc-select>
        </div>
        <div>
          ${getStoreAddressTemplate()}
        </div>
        <br /><br />
        <div>
          ${this.getProductListTemplate_()} ${this.getUnmatchedItemsTemplate_()}
        </div>
      `;
    }

    return '';
  }

  getFamilyPlanContentTemplate_() {
    const familySize = store.getState().menu.metadata.familySize;
    const isLarge = familySize === FAMILY_SIZE.full.value;

    return html`
      <div class="dd-flex-container">
        <mwc-formfield label="meals for ${FAMILY_SIZE.full.description}">
          <mwc-radio
            name="family-size"
            id="option-family-size-full"
            ?checked=${isLarge}
            .value=${FAMILY_SIZE.full.value.toString()}
            @change="${this.onFamilySizeRadioChanged_}"
          ></mwc-radio>
        </mwc-formfield>
        <mwc-icon class="onboarding-icon" title="">groups</mwc-icon>
      </div>
      <div class="dd-flex-container">
        <mwc-formfield label="meals for ${FAMILY_SIZE.half.description}">
          <mwc-radio
            name="family-size"
            id="option-family-size-half"
            ?checked=${!isLarge}
            .value=${FAMILY_SIZE.half.value.toString()}
            @change="${this.onFamilySizeRadioChanged_}"
          ></mwc-radio>
        </mwc-formfield>
        <mwc-icon class="onboarding-icon" title="">group</mwc-icon>
      </div>
    `;
  }

  getActionDialogContentTemplate_() {
    switch (this._dialogSelection.type) {
      case ACTION_DIALOG.STORE_CHANGE:
        return this.getStoreChangeContentTemplate_();
      case ACTION_DIALOG.DIET_PLAN_OPTIONS:
        return this.getDietPlanOptionsContentTemplate_();
      case ACTION_DIALOG.DIETARY_RESTRICTIONS:
        return this.getDietaryRestrictionsContentTemplate_();
      case ACTION_DIALOG.NEW_MENU_NOTIFICATION:
        return this.getNewMenuContentTemplate_();
      case ACTION_DIALOG.RELOAD:
        return getReloadContentTemplate();
      case ACTION_DIALOG.KROGER_CART:
        return this.getKrogerCartContentTemplate_();
      case ACTION_DIALOG.DELETE_PERSONAL_RECIPE:
        return getDeletePersonalRecipeContentTemplate();
      case ACTION_DIALOG.GOOGLE_CONNECT:
        return getGoogleConnectContentTemplate();
      case ACTION_DIALOG.FAMILY_PLAN:
        return this.getFamilyPlanContentTemplate_();
      case ACTION_DIALOG.CHANGE_EMAIL:
        return this.getChangeEmailContentTemplate_();
      case ACTION_DIALOG.CHANGE_PASSWORD:
        return this.getChangePasswordContentTemplate_();
      default:
        return '';
    }
  }

  /* eslint class-methods-use-this: "off" */
  /* stub */
  getSimpleDialogContentTemplate_() {
    return '';
  }

  getFreeTrialCtaTemplate_() {
    return this.isFreeTrial
      ? html`
          <mwc-snackbar timeoutMs="9999" ?stacked="${true}" ?open="${true}" labelText="You have ${this._subscription.trialDays} days left in your trial.">
            <mwc-button slot="dismiss">Dismiss</mwc-button>
            <mwc-button  @click="${onFreeTrialCtaClick}" slot="action">Subscribe</mwc-button>
          </mwc-snackbar>
        `
      : '';
  }

  getCartDialogHeading_() {
    const numberItemsToFulfill = this._itemIdsToFulfill.length;
    const numberUnmatchedItems = KrogerProductsProvider.getUnmatchedShoppingItems().length;
    let numberToSend = numberItemsToFulfill - numberUnmatchedItems;
    if (numberToSend < 0) {
      numberToSend = 0;
    }

    return `Add ${numberToSend} of ${numberItemsToFulfill} Items to Your Cart?`;
  }

  getProductListTemplate_() {
    const productMaps = this.getProductMaps_();

    return productMaps.length
      ? productMaps.map(productMap => {
          let price = productMap.price_regular ? productMap.price_regular : '';
          if (price && productMap.price_promo) {
            price = productMap.price_promo;
          }
          const totalPrice = price ? productMap.quantity * price : 0;
          const currency = totalPrice ? `, $${totalPrice.toFixed(2)}` : '';
          const productInfo = `(${productMap.quantity}) ${productMap.size}${currency}`;

          return html`
            <div class="product-listing">
              <span>${productMap.name}</span><br />
              <span>${productInfo}</span>
            </div>
          `;
        })
      : html`
          <div class="dd-warning">
            No products are available from this store at this time. Please try a different location.
          </div>
          <!--div class="dd-warning">Houston, we have a problem! We're working with your grocery store to restore online ordering.</div-->
        `;
  }

  getUnmatchedItemsTemplate_() {
    const itemIdsToFulfill = this._itemIdsToFulfill;
    const unmatchedItems = KrogerProductsProvider.getUnmatchedShoppingItems();
    const itemsNotSent = unmatchedItems.filter(item =>
      itemIdsToFulfill.includes(item.shopping_list_item_id),
    );

    if (itemsNotSent.length) {
      return [
        html`
          <div class="content-subtitle dd-card-mini-primary-title ">
            <span>We could not order</span>
          </div>
        `,
        itemsNotSent.map(
          itemNotSent => html`
            <div class="product-listing">
              <span>${itemNotSent.ingredient_name}</span>
            </div>
          `,
        ),
      ];
    }

    return '';
  }

  clearActionContentHeight_() {
    const actionDialogContent = this.shadowRoot.querySelector('#action-dialog-content');
    actionDialogContent.style.height = null;
  }

  clearSimpleContentHeight_() {
    const actionDialogContent = this.shadowRoot.querySelector('#simple-dialog-content');
    actionDialogContent.style.height = null;
  }

  showConfirmationDialog_(dialogSelection, dynamicHeading = '') {
    this._dialogSelection = dialogSelection;
    const dialog = this.shadowRoot.querySelector('#action-dialog');
    dialog.heading = dynamicHeading || getActionDialogHeading(dialogSelection.type);
    this.requestUpdate();
    // ex-template view management
    const actionDialogContent = this.shadowRoot.querySelector('#action-dialog-content');
    let initialOkDisabled = true;
    const self = this;
    switch (this._dialogSelection.type) {
      case ACTION_DIALOG.STORE_CHANGE:
        setContentHeight(actionDialogContent,this.largeScreen);
        break;
      case ACTION_DIALOG.DIET_PLAN_OPTIONS:
        setTimeout(() => {
          const foodLimits = self.shadowRoot.querySelector('dd-food-limits');
          foodLimits.onShow();
        }, ASYNC_PAUSE);
        break;
      case ACTION_DIALOG.DIETARY_RESTRICTIONS:
        break;
      case ACTION_DIALOG.NEW_MENU_NOTIFICATION:
        initialOkDisabled = false;
        break;
      case ACTION_DIALOG.RELOAD:
        initialOkDisabled = false;
        break;
      case ACTION_DIALOG.KROGER_CART:
        setContentHeight(actionDialogContent,this.largeScreen);
        initialOkDisabled = !this.getProductMaps_().length;
        break;
      case ACTION_DIALOG.DELETE_PERSONAL_RECIPE:
        initialOkDisabled = false;
        break;
      case ACTION_DIALOG.GOOGLE_CONNECT:
        initialOkDisabled = false;
        break;
      case ACTION_DIALOG.CHANGE_EMAIL:
        setContentHeight(actionDialogContent);
        break;
      case ACTION_DIALOG.CHANGE_PASSWORD:
        setContentHeight(actionDialogContent);
        break;
      default:
        break;
    }

    const actionDialogOk = this.shadowRoot.querySelector('#action-dialog-ok');
    actionDialogOk.disabled = initialOkDisabled;
    dialog.show();
    const stateList = this.shadowRoot.querySelector('#state-select')?.shadowRoot.querySelector('mwc-menu')?.shadowRoot.querySelector('mwc-list');
    if (stateList) {
      console.log(stateList);
      stateList.style.maxHeight = this.largeScreen ? "600px" : "300px";
    }
    const storeList = this.shadowRoot.querySelector('#store-select')?.shadowRoot.querySelector('mwc-menu')?.shadowRoot.querySelector('mwc-list');
    if (storeList) {
      storeList.style.maxHeight = this.largeScreen ? "600px" : "300px";
    }
  }

  showSimpleDialog_(dialogSelection, dynamicHeading = '') {
    this._dialogSelection = dialogSelection;
    const dialog = this.shadowRoot.querySelector('#simple-dialog');
    dialog.heading = dynamicHeading || getActionDialogHeading(dialogSelection.type);
    this.requestUpdate();

    /* ex-template view management (currently not used)
		const dialogContent = this.shadowRoot.querySelector('#simple-dialog-content');
		switch(this._dialogSelection.type) {
			default:
				break;
		}
		*/

    dialog.show();
  }

  closeModalDrawer_() {
    const drawer = this.shadowRoot.querySelector('mwc-drawer');

    if (drawer.type === 'modal' && drawer.open) {
      drawer.open = false;
    }
  }

  manageHeartHealthyDependentControls_(hhCheckbox) {
    const heartHealthyExpandable = this.shadowRoot.querySelector('#heart-healthy-expandable');
    heartHealthyExpandable.close();

    const ddFoodLimits = this.shadowRoot.querySelector('dd-food-limits');
    ddFoodLimits.heartHealthy = hhCheckbox.checked;
  }

  onFoodLimitTotalChanged_(event) {
    const appFlags = store.getState().appFlags;
    const dietPlanOptionsOk = this.shadowRoot.querySelector('#action-dialog-ok');

    if (event.detail.validTotal) {
      if (appFlags[APP_FLAGS.NOTIFIED_BELOW_LIMIT]) {
        const action = setFlag(APP_FLAGS.NOTIFIED_BELOW_LIMIT, false);
        store.dispatch(action);
      }

      if (dietPlanOptionsOk) {
        const isChanged = this.areDietPlanOptionsChanged_();
        if (isChanged && !this._notifiedMenuWillchange) {
          this._notifiedMenuWillchange = true;
          this.showSnackBarHint_(MENU_GENERATION_WARNING);
        }
        dietPlanOptionsOk.disabled = !isChanged;
      }
    } else if (!appFlags[APP_FLAGS.NOTIFIED_BELOW_LIMIT]) {
      const action = setFlag(APP_FLAGS.NOTIFIED_BELOW_LIMIT, true);
      store.dispatch(action);

      this.showSnackBarHint_(INVALID_LIMIT_HINT);
      if (dietPlanOptionsOk) {
        dietPlanOptionsOk.disabled = true;
      }
    }
  }

  onHeartHealthyCheckedChanged_(event) {
    this.manageHeartHealthyDependentControls_(event.currentTarget);
    if (event.currentTarget.checked) {
      this.showSnackBarHint_(HH_CHECKED_HINT);
    }

    const ddFoodLimits = this.shadowRoot.querySelector('dd-food-limits');
    const dietPlanOptionsOk = this.shadowRoot.querySelector('#action-dialog-ok');
    dietPlanOptionsOk.disabled = !(
      ddFoodLimits.isValidTotal() && this.areDietPlanOptionsChanged_()
    );
  }

  updateDietaryRestrictionsDialogState_() {
    if (!isEmpty(store.getState().dietPlanOptions)) {
      const dietaryRestrictionsChanged = this.areDietaryRestrictionsChanged_();
      const dietaryRestrictionsOk = this.shadowRoot.querySelector('#action-dialog-ok');
      dietaryRestrictionsOk.disabled = !dietaryRestrictionsChanged;

      if (dietaryRestrictionsChanged && !this._notifiedMenuWillchange) {
        this._notifiedMenuWillchange = true;
        this.showSnackBarHint_(MENU_GENERATION_WARNING);
      }
    }
  }

  areDietPlanOptionsChanged_() {
    const ddFoodLimits = this.shadowRoot.querySelector('dd-food-limits');
    if (ddFoodLimits) {
      const tempDietPlanOptions = ddFoodLimits.dietPlanOptions;
      let areChanged = !isEqual(this._dietPlanOptions, tempDietPlanOptions);

      const isHeartHealthy = store.getState().dietPlanOptions.heartHealthy;
      const hhCheckbox = this.shadowRoot.querySelector('#heart-healthy-checkbox');
      areChanged ||= isHeartHealthy !== hhCheckbox.checked;

      return areChanged;
    }

    // called during onboarding
    return false;
  }

  // TODO
  areDietaryRestrictionsChanged_() {
    const ddDietaryNeeds = this.shadowRoot.querySelector('dd-dietary-needs');
    const tempDietPlanOptions = ddDietaryNeeds.dietPlanOptions;
    const areChanged = !isEqual(this._dietPlanOptions, tempDietPlanOptions);

    return areChanged;
  }

  resetStoreChange_() {
    const storeSelect = this.shadowRoot.querySelector('#store-select');
    if (storeSelect) {
      storeSelect.value = '';
      this.validateStoreChangeOk_('');
    }
  }

  validateFamilySizeChangeOk_(newFamilySize) {
    const oldFamilySize = store.getState().menu.metadata.familySize;
    this.validateDialogChangeOk_(oldFamilySize, newFamilySize);
  }

  validateStoreChangeOk_(newStoreName) {
    const oldStoreName = store.getState().menu.name;
    const isChanged = this.validateDialogChangeOk_(oldStoreName, newStoreName);
		if (isChanged && !this._notifiedMenuWillchange) {
			this._notifiedMenuWillchange = true;
			this.showSnackBarHint_(MENU_GENERATION_WARNING);
		}
  }

  validateDialogChangeOk_(oldValue, newValue) {
    const notChanged = oldValue === newValue;

    const actionDialogOk = this.shadowRoot.querySelector('#action-dialog-ok');
    actionDialogOk.disabled = !newValue || notChanged;

    return !notChanged;
  }

  validateDialogTextOk_(textField) {
    const actionDialogOk = this.shadowRoot.querySelector('#action-dialog-ok');
    actionDialogOk.disabled = !textField.reportValidity();
  }

  resetDietPlanOptions_() {
    const isHeartHealthy = store.getState().dietPlanOptions.heartHealthy;
    const hhCheckbox = this.shadowRoot.querySelector('#heart-healthy-checkbox');
    hhCheckbox.checked = isHeartHealthy;
    this.manageHeartHealthyDependentControls_(hhCheckbox);
    const ddFoodLimits = this.shadowRoot.querySelector('dd-food-limits');
    ddFoodLimits.reset();
  }

  resetDietaryRestrictions_() {
    const ddDietaryNeeds = this.shadowRoot.querySelector('dd-dietary-needs');
    ddDietaryNeeds.reset();
  }

  createDietPlanOptionsDTO_() {
    // ref AppBundle\Controller\Rest\DTO\DietPlanOptions::DTO_NAME
    const optionSliderDto = this.getFoodPrefsDto_();
    const dietaryNeedsDto = this.getDietaryNeedsDto_();

    const dto = {
      options: {
        ...optionSliderDto,
        ...dietaryNeedsDto,
      },
    };

    return dto;
  }

  getFoodPrefsDto_() {
    const ddFoodLimits = this.shadowRoot.querySelector('dd-food-limits');
    const options = ddFoodLimits ? ddFoodLimits.dietPlanOptionsDto : {};

    const hhCheckbox = this.shadowRoot.querySelector('#heart-healthy-checkbox');
    if (hhCheckbox) {
      options.heartHealthy = hhCheckbox.checked;
    }

    return options;
  }

  getDietaryNeedsDto_() {
    const ddDietaryNeeds = this.shadowRoot.querySelector('dd-dietary-needs');
    const options = ddDietaryNeeds ? ddDietaryNeeds.dietPlanOptionsDto : {};

    return options;
  }

  getSelectOptionValues_() {
    const options = {};
    const selectOptions = this.shadowRoot.querySelectorAll('#action-dialog dd-select-option');

    selectOptions.forEach(selectOption => {
      switch (selectOption.option.id) {
        case DIETARY_RESTRICTION.DAIRY_FREE:
          options.dairyFree = selectOption.getCurrentValue();
          break;
        case DIETARY_RESTRICTION.GLUTEN_FREE:
          options.glutenFree = selectOption.getCurrentValue();
          break;
        case DIETARY_RESTRICTION.PEANUT_FREE:
          options.peanutFree = selectOption.getCurrentValue();
          break;
        case DIETARY_RESTRICTION.TREE_NUT_FREE:
          options.treeNutFree = selectOption.getCurrentValue();
          break;
        case DIETARY_RESTRICTION.REDUCED_CARB:
          options.reducedCarb = selectOption.getCurrentValue();
          break;
        case DIETARY_RESTRICTION.SOY_FREE:
          options.soyFree = selectOption.getCurrentValue();
          break;
        case DIETARY_RESTRICTION.EGG_FREE:
          options.eggFree = selectOption.getCurrentValue();
          break;
        case DIETARY_RESTRICTION.STICK_TO_BASICS:
          options.stickToBasics = selectOption.getCurrentValue();
          break;
        default:
          break;
      }
    });

    return options;
  }

  getProductMaps_() {
    const productMaps = [];

    this._itemIdsToFulfill.forEach(itemId => {
      const product = KrogerProductsProvider.getDefaultProduct(itemId);
      if (product) {
        productMaps.push(product);
      }
    });

    return productMaps;
  }

  backgroundGetMenu_() {
    const isMenuView = store.getState().navigation.view === 'MENU_VIEW';
    const previousWeek = AppFlagProvider.getFlag(APP_FLAGS.PREVIOUS_WEEK);
    if (isMenuView && !this._timerId && !store.getState().api.isFetching) {
      store.dispatch(requestMenu(false, previousWeek));
    }
  }

  stateChanged(state) {
    this._notifiedMenuWillchange = false;

    // set services and trigger rendering of dd-app
    this._dietPlanOptions = state.dietPlanOptions;
    this._features = state.features;
    this._metadata = state.menu ? state.menu.metadata : null;
    this._subscription = state.subscription;
    const userId = this._metadata ? this._metadata.id : 0;

    AppFeatureLevelProvider.setSubscription(state.subscription);
    AppFlagProvider.setFlags(state.appFlags, userId);
    if (state.menu.dayMenus.length) {
      DayMenuColorProvider.setDayMenus(state.menu.dayMenus);
    }
    FeatureProvider.setFlags(state.features);
    Instrumentation.identifyUser(userId);
    FulfillmentAccountProvider.setStatus(state.fulfillment.accountStatus);
    this.watchForMissingMenu_(state);
    this.watchForValidMenu_(state);

    const foodLimitsComponent = this.shadowRoot.querySelector('dd-food-limits');
    if (foodLimitsComponent) {
      foodLimitsComponent.dietPlanOptions = state.dietPlanOptions;
      foodLimitsComponent.onShow();
    }

    const onboardingComponent = this.shadowRoot.querySelector('dd-onboarding');
    if (!isOnboardingCompleted() && onboardingComponent) {
      onboardingComponent.dietPlanOptions = state.dietPlanOptions;
      onboardingComponent.store = state.menu.name;
      onboardingComponent.weekMenu = state.menu;
    }

    // when product data received, update properties to trigger render()
    if (state.fulfillment.krogerProducts.length) {
      KrogerProductsProvider.setProductShoppingItemMaps(state.fulfillment.krogerProducts);
      this._itemIdsToFulfill = this.getItemIdsToFulfill_();
    }

    if (state.ingredients.ingredients.length) {
      IngredientsProvider.setData(state.ingredients);
    }

    const homeComponent = this.shadowRoot.querySelector('dd-home');
    if (homeComponent) {
      if (state.menu.metadata) {
        homeComponent.familySize = state.menu.metadata.familySize;
      }

      homeComponent.previousWeek = state.appFlags.previousWeek;
      homeComponent.menuItems = state.menu.dayMenus;
      homeComponent.news = state.news;
      homeComponent.store = getSanitizedStoreName(state.menu.name);
      homeComponent.saleCount = getSaleCount(state.shoppingList);
      homeComponent.showWeekSelect = !state.menu.metadata.isNewUser;

      const produceCount = getProduceCount(
        state.shoppingList,
        FrozenIngredients,
        ProduceIngredients,
      );
      homeComponent.produceCount = produceCount;
    }

    const menuComponent = this.shadowRoot.querySelector('dd-menu-2');
    const weekMenuPrintComponent = this.shadowRoot.querySelector('dd-week-menu-print');
    if (menuComponent && weekMenuPrintComponent && state.menu.startDate) {
      menuComponent.previousWeek = state.appFlags.previousWeek;
      menuComponent.weekMenu = state.menu;
      weekMenuPrintComponent.weekMenu = state.menu;
      menuComponent.searchResults = state.search.results;
      FavoritesProvider.setOptions(state.favorites);
      PersonalRecipesProvider.setPersonalRecipes(state.personalRecipes);
      SideRecipeOptionsProvider.setOptions(state.menu.sideRecipeOptions);
      this._heading = toDateRange(state.menu.startDate, state.menu.endDate);
      this._storeName = getSanitizedStoreName(state.menu.name);
    }

    const selectorComponent = this.shadowRoot.querySelector('#meals-selector');
    const slComponent = this.shadowRoot.querySelector('dd-shopping-list-2');
    const slPrintComponent = this.shadowRoot.querySelector('dd-week-sl-print');
    const dayMenusExist = state.menu && state.menu.dayMenus && state.menu.dayMenus.length;
    if (
      dayMenusExist &&
      state.shoppingList.id &&
      slComponent &&
      selectorComponent &&
      slPrintComponent
    ) {
      RecipeShopItemMapProvider.setMap(state.recipeShopItems.map);
      slComponent.shoppingList = state.shoppingList;
      slPrintComponent.shoppingList = state.shoppingList;
      slPrintComponent.familySize = state.menu.metadata.familySize;

      // sort switch setting is default true if not in state
      // const sortSet = !(SORT_SWITCH_ID in state.uiSettings) || state.uiSettings.sortSwitchId;
      const sortSet = true; // TODO: remove hard setting when re-enabling sort switch

      slComponent.sortSimilar = sortSet;
      selectorComponent.sortEnabled = sortSet;
      selectorComponent.options = createSelectOptions(state.menu.dayMenus);
      if (!this.largeScreen) {
        selectorComponent.storeName = this._storeName;
      }

      slComponent.asyncUpdateActive();
    }

    const personalRecipesComponent = this.shadowRoot.querySelector('dd-personal-recipes-2');
    if (personalRecipesComponent) {
      personalRecipesComponent.mains = state.personalRecipes.mains;
    }

    const apiSnackbar = this.shadowRoot.querySelector('dd-api-snackbar');
    if (apiSnackbar) {
      apiSnackbar.message = state.api.message;
      apiSnackbar.isFetching = state.api.isFetching;
    }

    const apiProgress = this.shadowRoot.querySelector('dd-api-progress');
    if (apiProgress) {
      apiProgress.isFetching = state.api.isFetching;
    }

    this.setTaskView_(state.navigation);
  }

  showNoticeDialog(heading, content) {
    const noticeDialog = this.shadowRoot.querySelector('#notice-dialog');
    noticeDialog.heading = heading;
    const noticeContent = this.shadowRoot.querySelector('#notice-content');
    noticeContent.innerHTML = content;
    noticeDialog.show();
  }

  showIncognitoNoticeDialog_() {
    this.showNoticeDialog(INCOGNITO_HEADING, INCOGNITO_CONTENT);
  }

  setTaskView_(navigationState) {
    // only call on view change to prevent recursion of calls when same view but other substate changed
    const isViewChanged = this._instantView !== navigationState.view;
    if (isViewChanged) {
      const iconTray = this.shadowRoot.querySelector('dd-icon-tray');
      if (iconTray) {
        this._instantView = navigationState.view;
        if (
          navigationState.view === SL_PRINT_VIEW ||
          navigationState.view === WEEK_PRINT_VIEW ||
          navigationState.view === ONBOARDING_VIEW
        ) {
          this.changeTaskView_(navigationState);
        } else {
          this.changeTabWithAnimation_(navigationState);
        }
      }
    }

    // start onboarding whenever in home view and never run or rerun was triggered
    if (this._view === HOME_VIEW && !isOnboardingCompleted()) {
      setTimeout(() => {
        store.dispatch(navigate(ONBOARDING_VIEW));
      }, ASYNC_PAUSE);
    }
  }

  changeTaskView_(navigationState) {
    this._view = navigationState.view;
    const topAppBar = this.shadowRoot.querySelector('#top-app-bar-container');
    const taskView = this.shadowRoot.querySelector('#task-view');
    const iconTray = this.shadowRoot.querySelector('dd-icon-tray');
    const isPreviousWeek = AppFlagProvider.getFlag(APP_FLAGS.PREVIOUS_WEEK);
    let iframe = {};

    switch (this._view) {
      case HOME_VIEW:
        topAppBar.classList.replace('dd_hide', 'dd_show');
        taskView.style.display = 'block';
        window.scrollTo(0, 0);
        if (this.largeScreen) {
          const primaryNavList = this.shadowRoot.querySelector('#primary-nav-list');
          primaryNavList.select(0);
        } else {
          iconTray.select(ICON_TRAY.HOME_ID);
        }

        store.dispatch([
          requestMenu(false, isPreviousWeek),
          requestShoppingList(isPreviousWeek),
          requestFeatured(),
          requestMemberNews(),
          requestQuote(),
          requestWeeklyTip(),
        ]);
        break;

      case MENU_VIEW:
        topAppBar.classList.replace('dd_hide', 'dd_show');
        manageFirstVisitAppFlag();
        taskView.style.display = 'block';
        if (this.largeScreen) {
          const primaryNavList = this.shadowRoot.querySelector('#primary-nav-list');
          primaryNavList.select(1);
        } else {
          iconTray.select(ICON_TRAY.MENU_ID);
        }

        this.stopMonitoring_();
        store.dispatch([
          requestFeatures(),
          requestMenu(false, isPreviousWeek),
          requestFavorites(),
          requestFulfillmentAccounts(),
          requestPersonalRecipes(),
        ]);

        if (navigationState.destination) {
          // expand one or more days and scroll to top or card
          if (navigationState.destination === ALL_DAYS_ID) {
            window.scrollTo(0, 0);
          }

          const ddMenu = this.shadowRoot.querySelector('dd-menu-2');
          setTimeout(() => {
            ddMenu.select(navigationState.destination);
          }, ASYNC_PAUSE);
        } else {
          window.scrollTo(0, 0);
        }
        break;

      case SL_VIEW:
        topAppBar.classList.replace('dd_hide', 'dd_show');
        taskView.style.display = 'block';
        window.scrollTo(0, 0);
        if (this.largeScreen) {
          const primaryNavList = this.shadowRoot.querySelector('#primary-nav-list');
          primaryNavList.select(2);
        } else {
          iconTray.select(ICON_TRAY.SHOPPING_LIST_ID);
        }

        store.dispatch([
          requestFeatures(),
          requestShoppingList(isPreviousWeek),
          requestFulfillmentAccounts(),
        ]);

        setTimeout(() => {
          tryGetKrogerLocations();
        }, getApiSequencePause());
        break;

      case RECIPES_VIEW:
        topAppBar.classList.replace('dd_hide', 'dd_show');
        taskView.style.display = 'block';
        window.scrollTo(0, 0);
        if (this.largeScreen) {
          const primaryNavList = this.shadowRoot.querySelector('#primary-nav-list');
          primaryNavList.select(3);
        } else {
          iconTray.select(ICON_TRAY.MY_RECIPES_ID);
        }

        store.dispatch([requestPersonalRecipes(), requestIngredients()]);
        break;

      case WEB_VIEW:
        topAppBar.classList.replace('dd_show', 'dd_hide');
        this._webViewUrl = navigationState.destination
          ? getTimestampedUrl(navigationState.destination, Date.now())
          : '';
        taskView.style.display = 'none';
        window.scrollTo(0, 0);
        iframe = this.shadowRoot.querySelector('#iframe-container');
        iframe.title = navigationState.title;
        break;
      case ONBOARDING_VIEW:
        topAppBar.classList.replace('dd_show', 'dd_hide');
        taskView.style.display = 'none';
        store.dispatch(requestDietPlanOptions());
        break;
      case SUBSCRIPTION_VIEW:
        topAppBar.classList.replace('dd_show', 'dd_hide');
        taskView.style.display = 'none';
        break;

      default:
        taskView.style.display = 'none';
        break;
    }

    Instrumentation.getAnalytics().track(`${ANALYTICS.NAVIGATION}: ${this._view}`);
  }

  manageVisitedClassicAppFlag_(pageName) {
    if (!AppFlagProvider.getFlag(APP_FLAGS.VISITED_CLASSIC)) {
      const dialogHeading = `You're visiting ${pageName}`;
      this.showNoticeDialog(dialogHeading, getLegacyNoticeContent(pageName));
      const action = setFlag(APP_FLAGS.VISITED_CLASSIC, true);
      store.dispatch(action);
    }
  }

  getItemIdsToFulfill_() {
    const ddShoppingList = this.shadowRoot.querySelector('dd-shopping-list-2');
    return ddShoppingList ? ddShoppingList.getItemIdsToFulfill() : [];
  }

  hasCheckedItems_() {
    const ddShoppingList = this.shadowRoot.querySelector('dd-shopping-list-2');
    return ddShoppingList && ddShoppingList.hascheckedItems();
  }

  showKrogerCartDialog_() {
    const heading = this.getCartDialogHeading_();
    this.showConfirmationDialog_({ type: ACTION_DIALOG.KROGER_CART }, heading);
  }

  /**
   * if menu substate has been hydrated but doesn't exist and create not triggered, create new and start monitoring
   * @param {*} state
   */
  watchForMissingMenu_(state) {
    if (isOnboardingCompleted() && isMenuMissing(state.menu)) {
      const currentTime = Date.now();
      let requestCount = AppFlagProvider.getFlag(APP_FLAGS.MENU_REQUEST_COUNT);
      const waitPeriod = getWaitPeriod(requestCount);
      requestCount += 1;

      const menuGenerationPeriodExpired =
        currentTime - AppFlagProvider.getFlag(APP_FLAGS.CREATE_MENU_TIMESTAMP) > waitPeriod;

      // async to allow initial CREATE_MENU_TIMESTAMP to be set first and avoid state change recursion
      const startMenuMonitoring = this.startMenuMonitoring_.bind(this);
      setTimeout(() => {
        startMenuMonitoring();

        if (menuGenerationPeriodExpired) {
          const hideHint = !AppFlagProvider.getFlag(APP_FLAGS.ONBOARDING_COMPLETED);
          store.dispatch([
            setFlag(APP_FLAGS.MENU_REQUEST_COUNT, requestCount),
            setFlag(APP_FLAGS.CREATE_MENU_TIMESTAMP, currentTime),
            requestCreateMenu(hideHint),
          ]);
        }
      }, ASYNC_PAUSE);
    }
  }

  /**
   * if menu changed and is valid, stop monitoring
   * @param {*} state
   */
  watchForValidMenu_(state) {
    const oldMenuId = AppFlagProvider.getFlag(APP_FLAGS.MENU_ID);
    const validNewMenuReceived = state.menu.id && state.menu.id !== oldMenuId;

    if (validNewMenuReceived) {
      // async to allow MENU_ID to be set first and avoid state change recursion
      const stopMenuMonitoring = this.stopMonitoring_.bind(this);

      setTimeout(() => {
        const action = setFlag(APP_FLAGS.MENU_ID, state.menu.id);
        store.dispatch(action);
        const hideHint = !AppFlagProvider.getFlag(APP_FLAGS.ONBOARDING_COMPLETED);
        stopMenuMonitoring(hideHint);
      }, ASYNC_PAUSE);
    }
  }

  startFulfillmentMonitoring_() {
    this._timerId = setInterval(() => {
      store.dispatch(requestFulfillmentAccounts());
    }, getMonitorRequestInterval());
  }

  startMenuMonitoring_() {
    if (!this._timerId) {
      const currentTime = Date.now();
      store.dispatch([
        setFlag(APP_FLAGS.MENU_REQUEST_COUNT, 0),
        setFlag(APP_FLAGS.CREATE_MENU_TIMESTAMP, currentTime),
      ]);

      this._timerId = setInterval(() => {
        store.dispatch([requestMenu(true, false), requestShoppingList(false)]);
      }, getMonitorRequestInterval());
      this.disableMenuChangeControls_();
    }
  }

  stopMonitoring_(hideHint = false) {
    if (this._timerId) {
      window.clearInterval(this._timerId);
      this._timerId = 0;
      store.dispatch(requestFulfillmentAccounts());
      const hint = hideHint ? '' : NEW_MENU_HINT;
      this.showSnackBarHint_(hint);
      this.enableMenuChangeControls_();
    }
  }

  /**
   * transition effects
   */
  changeTabWithAnimation_(navigationState) {
    this.addTabChangeFade_();

    const changeTaskView = this.changeTaskView_.bind(this);
    const fadeInDurationMs = 150;
    setTimeout(() => {
      changeTaskView(navigationState);
    }, fadeInDurationMs);

    const remove = this.removeTabChangeFade_.bind(this);
    setTimeout(() => {
      remove();
    }, 2 * fadeInDurationMs);
  }

  addTabChangeFade_() {
    const tabScrim = this.shadowRoot.querySelector('#tab-scrim');
    if (tabScrim) {
      tabScrim.classList.add('activate-scrim', 'tab-fade-in-out');
    }
  }

  removeTabChangeFade_() {
    const tabScrim = this.shadowRoot.querySelector('#tab-scrim');
    if (tabScrim) {
      tabScrim.classList.remove('activate-scrim', 'tab-fade-in-out');
    }
  }

  /**
   * on-boarding
   */
  onboardingSetDietPlanOptions_(event) {
    const menu = store.getState().menu;
    const dto = { options: event.detail.dto };

    store.dispatch([
      requestUpdateDietPlanOptions(dto, true),
      setFlag(APP_FLAGS.PREVIOUS_WEEK, false),
      setFlag(APP_FLAGS.MENU_ID, menu.id),
    ]);
    this.startMenuMonitoring_();
  }

  showSnackBarHint_(hint) {
    if (hint) {
      const snackBar = this.shadowRoot.querySelector('dd-api-snackbar');
      snackBar.openSnackBar(hint);
    }
  }

  disableMenuChangeControls_() {
    const ddMenu = this.shadowRoot.querySelector('dd-menu-2');
    ddMenu.disableMenuChangeControls();
    const iconTray = this.shadowRoot.querySelector('dd-icon-tray');
    iconTray.disableOption(ICON_TRAY.SHOPPING_LIST_ID);
    this.setNavigationControls_(false);
  }

  enableMenuChangeControls_() {
    const ddMenu = this.shadowRoot.querySelector('dd-menu-2');
    ddMenu.enableMenuChangeControls();
    const iconTray = this.shadowRoot.querySelector('dd-icon-tray');
    iconTray.enableOption(ICON_TRAY.SHOPPING_LIST_ID);
    this.setNavigationControls_(true);
  }

  setNavigationControls_(enable) {
    if (!this.largeScreen) {
      const navMenuItems = this.shadowRoot.querySelectorAll('#nav-menu mwc-list-item');
      navMenuItems.forEach(navMenuItem => {
        navMenuItem.disabled = !enable;
      });
    }
  }

  onLocationsSet_() {
    if (store.getState().fulfillment.krogerLocations.length) {
      const location = getSelectedKrogerLocation();
      store.dispatch(setFlag(APP_FLAGS.NOTIFIED_NO_STORES, false));

      if (this.hasCheckedItems_() && !KrogerProductsProvider.hasProducts()) {
        if (AppFlagProvider.isDebug()) {
          console.log('onLocationsSet_: has checked items');
        }
        const shoppingListId = store.getState().shoppingList.id;
        store.dispatch(requestFulfillmentKrogerProducts(location.id, shoppingListId));
      }
    } else if (!AppFlagProvider.getFlag(APP_FLAGS.NOTIFIED_NO_STORES)) {
      this.showNoticeDialog(NO_LOCATIONS_HEADING, NO_LOCATIONS_CONTENT);
      store.dispatch(setFlag(APP_FLAGS.NOTIFIED_NO_STORES, true));
    }
  }

  onRequestCancelled_() {
    this.stopMonitoring_(true);
  }

  onLocationSelection_(event) {
    const locationId = event.target.value;
    saveKrogerLocationId(locationId);
    this.requestUpdate();

    if (this.hasCheckedItems_()) {
      if (AppFlagProvider.isDebug()) {
        console.log('onLocationSelection_: has checked items');
      }

      const shoppingListId = store.getState().shoppingList.id;
      store.dispatch(requestFulfillmentKrogerProducts(locationId, shoppingListId));
    }
  }

  onNotificationToggleChange_() {
    const dynamicHeading = this.shadowRoot.querySelector('#notification-switch').checked ? 'Enable Notifications' : 'Disable Notifications';

    this.showConfirmationDialog_({ type: ACTION_DIALOG.NEW_MENU_NOTIFICATION }, dynamicHeading);
  }

  handleFamilyPlanDialogClose_() {
    const familySize = this.shadowRoot.querySelector('#option-family-size-full').checked
      ? FAMILY_SIZE.full.value
      : FAMILY_SIZE.half.value;
    const event = {
      detail: {
        familySize,
      },
    };
    onChangeFamilySize(event);
  }

  handleFamilyPlanSimpleDialogClose_() {
    const familySize = this.shadowRoot.querySelector('#option-simple-family-size-full').checked
      ? FAMILY_SIZE.full.value
      : FAMILY_SIZE.half.value;
    const oldFamilySize = store.getState().menu.metadata.familySize;
    if (familySize !== oldFamilySize) {
      const event = {
        detail: {
          familySize,
        },
      };
      onChangeFamilySize(event);
    }
  }

  handleChangeEmailDialogClose_() {
    const newEmail = this.shadowRoot.querySelector('#new-email-text-field').value;

    // console.log(`handleChangeEmailDialogClose_: newEmail=${  newEmail}`);
    // console.log(`handleChangeEmailDialogClose_: userManagement.isAuth0User=${  userManagement.isAuth0User}`);
    // console.log(`handleChangeEmailDialogClose_: userManagement.auth0UserEmail=${  userManagement.auth0UserEmail}`);

    const event = {
      detail: {
        currentEmail: userManagement.auth0UserEmail,
        newEmail
      }
    }

    onChangeEmail(event);
  }

  handlePersonalRecipeDialogClose_() {
    const nameField = this.shadowRoot.querySelector('#recipe-dialog-name-field');
    const action = requestCreatePersonalRecipe(nameField.value, DISH_TYPE_MAIN);
    store.dispatch(action);
  }

  handleStoreChangeDialogClose_() {
    const menu = store.getState().menu;
    const simpleStoreSelect = this.shadowRoot.querySelector('#simple-store-select');
    const storeInternalName = simpleStoreSelect
      ? simpleStoreSelect.value
      : this.shadowRoot.querySelector('#store-select').value;
    this._selectInternalStoreName = storeInternalName;

    if (isOnboardingCompleted()) {
      Instrumentation.getAnalytics().track(`${ANALYTICS.PREFS}: Change store`);
      store.dispatch([
        requestChangeStore(storeInternalName),
        setFlag(APP_FLAGS.PREVIOUS_WEEK, false),
        setFlag(APP_FLAGS.MENU_ID, menu.id),
      ]);
      this.startMenuMonitoring_();
    }
  }

  handleDietPlanOptionsDialogClose_() {
    if (this.areDietPlanOptionsChanged_()) {
      const dto = this.createDietPlanOptionsDTO_();
      const menuId = store.getState().menu.id;

      if (isOnboardingCompleted()) {
        store.dispatch([
          requestUpdateDietPlanOptions(dto),
          setFlag(APP_FLAGS.PREVIOUS_WEEK, false),
          setFlag(APP_FLAGS.MENU_ID, menuId),
        ]);

        Instrumentation.getAnalytics().track(`${ANALYTICS.PREFS}: Change food preferences`);
        this.startMenuMonitoring_();
      }
    }

    this.resetDietPlanOptions_();
  }

  handleDietaryRestrictionsDialogClose_() {
    if (this.areDietaryRestrictionsChanged_()) {
      const dto = this.createDietPlanOptionsDTO_();
      const menuId = store.getState().menu.id;

      if (isOnboardingCompleted()) {
        store.dispatch([
          requestUpdateDietPlanOptions(dto),
          setFlag(APP_FLAGS.PREVIOUS_WEEK, false),
          setFlag(APP_FLAGS.MENU_ID, menuId),
        ]);

        Instrumentation.getAnalytics().track(`${ANALYTICS.PREFS}: Change dietary restrictions`);
        this.startMenuMonitoring_();
      }
    }

    this.resetDietaryRestrictions_();
  }

  handleNewMenuDialogClose_() {
    const notificationSwitch = this.shadowRoot.querySelector('#notification-switch');

    if (notificationSwitch.checked) {
      Notification.requestPermission(status => {
        if (status === 'granted') {
          NotificationService.getToken(store.dispatch);
        } else {
          notificationToggle.on = false;
        }
      });
    } else {
      // immediately hide notification toggle
      notificationSwitch.checked = false;
      NotificationService.removeToken(store.dispatch);
      this.requestUpdate();
    }
  }

  handleReloadDialogClose_() {
    if (this._reloadOkCallback) {
      this._reloadOkCallback();
    }
  }

  handleKrogerCartDialogClose_() {
    const shoppingListId = store.getState().shoppingList.id;
    const productMaps = this.getProductMaps_();
    const dtos = productMaps.map(productMap => ({
      shoppingListItemId: productMap.shoppingListItemId,
      productId: productMap.id,
      size: productMap.size,
      quantity: productMap.quantity,
    }));

    const selectedLocation = getSelectedKrogerLocation();
    store.dispatch(
      requestKrogerFulfillment(
        AppFlagProvider.getFlag(APP_FLAGS.PREVIOUS_WEEK),
        shoppingListId,
        selectedLocation.id,
        dtos,
        selectedLocation.name,
      ),
    );

    Instrumentation.getAnalytics().track(`${ANALYTICS.SHOPPING}: Send to Kroger`);
  }

  handleDeletePersonalRecipeDialogClose_() {
    store.dispatch(this._dialogSelection.action);
  }

  handleGoogleConnectDialogClose_() {
    if (FulfillmentAccountProvider.isGoogleAuthorized()) {
      sendToCalendar();
    } else {
      const event = {
        detail: {
          service: OAUTH_SERVICE.GOOGLE,
        },
      };

      this.onOauthConnect_(event);
    }
  }

  onTokenSet_() {
    this._userManagementInitialized = true;
  }

  onCalendarClick_() {
    if (FulfillmentAccountProvider.isGoogleAuthorized()) {
      sendToCalendar();
    } else {
      this.showConfirmationDialog_({ type: ACTION_DIALOG.GOOGLE_CONNECT });
    }
  }

  onOauthConnected_() {
    this.stopMonitoring_(true);
    const hint = OAUTH_DIALOG_TEXT[this._accountConnecting].connectedHint;
    this.showSnackBarHint_(hint);

    if (this._accountConnecting === OAUTH_SERVICE.KROGER) {
      tryGetKrogerLocations();
    }

    this._accountConnecting = OAUTH_SERVICE.NONE;
  }

  resetNewMenuNotification_() {
    const notificationSwitch = this.shadowRoot.querySelector('#notification-switch');
    notificationSwitch.checked = !notificationSwitch.checked;
  }

  onActionDialogClose_(event) {
    const isNotDialogChildEvent = event.currentTarget === event.target;
    if (isNotDialogChildEvent) {
      const closedDialogSelectionType = this._dialogSelection.type;
      this._dialogSelection.type = ACTION_DIALOG.NONE; // so requestUpdate won't render dialog upon closing

      if (event.detail.action === 'ok') {
        switch (closedDialogSelectionType) {
          case ACTION_DIALOG.STORE_CHANGE:
            this.handleStoreChangeDialogClose_();
            break;
          case ACTION_DIALOG.DIET_PLAN_OPTIONS:
            this.handleDietPlanOptionsDialogClose_();
            break;
          case ACTION_DIALOG.DIETARY_RESTRICTIONS:
            this.handleDietaryRestrictionsDialogClose_();
            break;
          case ACTION_DIALOG.NEW_MENU_NOTIFICATION:
            this.handleNewMenuDialogClose_();
            break;
          case ACTION_DIALOG.RELOAD:
            this.handleReloadDialogClose_();
            break;
          case ACTION_DIALOG.KROGER_CART:
            this.handleKrogerCartDialogClose_();
            break;
          case ACTION_DIALOG.DELETE_PERSONAL_RECIPE:
            this.handleDeletePersonalRecipeDialogClose_();
            break;
          case ACTION_DIALOG.GOOGLE_CONNECT:
            this.handleGoogleConnectDialogClose_();
            break;
          case ACTION_DIALOG.FAMILY_PLAN:
            this.handleFamilyPlanDialogClose_();
            break;
          case ACTION_DIALOG.CHANGE_EMAIL:
            this.handleChangeEmailDialogClose_();
            break;
          case ACTION_DIALOG.CHANGE_PASSWORD:
            // TODO: this.handleChangePasswordDialogClose_();
            break;
          default:
            break;
        }
      } else {
        switch (closedDialogSelectionType) {
          case ACTION_DIALOG.STORE_CHANGE:
            this.resetStoreChange_();
            this.validateStoreChangeOk_(store.getState().menu.name);
            break;
          case ACTION_DIALOG.DIET_PLAN_OPTIONS:
            this.resetDietPlanOptions_();
            break;
          case ACTION_DIALOG.DIETARY_RESTRICTIONS:
            this.resetDietaryRestrictions_();
            this.updateDietaryRestrictionsDialogState_();
            break;
          case ACTION_DIALOG.NEW_MENU_NOTIFICATION:
            this.resetNewMenuNotification_();
            break;
          case ACTION_DIALOG.CHANGE_EMAIL:
            break;
          case ACTION_DIALOG.CHANGE_PASSWORD:
            break;
          default:
            break;
        }
      }

      this.clearActionContentHeight_();
    }
  }

  onSimpleDialogClose_(event) {
    const isNotDialogChildEvent = event.currentTarget === event.target;
    if (isNotDialogChildEvent) {
      const closedDialogSelectionType = this._dialogSelection.type;
      this._dialogSelection.type = ACTION_DIALOG.NONE; // so requestUpdate won't render dialog upon closing

      switch (closedDialogSelectionType) {
        default:
          break;
      }

      this.clearSimpleContentHeight_();
    }
  }

  onPrintMenuClick_() {
    if (this.largeScreen || MOBILE_PRINT) {
      store.dispatch(navigate(WEEK_PRINT_VIEW));
      setTimeout(() => {
        window.print();
      }, ASYNC_PAUSE);
    } else {
      this.showNoticeDialog(MENU_PRINT_NA_HEADING, MENU_PRINT_NA_CONTENT);
    }
  }

  onPrintSlClick_() {
    // selectorComponent = this.shadowRoot.querySelector('#meals-selector');
    // activeDayMenus = selectorComponent.getActive();

    if (SL_PRINT || AppFlagProvider.getFlag(APP_FLAGS.DEV_FEATURE)) {
      const selectOptions = createSelectOptions(store.getState().menu.dayMenus);

      const onlyActiveMeals = [];
      for (let t = 0; t < selectOptions.length; t += 1) {
        if (selectOptions[t].active) {
          onlyActiveMeals.push(selectOptions[t]);
        }
      }

      const slPrintComponent = this.shadowRoot.querySelector('dd-week-sl-print');
      slPrintComponent.activeDayMenus = onlyActiveMeals;

      store.dispatch(navigate(SL_PRINT_VIEW));
      setTimeout(() => {
        window.print();
      }, ASYNC_PAUSE);
    } else {
      this.showNoticeDialog(SL_PRINT_NA_HEADING, SL_PRINT_NA_CONTENT);
    }
  }

  triggerNavSelect_(navId) {
    const event = {
      detail: { buttonId: navId },
    };
    this.onNavIconSelect_(event);
  }

  onHomeListItemClick_() {
    this.triggerNavSelect_(ICON_TRAY.HOME_ID);
  }

  onMenuListItemClick_() {
    this.triggerNavSelect_(ICON_TRAY.MENU_ID);
  }

  onShoppingListItemClick_() {
    this.triggerNavSelect_(ICON_TRAY.SHOPPING_LIST_ID);
  }

  onPersonalRecipesListItemClick_() {
    this.triggerNavSelect_(ICON_TRAY.MY_RECIPES_ID);
  }

  onNavIconSelect_(event) {
    switch (event.detail.buttonId) {
      case ICON_TRAY.HOME_ID:
        if (this._view !== HOME_VIEW) {
          const action = navigate(HOME_VIEW);
          store.dispatch(action);
        }
        break;
      case ICON_TRAY.MENU_ID:
        if (this._view !== MENU_VIEW) {
          const action = navigate(MENU_VIEW);
          store.dispatch(action);
        }
        break;
      case ICON_TRAY.SHOPPING_LIST_ID:
        if (this._view !== SL_VIEW) {
          const action = navigate(SL_VIEW);
          store.dispatch(action);
        }
        break;
      case ICON_TRAY.MY_RECIPES_ID:
        if (this._view !== RECIPES_VIEW) {
          const action = navigate(RECIPES_VIEW);
          store.dispatch(action);
        }
        break;
      default:
        break;
    }
  }

  onNavClick_() {
    const drawer = this.shadowRoot.querySelector('mwc-drawer');
    drawer.open = !drawer.open;
    const navIcon = this.shadowRoot.querySelector('#navigation-icon');
    navIcon.on = drawer.open;
    if (!this.largeScreen) this.shadowRoot.querySelector('main').classList.add("drawer-open");
    // const appBar = this.shadowRoot.querySelector('mwc-top-app-bar-fixed');
    // if (appBar) {
    //   const oldStyle = appBar.shadowRoot.querySelector('style');
    //   let style;
    //   if (oldStyle) {
    //     style = oldStyle
    //   } else {
    //     style = document.createElement('style');
    //     appBar.shadowRoot.appendChild(style);
    //   }
    //   style.innerHTML = css`
    //     header {
    //       border-color: transparent !important;
    //       box-shadow: ${drawer.open ? 312 : 0}px 12px 20px rgba(48, 48, 48, 0.2);
    //       border-radius: 0px 0px 10px ${drawer.open ? 0 : 10}px;
    //     }
    //   `;
    // }

  }

  onNavClose() {
    const navIcon = this.shadowRoot.querySelector('#navigation-icon');
    navIcon.on = false;
    this.shadowRoot.querySelector('main').classList.remove("drawer-open");
    // if (appBar) {
    //   const oldStyle = appBar.shadowRoot.querySelector('style');
    //   let style;
    //   if (oldStyle) {
    //     style = oldStyle
    //   } else {
    //     style = document.createElement('style');
    //     appBar.shadowRoot.appendChild(style);
    //   }
    //   style.innerHTML = css`
    //     header {
    //       border-color: transparent !important;
    //       box-shadow: 0px 12px 20px rgba(48, 48, 48, 0.2);
    //       border-radius: 0px 0px 10px 10px;
    //     }
    //   `;
    // }
  }

  onAccountMenuClick_() {
    const accountMenu = this.shadowRoot.querySelector('#account-menu');
    accountMenu.show();
    // Custom Figma shadow
    const surfSR = accountMenu.shadowRoot.querySelector('mwc-menu-surface').shadowRoot;
    if (!surfSR.querySelector('style')) {
      const menuSurfaceStyle = document.createElement("style");
      menuSurfaceStyle.innerHTML = css`div.mdc-menu-surface {
        box-shadow: 0px 12px 30px rgba(48, 48, 48, 0.2) !important;
      }`;
      surfSR.appendChild(menuSurfaceStyle);
    }

    const notificationSwitch = this.shadowRoot.querySelector('#account-menu mwc-switch');
    if (notificationSwitch) {
      const switchSR = notificationSwitch.shadowRoot;

      if (!switchSR.querySelector('style')) {
        const switchStyle = document.createElement("style");
        switchStyle.innerHTML = css`
        .mdc-switch__track {
          box-sizing: border-box !important;
          width: 24px !important;
          height: 8px !important;
          border: 1px solid transparent !important;
          border-radius: 7px !important;
          opacity: 0.38 !important;
          transition: opacity 90ms cubic-bezier(0.4, 0, 0.2, 1) 0s, background-color 90ms cubic-bezier(0.4, 0, 0.2, 1) 0s, border-color 90ms cubic-bezier(0.4, 0, 0.2, 1) 0s !important;
        }
        .mdc-switch__thumb-underlay {
          left: -1px !important;
          right: initial !important;
          top: -3px !important;
          width: 14px !important;
          height: 14px !important;
        }
        .mdc-switch__thumb {
          box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px !important;
          box-sizing: border-box !important;
          width: 14px !important;
          height: 14px !important;
          border: 0px solid !important;
          border-radius: 50% !important;
          pointer-events: none !important;
          z-index: 1 !important;
        }
        `;
        switchSR.appendChild(switchStyle);
      }
    }
  }

  onOpenSubmenu_() {
    const rerunSetupListItem = this.shadowRoot.querySelector('#rerun-setup-list-item');
    rerunSetupListItem.classList.replace('dd_hide', 'dd_show');
    const accountListItem = this.shadowRoot.querySelector('#account-list-item');
    accountListItem.classList.replace('dd_hide', 'dd_show');
  }

  onCloseSubmenu_() {
    const rerunSetupListItem = this.shadowRoot.querySelector('#rerun-setup-list-item');
    rerunSetupListItem.classList.replace('dd_show', 'dd_hide');
    const accountListItem = this.shadowRoot.querySelector('#account-list-item');
    accountListItem.classList.replace('dd_show', 'dd_hide');
  }

  onOpenPortal_(event) {
    // place close button in Chargebee iframe container for checkout if small screen and touch enabled
    const isMobileCheckout =
      isMobileTouch() &&
      (this._subscription.status === SUBSCRIPTION_STATUS.FREE ||
        this._subscription.status === SUBSCRIPTION_STATUS.CANCELLED);

    if (isMobileCheckout) {
      const chargebeeContainer = document.getElementById('cb-container');
      const chargebeeCloseButton = this.shadowRoot.getElementById('cb-iframe-close-button');
      const shouldAppendCloseButton =
        chargebeeContainer &&
        chargebeeCloseButton &&
        chargebeeCloseButton.parentNode !== chargebeeContainer;
      if (shouldAppendCloseButton) {
        chargebeeContainer.appendChild(chargebeeCloseButton);
      }
    }

    startSubscriptionCheckout(event.detail.planId);
  }

  onStateSelected_(event) {
    const action = setFlag(APP_FLAGS.SELECTED_STATE, event.detail.state);
    store.dispatch(action);
    this.resetStoreChange_();
  }

  onDialogNameInput_() {
    const nameField = this.shadowRoot.querySelector('#recipe-dialog-name-field');
    this.validateDialogTextOk_(nameField);
  }

  onDietaryRestrictionChanged_() {
    this.updateDietaryRestrictionsDialogState_();
  }

  onDisabledClick_(event) {
    if (this._dietPlanOptions.heartHealthy) {
      this.showSnackBarHint_(HH_INCOMPATIBLE_HINT);
    } else if (event.detail.id === 'stickToBasics') {
      this.showSnackBarHint_(BASICS_UNAVAILABLE_HINT);
    }
  }

  // TODO: deprecate
  onFamilySizeRadioChanged_(event) {
    const familySize = parseInt(event.currentTarget.value, 10);
    this.validateFamilySizeChangeOk_(familySize);
  }

  onMenuLimitsListItemClick_() {
    store.dispatch(requestDietPlanOptions());
    this.showConfirmationDialog_({ type: ACTION_DIALOG.DIET_PLAN_OPTIONS });
    this.closeModalDrawer_();
  }

  onDietaryRestrictionsListItemClick_() {
    store.dispatch(requestDietPlanOptions());
    this.showConfirmationDialog_({ type: ACTION_DIALOG.DIETARY_RESTRICTIONS });
    this.closeModalDrawer_();
  }

  onFamilySizeItemClick_() {
    this.showConfirmationDialog_({ type: ACTION_DIALOG.FAMILY_PLAN });
    this.closeModalDrawer_();
  }

  onSubscriptionSettingsListItemClick_() {
    if (isActiveRetailSubscription()) {
      Instrumentation.getAnalytics().track(`${ANALYTICS.ACCOUNT}: Open Retail Subscription Settings`);
      const cbPortal = userManagement.chargebeeInstance.createChargebeePortal();
      cbPortal.open({
        close() {
          store.dispatch(requestUpdateSubscription());
        },
      });
    } else {
      store.dispatch(navigate(SUBSCRIPTION_VIEW));
    }
  }

  onHomeChangeStore_(event) {
    this.onChangeStore_(event);
    window.scrollTo(0, 0);
  }

  onChangeStore_(event) {
    this._currentStore = event.detail.store;
    const persistedState = AppFlagProvider.getFlag(APP_FLAGS.SELECTED_STATE);
    const defaultStateForDiscontinuedStore = StateNames[0].code;

    this._selectedStateCode =
      persistedState || getDefaultStateCode(event.detail.store) || defaultStateForDiscontinuedStore;

    if (FeatureProvider.isActive(FEATURES.LOCATE_STORE)) {
      notifyIfLocationBlocked(CHANGE_STORE_LOCATION_BLOCKED_CONTENT);

      /*
			getKrogerLocations().then((params) => {
				if (params) {
					// WIP - get state from lat/long
				}
			});
			*/
    }

    this.showConfirmationDialog_({ type: ACTION_DIALOG.STORE_CHANGE });
    this.closeModalDrawer_();
  }

  onBonusContentListItemClick_() {
    const pageName = 'Bonus Content';
    const url = getWebUrl(`${WORDPRESS_PATH.bonusContent}?${QUERY_IDENTIFIER}`);
    openWebView(url, pageName);
    this.closeModalDrawer_();
  }

  onChangeEmailListItemClick_() {
    this.showConfirmationDialog_({ type: ACTION_DIALOG.CHANGE_EMAIL });
  }

  onChangePasswordListItemClick_() {
    this.showConfirmationDialog_({ type: ACTION_DIALOG.CHANGE_PASSWORD });
  }

  onStateAction_(event) {
    this._selectedStateCode = event.target.value;
    const action = setFlag(APP_FLAGS.SELECTED_STATE, this._selectedStateCode);
    store.dispatch(action);
    this.resetStoreChange_();
  }

  onStoreAction_(event) {
    this.validateStoreChangeOk_(event.target.value);
  }

  onStateStoreOpen_() {
    const stateList = this.shadowRoot.querySelector('#state-select')?.shadowRoot.querySelector('mwc-menu')?.shadowRoot.querySelector('mwc-list');
    if (stateList) {
      stateList.style.maxHeight = this.largeScreen ? "600px" : "300px";
    }
    const storeList = this.shadowRoot.querySelector('#store-select')?.shadowRoot.querySelector('mwc-menu')?.shadowRoot.querySelector('mwc-list');
    if (storeList) {
      storeList.style.maxHeight = this.largeScreen ? "600px" : "300px";
    }
  }

  onSimpleDialogAction_() {
    const simpleDialog = this.shadowRoot.querySelector('#simple-dialog');
    simpleDialog.close();
  }

  onOauthConnect_(event) {
    const service = OAUTH_SERVICE_NAME[event.detail.service];
    if (service) {
      this._accountConnecting = event.detail.service;
      oauthConnect(service);
      this.startFulfillmentMonitoring_();
    }
  }

  onDeletePersonalRecipe_(event) {
    const action = requestDeletePersonalRecipe(event.detail.recipeId);

    if (isInMenu(event.detail.recipeId)) {
      const dialogSelection = {
        type: ACTION_DIALOG.DELETE_PERSONAL_RECIPE,
        action,
      };
      this.showConfirmationDialog_(dialogSelection);
    } else {
      store.dispatch(action);
    }
  }

  onElectFulfillment_(event) {
    if (FulfillmentAccountProvider.getPeapod() === FULFILLMENT_ACCOUNT_STATE.IN_SUPPORTED_ZONE) {
      const shoppingListId = event.detail.shoppingListId;
      store.dispatch(
        requestPeapodFulfillment(AppFlagProvider.getFlag(APP_FLAGS.PREVIOUS_WEEK), shoppingListId),
      );
    } else if (FulfillmentAccountProvider.isKrogerAuthorized()) {
      this.showKrogerCartDialog_();
    }
  }
}

Instrumentation.setMetadata();
if (typeof COMMIT_HASH !== 'undefined') {
  console.log(`release ${COMMIT_HASH}, debug: ${AppFlagProvider.isDebug()}`);
}

if ('PushManager' in window) {
  NotificationService.initialize(store.dispatch);
}

customElements.define('dd-app', DdApp);
