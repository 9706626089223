/**
 * shopping list data actions
 */
import { toCamelCase } from '../utilities/object-evaluation.js';

export const SET_INGREDIENTS = 'SET_INGREDIENTS';

export function saveIngredients(dto) {
 return {
  type: SET_INGREDIENTS,
  payload: {
    ingredients: toCamelCase(dto)
  }
 }
}
