import { html } from 'lit-element';
import { PageViewElement } from './page-view-element.js';
import { ASYNC_PAUSE } from '../utilities/constants.js';

// standard element IDs
export const PRINT_CONTAINER = 'print-container'; // print elements container
export const VIEW_CONTAINER = 'view-container';   // component container
const PRINT_SPINNER_CONTAINER = 'print-spinner-container';

const PRINT_VIEW_INTERACTION_EVENT = 'print-view-interaction';

/**
 * base class for print views, handles print scrim
 */
export class DdPrint extends PageViewElement {
  getScrimTemplate_() {
    return html`
      <div id="${PRINT_SPINNER_CONTAINER}" class="blur-scrim"
        @click="${this.onInteraction_}"
        @touchstart="${this.onInteraction_}">
        <div class="print-spinner">
          <span class="dd-pseudo-button">close</span><span>after printing</span>
        </div>
      </div>
      <div id="navigation-container" class="dd-flex-end-container">
        <mwc-icon-button icon="close"
          @click="${this.onInteraction_}"></mwc-icon-button>
      </div>
    `;
  }

  scrollSpinnerContainer_() {
    const self = this;

		setTimeout(() => {
			const printSpinnerContainer = self.shadowRoot.getElementById('print-spinner-container');
			printSpinnerContainer.scrollIntoView(true);
		}, ASYNC_PAUSE);
  }

  onInteraction_() {
		const event = new CustomEvent(PRINT_VIEW_INTERACTION_EVENT, {});
		this.dispatchEvent(event);
	}
}
