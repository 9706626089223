import { html, LitElement } from 'lit-element';
import '@material/mwc-icon-button-toggle';
import { sharedStyles } from '../../theme/shared-styles.js';
import style from './dd-icon-tray.scss';

export const ICON_TRAY = {
  HOME_ID: 'home',
  MENU_ID: 'menu',
  SHOPPING_LIST_ID: 'shopping-list',
  MY_RECIPES_ID: 'my-recipes'
}

export const ICON_TRAY_HEIGHT = 68;

const NAV_SELECTION_EVENT = 'nav_selection';

class DdIconTray extends LitElement {
	static get styles() {
		return [
			sharedStyles,
			style
		];
	}

  render() {
    return html`
        <div class="dd-transparent-surface-bg tray-container">
          <div class="dd-flex-container">
            <mwc-icon-button-toggle id="${ICON_TRAY.HOME_ID}" onIcon="home" offIcon="home" @click="${this.onClick_}"></mwc-icon-button-toggle>
            <mwc-icon-button-toggle id="${ICON_TRAY.MENU_ID}" onIcon="restaurant_menu" offIcon="restaurant_menu" @click="${this.onClick_}"></mwc-icon-button-toggle>
            <mwc-icon-button-toggle id="${ICON_TRAY.SHOPPING_LIST_ID}" onIcon="shopping_basket" offIcon="shopping_basket" @click="${this.onClick_}"></mwc-icon-button-toggle>
            <mwc-icon-button-toggle id="${ICON_TRAY.MY_RECIPES_ID}" onIcon="menu_book" offIcon="menu_book" @click="${this.onClick_}"></mwc-icon-button-toggle>
          </div>
        </div>
      `;
  }

  /**
   *
   * @param {*} iconTrayId ICON_TRAY
   */
  select(iconTrayId) {
    const buttons = this.shadowRoot.querySelectorAll('mwc-icon-button-toggle');
    buttons.forEach(button => {
      button.on = (button.id === iconTrayId);
    });
  }

  disableOption(iconTrayId) {
    const button = this.shadowRoot.querySelector(`#${iconTrayId}`);
    button.disabled = true;
  }

  enableOption(iconTrayId) {
    const button = this.shadowRoot.querySelector(`#${iconTrayId}`);
    button.disabled = false;
  }

  onClick_(event) {
    let buttonId = event.target.id;

    if (event.target.on) {
      const buttons = this.shadowRoot.querySelectorAll('mwc-icon-button-toggle');
      buttons.forEach(button => {
        if (button.id !== event.target.id) {
          button.on = false;
        }
      });
    } else {
      const homeButton = this.shadowRoot.querySelector(`#${ICON_TRAY.HOME_ID}`);
      homeButton.on = true;
      buttonId = ICON_TRAY.HOME_ID;
    }

    const selecEvent = new CustomEvent(NAV_SELECTION_EVENT, {
			detail: { buttonId }
		});
		this.dispatchEvent(selecEvent);
  }
}

customElements.define('dd-icon-tray', DdIconTray);