import { html } from 'lit-element';
import { DdCard2 } from './dd-card-2.js';
import { getTopCenterRoundelTemplate } from '../../template-helpers.js';
import { sharedStyles } from '../../../theme/shared-styles.js';
import style from './dd-callout-card.scss';

export const COLOR_SALES = 'color-sales';
export const COLOR_VEG = 'color-veg';

const COLOR_CLASSES = {
  [COLOR_SALES]: {
    iconColor: 'color-sales-icon',
    textColor: 'color-sales-text'
  },
  [COLOR_VEG]: {
    iconColor: 'color-veg-icon',
    textColor: 'color-veg-text'
  }
}

class DdCalloutCard extends DdCard2 {
  static get properties() {
		return {
			iconClass: { String },
      quantity: { Number },
      bodyPrefix: { String },
      bodySuffix: { String },
      exhortation: { String },
      blurClass: { String },
      color: { String }
		};
	}

  static get styles() {
		return [
			sharedStyles,
			style
		];
	}

  render() {
    const colorClassName = COLOR_CLASSES[this.color].iconColor;
    const textColorClassName = COLOR_CLASSES[this.color].textColor;

		return html`
    <div class="dd-card-body-2">
      ${getTopCenterRoundelTemplate(this.iconClass, colorClassName, this.blurClass)}

      <div id="text-container">
        <div>
          <span class="dd-body1">${this.bodyPrefix}</span>
          <span class="dd-body1">${this.quantity}</span>
          <span class="dd-body1">${this.bodySuffix}</span>
        </div>
        <div id="exhortation-container" class="${textColorClassName}">
          ${this.exhortation}
        </div>
      </div>
    </div>
    `;
  }
}

customElements.define('dd-callout-card', DdCalloutCard);
