/**
 * shopping list data actions
 */
import { toCamelCase } from '../utilities/object-evaluation.js';

export const SET_RECIPE_INGREDIENT = 'SET_RECIPE_INGREDIENT';
export const REMOVE_EMPTY_RECIPE_INGREDIENT = 'REMOVE_EMPTY_RECIPE_INGREDIENT';
export const REMOVE_EMPTY_RECIPE = 'REMOVE_EMPTY_RECIPE';
export const SET_PERSONAL_RECIPE = 'SET_PERSONAL_RECIPE';
export const SET_PERSONAL_RECIPES = 'SET_PERSONAL_RECIPES';

export function createPersonalRecipe(dto) {
  return {
    type: SET_PERSONAL_RECIPE,
    payload: toCamelCase(dto)
  }
}

export function removeEmptyRecipe() {
  return {
    type: REMOVE_EMPTY_RECIPE,
    payload: {}
  }
}

export function savePersonalRecipes(dto) {
  return {
    type: SET_PERSONAL_RECIPES,
    payload: {
      personalRecipes: toCamelCase(dto)
    }
  }
}

export function addRecipeIngredient(recipeIngredientId, recipeId) {
  return {
    type: SET_RECIPE_INGREDIENT,
    payload: {
      id: recipeIngredientId,
      recipeId
    }
  }
}

export function removeEmptyRecipeIngredient(recipeId) {
  return {
    type: REMOVE_EMPTY_RECIPE_INGREDIENT,
    payload: { recipeId }
  }
}
