/**
 * produce included in the Home Screen fruit/veg callout
 */
export const ProduceIngredients = [
  13,
  15,
  19,
  31,
  32,
  61,
  62,
  63,
  64,
  65,
  72,
  99,
  100,
  102,
  103,
  104,
  119,
//  124, garlic clove
  129,
  133,
  142,
  154,
  163,
  166,
  173,
  184,
  189,
  192,
  210,
  220,
  221,
  226,
  231,
  237,
  238,
  241,
  242,
  243,
  247,
//  260, fresh ginger
  262,
  270,
  282,
  297,
  320,
  324,
  337,
  352,
  364,
  375,
  383,
  395,
  399,
  401,
  432,
  461,
  463,
  465,
  476,
  486,
  488,
  498,
  517,
  527,
  531,
  536,
  551,
  560,
//  579, tofu
  582,
//  586, veggie protein crumble
  594,
  616,
  636,
  640,
  642,
  645,
  657,
  663,
  665,
  676,
  680,
  681,
//  703, jalapeno pepper
  725,
  736,
  752,
  755,
  761,
  766,
  767,
  774,
  789,
//  791, tempeh
  800,
  803,
  805,
  808,
  809,
//  811, fresh chives
  818,
  824,
  842,
  845,
  849,
  859,
  865,
  881,
  901,
  909,
  915,
  917,
  924,
  926,
  946,
  964,
  999,
  1011,
  1029,
  1034,
  1037,
  1038,
  1039,
  1043,
  1047,
  1051,
  1056,
  1064,
  1082,
  1085,
  1087,
  1088,
  1089,
  1093,
  1100,
  1106,
  1107,
  1111,
  1118,
  1122,
  1133,
  1157,
  1159,
//  1164, guacamole
  1168,
  1195,
  1200,
  1204,
//  1222, serrano chilies
  1228,
  1229,
//  1234, mirepoix
  1253,
  1256,
  1273,
  1291,
  1292,
  1298,
  1299,
  1301,
  1307,
//  1308, horseradish
  1314,
  1350,
  1353,
  1355,
  1356,
  1359,
  1367,
  1371,
  1385,
  1386,
  1387,
  1388,
  1389,
  1390,
  1391,
  1392,
  1394,
  1395,
  1396,
  1397
];
