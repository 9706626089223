import { html } from 'lit-element';
import "@material/mwc-icon-button";
import "@material/mwc-button";
import '@material/mwc-list/mwc-list-item';
import '@material/mwc-select';
import { CARD_BODY_ID, DdCard2 } from './dd-card-2.js';
import './dd-card-expander.js';
import { sharedStyles } from '../../../theme/shared-styles.js';
import style from './dd-recipe-ingredient-card-2.scss';
import { getCategoryIconTemplate } from '../../template-helpers.js';
import '../../dd-combo-box.js';
import { EVENTS } from '../../events.js';
import { IngredientsProvider, INGREDIENTS_SET_EVENT } from '../../../services/ingredients-provider.js';
import { PubSub } from '../../../services/pub-sub.js';
import {
	ASYNC_PAUSE,
	CATEGORY_MAP,
	SCROLL_BEHAVIOR_START,
	SHOPPING_CATEGORY,
	VALID_CHAR_REGEX
} from '../../../utilities/constants.js';
import { TEMPORARY_ID } from '../../../utilities/new-item-id.js';
import { getCategoryKey } from '../../../utilities/map-helpers.js';
import { isNumericallyEqual } from '../../../utilities/object-evaluation.js';

const CREATE_EVENT = 'create';
const DELETE_EVENT = 'delete';
const CHANGED_EVENT = 'changed';

const CREATE_LOW_SCORE_THRESHOLD = 0.1;

const VIEW_STATE = {
	initial: 0,
	candidate: 1,
	editableEntity: 2
}
Object.freeze(VIEW_STATE);

function getCategoryListItemsTemplate() {
	const defaultId = SHOPPING_CATEGORY.UNKNOWN;
	const categories = Object.keys(CATEGORY_MAP);

	return categories.map(categoryKey => {
		const isDefault = (CATEGORY_MAP[categoryKey].id === defaultId);

		return html`
			<mwc-list-item graphic="icon"
					?selected=${isDefault}
					value="${CATEGORY_MAP[categoryKey].id}">
					<span>${CATEGORY_MAP[categoryKey].name}</span>
					<span slot="graphic">${getCategoryIconTemplate(categoryKey)}</span>
			</mwc-list-item>
		`;
	});
}

class DdRecipeIngredientCard2 extends DdCard2 {
	static get properties() {
		return {
			recipeIngredient: { type: Object },
			_ingredientOptions: { type: Object },
			_cookingMeasurements: { type: Object }
		};
	}

	static get styles() {
		return [
			sharedStyles,
			style
		];
	}

  constructor() {
		super();
		this._ingredientOptions = [];
		this._cookingMeasurements = [];
		this._selectedIngredientOption = null;
		this._lowScore = 0;
	}

	get showCreateOption() {
		return (this._lowScore > CREATE_LOW_SCORE_THRESHOLD);
	}

	firstUpdated() {
    super.firstUpdated();
    PubSub.subscribe(INGREDIENTS_SET_EVENT, (this.onIngredientsSet_).bind(this));
	}

	render() {
		const closedIcon = 'more_vert';
		const categoryKey = this.recipeIngredient.shoppingCategory? getCategoryKey(this.recipeIngredient.shoppingCategory) : SHOPPING_CATEGORY.UNKNOWN;

		return html`
		<div id="${CARD_BODY_ID}">
			<dd-card-expander
				.closedIcon=${closedIcon}
				?clickableTitle=${false}
				@pre-open="${this.onExpanderOpen_}"
				@close="${this.onExpanderClose_}">
				<div class="dd-flex-start-container" slot="title">
					${getCategoryIconTemplate(categoryKey)}
					<span class="dd-body1">${this.getFormattedTitle_()}</span>
				</div>
				<div slot="content">
					<div class="content-container">
						<div id="name-combo-box-container" class="dd_show">
							<dd-combo-box id="name-combo-box" .label=${'Name'} ?isIngredientOptions=${true}
								.options=${this._ingredientOptions} .placeholder=${'search ingredients'} .pattern="${VALID_CHAR_REGEX}"
								.writeableDefaultOption=${this.showCreateOption} @text-entry="${this.onNameTextEntry_}"
								@option-selected="${this.onNameOptionSelected_}"></dd-combo-box>
						</div>
						<div id="measurement-container" class="dd-flex-container dd_hide">
							<mwc-select id="amount-select" outlined fixedMenuPosition label="Amount" @action="${this.onChange_}">
								<mwc-list-item value="0">To taste</mwc-list-item>
								<mwc-list-item value="0.25">1/4</mwc-list-item>
								<mwc-list-item value="0.33">1/3</mwc-list-item>
								<mwc-list-item value="0.5">1/2</mwc-list-item>
								<mwc-list-item value="0.67">2/3</mwc-list-item>
								<mwc-list-item value="0.75">3/4</mwc-list-item>
								<mwc-list-item value="1.0">1</mwc-list-item>
								<mwc-list-item value="1.25">1 1/4</mwc-list-item>
								<mwc-list-item value="1.33">1 1/3</mwc-list-item>
								<mwc-list-item value="1.5">1 1/2</mwc-list-item>
								<mwc-list-item value="1.67">1 2/3</mwc-list-item>
								<mwc-list-item value="1.75">1 3/4</mwc-list-item>
								<mwc-list-item value="2.0">2</mwc-list-item>
								<mwc-list-item value="2.5">2 1/2</mwc-list-item>
								<mwc-list-item value="3.0">3</mwc-list-item>
								<mwc-list-item value="4.0">4</mwc-list-item>
								<mwc-list-item value="5.0">5</mwc-list-item>
								<mwc-list-item value="6.0">6</mwc-list-item>
								<mwc-list-item value="7.0">7</mwc-list-item>
								<mwc-list-item value="8.0">8</mwc-list-item>
								<mwc-list-item value="10.0">10</mwc-list-item>
								<mwc-list-item value="12.0">12</mwc-list-item>
								<mwc-list-item value="14.0">14</mwc-list-item>
								<mwc-list-item value="16.0">16</mwc-list-item>
								<mwc-list-item value="18.0">18</mwc-list-item>
								<mwc-list-item value="20.0">20</mwc-list-item>
								<mwc-list-item value="24.0">24</mwc-list-item>
								<mwc-list-item value="28.0">28</mwc-list-item>
								<mwc-list-item value="32.0">32</mwc-list-item>
							</mwc-select>
							<mwc-select id="cooking-measure-select" outlined fixedMenuPosition @action="${this.onChange_}">
								${this.getCookingMeasureListItemsTemplate_()}
							</mwc-select>
						</div>
						<div id="category-container" class="dd_hide" @click="${this.onCategorySelectOpened_}">
							<mwc-select id="category-select" label="Category" outlined

							@closed="${this.onCategorySelectClosed_}">
								${getCategoryListItemsTemplate()}
							</mwc-select>
						</div>
					</div>
					<div id="create-button-container" class="dd-flex-end-container dd_hide">
						<div>
							<mwc-button @click="${this.onCreateClick_}">add this</mwc-button>
						</div>
					</div>
					<div id="bottom-card-controls" class="dd-flex-end-container dd_hide">
						<mwc-icon-button icon="delete_outline" title="remove this" @click="${this.onDeleteClick_}"></mwc-icon-button>
					</div>
					<div class="dd-card-bottom-border"></div>
				</div>
			</dd-card-expander>
		</div>
	`;
  }

	layout_() {
		const amountSelect = this.shadowRoot.querySelector('#amount-select');
		amountSelect.layout();
		const categorySelect = this.shadowRoot.querySelector('#category-select');
		if (categorySelect) {
			categorySelect.layout(true);
		}

		const viewState = this.getViewState_();
		if (this.isOpen && (viewState === VIEW_STATE.initial)) {
			const nameComboBox = this.shadowRoot.querySelector('#name-combo-box')
			nameComboBox.layout();

			setTimeout(() => {
				nameComboBox.focus();
			}, ASYNC_PAUSE);
		}
	}

	getViewState_() {
		if (this.recipeIngredient.id > TEMPORARY_ID) {
			return VIEW_STATE.editableEntity;
		}

		return this._selectedIngredientOption? VIEW_STATE.candidate : VIEW_STATE.initial;
	}

	updateView_(viewState) {
		const nameComboBoxContainerClasses = this.shadowRoot.querySelector('#name-combo-box-container').classList;
		const createButtonContainerClasses = this.shadowRoot.querySelector('#create-button-container').classList;
		const measurementContainerClasses = this.shadowRoot.querySelector('#measurement-container').classList;
		const categoryContainerClasses = this.shadowRoot.querySelector('#category-container').classList;
		const bottomCardControlsClasses = this.shadowRoot.querySelector('#bottom-card-controls').classList;

		switch (viewState) {
			case (VIEW_STATE.initial): {
				nameComboBoxContainerClasses.replace('dd_hide', 'dd_show');
				createButtonContainerClasses.replace('dd_show', 'dd_hide');
				measurementContainerClasses.replace('dd_show', 'dd_hide');
				categoryContainerClasses.replace('dd_show', 'dd_hide');
				bottomCardControlsClasses.replace('dd_show', 'dd_hide');
				const nameComboBox = this.shadowRoot.querySelector('#name-combo-box');
				if (this._isMaxView && nameComboBox) {
					nameComboBox.focus();
				}
				break;
			}

			case (VIEW_STATE.candidate):
				nameComboBoxContainerClasses.replace('dd_show', 'dd_hide');
				createButtonContainerClasses.replace('dd_hide', 'dd_show');
				measurementContainerClasses.replace('dd_hide', 'dd_show');
				bottomCardControlsClasses.replace('dd_show', 'dd_hide');
				if (this.showCreateOption) {
					categoryContainerClasses.replace('dd_hide', 'dd_show');
					// scroll to the category select container into view before selecting it so it opens downwards
					const categorySelectContainer = this.shadowRoot.querySelector('div.content-container');
					if (categorySelectContainer.scrollIntoView) {
						categorySelectContainer.scrollIntoView(SCROLL_BEHAVIOR_START);
					}
				} else {
					categoryContainerClasses.replace('dd_show', 'dd_hide');
				}
				break;

			case (VIEW_STATE.editableEntity):
			default:
				nameComboBoxContainerClasses.replace('dd_show', 'dd_hide');
				createButtonContainerClasses.replace('dd_show', 'dd_hide');
				measurementContainerClasses.replace('dd_hide', 'dd_show');
				bottomCardControlsClasses.replace('dd_hide', 'dd_show');
				categoryContainerClasses.replace('dd_show', 'dd_hide');
				break;
		}
	}

	updateFromState_() {
		const viewState = this.getViewState_();
		console.log(`Recipe Ingredient Card.updateFromState: ${viewState}, id: ${this.recipeIngredient.id}`);
		const amountSelect = this.shadowRoot.querySelector('#amount-select');
		const cookingMeasureSelect = this.shadowRoot.querySelector('#cooking-measure-select');

		if (amountSelect.items.length && cookingMeasureSelect.items.length) {
			if (viewState === VIEW_STATE.editableEntity) {
				this.setSelected_(cookingMeasureSelect, this.recipeIngredient.cookingMeasurementId);
				this.setSelected_(amountSelect, this.recipeIngredient.amount);
			} else {
				cookingMeasureSelect.select(0);

				// set sensible default amount
				if (cookingMeasureSelect.value) {
					amountSelect.select(1);
				} else {
					amountSelect.select(0);
				}
			}
		}

		this.updateView_(viewState);
	}

	getCookingMeasureListItemsTemplate_() {
		const viewState = this.getViewState_();
		let cookingMeasurementIds = null;
		let options = null;
		let ingredient = null;

		switch (viewState) {
			case (VIEW_STATE.initial):
				return '';

			case (VIEW_STATE.candidate):
				cookingMeasurementIds = this._selectedIngredientOption.cookingMeasurementIds;
				options = this._cookingMeasurements.filter(cookingMeasurement => cookingMeasurementIds.includes(cookingMeasurement.id));
				options = IngredientsProvider.addAlternateMeasurements(options);

				if (options.length > 0) {
					return options.map((option) => html`
						<mwc-list-item
							value="${option.id}"
							>${option.name}</mwc-list-item>
					`);
				}

				return html`<mwc-list-item selected value="0">To taste</mwc-list-item>`;

			case (VIEW_STATE.editableEntity):
			default:
				ingredient = IngredientsProvider.getIngredient(this.recipeIngredient.ingredientId);
				if (ingredient) {
					cookingMeasurementIds = ingredient.cookingMeasurementIds;
					options = IngredientsProvider.getCookingMeasurements().filter(cookingMeasurement => cookingMeasurementIds.includes(cookingMeasurement.id));
					options = IngredientsProvider.addAlternateMeasurements(options);

					if (options.length > 0) {
						return options.map((option) => html`
							<mwc-list-item value="${option.id}">${option.name}</mwc-list-item>
						`);
					}

					return html`<mwc-list-item selected value="0">To taste</mwc-list-item>`;
				}

				return '';
		}
	}

  getCurrentRecipeIngredientDto_() {
		const amountSelect = this.shadowRoot.querySelector('#amount-select');
		const cookingMeasureSelect = this.shadowRoot.querySelector('#cooking-measure-select');

		const dto = {
			...this.recipeIngredient,
			amount: parseFloat(amountSelect.value),
			cookingMeasurementId: parseInt(cookingMeasureSelect.value, 10)
		}

		return dto;
	}

	isRecipeIngredientChanged_() {
		const amountSelect = this.shadowRoot.querySelector('#amount-select');
		const cookingMeasureSelect = this.shadowRoot.querySelector('#cooking-measure-select');
		const isEditableEntity = amountSelect.value && cookingMeasureSelect.value && (this.getViewState_() === VIEW_STATE.editableEntity);

		const amountChanged = !isNumericallyEqual(this.recipeIngredient.amount, amountSelect.value);
		const cookingMeasureChanged = !isNumericallyEqual(this.recipeIngredient.cookingMeasurementId, cookingMeasureSelect.value);

    return isEditableEntity && (amountChanged || cookingMeasureChanged);
	}

	getFormattedTitle_() {
		return this.recipeIngredient.formattedName?? this.getTitle_();
	}

	getTitle_() {
		const viewState = this.getViewState_();

		switch (viewState) {
			case (VIEW_STATE.initial):
				return 'my new ingredient';
			case (VIEW_STATE.candidate):
				return this._selectedIngredientOption.name;
			case (VIEW_STATE.editableEntity):
			default: {
				const ingredient = IngredientsProvider.getIngredient(this.recipeIngredient.ingredientId);
				return ingredient? ingredient.name : '';
			}
		}
	}

	onExpanderOpen_() {
		this.layout_();

		const detail = {
			id: this.recipeIngredient.id
		};
		this.fireLocalEvent_(EVENTS.MAXIMIZE, detail);
	}

	onExpanderClose_() {
		const detail = {
			id: this.recipeIngredient.id
		};
		this.fireLocalEvent_(EVENTS.MINIMIZE, detail);
	}

	onIngredientsSet_() {
		this.requestUpdate();
	}

	onNameTextEntry_(event) {
		const searchResults = IngredientsProvider.findIngredients(event.detail.text);
		this._lowScore = searchResults.lowScore;
		if (this.showCreateOption) {
			const cookingMeasurements = IngredientsProvider.getCookingMeasurements();

			const createOption = {
				id: TEMPORARY_ID,
				name: 'Create my own',
				cookingMeasurementIds: cookingMeasurements.map(cookingMeasurement => cookingMeasurement.id)
			}
			searchResults.items.unshift(createOption);
		}
		this._ingredientOptions = searchResults.items;
	}

	onNameOptionSelected_(event) {
		this._selectedIngredientOption = event.detail.option;
		this._cookingMeasurements = IngredientsProvider.getCookingMeasurements();
		this.requestUpdate();
	}

	onCategorySelectOpened_() {
		const categorySelectContainer = this.shadowRoot.querySelector('div.content-container');
		categorySelectContainer.classList.add('category-select-spacer');
	}

	onCategorySelectClosed_() {
		const categorySelectContainer = this.shadowRoot.querySelector('div.content-container');
		categorySelectContainer.classList.remove('category-select-spacer');
	}

	onCreateClick_() {
		const amountSelect = this.shadowRoot.querySelector('#amount-select');
		const cookingMeasureSelect = this.shadowRoot.querySelector('#cooking-measure-select');
		const categorySelect = this.shadowRoot.querySelector('#category-select');
		const ingredientCategoryId = this.showCreateOption? parseInt(categorySelect.value, 10) : null;

		const detail = {
			ingredientId: this._selectedIngredientOption.id,
			ingredientName: this._selectedIngredientOption.name,
			ingredientCategoryId,
			amount: parseFloat(amountSelect.value, 10),
			cookingMeasurementId: parseInt(cookingMeasureSelect.value, 10)
		};
		this.fireLocalEvent_(CREATE_EVENT, detail);

		this.close();
	}

	onDeleteClick_() {
		const detail = {
			recipeIngredientId: this.recipeIngredient.id
		};
		this.fireLocalEvent_(DELETE_EVENT, detail);
	}

	onChange_() {
		if (this.isRecipeIngredientChanged_()) {
			const dto = this.getCurrentRecipeIngredientDto_();
			this.fireLocalEvent_(CHANGED_EVENT, dto);
		}

		const categorySelectContainer = this.shadowRoot.querySelector('div.content-container');
		categorySelectContainer.classList.remove('category-select-spacer');
	}
}

customElements.define('dd-recipe-ingredient-card-2', DdRecipeIngredientCard2);
