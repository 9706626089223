import { html, css } from 'lit-element';
import isEqual from 'lodash-es/isEqual.js';

// TDD components/libraries
import { PageViewElement } from '../page-view-element.js';
import { sharedStyles } from '../../theme/shared-styles.js';
import { TRANSITION_PAUSE } from './dd-card/dd-card-expander.js';
import './dd-card/dd-editable-recipe-card-2.js';
import { DISH_TYPE_MAIN, PROTEIN_CATEGORY } from '../../utilities/constants.js';
import { offsetScrollToTop } from '../../utilities/dom.js';
import {
  getNewItemId,
  INVALID_ID,
  TEMPORARY_ID
} from '../../utilities/new-item-id.js';

const CREATE_PERSONAL_RECIPE_EVENT = 'create-personal-recipe';
const REMOVE_EMPTY_RECIPE_EVENT = 'remove-empty-recipe';

const START_PROMPT = "You have not added any Personal Recipes yet. Add one today!"

/**
 * screen for managing personal recipes.
 * fields:
 *  - _maximizedRecipeId: reference link between temporary state recipe obj and Editable Recipe card until ID assigned
 */
class DdPersonalRecipes2 extends PageViewElement {
  // maximizedPersonalRecipe is reference to maximized instance in personalRecipes in state
	static get properties() {
		return {
      mains: { type: Object }
		};
	}

	static get styles() {
		return [
			sharedStyles,
			css`
      	.dd-bottom-spacer {
					background-color: transparent;
				}
				#personal-recipes-container {
					z-index: 0;
					background-color: var(--mdc-theme-surface);
        }

        .grid-outer {
					padding: 8px 24px 24px 24px;
				}

        /* large screen overrides, 1280x800+  */
        @media only screen and (min-width: 1248px) {
          .grid-outer {
						padding: 8px 24px 24px 32px;
					}

          .dd-content-container {
            padding: 16px 16px 40px 16px;
          }
        }
			`
		];
  }

  set mains(value) {
    // handle add ingredient case
    if (!isEqual(value, this._mains)) {
      this._maximizedRecipeId = getNewItemId(this._mains, value);
      this._mains = value;
      this.requestUpdate();
    }
  }

  get hasMains() {
    return Array.isArray(this._mains) && this._mains.length;
  }

  constructor() {
		super();
    this._mains = [];
    this._maximizedRecipeId = INVALID_ID;
  }

  updated() {
    super.updated();
  }

  render() {
    return html`
    <div class="grid-outer">
      <div id="grid-inner">
        <div id="child-cards-container">
          <div id="personal-recipes-container" class="dd-child-card-container">
            ${this.getEditableRecipeTemplates_()}
          </div>
        </div>
        <div class="dd-flex-end-container">
					<mwc-button id="add-recipe-button"
						outlined
						class="white-filled-cta dd_show"
						icon="add_circle"
						label="add recipe"
						title="Add recipe"
						@click="${this.onAddRecipeClick_}"></mwc-button>
				</div>
      </div>
    </div>
    ${this.getSpacerTemplate_()}
  `;
  }

  getEditableRecipeTemplates_() {
    if (this.hasMains) {
      const maximizedRecipeId = this._maximizedRecipeId;

      return this._mains.map((main) => html`
        <dd-editable-recipe-card-2
          ?isOpen=${maximizedRecipeId === TEMPORARY_ID}
          .recipe=${main}
          @create="${this.onCreateRecipe_}"
          @maximize-card="${this.onMaximizeCarouselCard_}"
          @minimize-card="${this.onMinimizeCarouselCard_}">
        </dd-editable-recipe-card-2>
      `);
    }

    return html`
      <div class="dd-content-container">
        <span>${START_PROMPT}</span>
      </div>
    `;
  }

  onAddRecipeClick_(event) {
    const globalEvent = new CustomEvent(CREATE_PERSONAL_RECIPE_EVENT, {
			bubbles: true,
      composed: true,
      detail: {
        id: TEMPORARY_ID,
        dishType: DISH_TYPE_MAIN,
        proteinCategory: PROTEIN_CATEGORY.NONE
      }
		});
		this.dispatchEvent(globalEvent);

    event.target.classList.replace('dd_show', 'dd_hide');
  }

  onCreateRecipe_(event) {
    const globalEvent = new CustomEvent(CREATE_PERSONAL_RECIPE_EVENT, {
			bubbles: true,
      composed: true,
      detail: event.detail
		});
		this.dispatchEvent(globalEvent);

    const addRecipeButton = this.shadowRoot.querySelector('#add-recipe-button');
		addRecipeButton.classList.replace('dd_hide', 'dd_show');
  }

  // BEGIN editable recipe card carousel methods
  onMaximizeCarouselCard_(event) {
		const ingredientCards = this.shadowRoot.querySelectorAll('dd-editable-recipe-card-2');
		ingredientCards.forEach(card => {
			if (card.recipe.id !== event.detail.id) {
				card.close();
			}
		});

    const paddedPause = 2 * TRANSITION_PAUSE;
    offsetScrollToTop(event.target, paddedPause);
	}

  onMinimizeCarouselCard_(event) {
    const isEmptyRecipe = (event.detail.id === TEMPORARY_ID);
    if (isEmptyRecipe) {
      const globalEvent = new CustomEvent(REMOVE_EMPTY_RECIPE_EVENT, {
        bubbles: true,
        composed: true
      });
      this.dispatchEvent(globalEvent);
      const addRecipeButton = this.shadowRoot.querySelector('#add-recipe-button');
      addRecipeButton.classList.replace('dd_hide', 'dd_show');
    }
  }
  // END editable recipe card carousel methods
}

customElements.define('dd-personal-recipes-2', DdPersonalRecipes2);