/**
 * shopping list data actions
 * // REST API DTO: AppBundle\Controller\Rest\DTO\ShoppingList.recipe_shop_items
 */
 import { toCamelCase } from '../utilities/object-evaluation.js';

export const SET_RECIPE_SHOP_ITEMS = 'SET_RECIPE_SHOP_ITEMS';
export const ACTIVATE_RECIPE_SHOP_ITEMS = 'ACTIVATE_RECIPE_SHOP_ITEMS';
export const DEACTIVATE_RECIPE_SHOP_ITEMS = 'DEACTIVATE_RECIPE_SHOP_ITEMS';

export function saveRecipeShopItems(dto) {
  return {
    type: SET_RECIPE_SHOP_ITEMS,
    payload: {
      recipeShopItems: toCamelCase(dto.recipe_shop_items)
    }
  }
}

export function activateRecipeShopItems(recipeIds) {
  return {
    type: ACTIVATE_RECIPE_SHOP_ITEMS,
    payload: {
      recipeIds
    }
  }
}

export function deactivateRecipeShopItems(recipeIds) {
  return {
    type: DEACTIVATE_RECIPE_SHOP_ITEMS,
    payload: {
      recipeIds
    }
  }
}
