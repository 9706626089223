import { LitElement } from 'lit-element';
import { isLargeScreen } from '../utilities/screen-size.js';

/**
 * replaces retired Webpack build parameter
 */
const DESKTOP = true;

/**
 * base element
 */
export class ScreenAwareElement extends LitElement {
  get largeScreen() {
    return this._largeScreen;
  }

  constructor() {
    super();
    this._largeScreen = (DESKTOP && isLargeScreen());
  }
}
