/**
* API actions for the events endpoint
* ref: https://github.com/redux-utilities/flux-standard-action
*/
import {axios,
	getApiUrl,
	API_REQUEST,
	startRequest,
	completeRequest,
	NOK_RESPONSE_HINT
} from './api-base.js';


// REST resources
const EVENTS_RESOURCE = 'events';

/**
 * send menu to calendar service
 * @param int shoppingListId 
 */
export function requestCreateEvents(weekMenuId, timezone) {
	return {
		type: API_REQUEST,
		payload (dispatch, token) {
			dispatch(startRequest('Added to your calendar.'));
			const url = getApiUrl(EVENTS_RESOURCE);

			return axios.post(url,
				{ 
          weekMenuId,
          timezone
        },
				{
				headers: { Authorization: `Bearer ${token}` }
			})
				.then(() => {
					dispatch(completeRequest());
				})
				.catch(() => {
					dispatch(completeRequest(NOK_RESPONSE_HINT));
				});
		}
	}
}
