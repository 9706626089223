import { html, LitElement } from 'lit-element';
import '@material/mwc-checkbox';
import '@material/mwc-switch/deprecated';
import '@material/mwc-formfield';
import { sharedStyles } from '../theme/shared-styles.js';
import style from './dd-select-option.scss';

export const DECORATOR_SEPARATOR = ' ';
const DISABLED_CLICK_EVENT = 'disabled-click';	// clicked when disabled
const CHECK_SELECT_OPTION_EVENT = 'check-select-option';
const UNCHECK_SELECT_OPTION_EVENT = 'uncheck-select-option';

/**
 * checkbox or switch with bubbling check/uncheck events
 */
export class DdSelectOption extends LitElement {
	static get properties() {
		return {
			isGray: { type: Boolean },
			isSwitch: { type: Boolean }, // default false=checkbox, true=switch
			rtl: { type: Boolean }, // true = right-to-left
			enabled: { type: Boolean },
			option: { type: Object },	// { id, name, printName, decorator }
			checked: { type: Boolean},	// initialization and reset state
			active: { type: Boolean, reflect: true }	// reflects current state, true = checked
		};
	}

	static get styles() {
		return [
			sharedStyles,
			style
		];
	}

	get selector() {
		return this.isSwitch? 'mwc-switch' : 'mwc-checkbox';
	}

	get value() {
		const checkbox = this.shadowRoot.querySelector(this.selector);
		if (checkbox) {
			return this.isSwitch? checkbox.selected : checkbox.checked;
		}

		return false;
	}

	constructor() {
		super();

		this.isSwitch = false;
		this.rtl = false;
		this.checked = true;
		this.active = true;
		this.enabled = true;
		this.option = { name: '' };
	}

  render() {
		const bgClass = this.isGray? 'gray-bg' : '';

		return html`
				<div @click="${this.onClick_}" class="dd-flex-container dd-select-option-print ${bgClass}">
					${this.rtl
					? html`
							${this.getDecoratorTemplate_()}
							${this.getRtlFormfieldTemplate_()}
						`
					: html`
							${this.getLtrFormfieldTemplate_()}
							${this.getDecoratorTemplate_()}
						`
					}

					<div id="printField">${this.option.printName}</div>
				</div>
			`;
	}

	getCurrentValue() {
		const checkbox = this.shadowRoot.querySelector(this.selector);
		return checkbox.checked;
	}

	hasChanged() {
    return this.enabled && (this.checked !== this.getCurrentValue());
	}

	reset() {
		this.setControl_(this.checked);
		this.active = this.checked;
	}

	setControl_(newValue) {
		const checkbox = this.shadowRoot.querySelector(this.selector);
		checkbox.checked = newValue;
	}

	getRtlFormfieldTemplate_() {
		const disableColorClass = this.enabled? '' : 'disabled';

		return html`
			<mwc-formfield label="${this.option.name}"
				class="${disableColorClass}"
				.spaceBetween=${!!this.option.decorator}
				.alignEnd=${true}>
				${this.getCheckboxTemplate_()}
			</mwc-formfield>
		`;
	}

	getLtrFormfieldTemplate_() {
		const disableColorClass = this.enabled? '' : 'disabled';

		return html`
			<mwc-formfield label="${this.option.name}" class="${disableColorClass}">
				${this.getCheckboxTemplate_()}
			</mwc-formfield>
		`;
	}

	getCheckboxTemplate_() {
		return this.isSwitch?
			html`
				<mwc-switch
					?disabled=${!this.enabled}
					?checked=${this.checked}
					@change="${this.onCheckedChange_}"></mwc-switch>
			`
		: html`
				<mwc-checkbox
					?disabled=${!this.enabled}
					?checked=${this.checked}
					@change="${this.onCheckedChange_}"></mwc-checkbox>
		`;
	}

	getDecoratorTemplate_() {
		const marginClass = this.rtl? 'left-decorator' : 'right-decorator';
		const disableColorClass = this.enabled? '' : 'disabled';

		let iconName = '';
		const isMaterialIcon = this.option.decorator && !this.option.decorator.includes('tddi-');
		if (isMaterialIcon) {
			const classes = this.option.decorator.split(DECORATOR_SEPARATOR);
			if (classes.length === 2) {
				if (classes[0].includes('-fg')) {
					iconName = classes[1];
					classes[1] = 'material-icons';
				} else {
					iconName = classes[0];
					classes[0] = 'material-icons';
				}

				this.option.decorator = classes.join(DECORATOR_SEPARATOR);
			}
		}

		return this.option.decorator
		? html`<span class="option-decorator ${this.option.decorator} ${disableColorClass} ${marginClass}">${iconName}</span>`
		: '';
	}

	onClick_() {
		if (!this.enabled) {
			// event to bubble out of parent's shadow DOM
			const event = new CustomEvent(DISABLED_CLICK_EVENT, {
				detail: {
					id: this.option.id
				}
			});
			this.dispatchEvent(event);
		}
	}

	onCheckedChange_() {
		const newValue = this.getCurrentValue();
		this.active = newValue;
		const eventName = newValue? CHECK_SELECT_OPTION_EVENT : UNCHECK_SELECT_OPTION_EVENT;

		// event to bubble out of parent's shadow DOM
    const event = new CustomEvent(eventName, {
      bubbles: true,
      composed: true,
      detail: {
        id: this.option.id
      }
    });
    this.dispatchEvent(event);
	}
}

customElements.define('dd-select-option', DdSelectOption);