import { html, css, LitElement } from 'lit-element';
import '@material/mwc-list';
import '@material/mwc-list/mwc-list-item';
import '@material/mwc-textfield';
import './dd-expandable-content.js';
import { getCategoryIconTemplate } from './template-helpers.js';
import { sharedStyles } from '../theme/shared-styles.js';
import { MAX_TEXTFIELD_LENGTH, VALIDATION_MESSAGE } from '../utilities/constants.js';
import debounce from '../utilities/debounce.js';

const TEXT_ENTRY_EVENT = 'text-entry';
const OPTION_SELECTED_EVENT = 'option-selected';

const MIN_LENGTH = 3;
const DEBOUNCE_MS = 400;

const SELECT_HINT = 'choose one or try another name';

function validateLength(text) {
  return text.length >= MIN_LENGTH;
}

/**
 * an editable combobox
 */
class DdComboBox extends LitElement {
	static get properties() {
		return {
			isIngredientOptions: { type: Boolean },
			isOpen: { type: Boolean },
			options: { type: Object },
			pattern: { type: String },
			label: { type: String },
			hint: { type: String },
			placeholder: { type: String },
			writeableDefaultOption: { type: Boolean}	// true = first select option retains entered text
		};
	}

	static get styles() {
		return [
			sharedStyles,
			css`
				#content-container {
					padding: 8px;
				}

				.dd-combobox-icon-container {
					display: inline-block;
					height: auto;
					width: auto;
				}

				/* mwc-select style dropdown shadow */
				mwc-list {
					box-shadow: rgba(0,0,0,0.2) 0px 5px 5px -3px, rgba(0,0,0,0.14) 0px 8px 10px 1px, rgba(0,0,0,0.12) 0px 3px 14px 2px;
				}

				mwc-textfield {
					margin: 16px 0 0 16px;
					width: 95%;
				}
			`
		];
	}

	constructor() {
		super();
		this.options = [];
		this.pattern = '';
    const callback = (this.fireTextEntryEvent_).bind(this);
    this._debouncedFireTextEntryEvent = debounce(callback, DEBOUNCE_MS, false);
	}

	render() {
		return html`
			<dd-expandable-content
				?clickableTitle=${false}
				?isOpen=${this.isOpen}
				@open="${this.onExpandibleOpen_}"
				@close="${this.onExpandibleClose_}">
				<div slot="title">
					<mwc-textfield
						outlined
						label="${this.label}"
						maxLength=${MAX_TEXTFIELD_LENGTH}
						placeholder="${this.placeholder}"
						pattern="${this.pattern}"
						validationMessage=${VALIDATION_MESSAGE}
						.type=${"text"}
						@input="${this.onInput_}"
						required></mwc-textfield>
				</div>
				<div id="content-container" slot="content">
					<mwc-list activatable @selected="${this.onNameSelected_}">
						${this.getOptionTemplates_()}
					</mwc-list>
				</div>
			</dd-expandable-content>
		`;
	}

	focus() {
    const textField = this.shadowRoot.querySelector('mwc-textfield');
    textField.focus();
	}

	open() {
		const expander = this.shadowRoot.querySelector('dd-expandable-content');
		expander.open();
		this.showSelectHint_();
	}

	getSelectedOption() {
		const optionList = this.shadowRoot.querySelector('mwc-list');
		if (optionList && optionList.selected) {
			return this.options.find(option => option.id === optionList.selected.value);
		}

		return null;
	}

	layout() {
		const textField = this.shadowRoot.querySelector('mwc-textfield');
		textField.layout();

		const list = this.shadowRoot.querySelector('mwc-list');
		list.layout();
	}

	getOptionTemplates_() {
		if (this.options) {
			const optionTemplates = this.options.map((option) => {
				if (this.isIngredientOptions) {
					return html`
						<mwc-list-item graphic="icon" .value=${option.id}>
							<span>${option.name}</span>
				<div slot="graphic" class="dd-combobox-icon-container">${getCategoryIconTemplate(option.shoppingCategory)}</div>
						</mwc-list-item>
					`;
				}
				return html`<mwc-list-item .value=${option.id}>${option.name}</mwc-list-item>`;
			});

			if (this.writeableDefaultOption) {
				optionTemplates.splice(1, 0, html`<li divider padded role="separator"></li>`);
			}

			return optionTemplates;
		}

		return '';
	}

	showSelectHint_() {
		const textField = this.shadowRoot.querySelector('mwc-textfield');
		textField.helper = SELECT_HINT;
	}

	hideSelectHint_() {
		const textField = this.shadowRoot.querySelector('mwc-textfield');
		textField.helper = '';
	}

	fireTextEntryEvent_(text) {
    const event = new CustomEvent(TEXT_ENTRY_EVENT, {
      detail: {
        text
      }
    });
    this.dispatchEvent(event);
	}

	fireTextSelectedEvent_(option) {
    const event = new CustomEvent(OPTION_SELECTED_EVENT, {
      detail: {
        option
      }
    });
    this.dispatchEvent(event);
	}

	onInput_() {
    const textField = this.shadowRoot.querySelector('mwc-textfield');
    if (validateLength(textField.value) && textField.reportValidity()) {
			this._debouncedFireTextEntryEvent(textField.value);
			this.open();
    }
	}

	onNameSelected_() {
		const textField = this.shadowRoot.querySelector('mwc-textfield');
		const optionList = this.shadowRoot.querySelector('mwc-list');
		const selectedOption = this.options.find(option => option.id === optionList.selected.value);

		if (this.writeableDefaultOption) {
			selectedOption.name = textField.value;
		}

		this.fireTextSelectedEvent_(selectedOption);

		const expandable = this.shadowRoot.querySelector('dd-expandable-content');
		expandable.close();
	}

	onExpandibleOpen_() {
		if (this.options.length) {
			this.showSelectHint_();
		}
		this.layout();
	}

	onExpandibleClose_() {
		this.hideSelectHint_();
	}
}

customElements.define('dd-combo-box', DdComboBox);