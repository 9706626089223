import { SET_UI_SETTINGS } from '../actions/ui-settings.js';


const INITIAL_STATE = {
};

export function uiSettings(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_UI_SETTINGS: {
      return {
        ...state,
        ...action.payload.uiSettings
      };
    }

    default:
      return state;
  }
}
