/**
 * search data action
 */

 export const SET_SEARCH = 'SET_SEARCH';

 export function saveSearch(search) {
  return {
    type: SET_SEARCH,
    payload: { search	}
  }
 }