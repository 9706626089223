import { css } from 'lit-element';
import { Dialog } from '@material/mwc-dialog';
import { sharedStyles } from '../theme/shared-styles.js';
import { PubSub } from '../services/pub-sub.js';
import { ORIENTATION_CHANGE_EVENT } from '../services/orientation-publisher.js';

/**
 * mwc-dialog resized to the smaller of window inner or outer dimensions
 */
class DdDialog extends Dialog {
	static get styles() {
    const baseStyles = Dialog.styles;
		return [
      sharedStyles,
      baseStyles,
      css`
        .mdc-dialog, .mdc-dialog__scrim {
          width: auto;
          height: auto;
        }
      `
		];
  }

  firstUpdated() {
    super.firstUpdated();
    this.size_();
    PubSub.subscribe(ORIENTATION_CHANGE_EVENT, (this.size_).bind(this));
  }

  scrollIntoView(scrollOptions) {
    const alertDialog = this.shadowRoot.querySelector('div.mdc-dialog');
    if (alertDialog.scrollIntoView) {
      alertDialog.scrollIntoView(scrollOptions);
    }
  }

  size_() {
    const smallestWidth = window.outerWidth > window.innerWidth? window.innerWidth : window.outerWidth;
    const smallestHeight = window.outerHeight > window.innerHeight? window.innerHeight : window.outerHeight;

    const alertDialog = this.shadowRoot.querySelector('div.mdc-dialog');
    alertDialog.style.width = `${smallestWidth}px`;
    alertDialog.style.height = `${smallestHeight}px`;

    const scrim = this.shadowRoot.querySelector('div.mdc-dialog__scrim');
    scrim.style.width = `${smallestWidth}px`;
    scrim.style.height = `${smallestHeight}px`;
  }
}

customElements.define('dd-dialog', DdDialog);