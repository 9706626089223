/**
 * DOM utilities
 */
import { FAB_Y_OFFSET } from './fab-integration.js';

const HEADER_HEIGHT = 56;
const FOOTER_HEIGHT = 68;

export function cumulativeOffset(element) {
  let top = 0;
  let left = 0;
  let tempElement = element;

  do {
      top += tempElement.offsetTop  || 0;
      left += tempElement.offsetLeft || 0;
      tempElement = tempElement.offsetParent;
  } while(tempElement);

  return {
    top,
    left
  };
};

/**
 * scroll bottom of element to bottom of viewport with footer offset
 * @param {*} element
 * @param {*} pause to allow resize animation to complete before positioning
 */
export function offsetScrollToBottom(element, pause) {
  setTimeout(() => {
    const elementViewportBottom = element.getBoundingClientRect().bottom;
    const footerTopWindowY = window.innerHeight - FOOTER_HEIGHT - FAB_Y_OFFSET;
    const elementBottomDocY = elementViewportBottom - document.body.getBoundingClientRect().top;
    const elementBottomToFooterTopY = footerTopWindowY - elementViewportBottom;
    // reduce scroll by elementBottomToFooterTopY to bring element bottom to footer offset
    const top = elementBottomDocY - elementBottomToFooterTopY;

    window.scrollTo({
      behavior: 'smooth',
      top
    });

  }, pause);
}

/**
 * scroll element to top of viewport with header offset
 * @param {*} element
 * @param {*} pause to allow resize animation to complete before positioning
 */
 export function offsetScrollToTop(element, pause) {
  setTimeout(() => {

    const top = element.getBoundingClientRect().top - document.body.getBoundingClientRect().top - HEADER_HEIGHT;
    window.scrollTo({
      behavior: 'smooth',
      top
    })
  }, pause);
}
