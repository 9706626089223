/**
 * wrapper for firebase app and messaging SDK
 */
import * as firebase from "firebase/app";
import "firebase/messaging";

import { setFlag } from '../actions/app-flags.js';
import { requestSubscribeStoreTopic, requestUnsubscribeNotifications } from '../actions/api/notifications-api.js';
import { AppFlagProvider } from './app-flag-provider.js';
import { APP_FLAGS } from '../utilities/constants.js';

const VAPID_KEY = 'BIx-aB8izhEdB53U3v1sWE5b42oRD2ib1CXFYiC7u2eGU-O8phtviDhPTztZV2PiboPRbt0JPxS5uGI2_6rmpmA';
const DISABLED_TOKEN = 'disabled';

// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: "AIzaSyBCelXw4qh-2ms3yuOooZezyeqlM_XAa2c",
	authDomain: "tdd-web-app.firebaseapp.com",
	databaseURL: "https://tdd-web-app.firebaseio.com",
	projectId: "tdd-web-app",
	storageBucket: "tdd-web-app.appspot.com",
	messagingSenderId: "259252537224",
	appId: "1:259252537224:web:eeab90d8331c3026df1c3d",
	measurementId: "G-K37Z82X17W"
};

// Send the Instance ID token your application server, so that it can:
// - send messages back to this app
// - subscribe/unsubscribe the token from topics
function sendTokenToServer(currentToken, dispatch) {
	if (!AppFlagProvider.getFlag(APP_FLAGS.SENT_TO_SERVER)) {
		dispatch([
			requestSubscribeStoreTopic(currentToken),
			setFlag(APP_FLAGS.SENT_TO_SERVER, currentToken)
		]);
	} else {
		console.log('Token already sent to server so won\'t send it again ' +
			'unless it changes');
	}
}

function initializeFirebaseClient(messaging, dispatch) {
	messaging.usePublicVapidKey(VAPID_KEY);

	messaging.onTokenRefresh(() => {
		messaging.getToken().then((refreshedToken) => {
			console.log('Token refreshed.');
			// Indicate that the new Instance ID token has not yet been sent to the
			// app server.
			dispatch(setFlag(APP_FLAGS.SENT_TO_SERVER, refreshedToken));
			// Send Instance ID token to app server.
			sendTokenToServer(refreshedToken, dispatch);
		}).catch((err) => {
			console.log('Unable to retrieve refreshed token ', err);
		});
	});

	if (AppFlagProvider.getFlag(APP_FLAGS.DEBUG)) {
		messaging.onMessage((payload) => {
			console.log('Message received. ', payload);
		});
	}
}

export const NotificationService = {
  _messaging: null,
	_isSupported: false,

  initialize (dispatch) {
		if (navigator.serviceWorker) {
			const self = this;
			navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
				firebase.default.initializeApp(firebaseConfig);
				self._messaging = firebase.default.messaging();
				// use workbox service worker instead of firebase default
				self._messaging.useServiceWorker(serviceWorkerRegistration);

				if (firebase.default.messaging.isSupported()) {
					self._isSupported = true;
					initializeFirebaseClient(self._messaging, dispatch);
				}
			});
		}
  },

  getToken (dispatch) {
		if (this._isSupported) {
			this._messaging.getToken().then((currentToken) => {
				if (currentToken) {
					sendTokenToServer(currentToken, dispatch);
				} else {
					console.log('No Instance ID token available. Request permission to generate one.');
					dispatch(setFlag(APP_FLAGS.SENT_TO_SERVER, ''));
				}
			}).catch((err) => {
				console.log('An error occurred while retrieving token. ', err);
				dispatch(setFlag(APP_FLAGS.SENT_TO_SERVER, ''));
			});
		}
	},

	removeToken (dispatch) {
		if (this._isSupported) {
			const token = AppFlagProvider.getFlag(APP_FLAGS.SENT_TO_SERVER);
			if (token) {
				dispatch([
					requestUnsubscribeNotifications(token),
					setFlag(APP_FLAGS.SENT_TO_SERVER, DISABLED_TOKEN)
				]);
			}
		}
	},

	isEnabled () {
		const browserSupport = ('PushManager' in window);

		if (!browserSupport || !this._isSupported) {
			console.log('Notifications not supported');
		}

		return browserSupport && this._isSupported && (AppFlagProvider.getFlag(APP_FLAGS.SENT_TO_SERVER) !== DISABLED_TOKEN);
	}
}
